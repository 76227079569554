<template>
  <div>
    <!-- 服务项配置 -->
    <Drawer :title="title" ref="drawer" :button-is-show="buttonIsShow" :submit-form-name="submitFormName">
      <div style="
          height: 30px;
          line-height: 30px;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 10px;
          font-weight: 700;
          display: flex;
          justify-content: space-between;
        ">
        <div>
          可选服务项列表
          <span class="serviceSelect">
            <i class="el-icon-info"></i>
            本服务包已选择：6项服务，合计服务时长：6分钟，合计价值:0
          </span>
        </div>
        <div>
          <el-button icon="el-icon-plus" size="small" type="primary" @click="handleAdd">添加可选服务项</el-button>
        </div>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column label="选择服务项" prop="serviceItemsId">
          <template slot-scope="scope">
            <el-select v-model="scope.row.serviceItems" placeholder="请选择服务项">
              <el-option v-for="item in serviceItemsList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click="handleCancel(scope.row)" type="text" size="small">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </Drawer>
  </div>
</template>

<script>
import { Drawer } from "@/components";
export default {
  components: { Drawer },
  data() {
    return {
      submitFormName: "保存",
      buttonIsShow: true,
      tableData: [],
      title: "服务项配置",
      serviceItemsList: [
        {
          value: 1,
          label: "助洁",
        },
        {
          value: 2,
          label: "助乐",
        },
        {
          value: 3,
          label: "助餐",
        },
        {
          value: 4,
          label: "助急",
        },
      ],
      idS: ''
    };
  },
  created() {
    this.tableData.push({
      serviceItemsId: 1,
    });
  },
  mounted() {
    this.deletelivingetDataList()
  },
  methods: {
    deletelivingetDataList() {
      const form = {
        currentPage: 1,
        size: 10,
        query: {
          packageId: this.idS
        }
      }
      this.$store.dispatch("jbsz/deletelivingetDataList", form).then((res) => {
        this.ids.shift()
        this.dataRefresh()
      });
    },
    handleAdd() {
      const serviceItemsList = {
        serviceItemsId: undefined,
      };
      this.tableData.push(serviceItemsList);
    },
    handleCancel(row) {
      const index = this.tableData.indexOf(row);
      if (index !== -1) {
        this.tableData.splice(index, 1);
      }
    },
    open() {
      this.$refs.drawer.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.serviceSelect {
  color: #808080;
  font-size: 14px;
  margin-left: 20px;
}
</style>
