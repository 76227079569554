<template>
  <div>
    <!-- 添加/修改  政府购买服务批次 -->
    <Drawer
      :title="title"
      ref="drawer"
      :button-is-show="buttonIsShow"
      :submit-form-name="submitFormName"
    >
      <el-form
        size="small"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
        :label-position="labelPosition"
      >
        <!-- <div
          style="
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            font-weight: 700;
          "
        >
          添加政府购买服务批次
        </div> -->
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="应用区划:" prop="applicationZoning">
              <el-select
                v-model="ruleForm.applicationZoning"
                placeholder="请选择应用区划"
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="所属年度:" prop="year">
              <el-date-picker
                v-model="ruleForm.year"
                type="year"
                placeholder="选择所属年度"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="批次名称:" prop="batchName">
              <el-input
                placeholder="请输入批次名称"
                v-model="ruleForm.batchName"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="批次描述:" prop="batchDescription">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入批次描述"
                v-model="ruleForm.batchDescription"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="服务策略:" prop="servicePolicy">
          <el-select
            v-model="ruleForm.servicePolicy"
            placeholder="请选择服务策略"
          >
            <el-option
              label="策略B3：按实际服务价值进行账户扣款"
              value="1"
            ></el-option>
            <el-option label="策略D：每月固定时长服务" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label-width="150"
          label="批次有效日期:"
          prop="batchEffectiveDate"
        >
          <el-date-picker
            v-model="ruleForm.batchEffectiveDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </Drawer>
  </div>
</template>

<script>
import { Drawer } from "@/components";
export default {
  components: { Drawer },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cityList: [
        {
          id: 0,
          active: "first",
          name: "博山区",
        },
        {
          id: 1,
          active: "second",
          name: "淄川区",
        },
        {
          id: 2,
          active: "third",
          name: "张店区",
        },
        {
          id: 3,
          active: "five",
          name: "临淄区",
        },
        {
          id: 4,
          active: "six",
          name: "周村区",
        },
        {
          id: 5,
          active: "seven",
          name: "桓台县",
        },
        {
          id: 6,
          active: "eight",
          name: "高青县",
        },
        {
          id: 7,
          active: "nine",
          name: "沂源县",
        },
      ],
      subsidyAmountFlag: false,
      labelPosition: "left",
      subsidyTypeList: [
        {
          label: "60-79岁老人",
          value: 1,
        },
        {
          label: "市级特殊",
          value: 2,
        },
        {
          label: "80岁以上老人",
          value: 3,
        },
      ],
      rules: {},
      submitFormName: "保存",
      buttonIsShow: true,
      ruleForm: {
        applicationZoning: "", // 应用区划
        year: "", // 所属年度
        batchName: "", // 批次名称
        batchDescription: "", // 批次描述
        servicePolicy: "", // 服务策略
        batchEffectiveDate: "", // 批次有效日期
      },
    };
  },
  created() {},
  methods: {
    handleSelect(val) {
      if (val === "2") {
        this.subsidyAmountFlag = true;
      } else {
        this.subsidyAmountFlag = false;
      }
    },
    open() {
      // alert(22)
      this.$refs.drawer.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
