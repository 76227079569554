let jjfwgdStaticData = [
  {
      "a": "GD594674367595417602",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇许家村",
      "l": "许翠明",
      "m": "13754782807",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594674367637360640",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇许家村",
      "l": "许翠明",
      "m": "13754782807",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594674367654137856",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇许家村",
      "l": "许翠明",
      "m": "13754782807",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594674367767384064",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇许家村",
      "l": "许翠明",
      "m": "13754782807",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601167142912",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601167142913",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601167142914",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601167142915",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601192308736",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601196503040",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601196503041",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601196503042",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601204891648",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601204891649",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601204891650",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601204891651",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601213280256",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601217474560",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601217474561",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601217474562",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601225863168",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601225863169",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601225863170",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601225863171",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601251028992",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601251028993",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601259417600",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601263611904",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601272000512",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601272000513",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601280389120",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601280389121",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601309749248",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601309749249",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601318137856",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601318137857",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601318137858",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601326526464",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601326526465",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601326526466",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601334915072",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601334915073",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601334915074",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601364275200",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601364275201",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601364275202",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601372663808",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601372663809",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601372663810",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601381052416",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601381052417",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601381052418",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601389441024",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601418801152",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601427189760",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601435578368",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601443966976",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601443966977",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601443966978",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601473327104",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601473327105",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601473327106",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601481715712",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601485910016",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601485910017",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601494298624",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601494298625",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601494298626",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601498492928",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601498492929",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601498492930",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601523658752",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601523658753",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601532047360",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601536241664",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601544630272",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601544630273",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601553018880",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601553018881",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601561407488",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD594695601561407489",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "皇城镇油坊村",
      "l": "王振太",
      "m": "18206435578",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598259422158786560",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "崔兰英",
      "m": "13853367336",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598259422162980864",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "崔兰英",
      "m": "13853367336",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598259422162980865",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "崔兰英",
      "m": "13853367336",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598259422162980866",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "崔兰英",
      "m": "13853367336",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598259422162980867",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "崔兰英",
      "m": "13853367336",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598259422162980868",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "崔兰英",
      "m": "13853367336",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598259422171369473",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "崔兰英",
      "m": "13853367336",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598259422230089728",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "崔兰英",
      "m": "13853367336",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598259422230089729",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "崔兰英",
      "m": "13853367336",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598259422230089730",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "崔兰英",
      "m": "13853367336",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598260946033315840",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598260946033315841",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598260946033315842",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598260946033315843",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598260946041704448",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598260946045898752",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598260946071064576",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598260946071064577",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598260946079453184",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598260946079453185",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598260946087841792",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598260946096230400",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD598282928258945024",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "5.000000",
      "f": "5.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助浴（闻韶）",
      "q": "修剪指甲",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD602221724956495872",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "崔兰英",
      "m": "13853367336",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD602221724964884480",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "崔兰英",
      "m": "13853367336",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD602221725027799040",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "崔兰英",
      "m": "13853367336",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD602221725044576256",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "崔兰英",
      "m": "13853367336",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD602221725107490816",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "崔兰英",
      "m": "13853367336",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD602221725153628160",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "崔兰英",
      "m": "13853367336",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD602223292321763328",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD602223292330151936",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD602223292355317760",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD602223292372094976",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD602223292409843712",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD602223292468563968",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136106879324160",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136106879324161",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136106879324162",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136106879324163",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136106912878592",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136106912878593",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136106921267200",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136106938044416",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136106946433024",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136106946433025",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136106946433026",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136106946433027",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136106984181760",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136106984181761",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136106992570368",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136106992570369",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136106992570370",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136106992570371",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136107000958976",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605136107000958977",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605476051712086016",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "赵王村",
      "l": "薛桂芳",
      "m": "15264364238",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605476051712086017",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "赵王村",
      "l": "薛桂芳",
      "m": "15264364238",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605476051720474624",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "赵王村",
      "l": "薛桂芳",
      "m": "15264364238",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605476051720474625",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "赵王村",
      "l": "薛桂芳",
      "m": "15264364238",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605476051728863232",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "赵王村",
      "l": "薛桂芳",
      "m": "15264364238",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605476051728863233",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "赵王村",
      "l": "薛桂芳",
      "m": "15264364238",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605476051737251840",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "赵王村",
      "l": "薛桂芳",
      "m": "15264364238",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605476051737251841",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "赵王村",
      "l": "薛桂芳",
      "m": "15264364238",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605476051762417664",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "赵王村",
      "l": "薛桂芳",
      "m": "15264364238",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605476051762417665",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "赵王村",
      "l": "薛桂芳",
      "m": "15264364238",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605502679586574336",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "南门村",
      "l": "王秀云",
      "m": "13705336849",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605502679636905984",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "南门村",
      "l": "王秀云",
      "m": "13705336849",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605502679636905985",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "南门村",
      "l": "王秀云",
      "m": "13705336849",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605502679645294592",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "南门村",
      "l": "王秀云",
      "m": "13705336849",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605502679645294593",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "南门村",
      "l": "王秀云",
      "m": "13705336849",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605502679670460416",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "南门村",
      "l": "王秀云",
      "m": "13705336849",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605502679678849024",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "南门村",
      "l": "王秀云",
      "m": "13705336849",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605502679683043328",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "南门村",
      "l": "王秀云",
      "m": "13705336849",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605748963811659776",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "刘家寨村",
      "l": "王守信",
      "m": "13561606101",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605748963832631296",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "刘家寨村",
      "l": "王守信",
      "m": "13561606101",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605748963866185728",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "刘家寨村",
      "l": "王守信",
      "m": "13561606101",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605748963866185729",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "刘家寨村",
      "l": "王守信",
      "m": "13561606101",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605748963874574336",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "刘家寨村",
      "l": "王守信",
      "m": "13561606101",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605748963878768640",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "刘家寨村",
      "l": "王守信",
      "m": "13561606101",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605748963887157248",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "刘家寨村",
      "l": "王守信",
      "m": "13561606101",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605748963887157249",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "刘家寨村",
      "l": "王守信",
      "m": "13561606101",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605748963895545856",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "刘家寨村",
      "l": "王守信",
      "m": "13561606101",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605748963895545857",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "刘家寨村",
      "l": "王守信",
      "m": "13561606101",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605748963920711680",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "刘家寨村",
      "l": "王守信",
      "m": "13561606101",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605748963920711681",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "刘家寨村",
      "l": "王守信",
      "m": "13561606101",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605748963933294592",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "刘家寨村",
      "l": "王守信",
      "m": "13561606101",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605748963941683200",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "刘家寨村",
      "l": "王守信",
      "m": "13561606101",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414173450240",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414173450241",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414173450242",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414173450243",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414181838848",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414181838849",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414190227456",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414190227457",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414219587584",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414219587585",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414227976192",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414227976193",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414236364800",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414236364801",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414248947712",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414248947713",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414257336320",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414257336321",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414282502144",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414282502145",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414290890752",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414290890753",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414299279360",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414299279361",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414307667968",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414307667969",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414337028096",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414337028097",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414341222400",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605836414341222401",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989799067648",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989799067649",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989811650560",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989811650561",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989820039168",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989820039169",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989841010688",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989841010689",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989849399296",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989849399297",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989857787904",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989857787905",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989861982208",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989861982209",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989870370816",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989870370817",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989895536640",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989899730944",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989903925248",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989908119552",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989912313856",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989916508160",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989920702464",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989924896768",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989929091072",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989933285376",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989958451200",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989958451201",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989966839808",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD605838989966839809",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "石佛村委会",
      "l": "齐文江",
      "m": "13561630045",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327457349632",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327457349633",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327457349634",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327457349635",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327465738240",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327465738241",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327474126848",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327474126849",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327482515456",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327482515457",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327482515458",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327482515459",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327507681280",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327507681281",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327516069888",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327516069889",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327516069890",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327516069891",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327524458496",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327524458497",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327524458498",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327524458499",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327532847104",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327532847105",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327558012928",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327558012929",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327566401536",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327566401537",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327574790144",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606471327578984448",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇麻王村",
      "l": "朱云楼",
      "m": "13002701518",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398049026048",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398053220352",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398053220353",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398053220354",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398061608960",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398061608961",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398061608962",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398061608963",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398069997568",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398069997569",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398078386176",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398078386177",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398107746304",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398107746305",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398116134912",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398120329216",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398128717824",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398128717825",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398128717826",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398128717827",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398137106432",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398137106433",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398162272256",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398162272257",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398170660864",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398170660865",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398170660866",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398170660867",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398179049472",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD606479398179049473",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "朱台镇后夏村",
      "l": "齐金安",
      "m": "13573312691",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468064677888",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468064677889",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468073066496",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468073066499",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468081455104",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468081455105",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468127592448",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468135981056",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468135981057",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468135981058",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468135981059",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468144369664",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468144369665",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468173729792",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468173729793",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468182118400",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468182118401",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468182118402",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468182118403",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468190507008",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607294468190507009",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556424923222016",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556424923222017",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556424923222018",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556424923222019",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556424939999232",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556424939999233",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556424944193536",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556424973553664",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556424977747968",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556424986136576",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556424986136577",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556424986136578",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556424986136579",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556424994525184",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556424994525185",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556424994525186",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556424994525187",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556425002913792",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556425002913793",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556425028079616",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556425032273920",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556425040662528",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556425040662529",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556425049051136",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607556425049051137",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇刘王村",
      "l": "焦文利",
      "m": "13869382936",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607557818111627264",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607557818149376000",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607557818166153216",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607557818174541824",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607557818174541825",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607557818199707648",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607557818199707649",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607557818199707650",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607557818199707651",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607557818208096256",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607557818208096257",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607557818216484864",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607557818216484865",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607557818224873472",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607557818224873473",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607557818224873474",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607557818224873475",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607557818254233600",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607557818254233601",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686748893186",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686748893187",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686757281794",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686765670401",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686803419136",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686811807744",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686824390656",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686824390657",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686824390658",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686824390659",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686832779264",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686832779265",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686857945088",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686857945089",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686866333696",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686866333697",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686866333698",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686866333699",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686874722304",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607561686874722305",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607581349973200896",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607581349989978112",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607582023867830272",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607582023867830273",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607582023867830274",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607582023867830275",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607582023876218880",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607582023876218881",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607582023884607488",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607582023884607489",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607582023888801792",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607582023888801793",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607582023888801794",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607582023888801795",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607582023918161920",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607582023918161921",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607999993312055296",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "北罗村",
      "l": "王敬",
      "m": "13792191379",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607999993320443907",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "北罗村",
      "l": "王敬",
      "m": "13792191379",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607999993328832512",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "北罗村",
      "l": "王敬",
      "m": "13792191379",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD607999993328832513",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "北罗村",
      "l": "王敬",
      "m": "13792191379",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890034110464",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890034110465",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890034110466",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890034110467",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890034110468",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890034110469",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890034110470",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890034110471",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890042499072",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890046693376",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890046693377",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890046693378",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890055081984",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890055081985",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890055081986",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890055081987",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890055081988",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890055081989",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890080247808",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890080247809",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890080247810",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890080247811",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890080247812",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890080247813",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890088636416",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890088636417",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890088636418",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890088636419",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890101219328",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610469890101219329",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545353932800",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545353932801",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545353932802",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545353932803",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545353932804",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545353932805",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545353932806",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545353932807",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545362321408",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545362321409",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545362321410",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545362321411",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545374904320",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545374904321",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545374904322",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545374904323",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545374904324",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545374904325",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545400070144",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545400070145",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545400070146",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545400070147",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545400070148",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545400070149",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545412653056",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545412653057",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545412653058",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545412653059",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545421041664",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610471545421041665",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "粉庄村79号",
      "l": "王桂富",
      "m": "15092345286",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610488180810846208",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610488180810846209",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610488180810846210",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610488180819234817",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610488180819234818",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610488180831817728",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610488180831817729",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610488180831817733",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610488180856983553",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610488180856983554",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610488180877955072",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇钓鱼村",
      "l": "张金亮",
      "m": "17706389977",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610540004502802432",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610540004502802433",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610540004532162563",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610540004540551168",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610540004548939776",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610540004557328384",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610540004557328386",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610540004557328387",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610540004586688512",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇李家东村",
      "l": "魏培义",
      "m": "18678175877",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610561851881558016",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610561851881558017",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610561851910918144",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610561851919306752",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610561851927695360",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610561851936083968",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610561851936083973",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610561851944472576",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇褚家村",
      "l": "鲍春西",
      "m": "15505331155",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610562761907113984",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "20.000000",
      "f": "20.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "室内基础清洁",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610562761915502592",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "16.000000",
      "f": "16.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助洁（闻韶）",
      "q": "清洗衣物",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610562761936474112",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610562761936474113",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "家庭医生巡诊",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610562761944862720",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610562761953251328",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610562761953251329",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610562761953251332",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610562761953251333",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610562761961639937",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇东柳村",
      "l": "王本元",
      "m": "13969378522",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610829598897082370",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610829598897082371",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助医（闻韶）",
      "q": "健康管理",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610829598905470978",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610829598905470979",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "10.000000",
      "f": "10.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助乐（闻韶）",
      "q": "情绪疏导/心理支持",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610829598913859584",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  },
  {
      "a": "GD610829598913859585",
      "b": "已完成",
      "c": "2023",
      "d": "上门服务",
      "e": "8.000000",
      "f": "8.000000",
      "g": "0.000000",
      "h": "0.000000",
      "i": "0.000000",
      "j": "1",
      "k": "敬仲镇蔡王村",
      "l": "高太保",
      "m": "18264325708",
      "n": "临淄区闻韶街道颐养服务中心",
      "o": "淄博中康爱邻里养老服务有限公司",
      "p": "助急(闻韶）",
      "q": "委托服务",
      "r": "",
      "s": "否",
      "t": "否",
      "u": "临淄区闻韶街道颐养服务中心",
      "": ""
  }
]


// 导出数据  
export default jjfwgdStaticData;
