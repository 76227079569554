<template>
  <div class="box">
    <!-- 头部显示 -->
    <div class="title">
      <div>
        <i class="el-icon-date"></i>
        <span>智能设备接入</span>
      </div>
    </div>
    <!-- 主页内容 -->
    <div class="mainContent">
      <!-- 搜索区域 -->
      <div class="search">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <!-- <el-form-item label="区划:">
            <el-cascader
              v-model="formInline.zoning"
              :options="zoningOptions"
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="签约组织:">
            <el-input
              v-model="formInline.user"
              placeholder="服务对象姓名"
              style="width: 12rem"
            ></el-input> -->
          <!-- </el-form-item> -->
          <el-form-item label="服务对象:">
            <el-input
              v-model="formInline.id"
              placeholder="服务对象"
              style="width: 12rem"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="设备编号:">
            <el-input
              v-model="formInline.id"
              placeholder="设备编号"
              style="width: 12rem"
            ></el-input>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="dataRefresh" size="mini"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- 表格 -->
      <div class="table">
        <hltableZ
          :columnEdit="table.columnEdit"
          :tableColumn="table.column"
          :apiurl="table.apiurl"
          :isPage="false"
          :staticData="table.staticData"
          :refreshFlag="table.refreshFlag"
          :selectedRows="table.selectedRows"
          :isChecked="table.isChecked"
          :isNumber="table.isNumber"
          :formSearch="formInline"
          :widthClick="'200%'"
        >
          <div slot-scope="selected" slot="EditColumn">
            <el-button type="text" size="small" @click="deviceAccess"
              >设置</el-button
            >
            <el-button type="text" size="small" @click="patrolInspection"
              >设备巡检</el-button
            >
          </div>
        </hltableZ>
      </div>
    </div>

    <!-- 设备接入 -->
    <Drawer
      :title="title"
      ref="drawerIshow"
      :drawerSize="drawerSize"
      :append-to-body="true"
    >
      <div class="deviceAccessContent">
        <div class="title">
          <div>
            <i class="el-icon-date"></i>
            <span>”XXX“已绑定设备</span>
          </div>
          <div>
            <el-button type="primary" size="mini" @click="deviceAccessIncrease"
              ><i class="el-icon-plus"></i>绑定设备</el-button
            >
          </div>
        </div>
        <div class="search">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            style="width: 100%"
          >
            <el-form-item label="设备名称:">
              <el-input
                v-model="formInline.user"
                placeholder="设备名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="设备编号:">
              <el-input
                v-model="formInline.id"
                placeholder="设备编号"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit" size="mini"
                >查询</el-button
              >
              <el-button
                @click="resetForm(formInline)"
                type="primary"
                size="mini"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="table">
          <hltableZ
            :columnEdit="deviceTable.columnEdit"
            :tableColumn="deviceTable.column"
            :apiurl="deviceTable.apiurl"
            :refreshFlag="deviceTable.refreshFlag"
            :selectedRows="deviceTable.selectedRows"
            :isChecked="deviceTable.isChecked"
            :isNumber="deviceTable.isNumber"
            :formSearch="formInline"
            :widthClick="'300%'"
          >
            <div slot-scope="selected" slot="EditColumn">
              <el-button type="text" size="small" @click="deviceAccessPut"
                >修改</el-button
              >
              <el-button type="text" size="small">解绑</el-button>
            </div>
          </hltableZ>
        </div>
        <Drawer
          :title="deviceAccessTitle"
          ref="drawerIshowDeviceAccess"
          :drawerSize="'50%'"
          :append-to-body="true"
        >
          <div class="information">
            <div class="title" style="margin-bottom: 1rem">
              <div>
                <span style="color: skyblue">设备信息</span>
              </div>
            </div>
            <div class="informationContent">
              <el-form
                :label-position="labelPosition"
                label-width="150px"
                :model="formLabelAlign"
                :inline="true"
                :rules="rules"
                ref="formLabelAlign"
                :hide-required-asterisk="false"
                style="display: flex; flex-wrap: wrap"
              >
                <el-form-item label="设备厂商:" style="width: 100%" required>
                  <el-select
                    v-model="formLabelAlign.idLei"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="设备类型:" style="width: 100%" required>
                  <el-select
                    v-model="formLabelAlign.idLei"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="设备型号:"
                  prop="name1"
                  style="width: 100%"
                >
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 40rem"
                  ></el-input>
                </el-form-item>
                <el-form-item label="产品分类:" style="width: 100%">
                  <el-select
                    v-model="formLabelAlign.idLei"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="设备序列号:"
                  prop="name"
                  style="width: 100%"
                >
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 40rem"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="紧急联系方式:"
                  prop="name1"
                  style="width: 100%"
                >
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 40rem"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="紧急联系方式(2):"
                  prop="name1"
                  style="width: 100%"
                >
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 40rem"
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注:" prop="beizhu" style="width: 100%">
                  <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="formLabelAlign.textarea"
                    style="width: 40rem"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="familyNumber">
            <div class="title" style="margin-bottom: 1rem">
              <div>
                <span style="color: skyblue">亲情号(根据设备支持而定)</span>
              </div>
            </div>
            <div class="familyNumberContent">
              <el-form
                :label-position="labelPosition"
                label-width="150px"
                :model="formLabelAlign"
                :inline="true"
                :rules="rules"
                ref="formLabelAlign"
                :hide-required-asterisk="false"
                style="display: flex; flex-wrap: wrap"
              >
                <el-form-item
                  label="亲情号(1):"
                  prop="name1"
                  style="width: 100%"
                >
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 19rem; margin-right: 0.8rem"
                    placeholder="请输入亲情人姓名"
                  ></el-input>
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 19rem"
                    placeholder="请输入亲情人联系电话"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="亲情号(2):"
                  prop="name1"
                  style="width: 100%"
                >
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 19rem; margin-right: 0.8rem"
                    placeholder="请输入亲情人姓名"
                  ></el-input>
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 19rem"
                    placeholder="请输入亲情人联系电话"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="亲情号(3):"
                  prop="name1"
                  style="width: 100%"
                >
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 19rem; margin-right: 0.8rem"
                    placeholder="请输入亲情人姓名"
                  ></el-input>
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 19rem"
                    placeholder="请输入亲情人联系电话"
                  ></el-input>
                </el-form-item>
                <el-form-item style="width: 100%; text-align: center">
                  <el-button type="primary" @click="onSubmit">保存</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </Drawer>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { hltableZ, Drawer } from "@/components";
export default {
  components: { hltableZ, Drawer },
  data() {
    return {
      title: "",
      deviceAccessTitle: "绑定设备",
      drawerSize: "100%",
      drawerIshow: false,
      drawerIshowDeviceAccess: false,
      tableShow: false,
      formInline: {
        user: "",
        estimate: "",
        zoning: "",
        id: "",
      },
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      zoningOptions: [
        {
          value: "zhinan",
          label: "指南",
          children: [
            {
              value: "shejiyuanze",
              label: "设计原则",
              children: [
                {
                  value: "yizhi",
                  label: "一致",
                },
                {
                  value: "fankui",
                  label: "反馈",
                },
                {
                  value: "xiaolv",
                  label: "效率",
                },
                {
                  value: "kekong",
                  label: "可控",
                },
              ],
            },
            {
              value: "daohang",
              label: "导航",
              children: [
                {
                  value: "cexiangdaohang",
                  label: "侧向导航",
                },
                {
                  value: "dingbudaohang",
                  label: "顶部导航",
                },
              ],
            },
          ],
        },
        {
          value: "ziyuan",
          label: "资源",
          children: [
            {
              value: "axure",
              label: "Axure Components",
            },
            {
              value: "sketch",
              label: "Sketch Templates",
            },
            {
              value: "jiaohu",
              label: "组件交互文档",
            },
          ],
        },
      ],
      //   主表格
      table: {
        staticData: [],
        columnEdit: false,
        column: [
          {
            label: "服务对象",
            prop: "a",
            checked: true,
          },
          {
            label: "身份证号",
            prop: "b",
            checked: true,
          },
          {
            label: "紧急电话",
            prop: "c",
            checked: true,
          },
          {
            label: "设备SN",
            prop: "d",
            checked: true,
          },
          {
            label: "设备型号",
            prop: "e",
            checked: true,
          },
          {
            label: "设备名称",
            prop: "f",
            checked: true,
          },
          {
            label: "工程",
            prop: "g",
            checked: true,
          },
          {
            label: "批次",
            prop: "h",
            checked: true,
          },
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },

      labelPosition: "right",
      formLabelAlign: {
        name: "",
        idLei: "",
        idCard: "",
        date1: "",
        textarea: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        region: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
      },
      // 设备接入数据位置
      deviceTable: {
        columnEdit: true,
        column: [
          {
            label: "老人姓名",
            prop: "oldName",
            checked: true,
          },
          {
            label: "设备sn号",
            prop: "2",
            checked: true,
          },
          {
            label: "设备号",
            prop: "organName",
            checked: true,
          },
          {
            label: "紧急联系电话",
            prop: "age",
            checked: true,
          },
          {
            label: "绑定日期",
            prop: "idCard",
            checked: true,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: true,
        isNumber: false,
      },
    };
  },
  mounted() {
    this.dataRefresh();
  },
  methods: {
    dataRefresh() {
      this.table.staticData = [
        {
          a: "吴玉美",
          b: "370305**********27",
          c: "13561672047",
          d: "868680051784965",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "吴玉美",
          b: "370305**********27",
          c: "13561672047",
          d: "867214051477122",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "张桂林",
          b: "370305**********13",
          c: "18953373578",
          d: "867214051480167",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "张桂林",
          b: "370305**********13",
          c: "18364357951",
          d: "868680051786820",
          e: "灵犀网关",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "徐敬春",
          b: "370305**********1X",
          c: "13869337819",
          d: "868680051787208",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "徐景华",
          b: "370305**********45",
          c: "13964302766",
          d: "867214051483781",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "徐景华",
          b: "370305**********45",
          c: "13964302766",
          d: "868680051778462",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "李国平",
          b: "370305**********17",
          c: "13678647958",
          d: "867214051477411",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "李国平",
          b: "370305**********17",
          c: "13678647958",
          d: "868680051781995",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },

        {
          a: "马思福",
          b: "370305**********17",
          c: "13156983713",
          d: "867214051073285",
          e: "",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "马思福",
          b: "370305**********17",
          c: "13156983713",
          d: "868680051789881",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "毕桂云",
          b: "370305**********27",
          c: "13884603242",
          d: "868680051787729",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "毕桂云",
          b: "370305**********27",
          c: "05337857227",
          d: "867214051490190",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "马维真",
          b: "370305**********21",
          c: "13969395653",
          d: "868680051787224",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "马维真",
          b: "370305**********21",
          c: "05337857227",
          d: "867214051478179",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "王凤娟",
          b: "370305**********45",
          c: "13864395472",
          d: "867214051090487",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "王凤娟",
          b: "370305**********45",
          c: "13864395472",
          d: "868680051786572",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "尹承业",
          b: "370922**********11",
          c: "13969317690",
          d: "867214051480233",
          e: "",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "尹承业",
          b: "370922**********11",
          c: "15069388053",
          d: "868680051775328",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "王春溪",
          b: "370305**********31",
          c: "15206681890",
          d: "867214051479417",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "王春溪",
          b: "370305**********31",
          c: "15206681890",
          d: "868680051785855",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "王春华",
          b: "370305**********49",
          c: "13675337244",
          d: "868680051785657",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "王春华",
          b: "370305**********49",
          c: "13675337244",
          d: "867214051483435",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "王国三",
          b: "370305**********18",
          c: "13964326976",
          d: "867214051479946",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "王国三",
          b: "370305**********18",
          c: "13964326976",
          d: "868680051786036",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "李作远",
          b: "370305**********1X",
          c: "13793338362",
          d: "867214051478203",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "李作远",
          b: "370305**********1X",
          c: "13793338362",
          d: "868680051775294",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "孙孟尧",
          b: "370305**********1X",
          c: "05337857227",
          d: "868680051790129",
          e: "灵犀网关",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "崔甲驹",
          b: "370305**********16",
          c: "13668837271",
          d: "867214051483823",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "崔甲驹",
          b: "370305**********16",
          c: "13668837271",
          d: "868680051784809",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "于景涛",
          b: "370305**********19",
          c: "13287013782",
          d: "868680051780443",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "于景涛",
          b: "370305**********19",
          c: "7884109",
          d: "867214051483344",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "杨美荣",
          b: "370305**********24",
          c: "13506443802",
          d: "868680051792992",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "扈本功",
          b: "370305**********1X",
          c: "15589313226",
          d: "868680051786598",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "扈本功",
          b: "370305**********1X",
          c: "15589313226",
          d: "867214051472750",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "李耀华",
          b: "370305**********23",
          c: "05337857227",
          d: "868680051784825",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "李耀华",
          b: "370305**********23",
          c: "15006435148",
          d: "867214051477163",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "冯其华",
          b: "370305**********18",
          c: "15314292161",
          d: "867214051477072",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "冯其华",
          b: "370305**********18",
          c: "15314292161",
          d: "868680051788321",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "王义英",
          b: "370305**********28",
          c: "13475597352",
          d: "868680051786143",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "王义英",
          b: "370305**********28",
          c: "13475597352",
          d: "867214051485059",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "邵美香",
          b: "370305**********2X",
          c: "13518646878",
          d: "867214051076528",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "邵美香",
          b: "370305**********2X",
          c: "13518646878",
          d: "868680051793099",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "张士君",
          b: "370305**********14",
          c: "05337857227",
          d: "867214051478724",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "张士君",
          b: "370305**********14",
          c: "18553321585",
          d: "868680051788040",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "于立德",
          b: "370305**********18",
          c: "13969378625",
          d: "867214051486719",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "于立德",
          b: "370305**********18",
          c: "13969378625",
          d: "868680051792950",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "孙崇怀",
          b: "370305**********18",
          c: "13475517537",
          d: "867214051477510",
          e: "智能胸卡",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "孙崇怀",
          b: "370305**********18",
          c: "13475517537",
          d: "868680051793255",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "于荣华",
          b: "370305**********1X",
          c: "18764333611",
          d: "868680051787521",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "于荣华",
          b: "370305**********1X",
          c: "18764333611",
          d: "867214051076601",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "郑秀双",
          b: "370305**********41",
          c: "18453316259",
          d: "868680051787349",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "郑秀双",
          b: "370305**********41",
          c: "18453316259",
          d: "867214051479763",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "王海臣",
          b: "370305**********37",
          c: "13678637095",
          d: "868680051784338",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "王海臣",
          b: "370305**********37",
          c: "13678637095",
          d: "867214051074507",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "张绍芬",
          b: "370305**********21",
          c: "13475597352",
          d: "867214051483377",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "张绍芬",
          b: "370305**********21",
          c: "13475597352",
          d: "868680051782373",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "杨林英",
          b: "370305**********24",
          c: "13589513326",
          d: "867214051076346",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "杨林英",
          b: "370305**********24",
          c: "13589513326",
          d: "868680051786978",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "田淑海",
          b: "370305**********1X",
          c: "13455329111",
          d: "868680051786515",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "田淑海",
          b: "370305**********1X",
          c: "13455329111",
          d: "867214051479193",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "李庆贵",
          b: "370305**********10",
          c: "13869392727",
          d: "867214051490141",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "李庆贵",
          b: "370305**********10",
          c: "13869392727",
          d: "868680051787653",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "李杰",
          b: "370305**********14",
          c: "13906437576",
          d: "868680051788016",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "李杰",
          b: "370305**********14",
          c: "05337781309",
          d: "867214051487147",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "王连滨",
          b: "370305**********14",
          c: "15653320353",
          d: "868680051786556",
          e: "",
          f: "合睿灵犀-居家安全-居家健康",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "王连滨",
          b: "370305**********14",
          c: "15653320353",
          d: "867214051483393", 
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
        {
          a: "孙翠真",
          b: "370305**********48",
          c: "13082711920",
          d: "867214051483575",
          e: "智能胸牌",
          f: "几米物联-无线终端-紧急联系定位终端",
          g: "临淄区家庭养老床位",
          h: "[2022] 临淄区家庭养老床位",
        },
      ];
      this.table.refreshFlag = new Date().getTime().toString();
    },
    // 级联事件
    handleChange(value) {
      console.log(value);
    },
    // 表单提交
    onSubmit() {
      console.log("submit!");
    },

    // 表单重置
    resetForm() {
      const th = this;
      Object.keys(th.formInline).forEach(function (key) {
        if (key == "value") {
          th.formInline.value = "1";
        } else {
          th.formInline[key] = "";
        }
      });
    },

    // 设备接入
    deviceAccess() {
      this.title = "设备接入";
      this.$refs.drawerIshow.drawer = true;
    },
    // 设备接入添加/修改
    deviceAccessIncrease() {
      this.deviceAccessTitle = "绑定设备";
      this.$refs.drawerIshowDeviceAccess.drawer = true;
    },
    deviceAccessPut() {
      this.deviceAccessTitle = "修改设备";
      this.$refs.drawerIshowDeviceAccess.drawer = true;
    },
    close() {
      this.$refs.drawerIshow.drawer = false;
    },
  },
};
</script>

<style lang="scss">
.box {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.title {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  background: white;
  border-radius: 0.5rem;
  font-size: 1.5rem;

  i {
    margin-right: 0.5rem;
  }
}

.mainContent {
  width: 100%;
  height: calc(100% - 4rem);
  background: white;
  border-radius: 0.5rem;

  .search {
    width: 100%;
    padding-top: 1rem;
    padding-left: 1rem;
    height: 4rem;
  }

  .demo-form-inline {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .table {
    width: 100%;
    height: calc(100% - 5rem);
  }

  .el-select {
    width: 7rem;
  }
}

.deviceAccessContent {
  .search {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
  }

  .table {
    width: 100%;
    height: 30rem;
    margin: 1rem 0 2rem 0;
  }
}

.el-select {
  width: 40rem;
}
</style>
