<!-- 资金流水 -->
<template>
  <div class="hl-full-container flexVertical">
    <!-- 上方标题 -->
    <div class="topTitle">
      <div>
        <i class="el-icon-film"></i>
        <span class="title">资金流水</span>
      </div>
    </div>
    <!-- 金额流水和表格 -->
    <div class="systemFlow">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="item in tabsLsit" :key="item.label" :label="item.label" :name="item.name">
          <template v-if="item.name === activeName">
            <!-- 金额流水 -->
            <div class="money">
              <span v-if="activeName === 'second'" class="desc">仅记录线下支付流水</span>
              <div class="nameNumber" v-for="item in moneyList" :key="item.name">
                <span class="money_name">{{ item.name }}</span>
                <span class="money_number">{{ item.number }}元</span>
              </div>
            </div>
            <!-- 系统内查询 -->
            <div v-if="activeName === 'first'" class="search">
              <el-form :model="query" ref="query" label-width="80px" inline>
                <el-form-item label="助餐站点" prop="buffetId">
                  <el-select v-model="query.buffetId" placeholder="请选择助餐站点">
                    <el-option v-for="item in buffetList" :key="item.id" :label="item.buffetName"
                      :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="日期" prop="date">
                  <el-date-picker v-model="query.date" type="daterange" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="" prop="name">
                  <el-input v-model="query.name" placeholder="请输入老人姓名"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button size="small" type="primary" icon="el-icon-search" @click="dataRefresh">查询</el-button>
                  <el-button size="small" icon="el-icon-refresh-left" @click="reset">重置</el-button>
                  <!-- <el-button size="small" type="info" :icon="searchIsShow == false
                    ? 'el-icon-arrow-down'
                    : 'el-icon-arrow-up'
                    " @click="more">更多</el-button> -->
                  <el-button size="small" type="info" plain icon="el-icon-download" @click="derive">导出</el-button>
                </el-form-item>
              </el-form>
            </div>
            <!-- 系统外查询 -->
            <div v-else>
              <el-form :model="query" ref="query" label-width="80px" inline>
                <el-form-item label="" prop="mealLocation">
                  <el-select v-model="query.mealLocation" placeholder="请选择">
                    <el-option v-for="item in mealLocationOptions" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="日期" prop="date" label-width="40px">
                  <el-date-picker v-model="query.date" type="daterange" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="客户类型" prop="customType">
                  <el-select v-model="query.customType" placeholder="请选择客户类型">
                    <el-option v-for="item in flowOptions" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="支付方式" prop="payType">
                  <el-select v-model="query.rechargeType" placeholder="请选择支付方式">
                    <el-option v-for="item in flowOptions" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <template v-if="searchIsShow">
                  <el-form-item label="">
                    <el-input v-model="query.name" placeholder="请输入老人姓名"></el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model="query.phone" placeholder="请输入手机号"></el-input>
                  </el-form-item>
                </template>
                <el-form-item>
                  <el-button size="small" type="primary" icon="el-icon-search" @click="dataRefresh">查询</el-button>
                  <el-button size="small" icon="el-icon-refresh-left" @click="reset">重置</el-button>
                  <el-button size="small" type="info" plain icon="el-icon-download" @click="derive">导出</el-button>
                </el-form-item>
              </el-form>
            </div>
            <!-- 表格 -->
            <hltableZ :columnEdit="table.columnEdit" :tableColumn="table.column" :apiurl="table.apiurl"
              :refreshFlag="table.refreshFlag" :isChecked="table.isChecked" :isNumber="table.isNumber"
              :isClickSelect="table.isClickSelect" :formSearch="query"></hltableZ>
          </template>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
import { moneyFlowTablesData } from "./statisticsTablesData";
const moneyList = [
  { name: "总消费额", number: 111 },
  { name: "总充值额", number: 222 },
  { name: "总退单额", number: 333 },
  { name: "总退款额", number: 444 },
];
export default {
  name: "Money",
  components: { hltableZ },
  data() {
    return {
      activeName: "first",
      tabsLsit: [
        { label: "系统余额流水", name: "first" },
        // { label: "系统外支付流水", name: "second" },
      ],
      moneyList: [...moneyList],
      // 助餐站点options
      mealLocationOptions: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "1",
          label: "辛店街道于家店村长者食堂",
        },
        {
          value: "2",
          label: "齐都镇东古村长者食堂",
        },
      ],
      // 流水状态
      flowOptions: [
        {
          value: "1",
          label: "消费",
        },
        {
          value: "2",
          label: "充值",
        },
        {
          value: "3",
          label: "退单",
        },
      ],
      table: { ...moneyFlowTablesData.first },
      // 搜索条件显隐
      searchIsShow: false,
      query: {
        buffetId: "",

      },
      buffetList: []
    };
  },

  mounted() {
    this.buffetCapitalgetcollect()
    this.buffet()
  },

  methods: {
    buffet() {
      this.$store.dispatch("homePage/buffet", {}).then((res) => {
        this.buffetList = res.result
        this.$refs.memberInfo.buffetList = res.result
      })
    },
    // 查询
    dataRefresh() { this.table.refreshFlag = new Date().getTime().toString(); this.buffetCapitalgetcollect() },
    // 重置
    reset() {
      this.$refs.query.forEach(item => item.resetFields())
    },
    // 显示更多
    more() {
      this.searchIsShow = !this.searchIsShow;
    },
    // 导出
    derive() { },
    // 选项卡切换
    handleClick() {
      this.searchIsShow = false;
      this.table = { ...moneyFlowTablesData[this.activeName] };
      this.reset()
    },
    timeFormatter(row, column, cellValue) {
      return cellValue ? this.$timestampTostr(cellValue) : "";
    },
    buffetCapitalgetcollect() {
      this.$store
        .dispatch("homePage/buffetCapitalGetcollect", this.query)
        .then((response) => {
          if (response.success) {
            this.moneyList[0].number = response.result.consume
            this.moneyList[1].number = response.result.recharge
            this.moneyList[2].number = response.result.chargeback
            this.moneyList[3].number = response.result.refund
          }
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.hl-full-container {
  font-size: 1rem;
  color: #606266;

  // 上方标题
  .topTitle {
    border-bottom: 1px solid #eee;
    padding-bottom: 0.3125rem;

    .el-icon-film {
      font-size: 0.875rem;
      margin-right: 0.1875rem;
    }

    .title {
      font-size: 1rem;
    }
  }

  // 流水金额
  .systemFlow {
    flex: 1;
    display: flex;
    flex-direction: column;

    // 金额
    .money {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid #eee;
      padding-bottom: 0.3125rem;
      margin-bottom: 0.9375rem;

      span {
        font-size: 0.875rem;
        color: #aaa;
        font-weight: bold;
      }

      // 类型和金额
      .nameNumber {
        width: 9.375rem;
        text-align: center;
        font-weight: bold;

        .money_name {
          font-size: 1rem;
          color: #409eff;
          margin-right: 0.625rem;
        }

        .money_number {
          font-size: 1rem;
          color: red;
        }
      }
    }

    // 表格自适应高度样式
    ::v-deep .el-tabs.el-tabs--top {
      flex: 1;
      display: flex;
      flex-direction: column;

      .el-tabs__content {
        flex: 1;
        display: flex;
        flex-direction: column;

        .el-tab-pane {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
</style>
