<template>
  <div>
    <!-- 基本信息 -->
    <Drawer
      :title="title"
      ref="drawer"
      :button-is-show="true"
      :submit-form-name="submitFormName"
      @submitForm="submitForm('ruleForm')"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="订单号:" prop="orderNo">
          <el-input v-model="ruleForm.orderNo"></el-input>
        </el-form-item>
        <el-form-item label="服务对象:" prop="serviceObject">
          <el-input v-model="ruleForm.serviceObject"></el-input>
        </el-form-item>
        <el-form-item label="服务组织:" prop="serviceOrganization">
          <el-input v-model="ruleForm.serviceOrganization"></el-input>
        </el-form-item>
        <el-form-item label="督办原因:" prop="supervisionReasons">
          <el-input
            type="textarea"
            :rows="3"
            v-model="ruleForm.supervisionReasons"
          ></el-input>
        </el-form-item>
      </el-form>
    </Drawer>
  </div>
</template>

<script>
import { Drawer } from "@/components";
export default {
  components: { Drawer },
  data() {
    return {
      submitFormName: "提交",
      title: "基本信息",
      ruleForm: {
        orderNo: "", // 订单号
        serviceObject: "", // 服务对象
        serviceOrganization: "", // 服务组织
        supervisionReasons: "", // 督办原因
      },
      rules: {
        orderNo: [{ required: true, message: "请输入订单号", trigger: "blur" }],
        serviceObject: [
          { required: true, message: "请输入服务对象", trigger: "blur" },
        ],
        serviceOrganization: [
          { required: true, message: "请输入服务组织", trigger: "blur" },
        ],
        supervisionReasons: [
          { required: true, message: "请填写督办原因", trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSave() {},
    open() {
      this.$refs.drawer.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
