<template>
  <div>
    <!-- 添加/修改  政府购买服务批次 -->
    <Drawer :title="title" ref="drawer" :button-is-show="buttonIsShow" :submit-form-name="submitFormName"
      @submitForm="submitForm">
      <el-form size="small" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm"
        :label-position="labelPosition">
        <!-- <div
          style="
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            font-weight: 700;
          "
        >
          添加政府购买服务批次
        </div> -->
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="应用区划:" prop="applicationZoning">
              <el-cascader v-model="ruleForm.area" :options="options" :props="optionProps"
                @change="handleBaseValue"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="所属年度:" prop="year">
              <el-date-picker v-model="ruleForm.year" type="year" placeholder="选择所属年度" value-format="yyyy">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="批次名称:" prop="batchName">
              <el-input placeholder="请输入批次名称" v-model="ruleForm.batchName">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="批次描述:" prop="describe">
              <el-input type="textarea" :rows="2" placeholder="请输入批次描述" v-model="ruleForm.describe">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-form-item label="服务策略:" prop="servicePolicy">
          <el-select v-model="ruleForm.strategy" placeholder="请选择服务策略">
            <el-option v-for='(item, index) in livingBatchgetLists' :label="item.describe" :value="item.id"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label-width="150" label="批次有效日期:" prop="batchEffectiveDate">
          <el-date-picker v-model="ruleForm.startTime" type="date" placeholder="选择日期" value-format="timestamp">
          </el-date-picker> - <el-date-picker v-model="ruleForm.endTime" type="date" placeholder="选择日期"
            value-format="timestamp">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </Drawer>
  </div>
</template>

<script>
import { Drawer } from "@/components";
export default {
  components: { Drawer },
  props: {
    title: {
      type: String,
      default: "",
    },
    // datahand: {
    //   type: Object,
    // }
  },
  // watch: {
  //   title: {
  //     handler(newValue, oldvalue) {
  //       if (newValue === '添加政府购买服务批次') {
  //         const th = this
  //         Object.keys(th.ruleForm).forEach(function (key) {
  //           th.ruleForm[key] = ''
  //         })
  //       } else {
  //         const th = this
  //         Object.keys(th.ruleForm).forEach(key => {
  //           th.ruleForm[key] = th.datahand[key] || th.ruleForm[key]
  //         })
  //       }
  //     },
  //     deep: true,
  //     immediate: true
  //   }
  // },
  data() {
    return {
      options: [],
      optionProps: {
        value: 'pcode',
        label: 'name',
        children: 'children'
      },
      message: '',
      subsidyAmountFlag: false,
      labelPosition: "left",
      subsidyTypeList: [
        {
          label: "60-79岁老人",
          value: 1,
        },
        {
          label: "市级特殊",
          value: 2,
        },
        {
          label: "80岁以上老人",
          value: 3,
        },
      ],
      rules: {},
      submitFormName: "保存",
      buttonIsShow: true,
      ruleForm: {
        area: "", // 应用区划
        year: "", // 所属年度
        batchName: "", // 批次名称
        describe: "", // 批次描述
        strategy: "", // 服务策略
        startTime: "", // 批次有效日期
        endTime: "",
        id: ''
      },
      livingBatchgetLists: []
    };
  },
  created() { },
  mounted() {
    this.livingBatchgetList()
    this.getDataTree()
  },
  methods: {
    getDataTree() {
      this.$store.dispatch("area/getDataTree",{
          areaCode: this.$store.getters.user.areaCode,
        }).then((res) => {
        this.options = res.result
      })
    },
    handleBaseValue(val) {
      console.log(val[val.length - 1])
      this.ruleForm.area = val[val.length - 1];
    },
    handleSelect(val) {
      if (val === "2") {
        this.subsidyAmountFlag = true;
      } else {
        this.subsidyAmountFlag = false;
      }
    },
    open() {
      // alert(22)
      this.$refs.drawer.drawer = true;
    },
    livingBatchgetList() {
      this.$store.dispatch("jbsz/livingBatchgetList").then((res) => {
        this.livingBatchgetLists = res.result
      })
    },
    submitForm() {
      this.$store.dispatch("jbsz/livingBatchsaveData", this.ruleForm).then((res) => {
        this.$message({
          message: this.message,
          type: 'success'
        });
        this.$refs.drawer.drawer = false
        const th = this
        Object.keys(th.ruleForm).forEach(function (key) {
          th.ruleForm[key] = ''
        })
        this.$emit('addsubmitForm')
      })
    }
  },
};
</script>

<style lang="scss" scoped></style>
