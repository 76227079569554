<template>
  <div class="hl-full-container flexVertical">
    <div class="search">
      <el-form :model="query" ref="query" label-width="120px" inline>
        <el-form-item label="机构名称">
          <el-input
            v-model="query.organName"
            placeholder="请输入机构名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="社会信用代码">
          <el-input
            v-model="query.code"
            placeholder="请输入社会信用代码"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人代表人">
          <el-input
            v-model="query.legalPerson"
            placeholder="请输入法人代表人"
          ></el-input>
        </el-form-item>

        <template v-if="searchIsShow">
          <el-form-item label="区域" prop="kqzt">
            <el-cascader
              v-model="query.areaName"
              :options="options"
              @change="handleBaseValue"
              :props="optionProps"
            ></el-cascader>
          </el-form-item>
        </template>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 中间 -->
    <hltableZ
      style="margin-top: 10px"
      :columnEdit="table.columnEdit"
      :tableColumn="table.column"
      :isPage="false"
      :staticData="table.staticData"
      :refreshFlag="table.refreshFlag"
      :selectedRows="table.selectedRows"
      :isChecked="table.isChecked"
      :widthClick="widthClick"
      :isNumber="table.isNumber"
      :formSearch="query"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-button
          icon="el-icon-edit"
          type="text"
          size="small"
          @click="compile(selected.singlerow, 2)"
          >编辑</el-button
        >
        <el-button
          icon="el-icon-delete"
          type="text"
          size="small"
          @click="deletes(selected.singlerow)"
          >删除</el-button
        >
        <el-button
          size="small"
          type="text"
          icon="el-icon-light-rain"
          @click="freeze(selected.singlerow)"
          >是否冻结</el-button
        >
        <el-button
          size="small"
          type="text"
          icon="el-icon-light-rain"
          @click="renClick(selected.singlerow)"
          >新增人员</el-button
        >
      </div>
    </hltableZ>
    <!-- 新增编辑 -->
    <Drawer
      :title="title"
      ref="drawerIshow2"
      :buttonIsShow="buttonIsShow"
      :submitFormName="submitFormName"
      @submitForm="submitForm"
    >
      <el-form
        :model="ruleForm"
        :rules="chRules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div
          style="
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 500;
            border-bottom: 1px solid rgba(0, 0, 0, 0.85);
            margin-bottom: 10px;
          "
        >
          基本信息
        </div>
        <el-form-item label="机构名称:" prop="organName">
          <el-input
            v-model="ruleForm.organName"
            placeholder="请输入机构名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="社会信用代码:" prop="code">
          <el-input
            v-model="ruleForm.code"
            placeholder="请输入社会信用代码"
          ></el-input>
        </el-form-item>
        <el-form-item label="成立日期:" prop="createTime">
          <el-date-picker
            v-model="ruleForm.createTime"
            value-format="timestamp"
            type="date"
            placeholder="选择成立日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="法人:" prop="legalPerson">
          <el-input
            v-model="ruleForm.legalPerson"
            placeholder="请输入法人"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人身份证号:" prop="idCard">
          <el-input
            v-model="ruleForm.idCard"
            placeholder="请输入法人身份证号"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人电话:" prop="phone">
          <el-input
            v-model="ruleForm.phone"
            maxlength="11"
            type="text"
            show-word-limit
            placeholder="请输入法人电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="运营方式">
          <el-select v-model="ruleForm.operation" placeholder="请选择运营方式">
            <el-option key="1" label="公办民营" value="1"> </el-option>
            <el-option key="2" label="公办公营" value="2"> </el-option>
            <el-option key="3" label="民办民营" value="3"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属区划:" prop="areaName">
          <el-cascader
            v-model="ruleForm.areaName"
            :options="options"
            @change="handleBaseValue2"
            :props="optionProps"
            placeholder="请选择所属区划"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="服务金额:" prop="money">
          <el-input
            v-model="ruleForm.money"
            placeholder="请输入服务金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="服务次数:" prop="num">
          <el-input
            v-model="ruleForm.num"
            placeholder="请输入服务次数"
          ></el-input>
        </el-form-item>
        <el-form-item label="经度:" prop="lon">
          <el-input
            v-model="ruleForm.lon"
            placeholder="请输入经纬度"
          ></el-input>
        </el-form-item>
        <el-form-item label="纬度:" prop="lat">
          <el-input
            v-model="ruleForm.lat"
            placeholder="请输入经纬度"
          ></el-input>
        </el-form-item>
        <el-form-item label="机构地址:" prop="address">
          <el-input
            v-model="ruleForm.address"
            placeholder="请输入机构地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="机构简介:" prop="intro">
          <el-input
            v-model="ruleForm.intro"
            show-word-limit
            :autosize="{ minRows: 4, maxRows: 10 }"
            type="textarea"
            placeholder="请输入机构简介"
          ></el-input>
        </el-form-item>

        <el-form-item label="机构地址:" v-if="idhow == 2">
          <Apmp :lon="lon" :lat="lat"></Apmp>
        </el-form-item>
        <!-- <div
                    style="height: 30px;line-height: 30px;font-size: 16px;font-weight: 500;border-bottom: 1px solid rgba(0, 0, 0, .85);margin-bottom: 10px;">
                    场所信息</div>
                <el-form-item label="服务状态:" prop="fwzt">
                    <el-select v-model="ruleForm.fwzt" placeholder="请选择">
                        <el-option>
                        </el-option>
                    </el-select>
                </el-form-item> -->
        <!-- <el-form-item label="建筑面积:" prop="jzmj">
                    <el-input placeholder="请输入内容" v-model="ruleForm.jzmj">
                        <template slot="append">㎡</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="占地面积:" prop="zdmj">
                    <el-input placeholder="请输入内容" v-model="ruleForm.zdmj">
                        <template slot="append">㎡</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="服务设施面积:" prop="fwssmj">
                    <el-input placeholder="请输入内容" v-model="ruleForm.fwssmj">
                        <template slot="append">㎡</template>
                    </el-input>
                </el-form-item> -->
        <!-- <el-form-item label="服务场所性质:" prop="fwcsxz">
                    <el-select v-model="ruleForm.fwcsxz" placeholder="请选择">
                        <el-option>
                        </el-option>
                    </el-select>
                </el-form-item> -->
        <!-- <div
                    style="height: 30px;line-height: 30px;font-size: 16px;font-weight: 500;border-bottom: 1px solid rgba(0, 0, 0, .85);margin-bottom: 10px;">
                    服务信息</div>
                <el-form-item label="运营单位性质:" prop="yydwxz">
                    <el-select v-model="ruleForm.yydwxz" placeholder="请选择">
                        <el-option>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属位置:" prop="sswz">
                    <el-select v-model="ruleForm.sswz" placeholder="请选择">
                        <el-option>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="建设性质:" prop="jsxz">
                    <el-select v-model="ruleForm.jsxz" placeholder="请选择">
                        <el-option>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="运营方式:" prop="yyfs">
                    <el-select v-model="ruleForm.yyfs" placeholder="请选择">
                        <el-option>
                        </el-option>
                    </el-select>
                </el-form-item> -->
        <!-- <el-form-item label="收费标准:" prop="sfbz">
                    <el-input placeholder="请输入内容" v-model="ruleForm.sfbz">
                        <template slot="append">㎡</template>
                    </el-input>
                </el-form-item> -->
        <!-- <el-form-item label="是否有生活服务:" prop="shfw">
                    <el-radio v-model="ruleForm.shfw" label="1">是</el-radio>
                    <el-radio v-model="ruleForm.shfw" label="2">否</el-radio>
                </el-form-item>
                <el-form-item label="是否有康复护理服务:" prop="jkhlfw">
                    <el-radio v-model="ruleForm.jkhlfw" label="1">是</el-radio>
                    <el-radio v-model="ruleForm.jkhlfw" label="2">否</el-radio>
                </el-form-item><el-form-item label="是否有托养服务:" prop="tyfw">
                    <el-radio v-model="ruleForm.tyfw" label="1">是</el-radio>
                    <el-radio v-model="ruleForm.tyfw" label="2">否</el-radio>
                </el-form-item><el-form-item label="是否有家庭支持服务:" prop="jtzc">
                    <el-radio v-model="ruleForm.jtzc" label="1">是</el-radio>
                    <el-radio v-model="ruleForm.jtzc" label="2">否</el-radio>
                </el-form-item><el-form-item label="是否有康复辅具租赁服务:" prop="kffj">
                    <el-radio v-model="ruleForm.kffj" label="1">是</el-radio>
                    <el-radio v-model="ruleForm.kffj" label="2">否</el-radio>
                </el-form-item><el-form-item label="社会工作和心理疏导服务:" prop="shgzxlsd">
                    <el-radio v-model="ruleForm.shgzxlsd" label="1">是</el-radio>
                    <el-radio v-model="ruleForm.shgzxlsd" label="2">否</el-radio>
                </el-form-item><el-form-item label="是否医保定点单位:" prop="ybdd">
                    <el-radio v-model="ruleForm.ybdd" label="1">是</el-radio>
                    <el-radio v-model="ruleForm.ybdd" label="2">否</el-radio>
                </el-form-item><el-form-item label="是否投保责任险:" prop="tbzrx">
                    <el-radio v-model="ruleForm.tbzrx" label="1">是</el-radio>
                    <el-radio v-model="ruleForm.tbzrx" label="2">否</el-radio>
                </el-form-item><el-form-item label="是否长护险定点机构:" prop="chxddjg">
                    <el-radio v-model="ruleForm.chxddjg" label="1">是</el-radio>
                    <el-radio v-model="ruleForm.chxddjg" label="2">否</el-radio>
                </el-form-item> -->
      </el-form>
    </Drawer>
    <Drawer :title="title" ref="drawerIshow3">
      <username
        style="
          position: absolute;
          z-index: 888;
          right: 0;
          bottom: 0;
          width: 98%;
        "
        :organIds="organId"
      ></username>
    </Drawer>
  </div>
</template>

<script>
import username from "./username.vue";
import { hltableZ, Drawer, Apmp } from "@/components";
export default {
  name: "fwsgl",
  components: { hltableZ, Drawer, username, Apmp },
  data() {
    // var _this = this
    // var Pass = (rule, value, callback) => {
    //     if (!value && _this.submitType == "add") {
    //         callback(new Error('请输入密码'));
    //     } else {
    //         if (this.formData.confirmpassword !== '') {
    //             this.$refs.elForm.validateField('confirmpassword');
    //         }
    //         callback();
    //     }
    // }
    // var PassConfirm = (rule, value, callback) => {
    //     if (!value && _this.submitType == "add") {
    //         callback(new Error('请再次输入密码'));
    //     } else if (value !== this.formData.password) {
    //         callback(new Error('两次输入密码不一致!'));
    //     } else {
    //         callback();
    //     }
    // }
    return {
      chRules: {
        organName: [
          { required: true, message: "请输入机构名称", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入社会信用代码", trigger: "blur" },
        ],
        createTime: [
          { required: true, message: "选择成立日期", trigger: "change" },
        ],
        legalPerson: [
          { required: true, message: "请输入法人", trigger: "blur" },
        ],
        idCard: [
          { required: true, message: "请输入法人身份证号", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请输入法人电话", trigger: "blur" }],
        operation: [
          { required: true, message: "请选择运营方式", trigger: "change" },
        ],
        areaName: [
          { required: true, message: "请选择所属区划", trigger: "change" },
        ],
        money: [{ required: true, message: "请输入服务金额", trigger: "blur" }],
        num: [{ required: true, message: "请输入服务次数", trigger: "blur" }],
        address: [
          { required: true, message: "请输入机构地址", trigger: "blur" },
        ],
      },
      optionProps: {
        value: "pcode",
        label: "name",
        children: "children",
      },
      query: { organName: "", areaName: "", code: "", legalPerson: "" },
      //   表格
      widthClick: "250",
      table: {
        staticData: [],
        columnEdit: false,
        column: [
          {
            label: "性质",
            prop: "a",
            checked: true,
            width: "150",
          },
          {
            label: "二级分类",
            prop: "b",
            checked: true,
            width: "200",
          },
          {
            label: "法人代表人",
            prop: "legalPerson",
            checked: true,
            width: "200",
          },
          {
            label: "名称",
            prop: "c",
            checked: true,
            width: "200",
          },
          {
            label: "简称",
            prop: "d",
            checked: true,
            width: "200",
          },
          {
            label: "编码",
            prop: "e",
            checked: true,
            width: "200",
          },
          {
            label: "所属区县",
            prop: "f",
            checked: true,
            width: "150",
          },
          {
            label: "所在街道/镇",
            prop: "g",
            checked: true,
            width: "150",
          },
          {
            label: "所在社区/村",
            prop: "h",
            checked: true,
            width: "150",
          },

          {
            label: "详细地址",
            prop: "i",
            checked: true,
            width: "150",
          },
          {
            label: "坐标经度",
            prop: "j",
            checked: true,
            width: "150",
          },
          {
            label: "坐标纬度",
            prop: "k",
            checked: true,
            width: "150",
          },
          {
            label: "服务星级",
            prop: "l",
            checked: true,
            width: "150",
          },
          {
            label: "负责人",
            prop: "m",
            checked: true,
            width: "150",
          },
          {
            label: "负责人电话",
            prop: "n",
            checked: true,
            width: "150",
          },
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
      },
      options: [
        {
          value: "zhinan",
          label: "闻韶街道",
          children: [
            {
              value: "yizhi",
              label: "南王镇",
            },
            {
              value: "fankui",
              label: "敬仲镇",
            },
            {
              value: "xiaolv",
              label: "梧台镇",
            },
            {
              value: "kekong",
              label: "朱台镇",
            },
          ],
        },
      ],
      drawer: false,
      direction: "rtl",
      descItem: [
        {
          name: "a",
          num: "b",
        },
        {
          name: "a",
          num: "b",
        },
        {
          name: "a",
          num: "b",
        },
        {
          name: "a",
          num: "b",
        },
        {
          name: "a",
          num: "b",
        },
        {
          name: "a",
          num: "b",
        },
        {
          name: "a",
          num: "b",
        },

        {
          name: "a",
          num: "b",
        },
        {
          name: "a",
          num: "b",
        },
        {
          name: "a",
          num: "b",
        },
      ],
      title: "",
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      radio: "1",
      //编辑
      ruleForm: {
        organName: "",
        code: "",
        dialogImageUrl: "",
        createTime: "",
        legalPerson: "",
        phone: "",
        areaName: "",
        money: "",
        num: "",
        address: "",
        intro: "",
        operation: "",
        organId: "",
        idCard: "",
        lon: "",
        lat: "",
      },
      dialogVisible: false,
      rules: {},
      buttonIsShow: true,
      submitFormName: "",
      searchList: {},
      searchIsShow: false,
      freezeIshow: true,
      dailogTitle: "",
      dialogFormVisible: false,
      organId: "",
      idhow: 2,
      lon: null,
      lat: null,
    };
  },

  mounted() {
    this.dataRefresh();
  },

  methods: {
    handleClose() {
      this.resetForm();
    },
    renClick(e) {
      this.organId = e.id;
      this.$refs.drawerIshow3.drawer = true;
    },
    resetForm() {
      const th = this;
      Object.keys(this.formData).forEach(function (key) {
        th.formData[key] = "";
      });
    },
    submitFormT() {},
    handleBaseValue(val) {
      this.query.areaName = val[val.length - 1];
    },
    handleBaseValue2(val) {
      this.ruleForm.areaName = val[val.length - 1];
    },
    getDataTree() {
      this.$store
        .dispatch("area/getDataTree", {
          areaCode: this.$store.getters.user.areaCode,
        })
        .then((res) => {
          this.options = res.result;
        });
    },
    //   查询
    dataRefresh() {
      this.table.staticData = [
        {
          a: "服务企业",
          b: "服务机构",
          c: "淄博市齐佳社会工作服务中心",
          d: "齐佳社会工作服务中心",
          f: "临淄区",
          g: "闻韶街道",
          h: "西王社区居委会",
          i: "山东理工大学大红炉众创空间三楼81座",
          j: 118.016361550842,
          k: 36.8151294583958,
        },
        {
          a: "服务企业",
          b: "服务机构",
          c: "临淄区义家人社会工作服务中心",
          d: "义家人社会工作服务中心",
          f: "临淄区",
          g: "闻韶街道",
          h: "勇士社区居委会",
          i: "淄博市临淄区闻韶街道勇士生活区36号楼",
          j: 118.332285,
          k: 36.819915,
          m: "陈宝明",
          n: 15762850566,
        },
        {
          a: "服务企业",
          b: "服务机构",
          c: "淄博市临淄区蓝丝带社会服务中心",
          d: "蓝丝带社会服务中心",
          f: "临淄区",
          g: "闻韶街道",
          h: "辛东社区居委会",
          i: "淄博市临淄区闻韶街道辛东社区",
          j: 118.333778,
          k: 36.814108,
          m: "亓艳红",
          n: 13589567301,
        },
        // {
        //   a: "服务企业",
        //   b: "服务机构",
        //   c: "高新区居家养老服务中心",
        //   d: "高新区居家养老服务中心",
        //   f: "桓台县",
        //   g: "少海街道",
        //   h: "东岳国际社区居委会",
        //   i: "",
        //   j: 118.095685700256,
        //   k: 36.9588369460706,
        // },
        // {
        //   a: "服务企业",
        //   b: "服务机构",
        //   c: "善行佳豪养老服务运营中心",
        //   d: "善行佳豪养老服务运营中心",
        //   f: "桓台县",
        //   g: "少海街道",
        //   h: "东岳国际社区居委会",
        //   i: "桓台县东岳路弈景嘉园969号",
        //   j: 118.095685700256,
        //   k: 36.9588369460706,
        // },
        {
          a: "服务企业",
          b: "服务机构",
          c: "郑大姐服务组织",
          d: "郑大姐服务组织",
          f: "临淄区",
          g: "闻韶街道",
          h: "西王社区居委会",
          i: "",
          j: 118.315394609345,
          k: 36.8322936351522,
        },
        {
          a: "服务企业",
          b: "服务机构",
          c: "青岛市12349公共服务中心",
          d: "12349公共服务中心",
          f: "临淄区",
          g: "闻韶街道",
          h: "遄台社区居委会",
          i: "山东省青岛市市南区南京路27号",
          j: 120.396642174209,
          k: 36.0749544880814,
          m: "彭勃",
          n: 15095295829,
        },
      ];
      this.table.refreshFlag = new Date().getTime().toString();
    },
    timeFormatter(row, column, cellValue) {
      return cellValue ? this.$timestampTostr(cellValue) : "";
    },
    reset() {
      const th = this;
      Object.keys(th.query).forEach(function (key) {
        if (key == "value") {
          th.query.value = "1";
        } else {
          th.query[key] = "";
        }
      });
      this.dataRefresh();
    },
    compile(e, a) {
      this.lon = e.lon;
      this.lat = e.lat;
      console.log(9999, e);
      this.idhow = a;
      this.title = "编辑";
      this.submitFormName = "修改";
      Object.keys(this.ruleForm).forEach((key) => {
        this.ruleForm[key] = e[key] || this.ruleForm[key];
      });
      this.ruleForm.id = e.id;
      this.$refs.drawerIshow2.drawer = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    add(e) {
      this.idhow = e;
      this.title = "新增";
      this.submitFormName = "保存";
      const th = this;
      Object.keys(th.ruleForm).forEach(function (key) {
        th.ruleForm[key] = "";
      });
      this.$refs.drawerIshow2.drawer = true;
    },
    deletes(e) {
      console.log(e);
      this.$confirm("确定将选择数据删除?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store
            .dispatch("fwjg/deleteData", { ids: [e.id] })
            .then((response) => {
              if (response.success) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.dataRefresh();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    freeze(e) {
      console.log(e);
      this.$confirm("确定冻结此服务商?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var status = "2";
          const data = {
            id: e.id,
            status: status,
          };
          this.$store.dispatch("fwjg/saveData", data).then((res) => {
            this.freezeIshow = false;
            this.dataRefresh();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消冻结",
          });
        });
    },
    recover(e) {
      console.log(e);
      this.$confirm("确定恢复此服务商?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var status = "1";
          const data = {
            id: e.id,
            status: status,
          };
          this.$store.dispatch("fwjg/saveData", data).then((res) => {
            this.freezeIshow = false;
            this.dataRefresh();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消恢复",
          });
        });
    },
    async derive() {
      const token = await this.$store.dispatch("user/getToken");
      this.$axios({
        method: "post",
        url: "/api/organization/doDownLoad",
        data: this.query,
        headers: {
          token: token,
          platform: "web",
        },
        responseType: "blob",
      })
        .then((response) => {
          if (response.headers["content-disposition"]) {
            const content = response.data;
            const blob = new Blob([content]); //构造一个blob对象来处理数据

            // fileName必用这种方式进行解析，否则乱码
            // Server.UrlEncode(filename);

            let contentDisposition = response.headers["content-disposition"];
            // fileName必用这种方式进行解析，否则乱码
            let fileName = window.decodeURI(
              contentDisposition.substring(contentDisposition.indexOf("=") + 1)
            );
            //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
            if ("download" in document.createElement("a")) {
              const link = document.createElement("a"); //创建a标签
              link.download = fileName; //a标签添加属性
              link.style.display = "none";
              link.href = URL.createObjectURL(blob);
              document.body.appendChild(link);
              link.click(); //执行下载
              URL.revokeObjectURL(link.href); //释放url
              document.body.removeChild(link); //释放标签
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    more() {
      this.searchIsShow = !this.searchIsShow;
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
        } else {
          this.$store.dispatch("fwjg/saveData", this.ruleForm).then((res) => {
            this.dataRefresh();
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";

::v-deep .el-table th,
::v-deep .el-table td {
  text-align: center !important;
}

.data {
  display: flex;
}

::v-deep .el-drawer__header {
  font-size: 20px;
  padding: 0;
}

::v-deep .el-drawer {
  padding: 10px;
}

/deep/.el-radio__input.is-disabled.is-checked .el-radio__inner {
  background-color: #409eff;

  // border-color: #E4E7ED;
}

.demo-ruleForm {
  width: 100%;

  ::v-deep .el-form-item {
    width: 49%;
  }

  ::v-deep .el-form-item__content {
    width: calc(100% - 100px);
  }

  ::v-deep .el-select {
    width: 100%;
  }

  ::v-deep .el-input__inner {
    height: 32px;
    width: 100%;
  }
}
</style>
