<template>
  <Drawer
    title="员工基础档案"
    ref="drawerIshow"
    :buttonIsShow="true"
    submitFormName="分配"
    @resetForm="resetForm"
  >
    <div class="batchAllocation">
      <subTitle information-name="授权职业"></subTitle>
      <div class="desc">此功能保留原有的职业授权，只完成职业的追加。</div>
      <div class="checkbox">
        <span class="title">授权职业</span>
        <el-checkbox-group v-model="checkedList">
          <el-checkbox
            style="display: block"
            v-for="item in careerList"
            :label="item.label"
            :key="item.id"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </div>
  </Drawer>
</template>

<script>
import { Drawer } from "@/components";
import subTitle from "../common/SubTitle";
export default {
  name: "BatchAllocation",
  components: { Drawer, subTitle },
  data() {
    return {
      checkedList: [],
      careerList: [
        { label: "家政服务员", id: 1 },
        { label: "养老护理员", id: 2 },
        { label: "康复理疗师", id: 3 },
        { label: "护士", id: 4 },
        { label: "医生", id: 5 },
        { label: "社工", id: 6 },
      ],
    };
  },

  mounted() {},

  methods: {
    resetForm() {
      this.checkedList = []
      this.$emit('close')
      console.log('111')
    }
  },
};
</script>

<style lang="scss" scoped>
.batchAllocation {
  font-size: 1rem;
  .checkedPeople {
    margin:  0.875rem auto;
  }
  .desc {
    font-size: 0.875rem;
    color: #d19405;
  }
  .checkbox {
    display: flex;
    margin-top: .9375rem;
    .title {
      margin-right: .9375rem;
    }
  }
}
</style>
