<template>
  <div>
    <div class="box-header">
      <div class="box-title"> <i class="el-icon-s-grid"></i> 规则修改记录</div>
    </div>
    <div class="jtcw1">
        <hltableZ
          :columnEdit="table1.columnEdit"
          :tableColumn="table1.column"
          :apiurl="table1.apiurl"
          :refreshFlag="table1.refreshFlag"
          :selectedRows="table1.selectedRows"
          :isChecked="table1.isChecked"
          :isNumber="table1.isNumber"
          :formSearch="query1"
        >
          <div slot-scope="selected" slot="EditColumn">
            <el-button
              icon="el-icon-delete"
              type="primary"
              size="small"
              @click="editing()"
              >详情</el-button
            >
          </div>
        </hltableZ>
    </div>
    <!-- 详情组件可复用需传不同值来进行判断 -->
    <Drawer :title="title" ref="drawerIshow" :drawerSize="drawerSize">
      <Details></Details>
    </Drawer>
  </div>
</template>

<script>
import { Drawer } from "@/components";
import Details from "./Details";
import { hltableZ } from "@/components";
export default {
  data() {
    return {
        drawerSize: "60%",
        title: "详细信息",
        table1: {
        columnEdit: true,
        column: [
          {
            label: "修改时间",
            prop: "oldName",
            checked: true,
          },
          {
            label: "修改人",
            prop: "organName",
            checked: true,
          },
          {
            label: "修改内容",
            prop: "oldName",
            checked: true,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      query1: {
        oldName: "",
        organ: "",
        sex: "",
        level: "",
        area: "",
        status: "",
        idCard: "",
        phone: "",
      },
    };
  },
  components: {hltableZ,Drawer,Details},
  mounted() {},
  methods: {
    editing(){
        this.$refs.drawerIshow.drawer = true;
    }
  },
};
</script>
<style scoped lang="scss">
 .box-header {
    width: 100%;
    height: 47px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 47px;
    .el-button--small {
      height: 30px !important;
    }
  }
  .jtcw1 {
      width: 100%;
      height: 40rem;
      margin-top: 1rem;
    }
  </style>
