<template>
  <Drawer
    title="修改"
    ref="drawerIshow"
    :buttonIsShow="true"
    @resetForm="resetForm"
    submitFormName="保存"
  >
    <el-row :gutter="24">
      <el-form class="form" ref="form" :model="dataForm" label-width="140px">
        <!-- 基本信息 -->
        <el-col :span="24">
          <subTitle information-name="基本信息"></subTitle>
        </el-col>
        <el-col :span="12">
          <el-form-item label="组织ID" prop="organizationId">
            <el-input v-model="dataForm.organizationId" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="社会统一信用代码" prop="creditCode">
            <el-input v-model="dataForm.creditCode"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="主体名称" prop="principalName">
            <el-input v-model="dataForm.creditCode"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="简称" prop="abbreviation">
            <el-input v-model="dataForm.creditCode"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="性质" prop="nature">
            <el-select
              v-model="dataForm.nature"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="item in natureOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="二级分类" prop="secondary">
            <el-select
              v-model="dataForm.secondary"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="item in secondaryOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item class="zoning" label="所在区划">
            <el-select
              v-model="dataForm.area"
              placeholder="请选择"
              fliterable
            >
              <el-option
                v-for="item in areaOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <span>-</span>
            <el-select
              v-model="dataForm.stree"
              placeholder="请选择"
              fliterable
            >
              <el-option
                v-for="item in streeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <span>-</span>
            <el-select
              v-model="dataForm.county"
              placeholder="请选择"
              fliterable
            >
              <el-option
                v-for="item in countyOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="详细地址" prop="address">
            <el-input v-model="dataForm.address"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="品牌化" prop="branding">
            <el-select
              v-model="dataForm.branding"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option label="有" value="1"></el-option>
              <el-option label="无" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="品牌名称" prop="brandingName">
            <el-input
              v-model="dataForm.brandingName"
              :disabled="dataForm.branding !== '1'"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="平台自营" prop="selfSupport">
            <el-select
              v-model="dataForm.selfSupport"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option label="属平台自营" value="1"></el-option>
              <el-option label="非平台自营" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="地理位置" prop="geographicLocation">
            <el-input v-model="dataForm.geographicLocation"></el-input>
          </el-form-item>
        </el-col>
        <!-- 法定代表人/负责人 -->
        <el-col :span="24">
          <subTitle information-name="法定代表人/负责人"></subTitle>
        </el-col>
        <el-col :span="12">
          <el-form-item label="公开负责人" prop="head">
            <el-input v-model="dataForm.head"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="负责人电话" prop="headPhone">
            <el-input v-model="dataForm.headPhone"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="负责人身份证" prop="headIDCard">
            <el-input v-model="dataForm.headIDCard"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="法定代表人" prop="legalPerson">
            <el-input v-model="dataForm.legalPerson"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="法人电话" prop="legalPersonPhone">
            <el-input v-model="dataForm.legalPersonPhone"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="法人身份证" prop="legalPersonIDCard">
            <el-input v-model="dataForm.legalPersonIDCard"></el-input>
          </el-form-item>
        </el-col>
        <!-- 其他 -->
        <el-col :span="24">
          <subTitle information-name="其他"></subTitle>
        </el-col>
        <el-col :span="12">
          <el-form-item label="评价分值" prop="evaluationScore">
            <el-input v-model="dataForm.evaluationScore"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="信誉分值" prop="reputationScore">
            <el-input v-model="dataForm.reputationScore"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电子签章是否有效" prop="signaturesEffective">
            <el-select
              v-model="dataForm.signaturesEffective"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option label="有效" value="1"></el-option>
              <el-option label="无效" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="信息公开" prop="informationDisclosure">
            <el-select
              v-model="dataForm.informationDisclosure"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option label="网站公开信息" value="1"></el-option>
              <el-option label="不公开" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序码" prop="sortCode">
            <el-input-number
              v-model="dataForm.sortCode"
              controls-position="right"
              :min="0"
              style="width: 100%"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="企业电子签章">
            <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :on-success="handlerImgUpload"
            >
              <img
                v-if="dataForm.imgSrc"
                :src="dataForm.imgSrc"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <!-- 平台使用 -->
        <el-col :span="24">
          <subTitle information-name="平台使用"></subTitle>
        </el-col>
        <el-col :span="24">
          <el-form-item label="开启平台" prop="openPlatform">
            <el-select
              v-model="dataForm.openPlatform"
              placeholder="请选择"
              style="width: 30%; margin-right: 5px;"
            >
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
            <span>如不开启平台，不能维护账号信息！</span>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="ROOT(管理员)账号" prop="openPlatform">
            <hltableZ
              style="height: 170px;"
              :columnEdit="table.columnEdit"
              :tableColumn="table.column"
              :apiurl="table.apiurl"
              :refreshFlag="table.refreshFlag"
              :selectedRows="table.selectedRows"
              :isChecked="table.isChecked"
              :isNumber="table.isNumber"
              :isClickSelect="table.isClickSelect"
              :staticData="tableDataSource"
            ></hltableZ>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </Drawer>
</template>

<script>
import { Drawer, hltableZ } from "@/components";
import subTitle from "@/views/ZC/components/common/subTitle.vue";
export default {
  name: "FwzcInfo",
  components: { Drawer, hltableZ, subTitle },
  data() {
    return {
      // 性质
      natureOptions: [{ label: "服务企业", value: "1" }],
      // 二级分类
      secondaryOptions: [{ label: "服务机构", value: "1" }],
      // 区
      areaOptions: [
        { label: "市辖区", value: "1" },
        { label: "临川区", value: "2" },
        { label: "张店区", value: "3" },
        { label: "博山区", value: "4" },
      ],
      // 街道
      streeOptions: [
        { label: "闻韶街道", value: "1" },
        { label: "雪宫街道", value: "2" },
        { label: "辛店街道", value: "3" },
        { label: "齐陵街道", value: "4" },
      ],
      // 县
      countyOptions: [
        { label: "西王社区居委会", value: "1" },
        { label: "东王社区居委会", value: "2" },
        { label: "辛东社区居委会", value: "3" },
        { label: "石鼓社区居委会", value: "4" },
      ],
      dataForm: {
        organizationId: "",
        creditCode: "",
        principalName: "",
        abbreviation: "",
        nature: "",
        secondary: "",
        area: "",
        stree: "",
        county: "",
        address: "",
        branding: "",
        brandingName: "",
        selfSupport: "",
        geographicLocation: "",
        head: "",
        headPhone: "",
        headIDCard: "",
        legalPerson: "",
        legalPersonPhone: "",
        legalPersonIDCard: "",
        evaluationScore: "",
        reputationScore: "",
        signaturesEffective: "",
        informationDisclosure: "",
        sortCode: "",
      },
      // 表格数据
      table: {
        columnEdit: false,
        column: [
          {
            label: "账号",
            prop: "1",
            checked: true,
          },
          {
            label: "使用人",
            prop: "2",
            checked: true,
          },
          {
            label: "创建人",
            prop: "3",
            checked: true,
          },
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
        isClickSelect: false,
      },
      tableDataSource: [
        {
          1: 'qijia',
          2: '管理员',
          3: 'null',
        }
      ]
    };
  },

  mounted() {},

  methods: {
     // 上传
     handlerImgUpload(res) {
      if (res.code === 200) {
        this.dataForm.imgSrc = res.url;
      } else {
        this.$message.warning(res.msg);
      }
    },
    resetForm() {
      this.$refs.form.resetFields()
    }
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 98%;
  // 所在区划
  .zoning ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
    .el-select {
      flex: 1;
    }
    span {
      margin: 0 .3125rem;
    }
  }
  // 图片缩略图样式
  .avatar-uploader {
    display: inline-block;
    text-align: center;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    cursor: pointer;
    line-height: 146px;
    vertical-align: top;
    .avatar {
      width: 148px;
      height: 148px;
    }
    i {
      font-size: 28px;
      color: #8c939d;
    }
  }
}
</style>
