<template>
  <div class="box">
    <!-- 头部显示 -->
    <div class="title">
      <div>
        <i class="el-icon-date"></i>
        <span>适老化改造(施工）管理</span>
      </div>
      <!-- <div>
                <el-button style="margin-right: 1rem;" type="primary" size="medium " @click="statistics"><i class="el-icon-s-data"></i>改造项统计</el-button>
                <el-dropdown>
                    <el-button type="success" size="medium "><i class="el-icon-check"></i>批量操作</el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>分配施工组织</el-dropdown-item>
                        <el-dropdown-item>分配验收组织</el-dropdown-item>

                    </el-dropdown-menu>
                </el-dropdown>
            </div> 
-->
    </div>
    <!-- 主页内容 -->
    <div class="mainContent">
      <!-- 搜索区域 -->
      <div class="search">
        <el-form
          :label-position="labelPosition"
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          style="width: 100%"
        >
          <!-- <el-form-item label="区划:">
            <el-cascader
              v-model="formInline.zoning"
              :options="zoningOptions"
              @change="handleChange"
            ></el-cascader>
          </el-form-item> -->
          <!-- <el-form-item label="服务对象:">
            <el-input
              v-model="formInline.user"
              placeholder="服务对象"
            ></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="身份证号:">
                        <el-input v-model="formInline.id" placeholder="身份证号"></el-input>
                    </el-form-item>
                    <el-form-item label="评估结果:">
                        <el-select v-model="formInline.estimate" placeholder="请选择">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item> -->
          <!-- <el-form-item>
            <el-button type="primary" @click="dataRefresh" size="mini"
              >查询</el-button
            > -->
            <!-- <el-button @click="resetForm(formInline)" type="primary" size="mini">重置</el-button>
                        <el-button size="mini" @click="onSubmit">导出</el-button>
                        <el-button size="mini" @click="moreConditions">更多条件</el-button> -->
          <!-- </el-form-item> -->
        </el-form>
        <el-form
          :label-position="labelPosition"
          :inline="true"
          :model="formInline"
          class="demo-form-inline1"
          style="width: 100%"
          v-show="moreShow"
        >
          <el-form-item label="施工组织:">
            <el-select v-model="formInline.estimate" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="施工状态:">
            <el-select v-model="formInline.estimate" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="施工状态:">
            <el-select v-model="formInline.estimate" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="生存状态:">
            <el-select v-model="formInline.estimate" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="验收状态:">
            <el-select v-model="formInline.estimate" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="签约组织:">
            <el-select v-model="formInline.estimate" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="验收组织:">
            <el-select v-model="formInline.estimate" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="服务批次:">
            <el-select v-model="formInline.estimate" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <!-- 表格 -->
      <div class="table">
        <hltableZ
          :columnEdit="table.columnEdit"
          :tableColumn="table.column"
          :apiurl="table.apiurl"
          :isPage="false"
          :staticData="table.staticData"
          :refreshFlag="table.refreshFlag"
          :selectedRows="table.selectedRows"
          :isChecked="table.isChecked"
          :isNumber="table.isNumber"
          :formSearch="formInline"
          @cellClick="cellClick"
          :widthClick="'300%'"
        >
          <div slot-scope="selected" slot="EditColumn">
            <el-button type="text" size="small" @click="transform"
              >改造项</el-button
            >
            <el-button type="text" size="small" @click="deviceAccess"
              >设备接入</el-button
            >
            <el-button type="text" size="small" @click="transformInformation"
              >改造详情</el-button
            >
            <el-button type="text" size="small" @click="delate">删除</el-button>
          </div>
        </hltableZ>
      </div>
    </div>
    <!-- 改造项 -->
    <Drawer
      :title="title"
      ref="drawerIshow"
      :drawerSize="drawerSize"
      :append-to-body="true"
    >
      <div class="transformContent">
        <div class="title">
          <div>
            <i class="el-icon-date"></i>
            <span>适老化改造清单</span>
          </div>
          <div>
            <el-button type="primary" size="mini" @click="transformIncrease"
              ><i class="el-icon-plus"></i>添加</el-button
            >
          </div>
        </div>
        <div class="table">
          <hltableZ
            :columnEdit="transformTable.columnEdit"
            :tableColumn="transformTable.column"
            :apiurl="transformTable.apiurl"
            :refreshFlag="transformTable.refreshFlag"
            :selectedRows="transformTable.selectedRows"
            :isChecked="transformTable.isChecked"
            :isNumber="transformTable.isNumber"
            :isPage="false"
            :isShow="false"
          >
          </hltableZ>
        </div>
        <div class="look">
          <span class="btn" @click="isTableShow">查看预选改造项</span>
          <div class="table" v-show="tableShow">
            <hltableZ
              :columnEdit="transformTable1.columnEdit"
              :tableColumn="transformTable1.column"
              :apiurl="transformTable1.apiurl"
              :refreshFlag="transformTable1.refreshFlag"
              :selectedRows="transformTable1.selectedRows"
              :isChecked="transformTable1.isChecked"
              :isNumber="transformTable1.isNumber"
              :isPage="false"
              :isShow="false"
            >
              <div slot-scope="selected" slot="EditColumn">
                <el-button type="text" size="small" @click="transform"
                  >编辑</el-button
                >
                <el-button type="text" size="small" @click="transform"
                  >删除</el-button
                >
              </div>
            </hltableZ>
          </div>
          <div class="bot" v-show="tableShow">
            <el-button type="primary" size="small">同步改造项</el-button>
            <span class="text"
              >同步说明：1、适老化改造中不存在的改造项，直接从预选改造中同步过来。
              2、适老化改造中存在的改造项，若数量、价格等不一致时，以预选改造项信息为准，同步修改。</span
            >
          </div>
        </div>
        <div
          class="table"
          style="width: 100%; height: 25rem; margin: 1rem 0 2rem 0"
        >
          <hltableZ
            :columnEdit="transformTable2.columnEdit"
            :tableColumn="transformTable2.column"
            :apiurl="transformTable2.apiurl"
            :refreshFlag="transformTable2.refreshFlag"
            :selectedRows="transformTable2.selectedRows"
            :isChecked="transformTable2.isChecked"
            :isNumber="transformTable2.isNumber"
            :isPage="false"
            :isShow="false"
          >
            <div slot-scope="selected" slot="EditColumn">
              <el-button type="text" size="small" @click="transformPut"
                >编辑</el-button
              >
              <el-button type="text" size="small" @click="transformDelate"
                >删除</el-button
              >
            </div>
          </hltableZ>
          <div class="zj">
            总费用:980元 非智能化设备费用：0元 智能化设备费用：0元
          </div>
        </div>
        <!-- 改造项新增/修改 -->
        <Drawer
          :title="transformTitle"
          ref="drawerIshowTransform"
          :drawerSize="'50%'"
          :append-to-body="true"
        >
          <div class="title" style="margin-bottom: 1rem">
            <div>
              <span style="color: skyblue">请选择设备</span>
            </div>
          </div>
          <el-form
            :label-position="labelPosition"
            label-width="150px"
            :model="formLabelAlign"
            :inline="true"
            :rules="rules"
            ref="formLabelAlign"
            :hide-required-asterisk="false"
            style="display: flex; flex-wrap: wrap"
          >
            <el-form-item label="设备类型:" style="width: 100%" required>
              <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="数量:"
              prop="name"
              style="width: 100%"
              required
            >
              <el-input
                v-model="formLabelAlign.name"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注:" prop="beizhu" style="width: 100%">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="formLabelAlign.textarea"
                style="width: 40rem"
              >
              </el-input>
            </el-form-item>
            <el-form-item style="width: 100%" label="改造前:">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item style="width: 100%" label="改造后:">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item style="width: 100%; text-align: center">
              <el-button type="primary" @click="onSubmit">保存</el-button>
            </el-form-item>
          </el-form>
        </Drawer>
      </div>
    </Drawer>
    <!-- 设备接入 -->
    <Drawer
      :title="title"
      ref="drawerIshow2"
      :drawerSize="drawerSize"
      :append-to-body="true"
    >
      <div class="deviceAccessContent">
        <div class="title">
          <div>
            <i class="el-icon-date"></i>
            <span>”XXX“已绑定设备</span>
          </div>
          <div>
            <el-button type="primary" size="mini" @click="deviceAccessIncrease"
              ><i class="el-icon-plus"></i>绑定设备</el-button
            >
          </div>
        </div>
        <div class="search">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            style="width: 100%"
          >
            <el-form-item label="设备名称:">
              <el-input
                v-model="formInline.user"
                placeholder="设备名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="设备编号:">
              <el-input
                v-model="formInline.id"
                placeholder="设备编号"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit" size="mini"
                >查询</el-button
              >
              <el-button
                @click="resetForm(formInline)"
                type="primary"
                size="mini"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="table">
          <hltableZ
            :columnEdit="deviceTable.columnEdit"
            :tableColumn="deviceTable.column"
            :apiurl="deviceTable.apiurl"
            :refreshFlag="deviceTable.refreshFlag"
            :selectedRows="deviceTable.selectedRows"
            :isChecked="deviceTable.isChecked"
            :isNumber="deviceTable.isNumber"
            :formSearch="formInline"
            @cellClick="cellClick"
            :widthClick="'300%'"
          >
            <div slot-scope="selected" slot="EditColumn">
              <el-button type="text" size="small" @click="deviceAccessPut"
                >修改</el-button
              >
              <el-button type="text" size="small">解绑</el-button>
            </div>
          </hltableZ>
        </div>
        <Drawer
          :title="deviceAccessTitle"
          ref="drawerIshowDeviceAccess"
          :drawerSize="'50%'"
          :append-to-body="true"
        >
          <div class="information">
            <div class="title" style="margin-bottom: 1rem">
              <div>
                <span style="color: skyblue">设备信息</span>
              </div>
            </div>
            <div class="informationContent">
              <el-form
                :label-position="labelPosition"
                label-width="150px"
                :model="formLabelAlign"
                :inline="true"
                :rules="rules"
                ref="formLabelAlign"
                :hide-required-asterisk="false"
                style="display: flex; flex-wrap: wrap"
              >
                <el-form-item label="设备厂商:" style="width: 100%" required>
                  <el-select
                    v-model="formLabelAlign.idLei"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="设备类型:" style="width: 100%" required>
                  <el-select
                    v-model="formLabelAlign.idLei"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="设备型号:"
                  prop="name1"
                  style="width: 100%"
                >
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 40rem"
                  ></el-input>
                </el-form-item>
                <el-form-item label="产品分类:" style="width: 100%">
                  <el-select
                    v-model="formLabelAlign.idLei"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="设备序列号:"
                  prop="name"
                  style="width: 100%"
                >
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 40rem"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="紧急联系方式:"
                  prop="name1"
                  style="width: 100%"
                >
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 40rem"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="紧急联系方式(2):"
                  prop="name1"
                  style="width: 100%"
                >
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 40rem"
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注:" prop="beizhu" style="width: 100%">
                  <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="formLabelAlign.textarea"
                    style="width: 40rem"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="familyNumber">
            <div class="title" style="margin-bottom: 1rem">
              <div>
                <span style="color: skyblue">亲情号(根据设备支持而定)</span>
              </div>
            </div>
            <div class="familyNumberContent">
              <el-form
                :label-position="labelPosition"
                label-width="150px"
                :model="formLabelAlign"
                :inline="true"
                :rules="rules"
                ref="formLabelAlign"
                :hide-required-asterisk="false"
                style="display: flex; flex-wrap: wrap"
              >
                <el-form-item
                  label="亲情号(1):"
                  prop="name1"
                  style="width: 100%"
                >
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 19rem; margin-right: 0.8rem"
                    placeholder="请输入亲情人姓名"
                  ></el-input>
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 19rem"
                    placeholder="请输入亲情人联系电话"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="亲情号(2):"
                  prop="name1"
                  style="width: 100%"
                >
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 19rem; margin-right: 0.8rem"
                    placeholder="请输入亲情人姓名"
                  ></el-input>
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 19rem"
                    placeholder="请输入亲情人联系电话"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="亲情号(3):"
                  prop="name1"
                  style="width: 100%"
                >
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 19rem; margin-right: 0.8rem"
                    placeholder="请输入亲情人姓名"
                  ></el-input>
                  <el-input
                    v-model="formLabelAlign.name"
                    style="width: 19rem"
                    placeholder="请输入亲情人联系电话"
                  ></el-input>
                </el-form-item>
                <el-form-item style="width: 100%; text-align: center">
                  <el-button type="primary" @click="onSubmit">保存</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </Drawer>
      </div>
    </Drawer>
    <!-- 改造详情 -->
    <Drawer
      :title="title"
      ref="drawerIshow3"
      :drawerSize="drawerSize"
      :append-to-body="true"
    >
      <div class="transformInformationContent">
        <el-button type="success" size="mini" class="btn"
          ><i class="el-icon-printer"></i>打印</el-button
        >
        <div class="header">家庭养老床位建设明细表</div>
        <div class="title" style="margin-bottom: 1rem">
          <div>
            <span style="color: skyblue">基本信息</span>
          </div>
        </div>
        <div class="information">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            style="width: 100%"
            :label-position="labelPosition"
            label-width="150px"
          >
            <el-form-item label="服务对象:" style="width: 45%">
              <el-input
                v-model="formInline.user"
                placeholder="服务对象姓名"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="城市:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="身份证号"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="区县:" style="width: 45%">
              <el-input
                v-model="formInline.user"
                placeholder="服务对象姓名"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="街道:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="身份证号"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="社区(村):" style="width: 45%">
              <el-input
                v-model="formInline.user"
                placeholder="服务对象姓名"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="身份证号"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="地址:" style="width: 100%">
              <el-input
                v-model="formInline.user"
                placeholder="服务对象姓名"
                style="width: 94rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="联系人"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话:" style="width: 45%">
              <el-input
                v-model="formInline.user"
                placeholder="联系电话"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="合同签订日期:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="身份证号"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="实际改造项总价:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="身份证号"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="改造状态:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="身份证号"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="施工人员:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="身份证号"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="施工现场照片:" style="width: 100%">
              <el-image>
                <div
                  slot="error"
                  class="image-slot"
                  style="
                    width: 10rem;
                    height: 10rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #999;
                  "
                >
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </el-form-item>
          </el-form>
        </div>
        <div class="title" style="margin-bottom: 1rem">
          <div>
            <span style="color: skyblue">改造项</span>
          </div>
        </div>
        <div class="table">
          <hltableZ
            :columnEdit="transformInforTable.columnEdit"
            :tableColumn="transformInforTable.column"
            :apiurl="transformInforTable.apiurl"
            :refreshFlag="transformInforTable.refreshFlag"
            :selectedRows="transformInforTable.selectedRows"
            :isChecked="transformInforTable.isChecked"
            :isNumber="transformInforTable.isNumber"
            :formSearch="query"
          >
            <div slot-scope="selected" slot="EditColumn">
              <el-button
                icon="el-icon-delete"
                type="text"
                size="small"
                @click="delete selected.singlerow"
                >删除</el-button
              >
            </div>
          </hltableZ>
        </div>
      </div>
    </Drawer>
    <!-- 改造项统计 -->
    <Drawer
      :title="'统计'"
      ref="drawerIshow4"
      :drawerSize="drawerSize"
      :append-to-body="true"
    >
      <div class="deviceAccessContent">
        <div class="title">
          <div>
            <i class="el-icon-date"></i>
            <span>改造项统计</span>
          </div>
        </div>
        <div class="search">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            style="width: 100%"
          >
            <el-form-item label="服务批次:">
              <el-input
                v-model="formInline.user"
                placeholder="服务批次"
              ></el-input>
            </el-form-item>
            <el-form-item label="设备名称:">
              <el-input
                v-model="formInline.user"
                placeholder="设备名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="服务组织名称:">
              <el-input
                v-model="formInline.id"
                placeholder="服务组织名称"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit" size="mini"
                >查询</el-button
              >
              <el-button
                @click="resetForm(formInline)"
                type="primary"
                size="mini"
                >重置</el-button
              >
              <el-button type="primary" size="mini">导出</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="table">
          <hltableZ
            :columnEdit="statisticsTable.columnEdit"
            :tableColumn="statisticsTable.column"
            :apiurl="statisticsTable.apiurl"
            :refreshFlag="statisticsTable.refreshFlag"
            :selectedRows="statisticsTable.selectedRows"
            :isChecked="statisticsTable.isChecked"
            :isNumber="statisticsTable.isNumber"
            :formSearch="formInline"
            @cellClick="cellClick"
            :widthClick="'300%'"
          >
          </hltableZ>
        </div>
      </div>
    </Drawer>
    <!-- 服务对象 -->
    <Drawer
      :title="'服务对象'"
      ref="columnShow1"
      :drawerSize="'100%'"
      :append-to-body="true"
    >
      <div class="transformInformationContent" style="overflow-x: hidden">
        <div class="title" style="margin-bottom: 1rem">
          <div>
            <span style="color: skyblue">基本信息</span>
          </div>
        </div>
        <div class="information">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            style="width: 100%"
            :label-position="labelPosition"
            label-width="150px"
          >
            <el-form-item label="申请渠道:" style="width: 45%">
              <el-input
                v-model="formInline.user"
                placeholder="老人姓名"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="服务组织:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="服务组织"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="城市:" style="width: 45%">
              <el-input
                v-model="formInline.user"
                placeholder="城市"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="区县:" style="width: 45%">
              <el-input
                v-model="formInline.user"
                placeholder="区县"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="街道:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="街道"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="社区(村):" style="width: 45%">
              <el-input
                v-model="formInline.user"
                placeholder="社区(村)"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="服务对象姓名:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="服务对象姓名"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="身份证号"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>

            <el-form-item label="电话:" style="width: 45%">
              <el-input
                v-model="formInline.user"
                placeholder="电话"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="预估自理类型:" style="width: 45%">
              <el-input
                v-model="formInline.user"
                placeholder="预估自理类型"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="性别:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="性别"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="出生日期:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="出生日期"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="户籍类型:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="户籍类型"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="户口所在地:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="户口所在地"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证(人像面):" style="width: 45%">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                disabled
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="身份证(国徽面):" style="width: 45%">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                disabled
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="户口照片(本人一栏):" style="width: 45%">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                disabled
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="人脸识别原始照片:" style="width: 45%">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                disabled
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="评估机构:" style="width: 100%">
              <el-input
                v-model="formInline.id"
                placeholder="评估机构"
                style="width: 90rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="原工作单位:" style="width: 100%">
              <el-input
                v-model="formInline.id"
                placeholder="原工作单位"
                style="width: 90rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item
              label="特殊情况（自理能力、既往病史）:"
              style="width: 100%"
            >
              <el-input
                type="textarea"
                :rows="3"
                v-model="formInline.id"
                placeholder="特殊情况（自理能力、既往病史）"
                style="width: 90rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="其他备注:" style="width: 100%">
              <el-input
                type="textarea"
                :rows="2"
                v-model="formInline.id"
                placeholder="其他备注"
                style="width: 90rem"
                disabled
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="title" style="margin-bottom: 1rem">
          <div>
            <span style="color: skyblue">补贴信息</span>
          </div>
        </div>
        <div class="information">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            style="width: 100%"
            :label-position="labelPosition"
            label-width="160px"
          >
            <el-form-item label="补贴方式:" style="width: 45%" required>
              <el-input
                v-model="formInline.user"
                disabled
                placeholder="补贴方式"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="服务形式:" style="width: 45%" required>
              <el-input
                v-model="formInline.id"
                disabled
                placeholder="服务形式"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="老人分类:" style="width: 45%" required>
              <el-input
                v-model="formInline.user"
                disabled
                placeholder="老人分类"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="可支配月收入(元):" style="width: 45%">
              <el-input
                v-model="formInline.user"
                disabled
                placeholder="可支配月收入(元)"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="救助类型:" style="width: 45%" required>
              <el-input
                v-model="formInline.user"
                disabled
                placeholder="救助类型"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="低保/特困证号:" style="width: 45%">
              <el-input
                v-model="formInline.user"
                disabled
                placeholder="低保/特困证号"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="分散供养方式:" style="width: 100%">
              <el-input
                v-model="formInline.user"
                disabled
                placeholder="分散供养方式"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="低保/特困证书:" style="width: 100%">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                disabled
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
        <div class="title" style="margin-bottom: 1rem">
          <div>
            <span style="color: skyblue">联系人信息</span>
          </div>
        </div>
        <div class="information">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            style="width: 100%"
            :label-position="labelPosition"
            label-width="160px"
          >
            <el-form-item label="与老人关系:" style="width: 45%" required>
              <el-input
                v-model="formInline.user"
                disabled
                placeholder="与老人关系"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="姓名:" style="width: 45%" required>
              <el-input
                v-model="formInline.id"
                disabled
                placeholder="姓名"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证:" style="width: 45%" required>
              <el-input
                v-model="formInline.user"
                disabled
                placeholder="身份证"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="电话:" style="width: 45%" required>
              <el-input
                v-model="formInline.user"
                disabled
                placeholder="电话"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="邮箱:" style="width: 100%" required>
              <el-input
                v-model="formInline.id"
                disabled
                placeholder="邮箱"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="工作单位:" style="width: 100%" required>
              <el-input
                v-model="formInline.user"
                disabled
                placeholder="工作单位"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="地址:" style="width: 100%" required>
              <el-input
                v-model="formInline.user"
                disabled
                placeholder="地址"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="title" style="margin-bottom: 1rem">
          <div>
            <span style="color: skyblue">房屋信息</span>
          </div>
        </div>
        <div class="information">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            style="width: 100%"
            :label-position="labelPosition"
            label-width="160px"
          >
            <el-form-item label="产权人姓名:" style="width: 45%" required>
              <el-input
                v-model="formInline.user"
                disabled
                placeholder="产权人姓名"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="建筑面积(㎡):" style="width: 45%" required>
              <el-input
                v-model="formInline.id"
                disabled
                placeholder="建筑面积(㎡)"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="房屋类型:" style="width: 45%" required>
              <el-input
                v-model="formInline.user"
                disabled
                placeholder="房屋类型"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="户型:" style="width: 45%" required>
              <el-input
                v-model="formInline.user"
                disabled
                placeholder="户型"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="家庭人数:" style="width: 100%" required>
              <el-input
                v-model="formInline.id"
                disabled
                placeholder="家庭人数"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="环境描述:" style="width: 100%">
              <el-input
                type="textarea"
                :rows="2"
                v-model="formInline.user"
                disabled
                placeholder="环境描述"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="改造建议:" style="width: 100%">
              <el-input
                type="textarea"
                :rows="2"
                v-model="formInline.user"
                disabled
                placeholder="改造建议"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </Drawer>
    <!-- 施工组织 -->
    <Drawer
      :title="'分配施工组织'"
      ref="columnShow2"
      :drawerSize="'50%'"
      :append-to-body="true"
    >
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        style="width: 100%"
        :label-position="labelPosition"
        label-width="100px"
        :hide-required-asterisk="false"
      >
        <el-form-item label="已选择订单:" required>
          <el-input
            v-model="formInline.user"
            placeholder="已选择订单"
            style="width: 40rem"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="施工组织:" required>
          <el-select v-model="formInline.estimate" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 90%; text-align: center">
          <el-button type="success" @click="onSubmit">分配</el-button>
        </el-form-item>
      </el-form>
    </Drawer>
    <!-- 施工状态 -->
    <Drawer
      :title="'施工状态'"
      ref="columnShow3"
      :drawerSize="'50%'"
      :append-to-body="true"
    >
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        :hide-required-asterisk="false"
        style="width: 100%"
        :label-position="labelPosition"
        label-width="100px"
      >
        <el-form-item label="改造对象:">
          <el-input
            v-model="formInline.user"
            placeholder="改造对象"
            style="width: 40rem"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="施工人员:">
          <el-input
            v-model="formInline.user"
            placeholder="施工人员"
            style="width: 40rem"
          ></el-input>
        </el-form-item>
        <el-form-item label="施工状态:" required>
          <el-select v-model="formInline.estimate" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 90%; text-align: center">
          <el-button type="success" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </Drawer>
    <!-- 验收组织 -->
    <Drawer
      :title="'验收组织'"
      ref="columnShow4"
      :drawerSize="'50%'"
      :append-to-body="true"
    >
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        style="width: 100%"
        :label-position="labelPosition"
        label-width="150px"
        :hide-required-asterisk="false"
      >
        <el-form-item label="已选择订单:" required>
          <el-input
            v-model="formInline.user"
            placeholder="已选择订单"
            style="width: 40rem"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="第三方验收组织:" required>
          <el-select v-model="formInline.estimate" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 90%; text-align: center">
          <el-button type="success" @click="onSubmit">分配</el-button>
        </el-form-item>
      </el-form>
    </Drawer>
    <!-- 验收状态 -->
    <Drawer
      :title="'验收'"
      ref="columnShow5"
      :drawerSize="'100%'"
      :append-to-body="true"
    >
      <div class="transformInformationContent" style="overflow-x: hidden">
        <el-button type="success" size="mini" class="btn"
          ><i class="el-icon-printer"></i>打印</el-button
        >
        <div class="header">家庭养老床位建设验收表</div>
        <div class="title" style="margin-bottom: 1rem">
          <div>
            <span style="color: skyblue">基本信息</span>
          </div>
        </div>
        <div class="information">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            style="width: 100%"
            :label-position="labelPosition"
            label-width="150px"
          >
            <el-form-item label="老人姓名:" style="width: 45%">
              <el-input
                v-model="formInline.user"
                placeholder="老人姓名"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="城市:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="城市"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="区县:" style="width: 45%">
              <el-input
                v-model="formInline.user"
                placeholder="区县"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="街道:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="街道"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="社区(村):" style="width: 45%">
              <el-input
                v-model="formInline.user"
                placeholder="社区(村)"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="身份证号"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="地址:" style="width: 100%">
              <el-input
                v-model="formInline.user"
                placeholder="服务对象姓名"
                style="width: 94rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="联系人"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话:" style="width: 45%">
              <el-input
                v-model="formInline.user"
                placeholder="联系电话"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="合同签订日期:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="合同签订日期"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="施工状态:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="施工状态"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="title" style="margin-bottom: 1rem">
          <div>
            <span style="color: skyblue">改造项</span>
          </div>
        </div>
        <div class="table">
          <hltableZ
            :columnEdit="checkAcceptTable.columnEdit"
            :tableColumn="checkAcceptTable.column"
            :apiurl="checkAcceptTable.apiurl"
            :refreshFlag="checkAcceptTable.refreshFlag"
            :selectedRows="checkAcceptTable.selectedRows"
            :isChecked="checkAcceptTable.isChecked"
            :isNumber="checkAcceptTable.isNumber"
            :formSearch="query"
          >
          </hltableZ>
        </div>
        <div class="title" style="margin-bottom: 1rem">
          <div>
            <span style="color: skyblue">验收</span>
          </div>
        </div>
        <div class="information">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            style="width: 100%"
            :label-position="labelPosition"
            label-width="160px"
          >
            <el-form-item label="验收负责人:" style="width: 45%" required>
              <el-input
                v-model="formInline.user"
                placeholder="验收负责人"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="验收负责人身份证:" style="width: 45%" required>
              <el-input
                v-model="formInline.id"
                placeholder="验收负责人身份证"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="验收负责人联系方式:"
              style="width: 45%"
              required
            >
              <el-input
                v-model="formInline.user"
                placeholder="验收负责人联系方式"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="验收日期:" style="width: 90%">
              <el-date-picker
                v-model="formInline.checkDate"
                type="date"
                placeholder="选择日期"
                style="width: 40rem"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="备注:" style="width: 90%">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="formLabelAlign.textarea"
                style="width: 40rem"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="证明材料:" style="width: 45%">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item style="width: 90%; text-align: center">
              <el-button type="success" @click="onSubmit">通过</el-button>
              <el-button type="danger" @click="onSubmit">不通过</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </Drawer>
    <!-- 终审 -->
    <Drawer
      :title="'终审'"
      ref="columnShow6"
      :drawerSize="'100%'"
      :append-to-body="true"
    >
      <div class="transformInformationContent" style="overflow-x: hidden">
        <div class="information">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            style="width: 100%"
            :label-position="labelPosition"
            label-width="150px"
          >
            <el-form-item label="老人姓名:" style="width: 45%">
              <el-input
                v-model="formInline.user"
                placeholder="老人姓名"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="城市:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="城市"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="区县:" style="width: 45%">
              <el-input
                v-model="formInline.user"
                placeholder="区县"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="街道:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="街道"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="社区(村):" style="width: 45%">
              <el-input
                v-model="formInline.user"
                placeholder="社区(村)"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="身份证号"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="地址:" style="width: 100%">
              <el-input
                v-model="formInline.user"
                placeholder="服务对象姓名"
                style="width: 94rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="联系人"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话:" style="width: 45%">
              <el-input
                v-model="formInline.user"
                placeholder="联系电话"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="合同签订日期:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="合同签订日期"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="施工状态:" style="width: 45%">
              <el-input
                v-model="formInline.id"
                placeholder="施工状态"
                style="width: 40rem"
                disabled
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="title" style="margin-bottom: 1rem">
          <div>
            <span style="color: skyblue">改造项</span>
          </div>
        </div>
        <div class="table">
          <hltableZ
            :columnEdit="checkAcceptTable.columnEdit"
            :tableColumn="checkAcceptTable.column"
            :apiurl="checkAcceptTable.apiurl"
            :refreshFlag="checkAcceptTable.refreshFlag"
            :selectedRows="checkAcceptTable.selectedRows"
            :isChecked="checkAcceptTable.isChecked"
            :isNumber="checkAcceptTable.isNumber"
            :formSearch="query"
          >
          </hltableZ>
        </div>
        <div class="title" style="margin-bottom: 1rem">
          <div>
            <span style="color: skyblue">验收信息</span>
          </div>
        </div>
        <div class="information">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            style="width: 100%"
            :label-position="labelPosition"
            label-width="160px"
          >
            <el-form-item label="验收负责人:" style="width: 45%" required>
              <el-input
                v-model="formInline.user"
                disabled
                placeholder="验收负责人"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="验收负责人身份证:" style="width: 45%" required>
              <el-input
                v-model="formInline.id"
                disabled
                placeholder="验收负责人身份证"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="验收负责人联系方式:"
              style="width: 45%"
              required
            >
              <el-input
                v-model="formInline.user"
                disabled
                placeholder="验收负责人联系方式"
                style="width: 40rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="验收日期:" style="width: 90%">
              <el-date-picker
                v-model="formInline.checkDate"
                type="date"
                placeholder="选择日期"
                style="width: 40rem"
                disabled
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="备注:" style="width: 90%">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="formLabelAlign.textarea"
                style="width: 40rem"
                disabled
              >
              </el-input>
            </el-form-item>
            <el-form-item label="证明材料:" style="width: 45%">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                disabled
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
        <div class="title" style="margin-bottom: 1rem">
          <div>
            <span style="color: skyblue">审核</span>
          </div>
        </div>
        <div class="information">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            style="width: 100%"
            :label-position="labelPosition"
            label-width="160px"
          >
            <el-form-item label="审核结果:" style="width: 45%" required>
              <el-radio v-model="formInline.radio" label="1">合格</el-radio>
              <el-radio v-model="formInline.radio" label="2">不合格</el-radio>
            </el-form-item>
            <el-form-item label="备注:" style="width: 90%">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="formLabelAlign.textarea"
                style="width: 40rem"
              >
              </el-input>
            </el-form-item>
            <el-form-item style="width: 90%; text-align: center">
              <el-button type="success" @click="onSubmit">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { hltableZ, Drawer } from "@/components";
export default {
  components: { hltableZ, Drawer },
  data() {
    return {
      moreShow: false,
      title: "",
      transformTitle: "添加",
      deviceAccessTitle: "绑定设备",
      drawerSize: "100%",
      drawerIshow: false,
      drawerIshow2: false,
      drawerIshow3: false,
      drawerIshow4: false,
      drawerIshowTransform: false,
      drawerIshowDeviceAccess: false,
      tableShow: false,
      formInline: {
        user: "",
        estimate: "",
        zoning: "",
        id: "",
        radio: "1",
      },
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      zoningOptions: [
        {
          value: "zhinan",
          label: "指南",
          children: [
            {
              value: "shejiyuanze",
              label: "设计原则",
              children: [
                {
                  value: "yizhi",
                  label: "一致",
                },
                {
                  value: "fankui",
                  label: "反馈",
                },
                {
                  value: "xiaolv",
                  label: "效率",
                },
                {
                  value: "kekong",
                  label: "可控",
                },
              ],
            },
            {
              value: "daohang",
              label: "导航",
              children: [
                {
                  value: "cexiangdaohang",
                  label: "侧向导航",
                },
                {
                  value: "dingbudaohang",
                  label: "顶部导航",
                },
              ],
            },
          ],
        },
        {
          value: "ziyuan",
          label: "资源",
          children: [
            {
              value: "axure",
              label: "Axure Components",
            },
            {
              value: "sketch",
              label: "Sketch Templates",
            },
            {
              value: "jiaohu",
              label: "组件交互文档",
            },
          ],
        },
      ],
      //   主表格
      table: {
        staticData: [],
        columnEdit: false,
        column:[
          {
            label: "姓名",
            prop: "a",
            checked: true,
            width:"100"
          },
          {
            label: "身份证",
            prop: "b",
            checked: true,
            width:"200"
          },
          {
            label: "家庭住址",
            prop: "c",
            checked: true,
          },
          {
            label: "改造项目",
            prop: "d",
            checked: true,
          },
          {
            label: "核实情况",
            prop: "e",
            checked: true,
          },
          {
            label: "去世时间",
            prop: "f",
            checked: true,
          },
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: true,
        isNumber: false,
      },
      // 改造项数据位置
      transformTable: {
        columnEdit: false,
        column: [
          {
            label: "服务对象",
            prop: "oldName",
            checked: true,
          },
          {
            label: "身份证",
            prop: "idCard",
            checked: true,
          },
          {
            label: "地址",
            prop: "idCard",
            checked: true,
          },
          {
            label: "联系人",
            prop: "age",
            checked: true,
          },
          {
            label: "联系电话",
            prop: "idCard",
            checked: true,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: false,
      },
      transformTable1: {
        columnEdit: true,
        column: [
          {
            label: "名称",
            prop: "oldName",
            checked: true,
          },
          {
            label: "智能/非智能",
            prop: "idCard",
            checked: true,
          },
          {
            label: "设备单价(元)",
            prop: "idCard",
            checked: true,
          },
          {
            label: "数量",
            prop: "age",
            checked: true,
          },
          {
            label: "总价(元)",
            prop: "idCard",
            checked: true,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      transformTable2: {
        columnEdit: true,
        column: [
          {
            label: "名称",
            prop: "oldName",
            checked: true,
          },
          {
            label: "智能/非智能",
            prop: "idCard",
            checked: true,
          },
          {
            label: "设备单价(元)",
            prop: "idCard",
            checked: true,
          },
          {
            label: "数量",
            prop: "age",
            checked: true,
          },
          {
            label: "总价(元)",
            prop: "idCard",
            checked: true,
          },
          {
            label: "改造前",
            prop: "age",
            checked: true,
          },
          {
            label: "改造后",
            prop: "idCard",
            checked: true,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      labelPosition: "right",
      formLabelAlign: {
        name: "",
        idLei: "",
        idCard: "",
        date1: "",
        textarea: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        region: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
      },
      // 设备接入数据位置
      deviceTable: {
        columnEdit: true,
        column: [
          {
            label: "老人姓名",
            prop: "oldName",
            checked: true,
          },
          {
            label: "设备sn号",
            prop: "2",
            checked: true,
          },
          {
            label: "设备号",
            prop: "organName",
            checked: true,
          },
          {
            label: "紧急联系电话",
            prop: "age",
            checked: true,
          },
          {
            label: "绑定日期",
            prop: "idCard",
            checked: true,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: true,
        isNumber: false,
      },
      // 改造详情
      transformInforTable: {
        columnEdit: false,
        column: [
          {
            label: "名称/数量",
            prop: "oldName",
            checked: true,
          },
          {
            label: "单价(元)",
            prop: "idCard",
            checked: true,
          },
          {
            label: "总价(元)",
            prop: "idCard",
            checked: true,
          },
          {
            label: "改造前",
            prop: "age",
            checked: true,
          },
          {
            label: "改造后",
            prop: "idCard",
            checked: true,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      // 主页改造想统计
      statisticsTable: {
        columnEdit: false,
        column: [
          {
            label: "服务批次",
            prop: "oldName",
            checked: true,
          },
          {
            label: "产品名称",
            prop: "idCard",
            checked: true,
          },
          {
            label: "签约改造数量",
            prop: "idCard",
            checked: true,
          },
          {
            label: "归属",
            prop: "age",
            checked: true,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      // 验收表格
      checkAcceptTable: {
        columnEdit: false,
        column: [
          {
            label: "名称/数量",
            prop: "oldName",
            checked: true,
          },
          {
            label: "单价(元)",
            prop: "idCard",
            checked: true,
          },
          {
            label: "总价(元)",
            prop: "idCard",
            checked: true,
          },
          {
            label: "备注",
            prop: "idCard",
            checked: true,
          },
          {
            label: "改造前",
            prop: "age",
            checked: true,
          },
          {
            label: "改造后",
            prop: "idCard",
            checked: true,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      // 单元格点击显示隐藏
      columnShow1: false,
      columnShow2: false,
      columnShow3: false,
      columnShow4: false,
      columnShow5: false,
      columnShow6: false,
    };
  },
  mounted() {
    this.dataRefresh();
  },
  methods: {
    dataRefresh() {
      this.table.staticData = [
    {
        "a": "郭素梅",
        "b": "370305194905055322",
        "c": "山东省淄博市临淄区凤凰镇南王村108号",
        "d": "安装防撞护角/防撞条、提示标识,紧急求救按钮2,紧急求救按钮1,安装防撞护角/防撞条、提示标识,起夜灯,智能无感看护,安装防撞护角/防撞条、提示标识,安全扶手,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王焕栋",
        "b": "370305195610105331",
        "c": "北曹村3-39",
        "d": "智能无感看护,紧急求救按钮2,智能老人卡,紧急求救按钮1",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王桂英",
        "b": "37030519330610532X",
        "c": "北曹村",
        "d": "安装防撞护角/防撞条、提示标识,智能无感看护,安装防撞护角/防撞条、提示标识,智能老人卡,紧急求救按钮1,紧急求救按钮2,安装防撞护角/防撞条、提示标识,起夜灯",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "齐玉华",
        "b": "370305194708295327",
        "c": "临淄区凤凰镇南王村98号",
        "d": "紧急求救按钮1,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安全扶手,紧急求救按钮2,起夜灯,智能无感看护,安装防撞护角/防撞条、提示标识,智能老人卡,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王国三",
        "b": "370305195708015318",
        "c": "临淄区凤凰镇南王村133号",
        "d": "智能无感看护,紧急求救按钮2,紧急求救按钮1,起夜灯,智能老人卡",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王瑞青",
        "b": "370305195801075331",
        "c": "临淄区凤凰镇南王村124号",
        "d": "安装防撞护角/防撞条、提示标识,智能无感看护,紧急求救按钮1,紧急求救按钮2,起夜灯,安装防撞护角/防撞条、提示标识,智能老人卡,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "杨永清",
        "b": "370305194311035324",
        "c": "临淄区凤凰镇南王村178号",
        "d": "安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮1,智能无感看护,起夜灯,紧急求救按钮2,安装防撞护角/防撞条、提示标识,智能老人卡",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王月花",
        "b": "370305195409275347",
        "c": "临淄区凤凰镇南王村60号",
        "d": "安全扶手,智能无感看护,智能老人卡,起夜灯,紧急求救按钮1,安装防撞护角/防撞条、提示标识,紧急求救按钮2,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王春域",
        "b": "37030519470329531X",
        "c": "临淄区凤凰镇南王村78号",
        "d": "智能无感看护,安装防撞护角/防撞条、提示标识,起夜灯,安装防撞护角/防撞条、提示标识,紧急求救按钮1,紧急求救按钮2,智能老人卡",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "段良坤",
        "b": "37030519410507471X",
        "c": "临淄区凤凰镇侯家屯村1组138号",
        "d": "智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮2,安装防撞护角/防撞条、提示标识,起夜灯,紧急求救按钮1,智能老人卡,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王荣祥",
        "b": "370305194102225316",
        "c": "临淄区凤凰镇柴南村",
        "d": "紧急求救按钮2,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,起夜灯,智能老人卡,智能无感看护,紧急求救按钮1,紧急求救按钮2",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王爱英",
        "b": "37030519551111534X",
        "c": "临淄区凤凰镇柴南村",
        "d": "紧急求救按钮2,安装防撞护角/防撞条、提示标识,智能无感看护,起夜灯,紧急求救按钮1,安装防撞护角/防撞条、提示标识,智能老人卡,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "于立德",
        "b": "370305194706085318",
        "c": "临淄区凤凰镇吴家村3组44号",
        "d": "智能无感看护,起夜灯,安全扶手,紧急求救按钮1,智能老人卡,紧急求救按钮2",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "曹桂英",
        "b": "370305194103275323",
        "c": "凤凰镇南曹村3组17号",
        "d": "智能无感看护,安装防撞护角/防撞条、提示标识,安全扶手,智能老人卡,安装防撞护角/防撞条、提示标识,起夜灯,紧急求救按钮1,紧急求救按钮2,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "段广义",
        "b": "370305193812184710",
        "c": "凤凰镇侯家屯村1组43号",
        "d": "安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮2,智能老人卡,紧急求救按钮1,起夜灯,智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": "2023.1"
    },
    {
        "a": "边秀兰",
        "b": "370305193807175326",
        "c": "山东省淄博市临淄区凤凰镇南王村139号",
        "d": "智能老人卡,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮2,安装防撞护角/防撞条、提示标识,安全扶手,紧急求救按钮1,起夜灯,安装防撞护角/防撞条、提示标识,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "杨凤英",
        "b": "370305193205165323",
        "c": "北曹村",
        "d": "智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,智能老人卡,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮2,起夜灯,安装防撞护角/防撞条、提示标识,紧急求救按钮1",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "路恒义",
        "b": "370305193302285319",
        "c": "北曹村",
        "d": "紧急求救按钮2,智能老人卡,安装防撞护角/防撞条、提示标识,起夜灯,紧急求救按钮1,智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "徐秀英",
        "b": "370305192712045321",
        "c": "北曹村",
        "d": "安全扶手,紧急求救按钮2,智能老人卡,智能老人卡,智能无感看护,紧急求救按钮1,紧急求救按钮1,安装防撞护角/防撞条、提示标识,紧急求救按钮2,起夜灯,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "贾桂香",
        "b": "370305194706085326",
        "c": "北曹村",
        "d": "智能老人卡,安全扶手,智能无感看护,安装防撞护角/防撞条、提示标识,起夜灯,紧急求救按钮1,紧急求救按钮2",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "边光友",
        "b": "370305195405054731",
        "c": "临淄区凤凰镇寇家村159号",
        "d": "安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,起夜灯,紧急求救按钮1,安装防撞护角/防撞条、提示标识,智能老人卡,起夜灯,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮2",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "郭庆范",
        "b": "370305195110134726",
        "c": "临淄区凤凰镇寇家村243号",
        "d": "紧急求救按钮2,安装防撞护角/防撞条、提示标识,智能老人卡,起夜灯,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,智能无感看护,紧急求救按钮1,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "张绍芬",
        "b": "370305194204255321",
        "c": "凤凰镇北曹村3组26号",
        "d": "智能老人卡,紧急求救按钮1,安装防撞护角/防撞条、提示标识,智能无感看护,紧急求救按钮2,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王义英",
        "b": "370305192709095328",
        "c": "北曹村",
        "d": "智能无感看护,紧急求救按钮2,安装防撞护角/防撞条、提示标识,起夜灯,紧急求救按钮1,智能老人卡",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王学汤",
        "b": "370305192802055312",
        "c": "临淄区北曹村",
        "d": "安全扶手,智能无感看护,紧急求救按钮1,起夜灯,紧急求救按钮2,智能老人卡,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王希荣",
        "b": "370305194001205324",
        "c": "北曹村",
        "d": "安装防撞护角/防撞条、提示标识,紧急求救按钮2,紧急求救按钮1,智能老人卡,起夜灯,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "孙孟英",
        "b": "370305194204205324",
        "c": "北曹村",
        "d": "智能老人卡,紧急求救按钮2,紧急求救按钮1,智能老人卡,起夜灯,智能无感看护,紧急求救按钮2,安装防撞护角/防撞条、提示标识,紧急求救按钮1,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王建民",
        "b": "370305194405255336",
        "c": "北曹村",
        "d": "安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,起夜灯",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "李秀美",
        "b": "370305194510105348",
        "c": "北曹村",
        "d": "安装防撞护角/防撞条、提示标识,起夜灯,安装防撞护角/防撞条、提示标识,智能老人卡,紧急求救按钮1,智能无感看护,紧急求救按钮2",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "崔秀英",
        "b": "370305194111245327",
        "c": "西老村1-23",
        "d": "安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,智能无感看护,起夜灯,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮2,智能老人卡,紧急求救按钮1",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "孙宝叶",
        "b": "370305196108174731",
        "c": "西申村551号",
        "d": "智能老人卡,紧急求救按钮1,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮2,安装防撞护角/防撞条、提示标识,安全扶手,安装防撞护角/防撞条、提示标识,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "杨焕章",
        "b": "37030519360525531X",
        "c": "西老村16-6",
        "d": "紧急求救按钮1,紧急求救按钮2,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,智能老人卡,起夜灯,安装防撞护角/防撞条、提示标识,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": "2023.6"
    },
    {
        "a": "王玉荣",
        "b": "370305193905115319",
        "c": "柴北村1组50号",
        "d": "起夜灯,紧急求救按钮2,紧急求救按钮1,智能无感看护,安装防撞护角/防撞条、提示标识,智能老人卡,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "周友云",
        "b": "370305195311224728",
        "c": "寇家村232号",
        "d": "安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安全扶手,紧急求救按钮1,智能老人卡,起夜灯,紧急求救按钮2,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王纯贞",
        "b": "370305194805235326",
        "c": "南王村119号",
        "d": "安装防撞护角/防撞条、提示标识,紧急求救按钮2,起夜灯,安装防撞护角/防撞条、提示标识,紧急求救按钮1,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,智能老人卡,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "郭振云",
        "b": "370305195212124721",
        "c": "梁家村83号",
        "d": "起夜灯,安全扶手,安装防撞护角/防撞条、提示标识,紧急求救按钮2,安装防撞护角/防撞条、提示标识,智能老人卡,紧急求救按钮1,智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "边存荣",
        "b": "370305195611115021",
        "c": "东齐村1组16号",
        "d": "起夜灯,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮2,紧急求救按钮1,安装防撞护角/防撞条、提示标识,安全扶手,智能无感看护,智能老人卡",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "滕文霞",
        "b": "370305194511104718",
        "c": "周家屯村委会",
        "d": "安装防撞护角/防撞条、提示标识,智能老人卡,起夜灯,紧急求救按钮1,安装防撞护角/防撞条、提示标识,紧急求救按钮2,安装防撞护角/防撞条、提示标识,智能无感看护,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "马学平",
        "b": "370305195307244718",
        "c": "梁家村238号",
        "d": "智能无感看护,安装防撞护角/防撞条、提示标识,紧急求救按钮1,安装防撞护角/防撞条、提示标识,紧急求救按钮2,智能老人卡,安装防撞护角/防撞条、提示标识,安全扶手,起夜灯",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王连滨",
        "b": "370305195607295314",
        "c": "柴北村2组71号",
        "d": "起夜灯,紧急求救按钮2,智能老人卡,安装防撞护角/防撞条、提示标识,紧急求救按钮1,安装防撞护角/防撞条、提示标识,智能无感看护,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "李德祥",
        "b": "370305195106135312",
        "c": "土桥村1-1",
        "d": "安装防撞护角/防撞条、提示标识,紧急求救按钮1,安装防撞护角/防撞条、提示标识,紧急求救按钮2,智能无感看护,安装防撞护角/防撞条、提示标识,起夜灯,智能老人卡",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "路美德",
        "b": "370305194508094715",
        "c": "西郝家村",
        "d": "智能无感看护,安装防撞护角/防撞条、提示标识,紧急求救按钮1,安装防撞护角/防撞条、提示标识,起夜灯,紧急求救按钮2,智能老人卡,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王秀春",
        "b": "370305195201174723",
        "c": "梁家村163号",
        "d": "安全扶手,智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,起夜灯,安装防撞护角/防撞条、提示标识,紧急求救按钮1,智能老人卡,紧急求救按钮2,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王焕炬",
        "b": "370305195310135336",
        "c": "北曹村2-49",
        "d": "智能无感看护,紧急求救按钮2,智能老人卡,紧急求救按钮1",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "李家夫",
        "b": "370305195008095337",
        "c": "北安合村",
        "d": "安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮1,紧急求救按钮2,智能老人卡,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王焕德",
        "b": "370305194802295331",
        "c": "北曹村2-78",
        "d": "安装防撞护角/防撞条、提示标识,安全扶手,安装防撞护角/防撞条、提示标识,起夜灯",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王建军",
        "b": "370305194201075317",
        "c": "北曹村3-109",
        "d": "智能无感看护,紧急求救按钮2,紧急求救按钮2,智能老人卡",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王泽谊",
        "b": "370305195311104718",
        "c": "西申村207号",
        "d": "智能老人卡,安装防撞护角/防撞条、提示标识,紧急求救按钮2,起夜灯,安装防撞护角/防撞条、提示标识,智能无感看护,安全扶手,紧急求救按钮1,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "呼金生",
        "b": "370305194909175313",
        "c": "九仙村37号",
        "d": "起夜灯,安装防撞护角/防撞条、提示标识,智能无感看护,安装防撞护角/防撞条、提示标识,紧急求救按钮2,安装防撞护角/防撞条、提示标识,紧急求救按钮1,智能老人卡,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "刘国学",
        "b": "370305195708075310",
        "c": "林家村369号",
        "d": "紧急求救按钮2,智能无感看护,紧急求救按钮1,智能老人卡,起夜灯,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": "2022.8"
    },
    {
        "a": "王爱国",
        "b": "370305196008264713",
        "c": "王桥村",
        "d": "紧急求救按钮2,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,智能无感看护,紧急求救按钮1,安全扶手,起夜灯",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "赵文永",
        "b": "370305194903264710",
        "c": "侯家屯村5组126号",
        "d": "起夜灯,安装防撞护角/防撞条、提示标识,安全扶手,紧急求救按钮1,紧急求救按钮2,智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "路其平",
        "b": "370305195012254716",
        "c": "北田旺村",
        "d": "起夜灯,紧急求救按钮2,智能老人卡,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,智能无感看护,紧急求救按钮1,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安全扶手",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "路常远",
        "b": "370305195407254710",
        "c": "西陈家村",
        "d": "紧急求救按钮2,智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,起夜灯,紧急求救按钮1",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王庆兰",
        "b": "370305193511264725",
        "c": "东申村4组39号",
        "d": "紧急求救按钮2,智能老人卡,安装防撞护角/防撞条、提示标识,紧急求救按钮1,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "杨方时",
        "b": "370305194607065311",
        "c": "北安合村",
        "d": "紧急求救按钮1,智能无感看护,起夜灯,安全扶手,智能老人卡,紧急求救按钮2,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "齐玉柱",
        "b": "370305194403215015",
        "c": "东齐村2组56号",
        "d": "智能老人卡,紧急求救按钮2,智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮1,起夜灯,安装防撞护角/防撞条、提示标识,智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": "2023.1"
    },
    {
        "a": "于连堂",
        "b": "370305195611285338",
        "c": "东梧村",
        "d": "安装防撞护角/防撞条、提示标识,智能无感看护,起夜灯,安装防撞护角/防撞条、提示标识,安全扶手,安装防撞护角/防撞条、提示标识,智能老人卡,紧急求救按钮1,紧急求救按钮2,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "范家祥",
        "b": "370305193908155316",
        "c": "西河村",
        "d": "紧急呼叫设备*2,烟感探测器*1,智能网关*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "郭素芳",
        "b": "370305194009115323",
        "c": "西河村",
        "d": "电源插座及开关改造*1,智能网关*1,视频监控设备*1,烟感探测器*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "韩玉考",
        "b": "370305192912053313",
        "c": "彩家村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "胡世芹",
        "b": "370305194005295320",
        "c": "西河村",
        "d": "电源插座及开关改造*1,紧急呼叫设备*1,智能网关*1,视频监控设备*1,烟感探测器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "李保祥",
        "b": "370305193906265319",
        "c": "南台合村",
        "d": "电源插座及开关改造*1,烟感探测器*1,视频监控设备*1,智能网关*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "李民太",
        "b": "370305193802095319",
        "c": "东河村",
        "d": "电源插座及开关改造*1,智能网关*1,视频监控设备*1,紧急呼叫设备*1,烟感探测器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "李盛太",
        "b": "370305194301225319",
        "c": "东河村",
        "d": "烟感探测器*1,智能网关*1,视频监控设备*1,紧急呼叫设备*2",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "李秀英",
        "b": "370305193709095322",
        "c": "西河村",
        "d": "电源插座及开关改造*1,视频监控设备*1,烟感探测器*1,紧急呼叫设备*1,智能网关*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "刘端成",
        "b": "370305193908095317",
        "c": "西河村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "路学英",
        "b": "370305194404015322",
        "c": "西梧村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "罗福荣",
        "b": "370305194307224712",
        "c": "南罗村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "罗麦田",
        "b": "370305192912074717",
        "c": "南罗村",
        "d": "烟感探测器*1,智能网关*1,视频监控设备*1,紧急呼叫设备*2",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "罗荣华",
        "b": "370305194006114712",
        "c": "南罗村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "罗希元",
        "b": "370305194108274717",
        "c": "南罗村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,视频监控设备*1,烟感探测器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "孙景华",
        "b": "370305194305175312",
        "c": "东河村",
        "d": "烟感探测器*1,智能网关*1,视频监控设备*1,紧急呼叫设备*2",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "孙玉英",
        "b": "370305193608288532",
        "c": "东河村",
        "d": "电源插座及开关改造*1,智能网关*1,视频监控设备*1,紧急呼叫设备*1,烟感探测器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王立英",
        "b": "370305192701085328",
        "c": "西河村",
        "d": "电源插座及开关改造*1,烟感探测器*1,智能网关*1,视频监控设备*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王世荣",
        "b": "370305194011255325",
        "c": "西河村",
        "d": "电源插座及开关改造*1,视频监控设备*1,紧急呼叫设备*1,烟感探测器*1,智能网关*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王新清",
        "b": "37030519400902531X",
        "c": "水牛村",
        "d": "电源插座及开关改造*1,紧急呼叫设备*1,智能网关*1,视频监控设备*1,烟感探测器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王友芬",
        "b": "370305193809205322",
        "c": "西河村",
        "d": "电源插座及开关改造*1,智能网关*1,视频监控设备*1,烟感探测器*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "徐桂英",
        "b": "370305193405115320",
        "c": "凤凰镇",
        "d": "电源插座及开关改造*1,紧急呼叫设备*1,智能网关*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "徐玉珍",
        "b": "370305194610155326",
        "c": "吴家桥",
        "d": "电源插座及开关改造*1,烟感探测器*1,视频监控设备*1,智能网关*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "杨桂香",
        "b": "370305193112244727",
        "c": "南罗村",
        "d": "电源插座及开关改造*1,紧急呼叫设备*1,智能网关*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "史冠中",
        "b": "370305193703135311",
        "c": "刘地村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王洋洲",
        "b": "370305194110085317",
        "c": "柴南",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "李淑云",
        "b": "370305194004035316",
        "c": "东河",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "张美芹",
        "b": "37030519431008532X",
        "c": "东河",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王江洲",
        "b": "370305194005285317",
        "c": "柴南",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "张建功",
        "b": "370305193612265313",
        "c": "东齐",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王彩云",
        "b": "370305194012035324",
        "c": "东齐",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王玉莲",
        "b": "370305193612215324",
        "c": "北田旺",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "冯玉兰",
        "b": "37030519280115532X",
        "c": "西申",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王和春",
        "b": "370305194302164714",
        "c": "王桥村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王英才",
        "b": "370305194001254716",
        "c": "王桥村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "曹清元",
        "b": "370305195309285337",
        "c": "南曹村",
        "d": "安装自动感应灯具*1,防滑处理*4,一字扶手*2,蹲便器改坐便器*1,三脚拐凳*1,配置淋浴椅*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "陈良玉",
        "b": "370305195009104717",
        "c": "大路南村",
        "d": "安装自动感应灯具*1,电源插座及开关改造*2,防滑处理*4,一字扶手*2,助行器*1,配置防压疮坐垫*1,配置淋浴椅*1,蹲便器改坐便器*1,烟感探测器*1,紧急呼叫设备*1,视频监控设备*1,智能网关*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "董庆年",
        "b": "37030519430524471X",
        "c": "小张村",
        "d": "配置护理床*1,蹲便器改坐便器*1,智能网关*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "李会全",
        "b": "370305194211065315",
        "c": "温家村",
        "d": "安装自动感应灯具*2,防滑处理*4,一字扶手*2,助听器*1,三脚拐凳*1,防撞条角*4,配置淋浴椅*1,助行器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "李茂太",
        "b": "370305194010045318",
        "c": "东河村",
        "d": "电源插座及开关改造*2,一字扶手*1,安装自动感应灯具*1,防滑处理*4,配置防压疮床垫*1,配置淋浴椅*1,三脚拐凳*1,视频监控设备*1,烟感探测器*1,紧急呼叫设备*1,智能网关*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "刘成功",
        "b": "370305194701115354",
        "c": "温家村",
        "d": "安装自动感应灯具*2,配置护理床*1,三脚拐凳*1,紧急呼叫设备*1,烟感探测器*1,智能网关*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "刘申贵",
        "b": "370305194201125310",
        "c": "南霸村",
        "d": "安装自动感应灯具*1,防滑处理*4,一字扶手*2,蹲便器改坐便器*1,三脚拐凳*1,配置淋浴椅*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "齐利民",
        "b": "370305195012095313",
        "c": "南齐村",
        "d": "安装自动感应灯具*1,电源插座及开关改造*1,防滑处理*4,一字扶手*2,配置淋浴椅*1,四脚拐杖*1,蹲便器改坐便器*1,紧急呼叫设备*2,烟感探测器*1,防走失装置*1,智能网关*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "史纯章",
        "b": "37030519490119531X",
        "c": "史家村",
        "d": "一字扶手*2,安装自动感应灯具*1,防滑处理*4,三脚拐凳*1,防撞条角*4,蹲便器改坐便器*1,配置淋浴椅*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "史业诚",
        "b": "370305195210115311",
        "c": "史家村",
        "d": "安装自动感应灯具*1,一字扶手*2,防滑处理*4,蹲便器改坐便器*1,防撞条角*4,三脚拐凳*1,配置淋浴椅*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "孙桂英",
        "b": "370305192409244723",
        "c": "大路北村",
        "d": "三脚拐凳*1,配置护理床*1,防撞条角*4,紧急呼叫设备*1,烟感探测器*1,智能网关*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王继文",
        "b": "370305195510125335",
        "c": "土桥村",
        "d": "一字扶手*2,安装自动感应灯具*1,防滑处理*4,蹲便器改坐便器*1,配置防压疮坐垫*1,配置淋浴椅*1,烟感探测器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王敬",
        "b": "370305196101304740",
        "c": "北罗村",
        "d": "安装自动感应灯具*1,防滑处理*2,一字扶手*2,三脚拐凳*1,蹲便器改坐便器*1,配置淋浴椅*1,智能网关*1,防走失装置*1,烟感探测器*1,活动监测设备*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王克茂",
        "b": "370305195303054714",
        "c": "寇家村",
        "d": "安装自动感应灯具*2,一字扶手*2,防滑处理*4,配置淋浴椅*1,配置防压疮坐垫*1,助行器*1,蹲便器改坐便器*1,烟感探测器*1,智能网关*1,视频监控设备*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王淑夫",
        "b": "370305194603034710",
        "c": "西申村",
        "d": "电源插座及开关改造*2,防撞条角*4,轮椅*1,自助进食器具*1,台盆扶手*1,紧急呼叫设备*2,烟感探测器*1,智能网关*1,活动监测设备*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王秀花",
        "b": "370305195510195325",
        "c": "柴南村",
        "d": "安装自动感应灯具*3,一字扶手*2,安装闪光震动门铃*1,防滑处理*4,四脚拐杖*1,蹲便器改坐便器*1,配置防压疮靠垫*1,防撞条角*4,配置淋浴椅*1,紧急呼叫设备*2,防走失装置*1,智能网关*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王旬良",
        "b": "370305194603015317",
        "c": "王青村",
        "d": "一字扶手*3,电源插座及开关改造*2,安装自动感应灯具*1,防滑处理*4,配置淋浴椅*1,三脚拐凳*1,助听器*1,视频监控设备*1,紧急呼叫设备*2,智能网关*1,烟感探测器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王玉增",
        "b": "370305196208155319",
        "c": "西老村",
        "d": "安装自动感应灯具*3,防滑处理*3,一字扶手*2,轮椅*1,配置淋浴椅*1,防撞条角*4,安装床边护栏（抓杆）*1,紧急呼叫设备*1,烟感探测器*1,智能网关*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王元农",
        "b": "370305195102034717",
        "c": "北罗村",
        "d": "安装自动感应灯具*3,防滑处理*4,电源插座及开关改造*1,一字扶手*2,蹲便器改坐便器*1,配置淋浴椅*1,智能网关*1,紧急呼叫设备*2,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "张正德",
        "b": "370305194503244710",
        "c": "小张村",
        "d": "安装自动感应灯具*3,防滑处理*4,一字扶手*1,配置淋浴椅*1,蹲便器改坐便器*1,配置防压疮坐垫*1,三脚拐凳*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "赵立俭",
        "b": "370305195110044712",
        "c": "侯屯村",
        "d": "安装自动感应灯具*3,防滑处理*4,一字扶手*2,四脚拐杖*1,防撞条角*4,配置淋浴椅*1,助听器*1,蹲便器改坐便器*1,安装床边护栏（抓杆）*1,智能网关*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "张树槐",
        "b": "370305194511085350",
        "c": "南太合村",
        "d": "安装自动感应灯具*3,防滑处理*4,电源插座及开关改造*1,一字扶手*2,蹲便器改坐便器*1,配置淋浴椅*1,智能网关*1,紧急呼叫设备*2,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王炳芳",
        "b": "370305194408204710",
        "c": "王桥村",
        "d": "安装自动感应灯具*3,防滑处理*4,电源插座及开关改造*1,一字扶手*2,蹲便器改坐便器*1,配置淋浴椅*1,智能网关*1,紧急呼叫设备*2,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "韩淑兰",
        "b": "370305194709104728",
        "c": "南罗村",
        "d": "安装自动感应灯具*3,防滑处理*4,电源插座及开关改造*1,一字扶手*2,蹲便器改坐便器*1,配置淋浴椅*1,智能网关*1,紧急呼叫设备*2,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "孙绪香",
        "b": "370305194112054725",
        "c": "东申村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "孙玉忠",
        "b": "370305194002244712",
        "c": "大张村",
        "d": "电源插座及开关改造*1,智能网关*1,视频监控设备*1,紧急呼叫设备*1,烟感探测器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王绪耀",
        "b": "370305194003144713 ",
        "c": "王桥村",
        "d": "安装自动感应灯具*3,防滑处理*3,一字扶手*2,四脚拐杖*1,配置淋浴椅*1,助听器*1,安装床边护栏（抓杆）*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王玉朴",
        "b": "370305195612085311",
        "c": "柴南村",
        "d": "一字扶手*2,安装自动感应灯具*3,防滑处理*4,三脚拐凳*1,蹲便器改坐便器*1,安装床边护栏（抓杆）*1,防撞条角*4,配置淋浴椅*1,烟感探测器*1,智能网关*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "罗领臣",
        "b": "37030519531018471x",
        "c": "南罗村",
        "d": "一字扶手*2,防滑处理*4,电源插座及开关改造*2,配置防压疮床垫*1,配置淋浴椅*1,助行器*1,视频监控设备*1,烟感探测器*1,智能网关*1,紧急呼叫设备*2",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "罗公平",
        "b": "370305195201264737",
        "c": "南罗村",
        "d": "电源插座及开关改造*1,配置护理床*1,三脚拐凳*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "罗洪友",
        "b": "370305195105034739",
        "c": "南罗村",
        "d": "蹲便器改坐便器*1,配置护理床*1,智能网关*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": "2024.7"
    }
]
      this.table.refreshFlag = new Date().getTime().toString();
    },
    // 更多条件
    moreConditions() {
      this.moreShow = !this.moreShow;
    },
    // 级联事件
    handleChange(value) {
      console.log(value);
    },
    // 上传成功返回
    handleAvatarSuccess() {},
    // 上传前
    beforeAvatarUpload() {},
    // 表单提交
    onSubmit() {
      console.log("submit!");
    },
    // 改造项统计
    statistics() {
      this.$refs.drawerIshow4.drawer = true;
    },
    // 表单重置
    resetForm() {
      const th = this;
      Object.keys(th.formInline).forEach(function (key) {
        if (key == "value") {
          th.formInline.value = "1";
        } else {
          th.formInline[key] = "";
        }
      });
    },
    // 单元格点击
    cellClick(row, column, cell, event) {
      // console.log(row, column, cell, event);
      const that = this;
      switch (column.label) {
        case "服务对象":
          that.$refs.columnShow1.drawer = true;
          break;
        case "施工组织":
          that.$refs.columnShow2.drawer = true;
          break;
        case "施工状态":
          that.$refs.columnShow3.drawer = true;
          break;
        case "验收组织":
          that.$refs.columnShow4.drawer = true;
          break;
        case "验收状态":
          that.$refs.columnShow5.drawer = true;
          break;
        case "终审":
          that.$refs.columnShow6.drawer = true;
          break;
        default:
          break;
      }
    },
    // 预选改造项显示隐藏
    isTableShow() {
      this.tableShow = !this.tableShow;
    },
    // 改造项
    transform() {
      this.title = "改造项清单";
      this.$refs.drawerIshow.drawer = true;
    },
    // 改造项新增
    transformIncrease() {
      this.transformTitle = "新增信息";
      this.$refs.drawerIshowTransform.drawer = true;
    },
    // 修改
    transformPut() {
      this.transformTitle = "编辑信息";
      this.$refs.drawerIshowTransform.drawer = true;
    },
    // 改造项解绑
    transformDelate() {},

    // 设备接入
    deviceAccess() {
      this.title = "设备接入";
      this.$refs.drawerIshow2.drawer = true;
    },
    // 设备接入添加/修改
    deviceAccessIncrease() {
      this.title = "绑定设备";
      this.$refs.drawerIshowDeviceAccess.drawer = true;
    },
    deviceAccessPut() {
      this.title = "修改设备";
      this.$refs.drawerIshowDeviceAccess.drawer = true;
    },

    // 改造详情
    transformInformation() {
      this.title = "改造详情";
      this.$refs.drawerIshow3.drawer = true;
    },
    // 主页删除
    delate() {},
    close() {
      this.$refs.drawerIshow.drawer = false;
    },
  },
};
</script>

<style lang="scss">
.box {
  width: 100%;
  height: 100%;
  overflow: hidden;

  // background: white;
}

.title {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  background: white;
  border-radius: 0.5rem;
  font-size: 1.5rem;

  i {
    margin-right: 0.5rem;
  }
}

// 主页
.mainContent {
  width: 100%;
  height: calc(100% - 5rem);
  background: white;
  border-radius: 0.5rem;
  margin-top: 1rem;
  .search {
    width: 100%;
    box-sizing: border-box;
  }

  .demo-form-inline {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .table {
    width: 100%;
    height: calc(100% - 4.5rem);
  }

  .el-input {
    width: 12rem;
  }

  .el-select {
    width: 12rem;
  }
}

// 改造项
.transformContent {
  width: 100%;
  height: 100%;

  .table {
    width: 100%;
    height: 15rem;
    margin: 1rem 0 2rem 0;
  }

  .look {
    width: 100%;

    .btn {
      font-size: 0.5rem;
      color: skyblue;
      cursor: pointer;
    }

    .bot {
      width: 100%;
      height: 3rem;
      background: #f0f0f0;
      display: flex;
      align-items: center;
      color: rgb(235, 190, 135);
      margin-top: 1.5rem;
      padding: 0 1rem;
      box-sizing: border-box;

      .text {
        margin-left: 1rem;
      }
    }
  }

  .zj {
    width: 100%;
    text-align: center;
  }
}

// 设备接入
.deviceAccessContent {
  .search {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
  }

  .table {
    width: 100%;
    height: 30rem;
    margin: 1rem 0 2rem 0;
  }
}

// 改造详情
.transformInformationContent {
  width: 100%;

  .header {
    width: 100%;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
  }

  .btn {
    position: absolute;
    top: 6rem;
    right: 5rem;
  }

  .table {
    width: 100%;
    height: 20rem;
  }
}

.el-input {
  width: 40rem;
}

.el-select {
  width: 40rem;
}

// 上传
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>



<!-- this.table.staticData = [
// {
//   a: "370305195105225025",
//   b: "魏建国",
//   c: 15966957314,
//   d: "临淄区",
//   e: "雪宫街道",
//   f: "桓公路社区居委会",
//   g: "桓公路北巷10-2-402",
//   j: "临淄区验收组织",
//   o: 0,
//   p: 0,
//   r: 0,
//   s: 0,
//   t: 3,
//   u: 980,
// },
// {
//   a: "370305193905192824",
//   b: "顾长城",
//   c: 13506443802,
//   d: "临淄区",
//   e: "雪宫街道",
//   f: "东高社区居委会",
//   g: "雪宫街道东高社区26-2-202",
//   j: "临淄区验收组织",
//   o: 0,
//   p: 0,
//   r: 0,
//   s: 0,
//   t: 3,
//   u: 980,
// },
// {
//   a: "370305193704260018",
//   b: "无",
//   c: "无",
//   d: "临淄区",
//   e: "辛店街道",
//   f: "仉行村委会",
//   g: "临淄区辛店街道仉行村3组71号",
//   j: "临淄区验收组织",
//   o: 0,
//   p: 0,
//   r: 0,
//   s: 0,
//   t: 3,
//   u: 980,
// },
// {
//   a: "370305195707043114",
//   b: "范友民",
//   c: 13581005738,
//   d: "临淄区",
//   e: "稷下街道",
//   f: "范家村委会",
//   g: "范家村8-1-203",
//   j: "临淄区验收组织",
//   o: 0,
//   p: 0,
//   r: 0,
//   s: 0,
//   t: 3,
//   u: 980,
// },
// {
//   a: "370305195604041829",
//   b: "刘建明",
//   c: 13287060605,
//   d: "临淄区",
//   e: "辛店街道",
//   f: "东夏社区居委会",
//   g: "东夏新村6-6-302",
//   j: "临淄区验收组织",
//   o: 0,
//   p: 0,
//   r: 0,
//   s: 0,
//   t: 3,
//   u: 980,
// },
// {
//   a: "370305195511062110",
//   b: "王斐",
//   c: 15053327270,
//   d: "临淄区",
//   e: "金岭镇",
//   f: "金岭二村委会",
//   g: "金岭二村1294号",
//   j: "临淄区验收组织",
//   o: 0,
//   p: 0,
//   r: 0,
//   s: 0,
//   t: 5,
//   u: 1610,
// },
// {
//   a: "370305195406122425",
//   b: "王寿山",
//   c: 13964404403,
//   d: "临淄区",
//   e: "齐都镇",
//   f: "韶院村委会",
//   g: "韶院16号",
//   j: "临淄区验收组织",
//   o: 0,
//   p: 0,
//   r: 0,
//   s: 0,
//   t: 5,
//   u: 1170,
// },
// {
//   a: "370305194206140026",
//   b: "魏爱军",
//   c: 18553371107,
//   d: "临淄区",
//   e: "雪宫街道",
//   f: "齐国商城社区居委会",
//   g: "齐国商城30-4-201",
//   j: "临淄区验收组织",
//   o: 0,
//   p: 0,
//   r: 0,
//   s: 0,
//   t: 12,
//   u: 1600,
// },
// {
//   a: "370305195512042816",
//   b: "孙志鹏",
//   c: 15689077666,
//   d: "临淄区",
//   e: "稷下街道",
//   f: "王家村委会",
//   g: "王家村141号",
//   j: "临淄区验收组织",
//   o: 0,
//   p: 0,
//   r: 0,
//   s: 0,
//   t: 10,
//   u: 1240,
// }, -->

<!-- {
  a: "边增芹",
  b: "370305195105225025",
  c: "魏建国",
  d: "15966957314",
  e: "临淄区",
  f: "雪宫街道",
  g: "桓公路社区居委会",
  h: "桓公路北巷10-2-402",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "3.0",
  m: "980.0",
},
{
  a: "杨美荣",
  b: "370305193905192824",
  c: "顾长城",
  d: "13506443802",
  e: "临淄区",
  f: "雪宫街道",
  g: "东高社区居委会",
  h: "雪宫街道东高社区26-2-202",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "3.0",
  m: "980.0",
},
{
  a: "贾广琨",
  b: "370305193704260018",
  c: "无",
  d: "无",
  e: "临淄区",
  f: "辛店街道",
  g: "仉行村委会",
  h: "临淄区辛店街道仉行村3组71号",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "3.0",
  m: "980.0",
},
{
  a: "范建国",
  b: "370305195707043114",
  c: "范友民",
  d: "13581005738",
  e: "临淄区",
  f: "稷下街道",
  g: "范家村委会",
  h: "范家村8-1-203",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "3.0",
  m: "980.0",
},
{
  a: "周明芹",
  b: "370305195604041829",
  c: "刘建明",
  d: "13287060605",
  e: "临淄区",
  f: "辛店街道",
  g: "东夏社区居委会",
  h: "东夏新村6-6-302",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "3.0",
  m: "980.0",
},
{
  a: "王能建",
  b: "370305195511062110",
  c: "王斐",
  d: "15053327270",
  e: "临淄区",
  f: "金岭镇",
  g: "金岭二村委会",
  h: "金岭二村1294号",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "5.0",
  m: "1610.0",
},
{
  a: "张会华",
  b: "370305195406122425",
  c: "王寿山",
  d: "13964404403",
  e: "临淄区",
  f: "齐都镇",
  g: "韶院村委会",
  h: "韶院16号",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "5.0",
  m: "1170.0",
},
{
  a: "郝慧兰",
  b: "370305194206140026",
  c: "魏爱军",
  d: "18553371107",
  e: "临淄区",
  f: "雪宫街道",
  g: "齐国商城社区居委会",
  h: "齐国商城30-4-201",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "12.0",
  m: "1600.0",
},
{
  a: "杜希明",
  b: "370305195512042816",
  c: "孙志鹏",
  d: "15689077666",
  e: "临淄区",
  f: "稷下街道",
  g: "王家村委会",
  h: "王家村141号",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "10.0",
  m: "1240.0",
},
{
  a: "安学胜",
  b: "370305194511022819",
  c: "杜希晨",
  d: "13626431712",
  e: "临淄区",
  f: "稷下街道",
  g: "王家村委会",
  h: "王家村480号",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "15.0",
  m: "1100.0",
},
{
  a: "赵学书",
  b: "370305193811212812",
  c: "赵永磊",
  d: "13754752359",
  e: "临淄区",
  f: "稷下街道",
  g: "高娄村委会",
  h: "高娄村191号",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "11.0",
  m: "1340.0",
},
{
  a: "赵培章",
  b: "370305196201202813",
  c: "李森",
  d: "15169259985",
  e: "临淄区",
  f: "稷下街道",
  g: "董褚村委会",
  h: "董褚村364号",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "10.0",
  m: "1140.0",
},
{
  a: "胡玉贵",
  b: "370305194301223110",
  c: "胡绣山",
  d: "13969334617",
  e: "临淄区",
  f: "稷下街道",
  g: "尧王村村委会",
  h: "稷下尧王村233号",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "13.0",
  m: "1360.0",
},
{
  a: "齐富英",
  b: "370305195401114741",
  c: "陈延文",
  d: "17865969303",
  e: "临淄区",
  f: "凤凰镇",
  g: "大路南村委会",
  h: "临淄区凤凰镇大路南村",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "8.0",
  m: "1220.0",
},
{
  a: "贾来用",
  b: "370305194702174313",
  c: "贾永建",
  d: "13280604377",
  e: "临淄区",
  f: "敬仲镇",
  g: "东苇村委会",
  h: "敬仲镇东苇河村",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "9.0",
  m: "1130.0",
},
{
  a: "吕桂英",
  b: "370305194811203427",
  c: "周新梅",
  d: "15169395534",
  e: "临淄区",
  f: "齐陵街道",
  g: "宋家村委会",
  h: "齐陵街道前李村",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "10.0",
  m: "1330.0",
},
{
  a: "石敦荣",
  b: "370305195512155917",
  c: "石英",
  d: "13455349832",
  e: "临淄区",
  f: "朱台镇",
  g: "前夏东村委会",
  h: "前夏东村",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "16.0",
  m: "1390.0",
},
{
  a: "蔺效忠",
  b: "370305195301223430",
  c: "王美花",
  d: "13864485312",
  e: "临淄区",
  f: "雪宫街道",
  g: "雪宫社区居委会",
  h: "雪南15-1206",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "4.0",
  m: "1080.0",
},
{
  a: "徐敬春",
  b: "37030519440919151X",
  c: "徐首博",
  d: "13869337819",
  e: "临淄区",
  f: "雪宫街道",
  g: "桓公路社区居委会",
  h: "桓公路北巷8-2-102",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "8.0",
  m: "1700.0",
},
{
  a: "张金河",
  b: "370305194711080714",
  c: "颜贵芸",
  d: "13853334184",
  e: "临淄区",
  f: "雪宫街道",
  g: "齐国商城社区居委会",
  h: "齐国商城40-5-201",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "7.0",
  m: "1550.0",
},
{
  a: "唐秀华",
  b: "370305195409210746",
  c: "梁延亮",
  d: "13969306122",
  e: "临淄区",
  f: "雪宫街道",
  g: "东高社区居委会",
  h: "东高5-1-401",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "9.0",
  m: "1570.0",
},
{
  a: "李风岐",
  b: "21072419461206001X",
  c: "李孟新",
  d: "18369913305",
  e: "临淄区",
  f: "雪宫街道",
  g: "齐国商城社区居委会",
  h: "齐国商城23-3-101",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "12.0",
  m: "1790.0",
},
{
  a: "何世英",
  b: "37030519580315288X",
  c: "赵怀堂",
  d: "19805337066",
  e: "临淄区",
  f: "雪宫街道",
  g: "雪宫社区居委会",
  h: "雪北42-202",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "5.0",
  m: "1090.0",
},
{
  a: "解希廷",
  b: "370305194502100416",
  c: "解玉香",
  d: "13355233128",
  e: "临淄区",
  f: "雪宫街道",
  g: "桓公路社区居委会",
  h: "桓公路北巷2-2-301",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "9.0",
  m: "1520.0",
},
{
  a: "闫洪兰",
  b: "370305195411015632",
  c: "路全修",
  d: "13969307767",
  e: "临淄区",
  f: "雪宫街道",
  g: "齐国商城社区居委会",
  h: "齐国商城32-3-102",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "7.0",
  m: "1300.0",
},
{
  a: "尹承业",
  b: "370922194011281311",
  c: "陆秀玲",
  d: "15069388053",
  e: "临淄区",
  f: "雪宫街道",
  g: "齐国商城社区居委会",
  h: "齐国商城29-2-202",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "9.0",
  m: "1570.0",
},
{
  a: "马思福",
  b: "370305194207162817",
  c: "王美英",
  d: "13156983713",
  e: "临淄区",
  f: "雪宫街道",
  g: "东高社区居委会",
  h: "雪宫街道东高社区24-3-101",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "8.0",
  m: "1560.0",
},
{
  a: "臧桂福",
  b: "370305195002262413",
  c: "邵春香",
  d: "13793311689",
  e: "临淄区",
  f: "雪宫街道",
  g: "齐国商城社区居委会",
  h: "大顺南一区16-3-101",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "5.0",
  m: "1090.0",
},
{
  a: "郗继英",
  b: "370305196505230020",
  c: "贾学栋",
  d: "15053324283",
  e: "临淄区",
  f: "辛店街道",
  g: "仉行村委会",
  h: "仉行村",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "6.0",
  m: "1100.0",
},
{
  a: "毕秀花",
  b: "370305195211142127",
  c: "范保亮",
  d: "15269677499",
  e: "临淄区",
  f: "金岭镇",
  g: "金岭五村委会",
  h: "金岭五村金岭路1046号",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "4.0",
  m: "1080.0",
},
{
  a: "周景凤",
  b: "370305195910033720",
  c: "荣若武",
  d: "15064384243",
  e: "临淄区",
  f: "皇城镇",
  g: "荣家村委会",
  h: "皇城镇荣家村",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "7.0",
  m: "160.0",
},
{
  a: "郭素梅",
  b: "370305194905055322",
  c: "王继雨",
  d: "15069373832",
  e: "临淄区",
  f: "凤凰镇",
  g: "南王村委会",
  h: "山东省淄博市临淄区凤凰镇南王村108号",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "16.0",
  m: "1390.0",
},
{
  a: "高思功",
  b: "370305194806191812",
  c: "高鹏",
  d: "13853334675",
  e: "临淄区",
  f: "辛店街道",
  g: "高家社区居委会",
  h: "勇士",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "4.0",
  m: "1430.0",
},
{
  a: "王焕栋",
  b: "370305195610105331",
  c: "路学军",
  d: "15206688666",
  e: "临淄区",
  f: "凤凰镇",
  g: "北曹村委会",
  h: "北曹村3-39",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "2.0",
  m: "1430.0",
},
{
  a: "王桂英",
  b: "370305193608213422",
  c: "于清波",
  d: "15153307486",
  e: "临淄区",
  f: "齐陵街道",
  g: "宋家村委会",
  h: "宋家村2组173",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "10.0",
  m: "1140.0",
},
{
  a: "李云英",
  b: "370305195602134028",
  c: "梁金华",
  d: "13964326505",
  e: "临淄区",
  f: "皇城镇",
  g: "西南羊村委会",
  h: "西南羊村",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "11.0",
  m: "1590.0",
},
{
  a: "王桂英",
  b: "37030519330610532X",
  c: "路保元",
  d: "15069395238",
  e: "临淄区",
  f: "凤凰镇",
  g: "北曹村委会",
  h: "北曹村",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "9.0",
  m: "1570.0",
},
{
  a: "徐美英",
  b: "370305195005222425",
  c: "高爱云",
  d: "15653377650",
  e: "临淄区",
  f: "齐都镇",
  g: "谭家村委会",
  h: "齐都镇谭家村241号",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "4.0",
  m: "1080.0",
},
{
  a: "吴玉莲",
  b: "370305195512132440",
  c: "侯元顺",
  d: "15064393025",
  e: "临淄区",
  f: "齐都镇",
  g: "付家村委会",
  h: "付家",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "9.0",
  m: "1040.0",
},
{
  a: "朱国英",
  b: "370305195510012429",
  c: "路俊霞",
  d: "13964399726",
  e: "临淄区",
  f: "齐都镇",
  g: "大王村委会",
  h: "大王村53号",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "11.0",
  m: "1590.0",
},
{
  a: "王德勋",
  b: "370305194802032419",
  c: "王凯",
  d: "15898737543",
  e: "临淄区",
  f: "齐都镇",
  g: "粉庄村委会",
  h: "粉庄村155",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "12.0",
  m: "300.0",
},
{
  a: "苏阳林",
  b: "370305195011132450",
  c: "苏之勇",
  d: "13792163134",
  e: "临淄区",
  f: "齐都镇",
  g: "尹家村委会",
  h: "尹家村420号",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "7.0",
  m: "1300.0",
},
{
  a: "姚兰英",
  b: "370305194210192427",
  c: "臧国栋",
  d: "13581011697",
  e: "临淄区",
  f: "齐都镇",
  g: "西关北村委会",
  h: "临淄区齐都镇西关北村34号",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "10.0",
  m: "1140.0",
},
{
  a: "刘云华",
  b: "370305195412262424",
  c: "赵来凤",
  d: "13616434192",
  e: "临淄区",
  f: "齐都镇",
  g: "督府村委会",
  h: "齐都镇督府村48号",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "5.0",
  m: "1000.0",
},
{
  a: "朱凤莲",
  b: "370305194312192444",
  c: "丁希田",
  d: "15169300441",
  e: "临淄区",
  f: "齐都镇",
  g: "西古村委会",
  h: "齐都镇西古村278号",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "14.0",
  m: "1810.0",
},
{
  a: "徐洪书",
  b: "370305195012134319",
  c: "徐忠新",
  d: "13706432677",
  e: "临淄区",
  f: "敬仲镇",
  g: "徐家圈村委会",
  h: "敬仲镇徐家圈村",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "11.0",
  m: "1780.0",
},
{
  a: "徐桂森",
  b: "370305194408104314",
  c: "徐忠新",
  d: "13706432677",
  e: "临淄区",
  f: "敬仲镇",
  g: "徐家圈村委会",
  h: "敬仲镇徐家圈村",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "13.0",
  m: "1610.0",
},
{
  a: "张敦武",
  b: "370305195802194359",
  c: "朱振国",
  d: "13589584629",
  e: "临淄区",
  f: "敬仲镇",
  g: "小东王村委会",
  h: "敬仲镇小东王村",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "13.0",
  m: "1610.0",
},
{
  a: "张树言",
  b: "370305195611124358",
  c: "朱振国",
  d: "13589584629",
  e: "临淄区",
  f: "敬仲镇",
  g: "小东王村委会",
  h: "敬仲镇小东王村",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "13.0",
  m: "1610.0",
},
{
  a: "张本洪",
  b: "370305195505234318",
  c: "朱振国",
  d: "13589584629",
  e: "临淄区",
  f: "敬仲镇",
  g: "小东王村委会",
  h: "敬仲镇小东王村",
  i: "海尔卡奥斯",
  j: "张金龙",
  k: "临淄区验收组织",
  l: "12.0",
  m: "1600.0",
},
]; -->

<!-- column: [
          {
            label: "服务对象",
            prop: "a",
            checked: true,
            width: "150",
          },
          {
            label: "身份证号",
            prop: "b",
            checked: true,
            width: "150",
          },
          {
            label: "联系人",
            prop: "c",
            checked: true,
            width: "150",
          },
          {
            label: "联系人电话",
            prop: "d",
            checked: true,
            width: "150",
          },
          {
            label: "区县",
            prop: "e",
            checked: true,
            width: "150",
          },
          {
            label: "街道",
            prop: "f",
            checked: true,
            width: "150",
          },
          {
            label: "社区",
            prop: "g",
            checked: true,
            width: "150",
          },
          {
            label: "地址",
            prop: "h",
            checked: true,
            width: "150",
          },
          {
            label: "施工组织",
            prop: "i",
            checked: true,
            width: "150",
          },
          {
            label: "施工人员",
            prop: "j",
            checked: true,
            width: "150",
          },
          {
            label: "验收组织",
            prop: "k",
            checked: true,
            width: "150",
          },
          // {
          //   label: "验收日期",
          //   prop: "k",
          //   checked: true,
          //   width: "150",
          // },
          // {
          //   label: "验收负责人",
          //   prop: "l",
          //   checked: true,
          //   width: "150",
          // },
          // {
          //   label: "验收状态",
          //   prop: "m",
          //   checked: true,
          //   width: "150",
          // },
          // {
          //   label: "非智能化设备",
          //   prop: "n",
          //   checked: true,
          //   width: "150",
          // },
          // {
          //   label: "非智能化设备费用",
          //   prop: "o",
          //   checked: true,
          //   width: "150",
          // },
          // {
          //   label: "非智能化设备总数量",
          //   prop: "p",
          //   checked: true,
          //   width: "150",
          // },
          // {
          //   label: "智能化设备",
          //   prop: "q",
          //   checked: true,
          //   width: "150",
          // },
          // {
          //   label: "智能化设备费用",
          //   prop: "r",
          //   checked: true,
          //   width: "150",
          // },
          // {
          //   label: "智能化设备总数量",
          //   prop: "s",
          //   checked: true,
          //   width: "150",
          // },
          {
            label: "设备总数量",
            prop: "l",
            checked: true,
            width: "150",
          },
          {
            label: "设备总费用",
            prop: "m",
            checked: true,
            width: "150",
          },
        ], -->