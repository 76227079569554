<template>
  <div class="hl-full-container flexVertical">
    <!-- 上方标题和按钮 -->
    <div class="assistedMealManagement">
      <div class="mealAssistance">
        <i class="el-icon-film"></i>
        <span class="title">助餐统计</span>
        <span class="subTitle">(所有统计均不包含生存状态异常的订单)</span>
      </div>
      <div class="management">
        <!-- <el-button type="primary" size="small" icon="el-icon-film" @click="openOperateStatistics">经营统计</el-button> -->
      </div>
    </div>
    <!-- 年份选择和echarts图 -->
    <div class="yearAndEcharts">
      <el-row :gutter="24">
        <el-form :model="query" label-width="3.8rem">
          <el-col :span="4">
            <el-form-item label="年份：">
              <el-date-picker
                v-model="query.year"
                type="year"
                placeholder="选择年"
                :clearable="false"
                @change="changeYear"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <!-- <el-form-item label="" label-width="0px">
              <el-checkbox class="selfFunded" v-model="query.selfFunded" @change="changeSelfFunded">显示自费</el-checkbox>
            </el-form-item> -->
          </el-col>
        </el-form>
      </el-row>
      <!-- echarts图 -->
      <barEchart
        :option="option"
        :eWidth="'100%'"
        :eHeight="'10rem'"
      ></barEchart>
    </div>
    <!-- 搜索和金额 -->
    <!-- <div class="seatchSubsidy">
      <el-form :model="query" ref="query" label-width="80px" inline>
        <el-form-item label="就餐日期" prop="kqzt">
          <el-date-picker
            v-model="query.kazt"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="服务组织">
          <el-select v-model="query.level" placeholder="请选择">
            <el-option key="全部" label="全部" :value="null"></el-option>
            <el-option key="待服务" label="待服务" value="待服务"></el-option>
            <el-option key="服务中" label="服务中" value="服务中"></el-option>
            <el-option key="已服务" label="已服务" value="已服务"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否自营">
          <el-select v-model="query.level" placeholder="请选择">
            <el-option key="全部" label="全部" :value="null"></el-option>
            <el-option key="待服务" label="待服务" value="待服务"></el-option>
            <el-option key="服务中" label="服务中" value="服务中"></el-option>
            <el-option key="已服务" label="已服务" value="已服务"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
          <el-button type="info" size="small" icon="el-icon-download" @click="derive">按补贴对象导出</el-button>
        </el-form-item>
      </el-form>
      <div class="subsidy">
        <div v-for="item in subsidyList" :key="item.name">
          <span class="name">{{ item.name }}</span>
          <span class="number">{{ item.number }}</span>
          <span class="unit">{{ item.unit }}</span>
        </div>
      </div>
    </div> -->
    <!-- 选项卡 -->
    <hltableZ
      :columnEdit="table.columnEdit"
      :tableColumn="table.column"
      :apiurl="table.apiurl"
      :isPage="false"
      :staticData="table.staticData"
      :refreshFlag="table.refreshFlag"
      :isChecked="table.isChecked"
      :isNumber="table.isNumber"
      :isClickSelect="table.isClickSelect"
      :formSearch="formSearch"
      style="height: 100%"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-button
          v-if="activeName === 'first'"
          icon="el-icon-view"
          type="text"
          size="small"
          @click="search(selected.singlerow)"
          >查看</el-button
        >
        <!-- <el-button icon="el-icon-back" type="text" size="small"
                  @click="chargeback(selected.singlerow)">退单</el-button> -->
      </div>
    </hltableZ>
    <!-- 经营统计 -->
    <operateStatisticsDrawer
      ref="operateStatisticsDrawer"
    ></operateStatisticsDrawer>
    <!-- 订单详情 -->
    <orderForm ref="orderForm"></orderForm>
  </div>
</template>
<script>
import { hltableZ } from "@/components";
import { orderFormTablesData } from "./statisticsTablesData";
import orderForm from "./components/orderForm/OrderFormInfo.vue";

import barEchart from "./echarts/barEchart.vue";
import statisticsTabs from "./components/statistics/StatisticsTabs.vue";
import operateStatisticsDrawer from "./components/statistics/OperateStatisticsDrawer.vue";
import orderFormInfo from "./components/statistics/orderFormInfo.vue";

const year = new Date().getFullYear().toString();
const defaultOption = {
  grid: {
    left: "2%",
    right: "5%",
    bottom: "5%",
    top: "20%",
    containLabel: true,
  },
  color: ["#3d9cf0", "#0b76d5"],
  tooltip: {
    trigger: "axis",
    backgroundColor: "rgba(50,50,50,0.6)",
    borderWidth: 0,
    textStyle: {
      color: "#fff",
    },
    axisPointer: {
      type: "shadow",
    },
  },
  xAxis: {
    name: "月份",
    nameTextStyle: {
      color: "#000000",
    },
    type: "category",
    data: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ],
    axisLabel: {
      textStyle: {
        fontSize: "14px",
        color: "#000000",
      },
    },
    axisLine: {
      show: true,
    },
  },
  yAxis: {
    name: "助餐人次",
    nameTextStyle: {
      color: "#000000",
    },
    type: "value",
    axisLabel: {
      textStyle: {
        fontSize: "14px",
        color: "#000000",
      },
    },
    axisLine: {
      show: true,
    },
    axisTick: {
      show: true,
    },
    splitLine: {
      lineStyle: {
        color: "#cccccc",
      },
    },
  },
  series: [
    {
      name: "补贴助餐人次",
      type: "bar",
      data: [50100, 45300, 48663, 50033, 21320, 0, 0, 0, 0, 0, 0, 0],
    },
  ],
};
const defaultSubsidyList = [
  { name: "日均：", number: 0, unit: "次/日" },
  { name: "金额：", number: 0, unit: "元" },
  { name: "人数：", number: 0, unit: "人" },
  { name: "次数：", number: 0, unit: "次" },
];

export default {
  name: "statistics",
  components: {
    barEchart,
    statisticsTabs,
    operateStatisticsDrawer,
    orderFormInfo,
    hltableZ,
    orderForm,
  },
  data() {
    return {
      query: {
        year,
        selfFunded: false,
      },
      option: { ...defaultOption },
      subsidyList: [...defaultSubsidyList],
      table: { ...orderFormTablesData.first },
      activeName: "first",
      formSearch: {
        mealLocation: null,
        dishType: null,
        subsidyOrder: null,
        diningDate: "",
        memberType: null,
        memberCheck: null,
        cooperateType: "",
        account: "",
        orderStatus: "",
        ageRange: "",
        payment: "",
        takedashStatus: null,
        deliveryStatus: null,
        takedashDate: null,
        name: "",
        IDCard: "",
        name: "",
        subsidy: "",
        date: "",
      },
    };
  },
  mounted() {
    this.dataRefresh();
  },
  methods: {
    // 获取echarts数据
    getEchartsData() {
      this.$store
        .dispatch("homePage/buffetDataCountGetMonthCount")
        .then((res) => {
          console.log("res===>", res);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取中间数据
    getCenterData() {
      console.log("this.query===>", this.query);
      var date = new Date();
      var Y = date.getFullYear();
      var M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var D =
        date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
      let startTime = Y + "-" + M + "-" + (D - 1);
      let endTime = Y + "-" + M + "-" + D;
      let query = {};
      if (this.query.kazt) {
        query = {
          startTime: this.query.kazt[0], //开始时间
          endTime: this.query.kazt[1], //结束时间
          buffetld: "7523ecd453e54838ae80df5d983a2c7c", //助餐站点id
        };
      } else {
        query = {
          startTime: startTime, //开始时间
          endTime: endTime, //结束时间
          buffetld: "7523ecd453e54838ae80df5d983a2c7c", //助餐站点id
        };
      }

      this.$store
        .dispatch("homePage/buffetDataCountGetCount", query)
        .then((res) => {
          this.subsidyList[0].number = res.result.avgCount;
          this.subsidyList[1].number = res.result.money;
          this.subsidyList[2].number = res.result.totalCount;
          this.subsidyList[3].number = res.result.totalUser;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取所有数据
    getdataList() {},
    // 查询
    dataRefresh() {
      this.table.staticData = [
        {
          a: "24143609235061986309",
          b: "齐都镇东古村长者食堂",
          c: "崔成玉",
          d: "370305195101242426",
          e: "13455352976",
          f: "73.0",
          g: "补贴会员",
          h: "60-79岁补贴对象",
          i: "馒头（60-79岁）(-) x1 ",
          j: "1.00",
          k: "0.00",
          l: "0.00",
          m: "0.00",
          n: "0.00",
          o: "0.0",
          p: "1.00",
          q: "2024-04-17",
          r: "",
          s: "2024-04-17T14:36:09",
        },
        {
          a: "24124436144064952659",
          b: "齐都镇东古村长者食堂",
          c: "王汉民",
          d: "370305195310182415",
          e: "13969335388",
          f: "70.0",
          g: "补贴会员",
          h: "60-79岁补贴对象",
          i: "馒头（60-79岁）(-) x1 ",
          j: "1.00",
          k: "0.00",
          l: "0.00",
          m: "0.00",
          n: "0.00",
          o: "0.0",
          p: "1.00",
          q: "2024-04-17",
          r: "",
          s: "2024-04-17T12:44:36",
        },
        {
          a: "24123013829017871080",
          b: "齐都镇东古村长者食堂",
          c: "王书华",
          d: "370305192709052467",
          e: "13475597157",
          f: "96.0",
          g: "补贴会员",
          h: "80岁以上补贴对象",
          i: "馒头（80岁以上）(-) x1 ",
          j: "0.00",
          k: "0.00",
          l: "0.00",
          m: "0.00",
          n: "0.00",
          o: "0.0",
          p: "0.00",
          q: "2024-04-17",
          r: "",
          s: "2024-04-17T12:30:14",
        },
        {
          a: "24121207860006053823",
          b: "勇士社区长者食堂",
          c: "徐桂兰",
          d: "370305195009092429",
          e: "13054875893",
          f: "73.0",
          g: "补贴会员",
          h: "60-79岁补贴对象",
          i: "肉蒸包(-) x1 ",
          j: "2.00",
          k: "2.00",
          l: "1.00",
          m: "1.00",
          n: "0.00",
          o: "0.0",
          p: "0.00",
          q: "2024-04-17",
          r: "",
          s: "2024-04-17T12:12:08",
        },
        {
          a: "24121154174038369767",
          b: "雪宫社区长者食堂",
          c: "袁传昌",
          d: "370305195805293117",
          e: "15169358059",
          f: "65.0",
          g: "补贴会员",
          h: "60-79岁补贴对象",
          i: "补贴2元(-) x1 ",
          j: "9.00",
          k: "2.00",
          l: "1.00",
          m: "1.00",
          n: "0.00",
          o: "0.0",
          p: "7.00",
          q: "2024-04-17",
          r: "",
          s: "2024-04-17T12:11:54",
        },
        {
          a: "24121148163090095660",
          b: "雪宫社区长者食堂",
          c: "宋乐梅",
          d: "370305196012213126",
          e: "15169358059",
          f: "63.0",
          g: "补贴会员",
          h: "60-79岁补贴对象",
          i: "补贴2元(-) x1 ",
          j: "9.00",
          k: "2.00",
          l: "1.00",
          m: "1.00",
          n: "0.00",
          o: "0.0",
          p: "7.00",
          q: "2024-04-17",
          r: "",
          s: "2024-04-17T12:11:48",
        },
        {
          a: "24121054538024038390",
          b: "雪宫社区长者食堂",
          c: "郑志国",
          d: "370305195310041217",
          e: "13869367944",
          f: "70.0",
          g: "补贴会员",
          h: "60-79岁补贴对象",
          i: "补贴2元(-) x1 ",
          j: "9.00",
          k: "2.00",
          l: "1.00",
          m: "1.00",
          n: "0.00",
          o: "0.0",
          p: "7.00",
          q: "2024-04-17",
          r: "",
          s: "2024-04-17T12:10:55",
        },
        {
          a: "24120728791016340633",
          b: "雪宫街道桓公路长者食堂",
          c: "郭鸿宾",
          d: "37030519450213072X",
          e: "",
          f: "79.0",
          g: "补贴会员",
          h: "60-79岁补贴对象",
          i: "老人午餐(-) x1 ",
          j: "11.00",
          k: "2.00",
          l: "1.00",
          m: "1.00",
          n: "0.00",
          o: "0.0",
          p: "9.00",
          q: "2024-04-17",
          r: "",
          s: "2024-04-17T12:07:29",
        },
        {
          a: "24120624574077248132",
          b: "雪宫街道桓公路长者食堂",
          c: "张月华",
          d: "370305194310040420",
          e: "13754758297",
          f: "80.0",
          g: "补贴会员",
          h: "80岁以上补贴对象",
          i: "老人午餐(-) x1 ",
          j: "11.00",
          k: "3.00",
          l: "2.00",
          m: "1.00",
          n: "0.00",
          o: "0.0",
          p: "8.00",
          q: "2024-04-17",
          r: "",
          s: "2024-04-17T12:06:25",
        },
      ];
      this.table.refreshFlag = new Date().getTime().toString();
    },
    // 导出
    derive() {},
    // 修改年份
    changeYear() {},
    // 显示自费的更改
    changeSelfFunded(val) {
      if (val) {
        this.option.series = [
          {
            name: "补贴助餐人次",
            type: "bar",
            data: [
              820, 932, 901, 934, 1290, 1330, 1320, 820, 932, 901, 934, 1290,
            ],
          },
          {
            name: "自费助餐人次",
            type: "bar",
            data: [
              820, 932, 901, 934, 1290, 1330, 1320, 820, 932, 901, 934, 1290,
            ],
          },
        ];
        this.subsidyList = [
          { name: "补贴助餐：", number: 111, unit: "人次" },
          { name: "自费助餐：", number: 111, unit: "人次" },
          { name: "日均补贴：", number: 111, unit: "次/日" },
          { name: "服务对象：", number: 111, unit: "人" },
          { name: "政府补贴：", number: 111, unit: "元" },
          { name: "总补贴：", number: 111, unit: "元" },
          { name: "合格补贴：", number: 111, unit: "元" },
          { name: "不合格订单：", number: 111, unit: "单" },
        ];
      } else {
        this.option = { ...defaultOption };
        this.subsidyList = [...defaultSubsidyList];
      }
    },
    // 打开经营统计弹窗
    openOperateStatistics() {
      this.$refs.operateStatisticsDrawer.$refs.drawerIshow.drawer = true;
    },
    //查看
    search(e) {
      this.$nextTick(() => {
        this.$refs.orderForm.$refs.drawerIshow.drawer = true;
        Object.keys(this.$refs.orderForm.dataForm).forEach((key) => {
          this.$refs.orderForm.dataForm[key] =
            e[key] || this.$refs.orderForm.dataForm[key];
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";

.hl-full-container {
  font-size: 1rem;
  color: #606266;
  overflow: auto;

  /** 助餐经营 */
  .assistedMealManagement {
    width: 100%;
    height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;

    /** 助餐 */
    .mealAssistance {
      .el-icon-film {
        font-size: 14px;
        margin-right: 3px;
      }

      .title {
        font-size: 16px;
      }

      .subTitle {
        font-size: 12px;
        color: #aaa;
      }
    }
  }

  .yearAndEcharts {
    width: 100%;
    margin-top: 1.25rem;
    border-bottom: 1px solid #eee;

    .selfFunded {
      margin-left: 5px;
    }
  }

  .seatchSubsidy {
    margin-top: 1.25rem;

    .subsidy {
      display: flex;

      div {
        flex: 1;

        .name {
          color: #3d9cf0;
          font-weight: bold;
        }

        .number,
        .unit {
          color: #ff0000;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
