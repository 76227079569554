<template>
  <div class="hl-full-container flexVertical">
    <!-- 上方标题 -->
    <div class="topTitle">
      <i class="el-icon-film"></i>
      <span class="title">服务批次与管理</span>
    </div>
    <!-- 表格 -->
    <hltableZ
      :columnEdit="table.columnEdit"
      :tableColumn="table.column"
      :apiurl="table.apiurl"
      :isPage="false"
      :onlyShowTotal="true"
      :staticData="table.staticData"
      :refreshFlag="table.refreshFlag"
      :selectedRows="table.selectedRows"
      :isChecked="table.isChecked"
      :isNumber="table.isNumber"
      :isClickSelect="table.isClickSelect"
      :formSearch="query"
      :handleCellStyle="cellStyle"
      @cellClick="cellClick"
    >
    </hltableZ>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
export default {
  name: "fwpc",
  components: { hltableZ },
  data() {
    return {
      // 营业状态
      businessStatusOptions: [
        { label: "全部", value: null },
        { label: "正常运营", value: "1" },
        { label: "注销", value: "2" },
        { label: "取缔", value: "3" },
        { label: "停业整顿", value: "4" },
        { label: "事实处于未开展服务状态", value: "5" },
        { label: "关停", value: "6" },
      ],
      // 表格属性值
      table: {
        staticData: [],
        columnEdit: false,
        column: [
          {
            label: "改造年度",
            prop: "a",
            checked: true,
            width:"70"
          },
          {
            label: "实施部门",
            prop: "b",
            checked: true,
            width:"70"
          },
          {
            label: "镇街道",
            prop: "c",
            checked: true,
            width:"100"
          },
          {
            label: "所属区划",
            prop: "d",
            checked: true,
            width:"150"
          },
          {
            label: "姓名",
            prop: "e",
            checked: true,
            width:"100"
          },
          {
            label: "身份证号",
            prop: "f",
            checked: true,
            width:"165"
          },
          {
            label: "联系方式",
            prop: "g",
            checked: true,
            width:"120"
          },
          {
            label: "家庭详细住址",
            prop: "h",
            checked: true,
            width:"150"
          },
          {
            label: "家庭类型",
            prop: "i",
            checked: true,
            width:"160"
          },
          {
            label: "评估机构",
            prop: "j",
            checked: true,
            width:"160"
          },
          {
            label: "评估人",
            prop: "k",
            checked: true,
          },
          {
            label: "评估时间",
            prop: "l",
            checked: true,
            width:"110"
          },
          {
            label: "评估结果",
            prop: "m",
            checked: true,
            width:"90"
          },
          {
            label: "是否改造",
            prop: "n",
            checked: true,
          },
          {
            label: "改造项目",
            prop: "o",
            checked: true,
            width:"200"
          },
          {
            label: "改造资金",
            prop: "p",
            checked: true,
          },
          {
            label: "资金来源",
            prop: "q",
            checked: true,
            width:"120"
          },
          {
            label: "改造机构",
            prop: "r",
            checked: true,
            width:"200"
          },
          {
            label: "竣工时间",
            prop: "s",
            checked: true,
          },
          {
            label: "是否验收",
            prop: "t",
            checked: true,
          },
          {
            label: "验收机构",
            prop: "u",
            checked: true,
            width:"200"
          },
          {
            label: "验收时间",
            prop: "v",
            checked: true,
          },
          {
            label: "验收结果",
            prop: "w",
            checked: true,
          }
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
        isClickSelect: false,
      },
      tableDataSource: [
        {
          1: 1,
          2: 2,
          3: 15655556666,
          4: 4,
          account: 5,
          6: 6,
        },
      ],
      query: {
        institutionName: "",
        businessStatus: "",
      },
    };
  },

  mounted() {
    this.dataRefresh();
  },

  methods: {
    // 查询
    dataRefresh() {
      this.table.staticData = [
    {
        "a": "2022",
        "b": "民政",
        "c": "辛店街道",
        "d": "370305003000000000",
        "e": "周明芹",
        "f": "370305195604041829",
        "g": "13001517615",
        "h": "东夏新村6-6-302",
        "i": "其他困难老年人家庭",
        "j": "青岛市12349公共服务中心",
        "k": "彭勃",
        "l": "2022-03-31",
        "m": "重度失能",
        "n": "是",
        "o": "智能无感看护,紧急求救按钮1,紧急求救按钮2",
        "p": "1820",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44651.47081",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2022",
        "b": "民政",
        "c": "金岭回族镇",
        "d": "370305107000000000",
        "e": "王能建",
        "f": "370305195511062110",
        "g": "15053327270",
        "h": "金岭二村1294号",
        "i": "其他困难老年人家庭",
        "j": "青岛市12349公共服务中心",
        "k": "唐秀兰",
        "l": "2022-03-05",
        "m": "重度失能",
        "n": "是",
        "o": "紧急求救按钮1,智能老人卡,智能无感看护,紧急求救按钮2,起夜灯",
        "p": "2050",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44625.47039",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2022",
        "b": "民政",
        "c": "雪宫街道",
        "d": "370305002000000000",
        "e": "边增芹",
        "f": "370305195105225000",
        "g": "15966957314",
        "h": "雪宫街道桓公路北巷10-2-402",
        "i": "其他困难老年人家庭",
        "j": "青岛市12349公共服务中心",
        "k": "彭勃",
        "l": "2022-05-26",
        "m": "轻度失能",
        "n": "是",
        "o": "智能无感看护,紧急求救按钮1,紧急求救按钮2",
        "p": "1820",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44707.78866",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2022",
        "b": "民政",
        "c": "雪宫街道",
        "d": "370305002000000000",
        "e": "郝慧兰",
        "f": "370305194206140026",
        "g": "18553371107",
        "h": "齐国商城30-4-201",
        "i": "其他困难老年人家庭",
        "j": "青岛市12349公共服务中心",
        "k": "王彩云",
        "l": "2022-05-26",
        "m": "中度失能",
        "n": "是",
        "o": "安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,起夜灯,智能无感看护,紧急求救按钮1,紧急求救按钮2,安装防撞护角/防撞条、提示标识,智能老人卡",
        "p": "2040",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44707.83356",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2022",
        "b": "民政",
        "c": "稷下街道",
        "d": "370305004000000000",
        "e": "杜希明",
        "f": "370305195512042816",
        "g": "15689077666",
        "h": "王家村141号",
        "i": "分散供养特困人员家庭",
        "j": "青岛市12349公共服务中心",
        "k": "王彩云",
        "l": "2022-05-27",
        "m": "轻度失能",
        "n": "是",
        "o": "安装防撞护角/防撞条、提示标识,紧急求救按钮1,紧急求救按钮2,智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安全扶手",
        "p": "2080",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44708.46747",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2022",
        "b": "民政",
        "c": "稷下街道",
        "d": "370305004000000000",
        "e": "安学胜",
        "f": "370305194511022819",
        "g": "13589486267",
        "h": "王家村480号",
        "i": "其他困难老年人家庭",
        "j": "青岛市12349公共服务中心",
        "k": "王彩云",
        "l": "2022-05-27",
        "m": "轻度失能",
        "n": "是",
        "o": "智能无感看护,紧急求救按钮2,紧急求救按钮1,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "p": "1540",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44708.46248",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2022",
        "b": "民政",
        "c": "稷下街道",
        "d": "370305004000000000",
        "e": "赵学书",
        "f": "370305193811212812",
        "g": "13754752359",
        "h": "高娄村191号",
        "i": "分散供养特困人员家庭",
        "j": "青岛市12349公共服务中心",
        "k": "王彩云",
        "l": "2022-05-27",
        "m": "轻度失能",
        "n": "是",
        "o": "起夜灯,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮1,智能无感看护,安全扶手,安装防撞护角/防撞条、提示标识,紧急求救按钮2",
        "p": "1780",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44708.44527",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2022",
        "b": "民政",
        "c": "稷下街道",
        "d": "370305004000000000",
        "e": "赵培章",
        "f": "370305196201202813",
        "g": "15564468553",
        "h": "董褚村364号",
        "i": "分散供养特困人员家庭",
        "j": "青岛市12349公共服务中心",
        "k": "王彩云",
        "l": "2022-05-27",
        "m": "中度失能",
        "n": "是",
        "o": "起夜灯,安装防撞护角/防撞条、提示标识,紧急求救按钮1,安装防撞护角/防撞条、提示标识,紧急求救按钮2,智能无感看护,安装防撞护角/防撞条、提示标识",
        "p": "1580",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44708.45514",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2022",
        "b": "民政",
        "c": "稷下街道",
        "d": "370305004000000000",
        "e": "胡玉贵",
        "f": "370305194301223110",
        "g": "13969334617",
        "h": "稷下尧王村233号",
        "i": "其他困难老年人家庭",
        "j": "青岛市12349公共服务中心",
        "k": "王彩云",
        "l": "2022-05-27",
        "m": "轻度失能",
        "n": "是",
        "o": "智能无感看护,安全扶手,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,起夜灯,安装防撞护角/防撞条、提示标识,紧急求救按钮1,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮2",
        "p": "1800",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44708.45222",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2022",
        "b": "民政",
        "c": "皇城镇",
        "d": "370305102000000000",
        "e": "齐富英",
        "f": "370305195401114741",
        "g": "17865969303",
        "h": "临淄区凤凰镇大路南村",
        "i": "其他困难老年人家庭",
        "j": "青岛市12349公共服务中心",
        "k": "张昊",
        "l": "2022-05-25",
        "m": "重度失能",
        "n": "是",
        "o": "紧急求救按钮1,紧急求救按钮2,安装防撞护角/防撞条、提示标识,安全扶手,智能无感看护",
        "p": "1660",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44706.77008",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2022",
        "b": "民政",
        "c": "敬仲镇",
        "d": "370305103000000000",
        "e": "贾来用",
        "f": "370305194702174313",
        "g": "13280604377",
        "h": "敬仲镇东苇河村",
        "i": "其他困难老年人家庭",
        "j": "青岛市12349公共服务中心",
        "k": "张昊",
        "l": "2022-05-25",
        "m": "中度失能",
        "n": "是",
        "o": "安装防撞护角/防撞条、提示标识,起夜灯,智能无感看护,安装防撞护角/防撞条、提示标识,紧急求救按钮1,紧急求救按钮2,安装防撞护角/防撞条、提示标识",
        "p": "1570",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44706.77458",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2022",
        "b": "民政",
        "c": "齐陵街道",
        "d": "370305005000000000",
        "e": "吕桂英",
        "f": "370305194811203427",
        "g": "15169395534",
        "h": "齐陵街道前李村",
        "i": "分散供养特困人员家庭",
        "j": "青岛市12349公共服务中心",
        "k": "彭勃",
        "l": "2022-05-27",
        "m": "中度失能",
        "n": "是",
        "o": "起夜灯,安装防撞护角/防撞条、提示标识,安全扶手,安装防撞护角/防撞条、提示标识,智能无感看护,紧急求救按钮1,安装防撞护角/防撞条、提示标识,紧急求救按钮2",
        "p": "1770",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44705.70836",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2022",
        "b": "民政",
        "c": "朱台镇",
        "d": "370305104000000000",
        "e": "石敦荣",
        "f": "370305195512155917",
        "g": "13287011120",
        "h": "前夏东村",
        "i": "其他困难老年人家庭",
        "j": "青岛市12349公共服务中心",
        "k": "范莹莹",
        "l": "2022-05-24",
        "m": "中度失能",
        "n": "是",
        "o": "安装防撞护角/防撞条、提示标识,紧急求救按钮2,安装防撞护角/防撞条、提示标识,起夜灯,安装防撞护角/防撞条、提示标识,智能无感看护,安全扶手,紧急求救按钮1,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "p": "1830",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44705.73988",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2022",
        "b": "民政",
        "c": "雪宫街道",
        "d": "370305002000000000",
        "e": "蔺效忠",
        "f": "370305195301223430",
        "g": "13864485312",
        "h": "雪南15-1206",
        "i": "其他困难老年人家庭",
        "j": "青岛市12349公共服务中心",
        "k": "王彩云",
        "l": "2022-05-26",
        "m": "重度失能",
        "n": "是",
        "o": "紧急求救按钮1,紧急求救按钮2,起夜灯,智能无感看护",
        "p": "1520",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44707.81528",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2022",
        "b": "民政",
        "c": "雪宫街道",
        "d": "370305002000000000",
        "e": "徐敬春",
        "f": "37030519440919151X",
        "g": "13869337819",
        "h": "桓公路北巷8-2-102",
        "i": "其他困难老年人家庭",
        "j": "青岛市12349公共服务中心",
        "k": "彭勃",
        "l": "2022-05-26",
        "m": "重度失能",
        "n": "是",
        "o": "安装防撞护角/防撞条、提示标识,紧急求救按钮1,双向通话急呼宝,安全扶手,安装防撞护角/防撞条、提示标识,智能无感看护,紧急求救按钮2,起夜灯",
        "p": "2140",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44707.80354",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2022",
        "b": "民政",
        "c": "雪宫街道",
        "d": "370305002000000000",
        "e": "张金河",
        "f": "370305194711080714",
        "g": "13853334184",
        "h": "齐国商城40-5-201",
        "i": "其他困难老年人家庭",
        "j": "青岛市12349公共服务中心",
        "k": "彭勃",
        "l": "2022-05-26",
        "m": "重度失能",
        "n": "是",
        "o": "起夜灯,紧急求救按钮1,安装防撞护角/防撞条、提示标识,紧急求救按钮2,智能无感看护,智能老人卡",
        "p": "1990",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44707.79689",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2022",
        "b": "民政",
        "c": "雪宫街道",
        "d": "370305002000000000",
        "e": "唐秀华",
        "f": "370305195409210746",
        "g": "13969306122",
        "h": "东高5-1-401",
        "i": "其他困难老年人家庭",
        "j": "青岛市12349公共服务中心",
        "k": "彭勃",
        "l": "2022-05-26",
        "m": "重度失能",
        "n": "是",
        "o": "安装防撞护角/防撞条、提示标识,紧急求救按钮1,起夜灯,紧急求救按钮2,智能老人卡,安装防撞护角/防撞条、提示标识,智能无感看护",
        "p": "2010",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44707.78317",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2022",
        "b": "民政",
        "c": "雪宫街道",
        "d": "370305002000000000",
        "e": "李风岐",
        "f": "21072419461206001X",
        "g": "13675337519",
        "h": "齐国商城23-3-101",
        "i": "其他困难老年人家庭",
        "j": "青岛市12349公共服务中心",
        "k": "王彩云",
        "l": "2022-05-26",
        "m": "中度失能",
        "n": "是",
        "o": "智能无感看护,安全扶手,智能老人卡,起夜灯,安装防撞护角/防撞条、提示标识,紧急求救按钮1,安装防撞护角/防撞条、提示标识,紧急求救按钮2",
        "p": "2230",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44707.81139",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2022",
        "b": "民政",
        "c": "雪宫街道",
        "d": "370305002000000000",
        "e": "何世英",
        "f": "37030519580315288X",
        "g": "19805337066",
        "h": "雪北42-202",
        "i": "其他困难老年人家庭",
        "j": "青岛市12349公共服务中心",
        "k": "王彩云",
        "l": "2022-05-26",
        "m": "重度失能",
        "n": "是",
        "o": "紧急求救按钮2,智能无感看护,紧急求救按钮1,安装防撞护角/防撞条、提示标识,起夜灯",
        "p": "1530",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44707.81935",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2022",
        "b": "民政",
        "c": "雪宫街道",
        "d": "370305002000000000",
        "e": "解希廷",
        "f": "370305194502100416",
        "g": "13355233128",
        "h": "桓公路北巷2-2-301",
        "i": "其他困难老年人家庭",
        "j": "青岛市12349公共服务中心",
        "k": "彭勃",
        "l": "2022-05-26",
        "m": "中度失能",
        "n": "是",
        "o": "安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,智能无感看护,紧急求救按钮2,双向通话急呼宝,紧急求救按钮1,起夜灯",
        "p": "1960",
        "q": "福彩公益金",
        "r": "海成方数字科技（青岛）有限公司",
        "s": "44707.77834",
        "t": "是",
        "u": "山东煜善缘信息服务有限公司",
        "v": "44727",
        "w": "合格"
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "范家祥",
        "f": "370305193908155316",
        "g": "13964303445",
        "h": "西河村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-01",
        "m": "轻度失能",
        "n": "是",
        "o": "紧急呼叫设备*2,烟感探测器*1,智能网关*1,视频监控设备*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-01",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "郭素芳",
        "f": "370305194009115323",
        "g": "13964303445",
        "h": "西河村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-01",
        "m": "轻度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,智能网关*1,视频监控设备*1,烟感探测器*1,紧急呼叫设备*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-01",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "韩玉考",
        "f": "370305192912053313",
        "g": "15169259337",
        "h": "凤凰镇",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-01",
        "m": "轻度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-01",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "胡世芹",
        "f": "370305194005295320",
        "g": "13964303445",
        "h": "西河村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-01",
        "m": "轻度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,紧急呼叫设备*1,智能网关*1,视频监控设备*1,烟感探测器*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-01",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "李保祥",
        "f": "370305193906265319",
        "g": "15064337770",
        "h": "南台合村",
        "i": "城乡低保对象家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-01",
        "m": "轻度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,烟感探测器*1,视频监控设备*1,智能网关*1,紧急呼叫设备*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-01",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "李民太",
        "f": "370305193802095319",
        "g": "13864352726",
        "h": "东河村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-01",
        "m": "轻度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,智能网关*1,视频监控设备*1,紧急呼叫设备*1,烟感探测器*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-01",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "李盛太",
        "f": "370305194301225319",
        "g": "13864352726",
        "h": "东河村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-01",
        "m": "轻度失能",
        "n": "是",
        "o": "烟感探测器*1,智能网关*1,视频监控设备*1,紧急呼叫设备*2",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-01",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "李秀英",
        "f": "370305193709095322",
        "g": "13964303445",
        "h": "西河村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-01",
        "m": "轻度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,视频监控设备*1,烟感探测器*1,紧急呼叫设备*1,智能网关*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-02",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "刘端成",
        "f": "370305193908095317",
        "g": "13964303445",
        "h": "西河村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-01",
        "m": "轻度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-02",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "路学英",
        "f": "370305194404015322",
        "g": "18753393053",
        "h": "西梧村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-01",
        "m": "轻度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-02",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "罗福荣",
        "f": "370305194307224712",
        "g": "13054885302",
        "h": "南罗村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-01",
        "m": "轻度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-02",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "罗麦田",
        "f": "370305192912074717",
        "g": "13054885302",
        "h": "南罗村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-01",
        "m": "重度失能",
        "n": "是",
        "o": "烟感探测器*1,智能网关*1,视频监控设备*1,紧急呼叫设备*2",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-02",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "罗荣华",
        "f": "370305194006114712",
        "g": "13054885302",
        "h": "南罗村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-01",
        "m": "轻度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-02",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "罗希元",
        "f": "370305194108274717",
        "g": "13054885302",
        "h": "南罗村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-01",
        "m": "中度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,视频监控设备*1,烟感探测器*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-02",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "孙景华",
        "f": "370305194305175312",
        "g": "13864352726",
        "h": "东河村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-02",
        "m": "轻度失能",
        "n": "是",
        "o": "烟感探测器*1,智能网关*1,视频监控设备*1,紧急呼叫设备*2",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-03",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "孙玉英",
        "f": "370305193608288532",
        "g": "13864391109",
        "h": "东河村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-02",
        "m": "重度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,智能网关*1,视频监控设备*1,紧急呼叫设备*1,烟感探测器*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-03",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "王立英",
        "f": "370305192701085328",
        "g": "13964303445",
        "h": "西河村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-02",
        "m": "中度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,烟感探测器*1,智能网关*1,视频监控设备*1,紧急呼叫设备*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-03",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "王世荣",
        "f": "370305194011255325",
        "g": "13964303445",
        "h": "西河村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-02",
        "m": "中度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,视频监控设备*1,紧急呼叫设备*1,烟感探测器*1,智能网关*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-03",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "王新清",
        "f": "37030519400902531X",
        "g": "15315208865",
        "h": "水牛村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-02",
        "m": "轻度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,紧急呼叫设备*1,智能网关*1,视频监控设备*1,烟感探测器*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-03",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "王友芬",
        "f": "370305193809205322",
        "g": "13964303445",
        "h": "西河村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-02",
        "m": "轻度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,智能网关*1,视频监控设备*1,烟感探测器*1,紧急呼叫设备*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-03",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "徐桂英",
        "f": "370305193405115320",
        "g": "15169259337",
        "h": "凤凰镇",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-02",
        "m": "中度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,紧急呼叫设备*1,智能网关*1,烟感探测器*1,视频监控设备*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-04",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "徐玉珍",
        "f": "370305194610155326",
        "g": "18753393207",
        "h": "吴家桥",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-02",
        "m": "中度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,烟感探测器*1,视频监控设备*1,智能网关*1,紧急呼叫设备*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-04",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "杨桂香",
        "f": "370305193112244727",
        "g": "13054885302",
        "h": "南罗村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-03",
        "m": "轻度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,紧急呼叫设备*1,智能网关*1,烟感探测器*1,视频监控设备*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-04",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "史冠中",
        "f": "370305193703135311",
        "g": "18264326804",
        "h": "刘地村",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-03",
        "m": "轻度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-04",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "王洋洲",
        "f": "370305194110085317",
        "g": "13561633077",
        "h": "柴南",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-03",
        "m": "重度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-05",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    },
    {
        "a": "2023",
        "b": "民政",
        "c": "凤凰镇",
        "d": "370305105000000000",
        "e": "李淑云",
        "f": "370305194004035316",
        "g": "13561633077",
        "h": "东河",
        "i": "其他困难老年人家庭",
        "j": "淄博市善德老年人能力评估中心",
        "k": "夏翠华",
        "l": "2023-07-03",
        "m": "中度失能",
        "n": "是",
        "o": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "p": "财政资金",
        "q": "乐惠居（苏州）健康产业有限公司",
        "r": "2023-08-05",
        "s": "是",
        "t": "山东煜善缘信息咨询服务有限公司",
        "u": "2023-10-25",
        "v": "合格",
        "w": ""
    }
];
      this.table.refreshFlag = new Date().getTime().toString();
    },
    // 重置
    reset() {
      this.$refs.query.resetFields();
    },
    // 单元格点击
    cellClick(row, column, cell, event) {
      if (column.property === "account") {
        this.handleAccount();
      }
    },
    cellStyle(row, column) {
      if (column.property === "account") {
        return {
          color: "#409EFF",
          cursor: "pointer",
        };
      }
    },
    // 编辑
    handleEdit() {
      this.$refs.fwzcInfo.$refs.drawerIshow.drawer = true;
    },
    // 模块适配
    handleModule() {},
    // 打开账号
    handleAccount() {
      this.$refs.accountInfo.$refs.drawerIshow.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.hl-full-container {
  font-size: 1rem;
  color: #606266;
  // 上方标题
  .topTitle {
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    margin-bottom: 0.9375rem;
    .el-icon-film {
      font-size: 14px;
      margin-right: 3px;
    }

    .title {
      font-size: 16px;
      margin-right: 0.3125rem;
    }

    .subTitle {
      font-size: 12px;
      color: #aaa;
    }
  }
}
</style>
