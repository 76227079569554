<template>
  <div>
    <!-- 添加/修改  政府购买服务批次 -->
    <Drawer
      :title="title"
      ref="drawer"
      :button-is-show="buttonIsShow"
      :submit-form-name="submitFormName"
    >
      <div
        style="
          height: 30px;
          line-height: 30px;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 10px;
          font-weight: 700;
        "
      >
        基本信息
      </div>
      <el-form
        size="small"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        :label-position="labelPosition"
      >
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="名称:" prop="batchName">
              <el-input
                placeholder="请输入批次名称"
                v-model="ruleForm.batchName"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="适用服务组织:" prop="year">
          <el-select
            v-model="ruleForm.servicePolicy"
            placeholder="请选择适用服务组织"
          >
            <el-option
              v-for="item in serviceOrganizationList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="状态:" prop="status">
              <el-select placeholder="请选择状态" v-model="ruleForm.status">
                <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="服务包描述:" prop="batchDescription">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入服务包描述"
                v-model="ruleForm.batchDescription"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="服务策略:" prop="servicePolicy">
          <el-select
            v-model="ruleForm.servicePolicy"
            placeholder="请选择服务策略"
          >
            <!-- <el-option
              label="策略B3：按实际服务价值进行账户扣款"
              value="1"
            ></el-option> -->
            <el-option label="策略D：每月固定时长服务" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="策略要求:" prop="strategicRequirements">
          <div>
            每月享受补贴的时长(小时)：<el-input-number
              v-model="ruleForm.numTime1"
            ></el-input-number>
          </div>
          <div>
            每月最高补贴金额(元)：<el-input-number
              v-model="ruleForm.numTime2"
            ></el-input-number>
          </div>
          <div>
            单次上门服务政府认可的最大时长(分钟)：<el-input-number
              v-model="ruleForm.numTime3"
            ></el-input-number>
          </div>
        </el-form-item>
      </el-form>
    </Drawer>
  </div>
</template>

<script>
import { Drawer } from "@/components";
export default {
  components: { Drawer },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      statusOptions: [
        {
          value: 1,
          label: "全部",
        },
        {
          value: 2,
          label: "可用",
        },
        {
          value: 3,
          label: "不可用",
        },
      ],
      ruleForm: {
        name: "", // 名称
        serviceOrganization: "", // 适用服务组织
        status: "", // 状态
        serviceDescription: "", // 服务包描述
        servicePolicy: "", // 服务策略
        strategicRequirements: "", // 策略要求
        numTime1: 0,
        numTime2: 0,
        numTime3: 0,
      },
      serviceOrganizationList: [
        {
          value: 1,
          label: "全部",
        },
        {
          value: 2,
          label: "郑大姐服务组织",
        },
        {
          value: 3,
          label: "善行佳豪养老服务运营中心",
        },
        {
          value: 4,
          label: "高新区居家养老服务中心",
        },
        {
          value: 5,
          label: "淄博市临淄区蓝丝带社会服务中心",
        },
        {
          value: 6,
          label: "临淄区义家人社会工作服务中心",
        },
        {
          value: 7,
          label: "淄博市齐佳社会工作服务中心",
        },
      ],
      subsidyAmountFlag: false,
      labelPosition: "left",
      rules: {},
      submitFormName: "保存",
      buttonIsShow: true,
    };
  },
  created() {},
  methods: {
    handleSelect(val) {
      if (val === "2") {
        this.subsidyAmountFlag = true;
      } else {
        this.subsidyAmountFlag = false;
      }
    },
    open() {
      // alert(22)
      this.$refs.drawer.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
