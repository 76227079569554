<template>
  <Drawer title="非会员订单" ref="drawerIshow">
    <div class="nomemberOrders">
      <!-- 记录 -->
      <div class="descMoney">
        <span class="desc">仅记录线下支付流水</span>
        <span class="title">支付总额</span>
        <span class="money">503元</span>
      </div>
      <!-- 搜索 -->
      <div class="search">
        <el-form
          class="form"
          ref="form"
          :model="query"
          label-width="80px"
          inline
        >
          <el-form-item label="" prop="mealLocation">
            <el-select
              v-model="query.mealLocation"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="item in mealLocationOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期" prop="diningDate" label-width="40px">
            <el-date-picker
              v-model="query.diningDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="支付方式" prop="payType">
            <el-select
              v-model="query.rechargeType"
              placeholder="请选择支付方式"
            >
              <el-option
                v-for="item in payTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="dataRefresh"
              >查询</el-button
            >
            <el-button size="small" icon="el-icon-refresh-left" @click="reset"
              >重置</el-button
            >
            <el-button
              size="small"
              type="info"
              plain
              icon="el-icon-download"
              @click="derive"
              >导出</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- 表格 -->
      <hltableZ
        :columnEdit="table.columnEdit"
        :tableColumn="table.column"
        :apiurl="table.apiurl"
        :refreshFlag="table.refreshFlag"
        :isChecked="table.isChecked"
        :isNumber="table.isNumber"
        :isClickSelect="table.isClickSelect"
        :staticData="tableDataSource"
        :formSearch="query"
      >
        <div slot-scope="selected" slot="EditColumn">
          <el-button
            icon="el-icon-error"
            type="text"
            size="small"
            @click="chargeback(selected.singlerow)"
            >退单</el-button
          >
        </div>
      </hltableZ>
    </div>
  </Drawer>
</template>

<script>
import { Drawer, hltableZ } from "@/components";
export default {
  name: "NomemberOrders",
  components: { Drawer, hltableZ },
  data() {
    return {
      // 助餐站点options
      mealLocationOptions: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "1",
          label: "辛店街道于家店村长者食堂",
        },
        {
          value: "2",
          label: "齐都镇东古村长者食堂",
        },
      ],
      // 支付方式
      payTypeOptions: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "1",
          label: "支付宝",
        },
        {
          value: "2",
          label: "微信",
        },
        {
          value: "3",
          label: "云闪付",
        },
      ],
      table: {
        columnEdit: true,
        column: [
          {
            label: "站点名称",
            prop: "1",
            checked: true,
          },
          {
            label: "菜品内容",
            prop: "2",
            checked: true,
          },
          {
            label: "金额(元)",
            prop: "3",
            checked: true,
          },
          {
            label: "支付方式",
            prop: "4",
            checked: true,
          },
          {
            label: "创建人",
            prop: "5",
            checked: true,
          },
          {
            label: "下单时间",
            prop: "6",
            checked: true,
          },
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
        isClickSelect: false,
      },
      // 表格静态数据
      tableDataSource: [
        {
          1: 1,
          2: 1,
          3: 1,
          4: 1,
          5: 1,
          6: 1,
        },
      ],
      query: {
        mealLocation: "",
        diningDate: "",
        payType: null,
      },
    };
  },

  mounted() {},

  methods: {
    // 查询
    dataRefresh() {},
    // 重置
    reset() {
      this.$refs.form.resetFields();
    },
    // 导出
    derive() {},
    // 退单
    chargeback() {
      this.$confirm("退单完成后，该订单将被删除。", "您确定要退单吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {})
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.nomemberOrders {
  height: 100%;
  display: flex;
  flex-direction: column;
  // 描述和金额
  .descMoney {
    border-bottom: 1px solid #eee;
    padding-bottom: 0.3125rem;
    margin-bottom: 0.9375rem;
    .desc {
      font-size: 0.875rem;
      color: #aaaaaa;
    }
    .title {
      font-size: 1rem;
      color: #409eff;
      margin-left: 0.625rem;
      margin-right: 0.9375rem;
    }
    .money {
      font-size: 1rem;
      color: red;
    }
  }
  // 搜索
  .search {
    margin-bottom: 1.25rem;
     // 时间选择器样式
    ::v-deep .el-date-editor--daterange {
      width: 15rem;
    }
  }
}
</style>
