<template>
  <div>
    <!-- 详细信息 -->
    <Drawer :title="title" ref="drawer">
      <div
        style="
          height: 30px;
          line-height: 30px;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 10px;
          font-weight: 700;
          display: flex;
          justify-content: space-between;
        "
      >
        修改信息
      </div>
      <el-row :gutter="20" class="formStyle">
        <el-col :span="12"
          ><div style="display: flex; align-items: center">
            <div style="width: 6rem">修改人：</div>
            <el-input v-model="updateBy"></el-input></div
        ></el-col>
        <el-col :span="12"
          ><div style="display: flex">
            <div style="width: 8rem">修改时间：</div>
            <el-input v-model="updateTime"></el-input></div
        ></el-col>
      </el-row>
      <table width="100%" border="1" cellspacing="1" cellpadding="0">
        <th>属性</th>
        <th>修改前数据</th>
        <th>修改后数据</th>
        <tr>
          <td class="tdWidth">服务人员最小年龄限制</td>
          <td>{{ dataObj.ageFront }}</td>
          <td>{{ dataObj.ageFront }}</td>
        </tr>
        <tr>
          <td class="tdWidth">从相册选择服务图片</td>
          <td>{{ dataObj.ageFront }}</td>
          <td>{{ dataObj.ageFront }}</td>
        </tr>
        <tr>
          <td class="tdWidth">视频记录功能</td>
          <td>{{ dataObj.ageFront }}</td>
          <td>{{ dataObj.ageFront }}</td>
        </tr>
        <tr>
          <td class="tdWidth" style="color: red">updateDate</td>
          <td style="color: red">{{ dataObj.ageFront }}</td>
          <td style="color: red">{{ dataObj.ageFront }}</td>
        </tr>
        <tr>
          <td class="tdWidth">强制距离校验</td>
          <td>{{ dataObj.ageFront }}</td>
          <td>{{ dataObj.ageFront }}</td>
        </tr>
        <tr>
          <td class="tdWidth">maiMod</td>
          <td>{{ dataObj.ageFront }}</td>
          <td>{{ dataObj.ageFront }}</td>
        </tr>
        <tr>
          <td class="tdWidth">minIntervalMinutesToNext</td>
          <td>{{ dataObj.ageFront }}</td>
          <td>{{ dataObj.ageFront }}</td>
        </tr>
        <tr>
          <td class="tdWidth">服务人员核验</td>
          <td>{{ dataObj.ageFront }}</td>
          <td>{{ dataObj.ageFront }}</td>
        </tr>
        <tr>
          <td class="tdWidth">验证福卡授权</td>
          <td>{{ dataObj.ageFront }}</td>
          <td>{{ dataObj.ageFront }}</td>
        </tr>
        <tr>
          <td class="tdWidth">服务人员最大年龄限制</td>
          <td>{{ dataObj.ageFront }}</td>
          <td>{{ dataObj.ageFront }}</td>
        </tr>
        <tr>
          <td class="tdWidth">updateBy</td>
          <td>{{ dataObj.ageFront }}</td>
          <td>{{ dataObj.ageFront }}</td>
        </tr>
        <tr>
          <td class="tdWidth">签入时间最短时间限制</td>
          <td>{{ dataObj.ageFront }}</td>
          <td>{{ dataObj.ageFront }}</td>
        </tr>
        <tr>
          <td class="tdWidth">服务定位距离</td>
          <td>{{ dataObj.ageFront }}</td>
          <td>{{ dataObj.ageFront }}</td>
        </tr>
        <tr>
          <td class="tdWidth">要求签名</td>
          <td>{{ dataObj.ageFront }}</td>
          <td>{{ dataObj.ageFront }}</td>
        </tr>
        <tr>
          <td class="tdWidth">locationCheckVerifyPhotoWatermark</td>
          <td>{{ dataObj.ageFront }}</td>
          <td>{{ dataObj.ageFront }}</td>
        </tr>
        <tr>
          <td class="tdWidth">签入时间最长时间限制</td>
          <td>{{ dataObj.ageFront }}</td>
          <td>{{ dataObj.ageFront }}</td>
        </tr>
        <tr>
          <td class="tdWidth">日重复服务限制</td>
          <td>{{ dataObj.ageFront }}</td>
          <td>{{ dataObj.ageFront }}</td>
        </tr>
        <tr>
          <td class="tdWidth">用户现场评价校验</td>
          <td>{{ dataObj.ageFront }}</td>
          <td>{{ dataObj.ageFront }}</td>
        </tr>
      </table>
    </Drawer>
  </div>
</template>

<script>
import { Drawer } from "@/components";
export default {
  components: { Drawer },
  data() {
    return {
      dataObj: {
        ageFront: 18,
      },
      title: "详细信息",
      updateBy: "",
      updateTime: "",
    };
  },
  created() {},
  methods: {
    open() {
      this.$refs.drawer.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.formStyle {
  display: flex;
  margin-bottom: 20px;
}
.tdWidth {
  font-weight: 700;
}
</style>
