<template>
  <div class="hl-full-container flexVertical">
    <!-- 表单s -->
    <el-drawer
      :title="dailogTitle"
      :visible.sync="dialogFormVisible"
      direction="rtl"
      size="100%"
      :modal="false"
      :destroy-on-close="true"
      class="el-drawer-hl"
      @close="handleClose"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :disabled="submitType == 'view' ? true : false"
        :rules="rules"
        size="medium"
        class="padding15"
        label-width="100px"
      >
        <el-col :span="24">
          <el-row>
            <el-col :span="12">
              <el-form-item label="名称" prop="name">
                <el-input
                  v-model="formData.name"
                  placeholder="请输入名称"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="单价" prop="unitPrice">
                <el-input-number
                  :controls="false"
                  :min="0"
                  :precision="2"
                  v-model="formData.unitPrice"
                  placeholder="请输入单价"
                  show-word-limit
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="24">
          <el-row>
            <el-col :span="24">
              <el-form-item label="站点" prop="siteId">
                <el-select
                  style="width: 100%"
                  v-model="formData.siteId"
                  placeholder="请选择助餐站点"
                >
                  <el-option
                    v-for="item in mealSiteData"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="24">
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input
                  v-model="formData.remark"
                  type="textarea"
                  placeholder="请输入备注"
                  :autosize="{ minRows: 4, maxRows: 4 }"
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="24">
          <el-row>
            <el-form-item size="large" style="text-align: center">
              <el-button
                v-show="submitType != 'view' ? true : false"
                class="qconfirm"
                type="primary"
                size="medium"
                @click="submitForm()"
                >提交</el-button
              >
              <el-button
                v-show="submitType != 'view' ? true : false"
                class="qcancle"
                size="medium"
                @click="resetForm()"
              >
                重置
              </el-button>
            </el-form-item>
          </el-row>
        </el-col>
      </el-form>
    </el-drawer>
    <!-- 表单e -->
    <div class="search">
      <el-form :model="form" ref="form" label-width="80px" inline>
        <el-form-item label="名称">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item>

        <el-form-item label="助餐站点">
          <el-select v-model="form.siteId" placeholder="请选择助餐站点">
            <el-option
              v-for="item in mealSiteData"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
          <el-button
            size="small"
            icon="el-icon-refresh-left"
            @click="reset('Form')"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="toolbar">
      <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-plus"
        @click="Add"
        >新增</el-button
      >
      <el-button
        type="danger"
        plain
        size="mini"
        icon="el-icon-delete"
        @click="Del"
        >删除</el-button
      >
    </div>
    <hltableZ
      :isNumber="false"
      :columnEdit="TableOptions.columnEdit"
      :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag"
      :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows"
      :apiurl="TableOptions.apiurl"
      @getSelectedRows="getSelectedRows"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-button
          type="primary"
          icon="el-icon-edit"
          size="mini"
          circle
          @click="Edit(selected.singlerow)"
        ></el-button>
        <el-button
          type="warning"
          size="mini"
          icon="el-icon-search"
          circle
          @click="View(selected.singlerow)"
        ></el-button>
      </div>
    </hltableZ>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
export default {
  name: "zdcd",
  components: { hltableZ },
  data() {
    return {
      token: { token: localStorage.getItem("systoken") },
      dailogTitle: "",
      dialogFormVisible: false,
      submitType: "",

      //表单信息-s
      formData: {
        name: "",
        unitPrice: "",
        siteId: "",
        remark: "",
      },
      //表单信息-e
      //功能树-s
      mealSiteData: [],
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        unitPrice: [
          {
            required: true,
            message: "请输入单价",
            trigger: "blur",
          },
        ],
        siteId: [
          {
            required: true,
            message: "请选择站点",
            trigger: "change",
          },
        ],
        remark: [],
      },
      //表单信息-e
      form: {
        name: "",
        siteId: "",
      },
      TableOptions: {
        selectedRows: [],
        apiurl: "api/getMealMenuList",
        refreshFlag: "",
        columnEdit: true,
        column: [
          {
            label: "名称",
            prop: "name",
            checked: true,
          },
          {
            label: "单价",
            prop: "unitPrice",
            checked: true,
          },
          {
            label: "站点",
            prop: "siteName",
            checked: true,
          },
          {
            label: "备注",
            prop: "remark",
            checked: true,
          },
        ],
      },
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.dataRefresh();
    this.getMealSite();
  },
  destroyed() {},
  methods: {
    getMealSite() {
      this.$store
        .dispatch("api/getMealSiteAllList", {
          areaCode: this.$store.getters.user.areaCode,
        })
        .then((response) => {
          if (response.success) {
            this.mealSiteData = response.result;
          } else {
            this.$message.error(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    dataRefresh() {
      this.TableOptions.refreshFlag = new Date().getTime().toString();
    },
    handleClose() {
      this.resetForm();
    },
    resetForm() {
      const th = this;
      Object.keys(this.formData).forEach(function (key) {
        th.formData[key] = "";
      });
    },
    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          const copyForm = Object.assign({}, this.formData);
          if (this.submitType == "add") {
            copyForm.id = "";
          }
          this.$store
            .dispatch("api/saveMealMenu", copyForm)
            .then((response) => {
              if (response.success) {
                this.$message.success("操作成功");
                this.dataRefresh();
              } else {
                this.$message.error(response.message);
              }
              this.dialogFormVisible = false;
            })
            .catch((error) => {
              this.dialogFormVisible = false;
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    loadform(row) {
      const rowinfo = Object.assign({}, row);
      this.formData = rowinfo;
    },
    setformType(type, title) {
      this.dialogFormVisible = true;
      this.dailogTitle = title;
      this.submitType = type;
    },
    Add() {
      this.setformType("add", "新增");
    },
    Edit(row) {
      this.setformType("edit", "编辑");
      this.loadform(row);
    },
    Del: function () {
      if (this.TableOptions.selectedRows.length == 0) {
        this.$message({
          type: "error",
          message: "请勾选下方表格中的数据",
        });
        return false;
      }
      var ids = [];
      this.TableOptions.selectedRows.forEach((key) => {
        ids.push(key.id);
      });
      this.$confirm("确定要进行删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store
          .dispatch("api/delMealMenu", { ids: ids })
          .then((response) => {
            if (response.success) {
              this.$message.success("操作成功");
              this.dataRefresh();
            } else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    View(row) {
      this.setformType("view", "查看");
      this.loadform(row);
    },
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows;
    },
    reset() {
      // this.$refs['form'].resetFields()
      // 比上方获取demo的清理方式更彻底
      const th = this;
      Object.keys(this.form).forEach(function (key) {
        th.form[key] = "";
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";
</style>
