import { render, staticRenderFns } from "./fwzc.vue?vue&type=template&id=4deef0b1&scoped=true"
import script from "./fwzc.vue?vue&type=script&lang=js"
export * from "./fwzc.vue?vue&type=script&lang=js"
import style0 from "./fwzc.vue?vue&type=style&index=0&id=4deef0b1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4deef0b1",
  null
  
)

export default component.exports