<template>
    <div>
        <div class="chart" ref="chart" :style="{ width: '100%', height: height }"></div>
    </div>
</template>

<script>

export default {
    name: 'pieEcharts',

    data() {
        return {
            chart: null
        };
    },
    props: {
        height: {
            type: String,
            default: '10rem'
        },
        dataList: {
            type: Array
        }
    },
    mounted() {
        this.echarts()
    },

    methods: {
        nowSize(val, initWidth = 1920) {
            //当前视口宽度
            let nowClientWidth = document.documentElement.clientWidth;
            return val * (nowClientWidth / initWidth);
        },
        echarts() {
            this.chart = this.$echarts.init(this.$refs.chart); // 初始化echarts
            var option = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {c} ({d}%)'
                },
                label: {
                    show: true,
                    color: '#000',
                    fontSize: this.nowSize(5),
                    formatter: function (params) {
                        return params.percent + '%' + '\n' + params.name;
                    }
                },
                legend: {
                    selectedMode: false,
                    icon: "circle",
                    itemHeight: this.nowSize(15),
                    top: "18%",
                    orient: "vertical",
                    itemGap: this.nowSize(30),
                    right: "20%",
                    textStyle: {
                        color: "#fff", // 图例文字颜色
                        textAlign: 'center',
                        rich: {
                            a: {
                                fontSize: this.nowSize(20),
                                align: 'center',
                                color: '#000',
                                padding: [2, 0, 2, 0]
                            }
                        }
                    },
                    formatter: (name) => {
                        // console.log(111111, this.dataList)
                        let total = 0
                        let target
                        console.log(total, target)
                        const th = this
                        const value = th.dataList.filter(x => x.name == name)[0].value
                        for (let i = 0, l = th.dataList.length; i < l; i++) {
                            total += th.dataList[i].value
                            if (th.dataList[i].name == name) {
                                target = th.dataList[i].value
                            }
                        }
                        const arr = [
                            '{a|' + value + '}' + '\n' + '{b|' + name + '}',
                        ]
                        return arr
                    },
                },
                color: ['#FB5D6B', '#3285DA'],
                series: [
                    {
                        type: 'pie',
                        radius: ['70%', '90%'],
                        center: ['34%', '50%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: this.nowSize(20),
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: this.dataList
                    }
                ]
            };
            this.chart.setOption(option); // 设置对应配置项
            // 当窗口大小改变 echarts重置大小
            window.addEventListener("resize", () => {
                this.chart.resize();
            });
            let index = 0;
            this.chart.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: 0,
            });
            this.chart.on("mouseover", (e) => {
                console.log(898989, e);
                if (e.dataIndex !== index) {
                    this.chart.dispatchAction({
                        type: "downplay",
                        seriesIndex: 0,
                        dataIndex: index,
                    });
                }
            });
            this.chart.on("mouseout", (e) => {
                index = e.dataIndex;
                this.chart.dispatchAction({
                    type: "highlight",
                    seriesIndex: 0,
                    dataIndex: e.dataIndex,
                });
            });
        }
    },
};
</script>

<style lang="scss" scoped></style>