<template>
  <div>
    <Drawer :title="title" ref="drawer" :button-is-show="buttonIsShow" :submit-form-name="submitFormName"
      @submitForm="submitForm">
      <el-form size="mini" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm"
        :label-position="labelPosition">
        <div style="
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            font-weight: 700;
          ">
          服务对象补贴
        </div>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="补贴分类:" prop="type">
              <el-input size="mini" v-model="ruleForm.type"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="补贴依据:" prop="reason">
              <el-input type="textarea" :rows="2" size="mini" placeholder="请输入补贴依据" v-model="ruleForm.reason">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="年龄依赖:" prop="ageDependence">
          <el-select size="mini" v-model="ruleForm.ageDependence" placeholder="请选择年龄依赖">
            <el-option label="开启" value="2"></el-option>
          </el-select>
          <el-input-number size="mini" :precision="0" controls-position="right" :min="0"
            v-model="ruleForm.low"></el-input-number>至 <el-input-number size="mini" :precision="0"
            controls-position="right" :min="0" v-model="ruleForm.high"></el-input-number>
        </el-form-item>
        <el-form-item label-width="150" label="区县补贴金额(默认):" prop="isAll">
          <el-select size="mini" v-model="ruleForm.isAll" placeholder="请选择区县补贴金额" @change="handleSelect">
            <el-option label="全天统一" value="1"></el-option>
            <el-option label="按餐细化" value="2"></el-option>
          </el-select>
          <span>
            默认额度：
            <el-input-number :precision="0" controls-position="right" :min="0" size="mini"
              v-model="ruleForm.money"></el-input-number>
          </span>
        </el-form-item>
        <el-form-item label-width="0" v-if="subsidyAmountFlag">
          <span>
            早餐：<el-input-number size="mini" :precision="0" controls-position="right" :min="0"
              v-model="ruleForm.breakfast"></el-input-number>
          </span>
          <span>
            午餐：<el-input-number size="mini" :precision="0" controls-position="right" :min="0"
              v-model="ruleForm.lunch"></el-input-number>
          </span>
          <span>
            晚餐：<el-input-number size="mini" :precision="0" controls-position="right" :min="0"
              v-model="ruleForm.dinner"></el-input-number>
          </span>
        </el-form-item>
        <el-form-item label-width="170" label="每月单人补贴金额上限(元):">
          <el-input-number size="mini" :precision="0" controls-position="right" :min="0"
            v-model="ruleForm.subsidyCap"></el-input-number>
        </el-form-item>
        <!-- <el-form-item label="区县配送补贴金额(元):" label-width="160px">
          <span>一次性配送：<el-input-number size="mini" :precision="0" controls-position="right" :min="0"
              v-model="ruleForm.deliverySubsidyAmount"></el-input-number></span>
        </el-form-item> -->
        <el-form-item label-width="120" label="享受市补贴类型:">
          <el-select v-model="ruleForm.cityType" placeholder="请选择享受市补贴类型" @change="selectClick($event)">
            <el-option v-for="item in subsidyTypeList" :key="item.id" :label="item.type" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="150" label="市补贴金额(默认):" prop="municipalSubsidyAmount">
          <el-input-number size="mini" :precision="0" controls-position="right" :min="0"
            v-model="ruleForm.municipalSubsidyAmount" disabled></el-input-number>
        </el-form-item>
        <el-form-item label-width="150" label="补贴金额(合计):" prop="totalAmount">
          <el-input-number size="mini" :precision="0" controls-position="right" :min="0"
            v-model="ruleForm.totalAmount"></el-input-number>
        </el-form-item>
        <el-form-item label-width="150" label="区县补贴最低消费标准:" prop="minConsumptionStandards">
          <el-input-number size="mini" :precision="0" controls-position="right" :min="0"
            v-model="ruleForm.minConsumptionStandards"></el-input-number>
          <span>单次消费低于最低消费标准时，不能享受补贴</span>
        </el-form-item>
      </el-form>
    </Drawer>
  </div>
</template>

<script>
import { Drawer } from "@/components";
export default {
  components: { Drawer },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      subsidyAmountFlag: false,
      labelPosition: "left",
      subsidyTypeList: [

      ],
      rules: {},
      submitFormName: "保存",
      buttonIsShow: true,
      ruleForm: {
        type: "", // 补贴分类
        reason: "", // 补贴依据
        ageDependence: '2', // 年龄依赖
        isAll: '1', // 区县补贴金额
        low: '',
        high: '',
        money: "", // 默认额度
        subsidyCap: "", // 每月单人补贴金额上限
        deliverySubsidyAmount: "", // 区县配送补贴金额
        cityType: "", // 享受市补贴类型
        municipalSubsidyAmount: "", // 市补贴金额(默认)
        totalAmount: "", // 补贴金额(合计)
        minConsumptionStandards: "", //区县补贴最低消费标准
        breakfast: 0, // 早餐
        lunch: 0, // 午餐
        dinner: 0, // 晚餐
        areaCode: '370301',
        level: 2
      },
    };
  },
  created() { },
  mounted() {
  },
  methods: {
    selectClick(e) {
      console.log(6666, this.subsidyTypeList)
      this.subsidyTypeList.forEach(item => {
        if (item.id == e) {
          this.ruleForm.municipalSubsidyAmount = item.money
        }
      })
    },
    getListByLevel() {
      this.$store
        .dispatch("sysModule/getListByLevel", { level: 1 })
        .then((response) => {
          if (response.success) {
            this.subsidyTypeList = response.result

          }
        })
    },
    close() {
      const th = this
      Object.keys(th.ruleForm).forEach(function (key) {
        if (key !== 'isAll' && key !== 'areaCode' && key !== 'level') {
          th.ruleForm[key] = ''
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("sysModule/subsidyStandardsaveData", this.ruleForm)
            .then((response) => {
              if (response.success) {
                this.$refs.drawer.drawer = false;
                this.$emit('subsidyStandardsaveData3');
              }
            })

        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSelect(val) {
      if (val === "2") {
        this.subsidyAmountFlag = true;
      } else {
        this.subsidyAmountFlag = false
      }
    },
    open() {
      this.$refs.drawer.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
