<template>
  <div class="hl-full-container flexVertical">
    <!-- 查询 -->
    <div class="search">
      <el-form :model="query" ref="query" label-width="80px" inline>
        <el-form-item label="站点名称">
          <el-input v-model="query.oldName" placeholder="请输入老人姓名"></el-input>
        </el-form-item>
        <el-form-item label="区域">
          <el-cascader v-model="query.areaName" :options="options"></el-cascader>
        </el-form-item>
        <template v-if="searchIsShow">

        </template>
        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search" @click="dataRefresh">查询</el-button>
          <el-button size="small" icon="el-icon-refresh-left" @click="reset('Form')">重置</el-button>
          <el-button size="small" type="info" :icon='searchIsShow == false ? "el-icon-arrow-down" : "el-icon-arrow-up"'
            @click="more">更多</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 中间 -->
    <div class="toolbar">
      <el-button type="primary" icon="el-icon-circle-plus" @click="add" size="small">新增服务批次</el-button>
      <el-button type="primary" icon="el-icon-circle-plus" @click="add" size="small">工程管理</el-button>
    </div>
    <hltableZ :columnEdit="table.columnEdit" :tableColumn="table.column" :apiurl="table.apiurl"
      :refreshFlag="table.refreshFlag" :selectedRows="table.selectedRows" :isChecked="table.isChecked"
      :isNumber="table.isNumber" :formSearch="query" :widthClick="widthClick">
      <div slot-scope="selected" slot="EditColumn">
        <el-button icon="el-icon-edit" type="text" size="small" @click="add(selected.singlerow)">编辑</el-button>
        <el-button icon="el-icon-edit" type="text" size="small" @click="add(selected.singlerow)">改造项（0）</el-button>
        <el-button icon="el-icon-edit" type="text" size="small" @click="add(selected.singlerow)">服务项0）</el-button>
        <el-button icon="el-icon-edit" type="text" size="small" @click="add(selected.singlerow)">服务规则</el-button>
        <el-button icon="el-icon-edit" type="text" size="small" @click="add(selected.singlerow)">服务包0）</el-button>
        <el-button icon="el-icon-edit" type="text" size="small" @click="add(selected.singlerow)">删除</el-button>
      </div>
    </hltableZ>
    <Drawer :title="title" ref="drawerIshow" :drawerSize="drawerSize" :buttonIsShow="true">
      <div class="qtxx">
        <div class="content">
          <el-form :label-position="labelPosition" label-width="170px" :model="formLabelAlign" :rules="rules"
            ref="formLabelAlign">
            <el-row :gutter="24">
              <el-col :span="12"> <el-form-item label="会统一信用代码:" prop="name">
                  <el-input v-model="formLabelAlign.name"></el-input>
                </el-form-item></el-col>
              <el-col :span="12"> <el-form-item label="主体名称:" prop="name">
                  <el-input v-model="formLabelAlign.name"></el-input>
                </el-form-item></el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="12"> <el-form-item label="简称:" prop="name">
                  <el-input v-model="formLabelAlign.name"></el-input>
                </el-form-item></el-col>
              <el-col :span="12"> <el-form-item label="负责人:" prop="name">
                  <el-input v-model="formLabelAlign.name"></el-input>
                </el-form-item></el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="12"> <el-form-item label="负责人电话:" prop="name">
                  <el-input v-model="formLabelAlign.name"></el-input>
                </el-form-item></el-col>
              <el-col :span="12"> <el-form-item label="所在区域:" prop="name">
                  <el-cascader v-model="query.areaName" :options="options"></el-cascader>
                </el-form-item></el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="24"> <el-form-item label="详细地址:" prop="name">
                  <el-input v-model="formLabelAlign.name"></el-input>
                </el-form-item></el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="24"> <el-form-item label="门店照片:" prop="name">
                  <el-input v-model="formLabelAlign.name"></el-input>
                </el-form-item></el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </Drawer>
  </div>
</template>
<script>

import { hltableZ, Drawer } from "@/components";
export default {
  name: "lrxx",
  components: { hltableZ, Drawer },
  data() {
    return {
      query: { oldName: "", organ: '', sex: '', level: '', area: '', status: "", idCard: '', phone: '' },
      //   表格
      widthClick: '300',
      table: {
        columnEdit: true,
        column: [
          {
            label: "工程",
            prop: "oldName",
            checked: true,
          },
          {
            label: "批次名称",
            prop: "2",
            checked: true,
          },
          {
            label: "适用范围",
            prop: "organName",
            checked: true,
          },
          {
            label: "已签约/名额",
            prop: "createTime",
            checked: true,
            formatter: this.timeFormatter,
          },
          {
            label: "有效日期",
            prop: "createTime",
            checked: true,
            formatter: this.timeFormatter,

          },
          {
            label: "关闭状态",
            prop: "createTime",
            checked: true,

          },
          {
            label: "创建时间",
            prop: "createTime",
            checked: true,
            formatter: this.timeFormatter,

          },
          {
            label: "操作人",
            prop: "createTime",
            checked: true,
            formatter: this.timeFormatter,

          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true
      },
      options: [{
        value: 'zhinan',
        label: '闻韶街道',
        children: [{
          value: 'yizhi',
          label: '南王镇'
        }, {
          value: 'fankui',
          label: '敬仲镇'
        }, {
          value: 'xiaolv',
          label: '梧台镇'
        }, {
          value: 'kekong',
          label: '朱台镇'
        }]
      },],
      rulesAdd: [],
      token: { token: localStorage.getItem("systoken") },
      xlsAreafileList: [],
      xlsAreaAction: "/api/oldUser/importUser.json",
      title: '新增站点',
      activeItem: {
      },
      searchIsShow: false,
      formLabelAlign: {
        name: "",
        idLei: "",
        idCard: "",
        date1: "",
        textarea: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        region: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        resource: [
          { required: true, message: "请选择活动资源", trigger: "change" },
        ],
        idCard: [
          { required: true, message: "请填写活动形式", trigger: "blur" },
        ],
      },
      labelPosition: "right",
    };
  },

  mounted() {
  },

  methods: {
    timeFormatter(row, column, cellValue) {
      return cellValue ? this.$timestampTostr(cellValue) : "";
    },
    //   查询
    dataRefresh() {
      this.table.refreshFlag = (new Date()).getTime().toString()
    },
    reset() {
      const th = this
      Object.keys(th.query).forEach(function (key) {
        if (key == 'value') {
          th.query.value = '1'
        } else {
          th.query[key] = ''
        }

      })
      this.dataRefresh();
    },
    add() {
      this.$refs.drawerIshow.drawer = true
    },
    setActiveItem(item) {
      this.activeItem = {
        name: item.name,
        key: item.key,
        component: item.component,
      };
    },
    more() {
      this.searchIsShow = !this.searchIsShow
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";

::v-deep .el-table th,
::v-deep .el-table td {
  text-align: center !important;
}



.component {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
</style>

