<template>
    <div class="hl-full-container flexVertical">
        <!-- 查询 -->
        <div class="search">
            <el-form :model="query" ref="query" label-width="80px" inline>
                <el-form-item label="老人姓名">
                    <el-input v-model="query.oldName" placeholder="请输入老人姓名"></el-input>
                </el-form-item>
                <el-form-item label="所属机构">
                    <el-input v-model="query.organ" placeholder="请输入所属机构"></el-input>
                </el-form-item>
                <el-form-item label="性别">
                    <el-select v-model="query.sex" placeholder="请选择">
                        <el-option key="1" label="男" value="1"> </el-option>
                        <el-option key="2" label="女" value="2"></el-option>
                    </el-select>

                </el-form-item>
                <template v-if="searchIsShow">
                    <el-form-item label="身份证号">
                        <el-input v-model="query.idCard" placeholder="请输入身份证号"></el-input>
                    </el-form-item>
                    <el-form-item label="电话">
                        <el-input v-model="query.phone" placeholder="请输入电话"></el-input>
                    </el-form-item>
                    <el-form-item label="能力等级">
                        <el-select v-model="query.level" placeholder="请选择">
                            <el-option key="能力完好" label="能力完好" value="能力完好"> </el-option>
                            <el-option key="轻度失能" label="轻度失能" value="轻度失能"></el-option>
                            <el-option key="中度失能" label="中度失能" value="中度失能"></el-option>
                            <el-option key="重度失能" label="重度失能" value="重度失能"></el-option>
                            <el-option key="完全失能" label="完全失能" value="完全失能"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="区域">
                        <el-cascader v-model="query.areaName" :options="options"></el-cascader>
                    </el-form-item>
                </template>
                <el-form-item>
                    <el-button size="small" type="primary" icon="el-icon-search" @click="dataRefresh">查询</el-button>
                    <el-button size="small" icon="el-icon-refresh-left" @click="reset('Form')">重置</el-button>
                    <el-button size="small" type="info"
                        :icon='searchIsShow == false ? "el-icon-arrow-down" : "el-icon-arrow-up"'
                        @click="more">更多</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 中间 -->
        <div class="toolbar">
            <el-button type="text" plain size="mini" icon="el-icon-upload2"> <a href="/static/jjlr.xlsx"
                    download="老人信息管理模板.xlsx">模板下载</a></el-button>

            <el-button type="primary" plain size="mini" icon="el-icon-download" @click="derive">导出</el-button>
        </div>
        <hltableZ :columnEdit="table.columnEdit" :tableColumn="table.column" :apiurl="table.apiurl"
            :refreshFlag="table.refreshFlag" :selectedRows="table.selectedRows" :isChecked="table.isChecked"
            :isNumber="table.isNumber" :formSearch="query">
            <div slot-scope="selected" slot="EditColumn">
                <el-button icon="el-icon-view" type="text" size="small" @click="search(selected.singlerow)">查看</el-button>
            </div>
        </hltableZ>
    </div>
</template>
  
<script>

import { hltableZ, Drawer } from "@/components";
export default {
    name: "lrxx",
    components: { hltableZ, Drawer },
    data() {
        return {
            query: { oldName: "", organ: '', sex: '', level: '', area: '', status: "", idCard: '', phone: '' },
            //   表格
            table: {
                columnEdit: true,
                column: [
                    {
                        label: "老人姓名",
                        prop: "oldName",
                        checked: true,
                        width: '150'
                    },
                    {
                        label: "照片",
                        prop: "2",
                        checked: true,
                        width: '150'
                    },
                    {
                        label: "所属机构",
                        prop: "organName",
                        checked: true,
                        width: '150'
                    },
                    {
                        label: "性别",
                        prop: "sex",
                        checked: true,
                        width: '150',
                        formatter(value) {
                            const sex = value.sex;
                            return sex == "1" ? "男" : "女";
                        },
                    },
                    {
                        label: "年龄",
                        prop: "age",
                        checked: true,
                        width: '150'
                    }, {
                        label: "身份证号",
                        prop: "idCard",
                        checked: true,
                        width: '150'
                    }, {
                        label: "联系电话",
                        prop: "phone",
                        checked: true,
                        width: '150'
                    }, {
                        label: "老人类别",
                        prop: "oldType",
                        checked: true,
                        width: '150'
                    },
                    {
                        label: "服务类型",
                        prop: "serveType",
                        checked: true,
                        width: '150'
                    },
                    {
                        label: "能力等级",
                        prop: "level",
                        checked: true,
                        width: '150'
                    },
                    {
                        label: "所属区别",
                        prop: "areaName",
                        checked: true,
                        width: '150'
                    },
                    {
                        label: "生存状态",
                        prop: "status",
                        checked: true,
                        width: '150'
                    },
                    {
                        label: "家庭地址",
                        prop: "address",
                        checked: true,
                        width: '150'
                    },
                    {
                        label: "登记时间",
                        prop: "createTime",
                        checked: true,
                        formatter: this.timeFormatter,
                        width: '150'
                    },
                ],
                apiurl: "lrxx/getDataList",
                refreshFlag: "",
                selectedRows: [],
                isChecked: false,
                isNumber: true
            },
            options: [{
                value: 'zhinan',
                label: '闻韶街道',
                children: [{
                    value: 'yizhi',
                    label: '南王镇'
                }, {
                    value: 'fankui',
                    label: '敬仲镇'
                }, {
                    value: 'xiaolv',
                    label: '梧台镇'
                }, {
                    value: 'kekong',
                    label: '朱台镇'
                }]
            },],
            rulesAdd: [],
            token: { token: localStorage.getItem("systoken") },
            xlsAreafileList: [],
            xlsAreaAction: "/api/oldUser/importUser.json",
            title: '老人档案',
            activeItem: {
            },
            systemConfig: this.$store.getters.systemSettings.config,
            searchIsShow: false
        };
    },

    mounted() {
        this.setActiveItem(this.systemConfig[0])
    },

    methods: {
        timeFormatter(row, column, cellValue) {
            return cellValue ? this.$timestampTostr(cellValue) : "";
        },
        //   查询
        dataRefresh() {
            this.table.refreshFlag = (new Date()).getTime().toString()
        },
        reset() {
            const th = this
            Object.keys(th.query).forEach(function (key) {
                if (key == 'value') {
                    th.query.value = '1'
                } else {
                    th.query[key] = ''
                }

            })
            this.dataRefresh();
        },
        // 导入
        onXlsUploadSuccess(response) {
            if (response.success) {
                this.$message({
                    type: "success",
                    message: "导入成功",
                });
                this.dataRefresh();
            } else {
                this.$message({
                    type: "error",
                    message: "导入失败",
                });
            }
        },
        async derive() {
            const token = await this.$store.dispatch("user/getToken");
            const form = Object.assign({}, this.form);
            if (this.options.shipColumn && this.options.shipColumn === "column2") {
                form.dataMode = 0;
            }
            this.$axios({
                method: "post",
                url: "/api/oldUser/doDownLoad",
                data: this.query,
                headers: {
                    token: token,
                    platform: "web",
                },
                responseType: "blob",
            })
                .then((response) => {
                    if (response.headers["content-disposition"]) {
                        const content = response.data;
                        const blob = new Blob([content]); //构造一个blob对象来处理数据

                        // fileName必用这种方式进行解析，否则乱码
                        // Server.UrlEncode(filename);

                        let contentDisposition = response.headers["content-disposition"];
                        // fileName必用这种方式进行解析，否则乱码
                        let fileName = window.decodeURI(
                            contentDisposition.substring(contentDisposition.indexOf("=") + 1)
                        );
                        //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
                        if ("download" in document.createElement("a")) {
                            const link = document.createElement("a"); //创建a标签
                            link.download = fileName; //a标签添加属性
                            link.style.display = "none";
                            link.href = URL.createObjectURL(blob);
                            document.body.appendChild(link);
                            link.click(); //执行下载
                            URL.revokeObjectURL(link.href); //释放url
                            document.body.removeChild(link); //释放标签
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        search() {
            this.$refs.drawerIshow.drawer = true
        },
        setActiveItem(item) {
            this.activeItem = {
                name: item.name,
                key: item.key,
                component: item.component,
            };
        },
        more() {
            this.searchIsShow = !this.searchIsShow
        }
    },
};
</script>
  
<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";

::v-deep .el-table th,
::v-deep .el-table td {
    text-align: center !important;
}

.data {
    display: flex;
}

.component {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
}
</style>
  