<template>
  <Drawer :title="title" ref="drawerIshow" :buttonIsShow="true" @resetForm="resetForm" submitFormName="提交"
    @submitForm="submitForm">
    <el-form class="form" ref="form" :model="dataForm" label-width="80px" label-position="top">
      <el-form-item label="助餐站点" prop="buffetId">
        <el-select v-model="dataForm.buffetId" placeholder="请选择助餐站点">
          <el-option v-for="item in buffetList" :key="item.id" :label="item.buffetName" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="菜品分类" prop="type">
        <el-input v-model="dataForm.type" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input-number :min="0" v-model="dataForm.sort" placeholder="请输入" show-word-limit style="width: 100%" />
      </el-form-item>
      <el-form-item label="描述" prop="describe">
        <el-input v-model="dataForm.describe" placeholder="请输入" type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"></el-input>
      </el-form-item>
    </el-form>
  </Drawer>
</template>

<script>
import { Drawer } from "@/components";
export default {
  name: "ClassificationInfo",
  components: { Drawer },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 助餐站点options
      // form字段
      dataForm: {
        buffetId: '',
        type: '',
        sort: '',
        describe: '',
        id: ''
      },
      buffetList: []
    };
  },

  mounted() {
    this.buffet()
  },
  methods: {
    buffet() {
      this.$store.dispatch("homePage/buffet", {}).then((res) => {
        this.buffetList = res.result
      })
    },
    // 根部id获取详情
    getInfo(id) {
      console.log('id', id)
    },
    // 重置form
    resetForm() {
      this.$refs.form.resetFields()
    },
    submitForm() {
      this.$store.dispatch("homePage/buffetDishesTypeSaveData", this.dataForm).then((res) => {
        if (res.success == true) {
          this.$refs.drawerIshow.drawer = false;
          this.$emit('event-name');

        } else {
          this.$message.error(res.message)
        }

      })
    }
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 80%;
}
</style>
