<template>
  <div>
    <!-- 服务包设置 -->
    <Drawer :title="title" ref="drawer">
      <el-form size="small" :model="operationsForm" class="demo-form-inline" label-width="120">
        <div class="title" style="
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            font-weight: 700;
            border-bottom: 1px solid #ddd;
          ">
          服务操作
          <el-button style="margin-left: 20px" type="text" @click="handleModifyRecord">修改记录</el-button>
        </div>
        <el-form-item label="签字确认：">
          <el-select v-model="operationsForm.confirmation" placeholder="请选择签字确认">
            <el-option label="开启" value="1"> </el-option>
            <el-option label="不开启" value="2"> </el-option>
          </el-select>
          <span style="color: #aaa; margin-left: 20px">当服务人员签 出时，服务人员和老人的签字均不能为空。</span>
        </el-form-item>
        <el-form-item label="福卡授权验证：">
          <el-select v-model="operationsForm.confirmation" placeholder="请选择福卡授权验证">
            <el-option label="开启" value="1"> </el-option>
            <el-option label="不开启" value="2"> </el-option>
          </el-select>
          <span style="color: #aaa; margin-left: 20px">需要服务对象福卡号认证身份，方可完成签出等闭环操作。</span>
        </el-form-item>
        <el-form-item label="服务人员人脸识别：">
          <el-select v-model="operationsForm.faceRecognition" placeholder="请选择服务人员人脸识别">
            <el-option label="开启" value="1"> </el-option>
            <el-option label="不开启" value="2"> </el-option>
          </el-select>
          <span style="color: #aaa; margin-left: 20px">需要服务人员人脸识别，方可完成签入。</span>
        </el-form-item>
        <el-form-item label="用户现场评价：">
          <el-select v-model="operationsForm.fieldEvaluation" placeholder="请选择用户现场评价">
            <el-option label="开启" value="1"> </el-option>
            <el-option label="不开启" value="2"> </el-option>
          </el-select>
          <span style="color: #aaa; margin-left: 20px">当服务人员签出前，需要填写用户对服务的评价。</span>
        </el-form-item>
        <el-form-item label="评价录音上传：">
          <el-select v-model="operationsForm.recordingUpload" placeholder="请选择评价录音上传">
            <el-option label="开启" value="1"> </el-option>
            <el-option label="不开启" value="2"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务录像上传：">
          <el-select v-model="operationsForm.videoUpload" placeholder="请选择服务录像上传">
            <el-option label="开启" value="1"> </el-option>
            <el-option label="不开启" value="2"> </el-option>
          </el-select>
        </el-form-item>

        <div class="title" style="
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            font-weight: 700;
            border-bottom: 1px solid #ddd;
          ">
          服务要求
        </div>
        <el-form-item label="从相册选择服务图片：">
          <el-select v-model="operationsForm.confirmation" placeholder="从相册选择服务图片">
            <el-option label="允许" value="1"> </el-option>
            <el-option label="不允许" value="2"> </el-option>
          </el-select>
          <span style="color: #aaa; margin-left: 20px">若不允许，则只能实时拍照上传。</span>
        </el-form-item>
        <el-form-item label="照片水印：">
          <el-select @change="changePhoto" v-model="operationsForm.photoWatermark" placeholder="请选择照片水印">
            <el-option label="不需要" value="1"> </el-option>
            <el-option label="需要" value="2"> </el-option>
          </el-select>
          <div v-if="photoFlag" style="width: 200px; display: inline-block; margin-left: 10px">
            <el-input placeholder="此为水印表达式" v-model="operationsForm.photo"></el-input>
          </div>
          <span style="color: #aaa; margin-left: 20px">开启对工单内所有照片都生效。水印内容默认为图片上传时间，如需显示其他内容，请联系技术人员配置。</span>
        </el-form-item>
        <el-form-item label="允许单日重复服务：">
          <el-select v-model="operationsForm.faceRecognition" placeholder="请选择允许单日重复服务">
            <el-option label="不允许" value="1"> </el-option>
            <el-option label="允许" value="2"> </el-option>
          </el-select>
          <span style="color: #aaa; margin-left: 20px">某服务对象的同一服务项在一天内是否允许被服务多次。</span>
        </el-form-item>
        <el-form-item label="签入签出传照片：">
          <el-select v-model="operationsForm.fieldEvaluation" placeholder="请选择签入签出传照片">
            <el-option label="开启" value="1"> </el-option>
            <el-option label="不开启" value="2"> </el-option>
          </el-select>
          <span style="color: #aaa; margin-left: 20px">签入签出时需要上传现场照片。</span>
        </el-form-item>
        <el-form-item label="服务定位距离（米）：">
          <el-input-number v-model="operationsForm.distance"></el-input-number>
          <div style="display: inline-block; width: 35rem">
            <el-select style="width: 100%" v-model="operationsForm.distance2" placeholder="请选择服务录像上传">
              <el-option label="非强制(签入签出时距离校验超出设定值，允许签入并判定为异常工单)" value="N">
              </el-option>
              <el-option label="强制(签入签出时距离校验超出设定值，不允许签入)" value="Y">
              </el-option>
            </el-select>
          </div>
          <div style="color: #aaa">
            1、非强制：签入签出时进行距离检测，若超出设定的值，或手机定位失败，依然允许签入签出，并判定该工单为“反常数据”，需在“服务位置反常数据检测”中进行人工审查和处理。
            2、强制：签入签出时距离校验超出设定值，不允许签入签出。
            3、若有效签到范围设置为0，则不启用距离检测。
          </div>
        </el-form-item>
        <el-form-item label="允许签入时间范围：">
          <el-time-picker disabled is-range v-model="operationsForm.time" range-separator="至" start-placeholder="开始时间"
            end-placeholder="结束时间" placeholder="选择时间范围">
          </el-time-picker>
        </el-form-item>

        <div class="title" style="
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            font-weight: 700;
            border-bottom: 1px solid #ddd;
          ">
          服务对象要求
        </div>
        <el-form-item label="服务对象最小年龄：">
          <el-input-number v-model="operationsForm.num" :min="1" :step="0"></el-input-number>
        </el-form-item>

        <div class="title" style="
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            font-weight: 700;
            border-bottom: 1px solid #ddd;
          ">
          服务人员要求
        </div>
        <el-form-item label="服务人员年龄：">
          <el-input-number v-model="operationsForm.num1" :min="1" :step="0"></el-input-number>
          到
          <el-input-number v-model="operationsForm.num2" :min="1" :step="0"></el-input-number>
          <span style="color: #aaa; margin-left: 20px">
            当服务人员签入时，会对其年龄进行校验。
          </span>
        </el-form-item>
      </el-form>
      <serviceOperations ref="serviceOperations"></serviceOperations>
    </Drawer>
  </div>
</template>

<script>
import serviceOperations from "./serviceOperations.vue";
import { Drawer } from "@/components";
export default {
  components: { Drawer, serviceOperations },
  data() {
    return {
      photoFlag: false,
      title: "当前页面数值为运营的服务规则",
      operationsForm: {
        confirmation: "1", //签字确认
        authorizationVerification: "1", // 福卡授权验证
        faceRecognition: "1", // 人脸识别
        fieldEvaluation: "1", // 现场评价
        recordingUpload: "1", // 录音上传
        videoUpload: "1", // 录像上传
        photoWatermark: "1", // 照片水印
        photo: "",
        distance: "5000",
        distance2: "N",
        time: "",
        num: 18,
        num1: 18,
        num2: 60,
      },
    };
  },
  created() { },
  methods: {
    changePhoto(val) {
      this.photoFlag = false;
      if (val === "2") {
        this.photoFlag = true;
      } else {
        this.photoFlag = false;
      }
    },
    handleModifyRecord() {
      this.$refs.serviceOperations.open();
    },
    onSubmit() { },
    open() {
      this.$refs.drawer.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
