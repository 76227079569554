<template>
  <div class="box">
    <div class="nav">
      <span v-for="(item, index) in navList" :key="item.name" :class="item.active ? 'active' : ''"
        @click="navClick(index)">
        {{ item.name }}
      </span>
    </div>
    <!-- 政府购买服务 -->
    <div class="zfgmfw" v-show="active">
      <hltableZ :columnEdit="table.columnEdit" :tableColumn="table.column" :apiurl="table.apiurl"
        :refreshFlag="table.refreshFlag" :selectedRows="table.selectedRows" :isChecked="table.isChecked"
        :isNumber="table.isNumber" :formSearch="query">
        <!-- <div slot-scope="selected" slot="EditColumn">
          <el-button
              icon="el-icon-delete"
              type="text"
              size="small"
              @click="delete selected.singlerow"
              >删除</el-button
            >
        </div> -->
      </hltableZ>
    </div>
    <!-- 家庭床位 -->
    <div class="jtcw" v-show="active1">
      <hltableZ :columnEdit="table1.columnEdit" :tableColumn="table1.column" :apiurl="table1.apiurl"
        :refreshFlag="table1.refreshFlag" :selectedRows="table1.selectedRows" :isChecked="table1.isChecked"
        :isNumber="table1.isNumber" :formSearch="query1">
        <!-- <div slot-scope="selected" slot="EditColumn">
          <el-button
              icon="el-icon-delete"
              type="text"
              size="small"
              @click="delete selected.singlerow"
              >删除</el-button
            >
        </div> -->
      </hltableZ>
    </div>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
export default {
  name: "YanglaoJjyl",
  components: { hltableZ },
  data() {
    return {
      navList: [
        { name: "政府购买服务", active: true },
        { name: "家庭床位", active: false },
      ],
      query: {
        oldName: "",
        organ: "",
        sex: "",
        level: "",
        area: "",
        status: "",
        idCard: "",
        phone: "",
      },
      table: {
        columnEdit: true,
        column: [
          {
            label: "服务对象",
            prop: "oldName",
            checked: true,
          },
          {
            label: "工单号",
            prop: "2",
            checked: true,
          },
          {
            label: "服务项",
            prop: "organName",
            checked: true,
          },
          {
            label: "服务人员",
            prop: "oldName",
            checked: true,
          },
          {
            label: "服务日期",
            prop: "2",
            checked: true,
          },
          {
            label: "入户时间",
            prop: "organName",
            checked: true,
          },
          {
            label: "服务状态",
            prop: "organName",
            checked: true,
          },
          {
            label: "服务总时长",
            prop: "oldName",
            checked: true,
          },
          {
            label: "服务产值",
            prop: "2",
            checked: true,
          },
          {
            label: "服务组织",
            prop: "organName",
            checked: true,
          },
          {
            label: "派单时间",
            prop: "organName",
            checked: true,
          },
          {
            label: "派单人",
            prop: "organName",
            checked: true,
          },
          {
            label: "回访结果",
            prop: "organName",
            checked: true,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      query1: {
        oldName: "",
        organ: "",
        sex: "",
        level: "",
        area: "",
        status: "",
        idCard: "",
        phone: "",
      },
      table1: {
        columnEdit: true,
        column: [
          {
            label: "床位编码",
            prop: "oldName",
            checked: true,
          },
          {
            label: "床位地址",
            prop: "2",
            checked: true,
          },
          {
            label: "服务组织",
            prop: "organName",
            checked: true,
          },
          {
            label: "老人姓名",
            prop: "oldName",
            checked: true,
          },
          {
            label: "老人身份证",
            prop: "2",
            checked: true,
          },
          {
            label: "服务起始日期",
            prop: "organName",
            checked: true,
          },
          {
            label: "服务终止日期",
            prop: "organName",
            checked: true,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      active: true,
      active1: false,
    };
  },

  mounted() { },

  methods: {
    navClick(e) {
      this.active = e == 0 ? true : false;
      this.active1 = e == 0 ? false : true;
      var array = this.navList;
      for (let i = 0; i < array.length; i++) {
        array[i].active = false;
        if (!array[e].active) {
          array[e].active = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100rem;
}

.zfgmfw,
.jtcw {
  width: 100%;
  height: 40rem;
  margin-top: 1rem;
}

.nav {
  width: 100%;
  height: 2rem;
  border-bottom: 1px solid #eee;
  font-size: 1rem;

  span {
    height: 1rem;
    display: inline-block;
    padding: 0.5rem;
    border-bottom: 1px solid rgba(0, 150, 136, 0);
    margin-right: 1rem;
  }

  .active {
    color: #009688;
    border-bottom: 1px solid #009688;
  }
}
</style>