<template>
  <div class="hl-full-container">
    <el-form ref="elForm" :model="formData" :disabled="submitType == 'view' ? true : false" :rules="rules" size="medium"
      class="padding15" label-width="100px">

      <el-col :span="24">
        <el-row>
          <el-col :span="12">
            <el-row>
              <el-col :span="24">
                <el-form-item label="用户名" prop="userName">
                  <el-input v-model="formData.userName" readonly placeholder="请输入用户名" clearable :style="{ width: '100%' }">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名" prop="fullName">
              <el-input v-model="formData.fullName" placeholder="请输入姓名" clearable :style="{ width: '100%' }">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="24">
        <el-row>
          <el-col :span="12">
            <el-form-item label="密码" prop="password">
              <el-input v-model="formData.password" placeholder="请输入密码" clearable show-password
                :style="{ width: '100%' }"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认密码" prop="confirmpassword">
              <el-input v-model="formData.confirmpassword" placeholder="请输入确认密码" clearable show-password
                :style="{ width: '100%' }"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="24">
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="formData.remark" type="textarea" placeholder="请输入备注" :autosize="{ minRows: 4, maxRows: 4 }"
                :style="{ width: '100%' }"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="24">
        <el-row>
          <el-form-item size="large" style="text-align: center;">
            <el-button v-show="submitType != 'view' ? true : false" class="qconfirm" type="primary" size="medium"
              @click="submitForm()">提交</el-button>
            <el-button v-show="submitType != 'view' ? true : false" class="qcancle" size="medium" @click="resetForm()">
              重置
            </el-button>
          </el-form-item>
        </el-row>
      </el-col>
    </el-form>
  </div>
</template>

<script>
import cryptoAES from '@/utils/cryptoAES'
export default {
  name: 'personal',
  components: {},
  computed: {
    userinfo() {
      return this.$store.getters.user
    },
  },
  data() {
    var _this = this
    var Pass = (rule, value, callback) => {
      if (!value && _this.submitType == "add") {
        callback(new Error('请输入密码'));
      } else {
        if (this.formData.confirmpassword !== '') {
          this.$refs.elForm.validateField('confirmpassword');
        }
        callback();
      }
    }
    var PassConfirm = (rule, value, callback) => {
      if (!value && _this.submitType == "add") {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.formData.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    }
    return {
      submitType: 'edit',
      formData: {
        userName: '',
        fullName: '',
        password: '',
        confirmpassword: '',
        remark: '',
      },
      rules: {
        userName: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        fullName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        password: [
          { validator: Pass, trigger: 'blur', required: true, }
        ],
        confirmpassword: [
          { validator: PassConfirm, trigger: 'blur', required: true, }
        ],
        remark: [],
      },
    }
  },
  watch: {
  },
  created() {

  },
  mounted() {
    this.dynamicKey()
    this.formData.id = this.userinfo.userid
    this.formData.userName = this.userinfo.userName
    this.formData.fullName = this.userinfo.fullName
    this.formData.remark = this.userinfo.remark
  },
  destroyed() {
  },
  methods: {
    dynamicKey() {
      this.$store.dispatch('user/dynamicKey')
    },
    resetForm() {
      const th = this
      Object.keys(this.formData).forEach(function (key) {
        if (key != "userName") {
          th.formData[key] = ""
        }
      })
    },
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (valid) {
          this.loading = true
          const copyForm = Object.assign({}, this.formData);
          const aesPassword = cryptoAES.encrypt(copyForm.password, this.$store.getters.user.key)
          copyForm.password = aesPassword
          if (this.submitType == "add") {
            copyForm.id = ''
          }
          this.$store.dispatch('sysUser/saveData', copyForm)
            .then((response) => {
              if (response.success) {
                this.$message.success("操作成功");
                this.dataRefresh()
              }
              else {
                this.$message.error(response.message);
              }
              this.dialogFormVisible = false
            })
            .catch((error) => {
              this.dialogFormVisible = false
              console.log(error)
            })
        }
        else {
          console.log('error submit!!')
          return false
        }
      })
    },
  }
}
</script>

<style lang="scss"></style>
