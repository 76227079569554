<template>
  <div class="tabs">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <template v-for="(item, index) in tabsList">
        <el-tab-pane :label="item.label" :name="item.name">
          <!-- 未点餐人数统计和导出 -->
          <div class="noOrderNumberExport" :style="{
            justifyContent: index !== 5 ? 'flex-end' : 'space-between',
          }">
            <span v-if="index === 5" class="noOrderNumber">
              未知助餐点就餐人次：{{ noOrderNumber }}
            </span>
            <el-button v-if="index > 1" class="export" type="info" size="small" icon="el-icon-download"
              @click="derive">导出</el-button>
          </div>
          <!-- 表格 -->
          <hltableZ v-if="activeName === item.name && item.name !== 'sixth'" style="height: 30rem"
            :columnEdit="table.columnEdit" :tableColumn="table.column" :apiurl="table.apiurl"
            :refreshFlag="table.refreshFlag" :selectedRows="table.selectedRows" :isChecked="table.isChecked"
            :isNumber="table.isNumber" :staticData="tableDataSource">
            <div slot-scope="selected" slot="EditColumn">
              <el-button icon="el-icon-view" type="text" size="small" @click="search(selected.singlerow)">查看</el-button>
            </div>
          </hltableZ>
          <!-- 县区选项卡组件 -->
          <countyDistrictTabs v-else></countyDistrictTabs>
        </el-tab-pane></template>
    </el-tabs>
    <!-- 查看详情 -->
    <orderFormInfo ref="orderFormInfo"></orderFormInfo>
  </div>
</template>
<script>
import { hltableZ } from "@/components";
import { statisticsTablesData } from "../../statisticsTablesData";
import countyDistrictTabs from "./CountyDistrictTabs.vue";
import orderFormInfo from "./orderFormInfo.vue"

const tabsList = [
  { label: "订单明细", name: "first" },
  // { label: "按补贴对象统计", name: "second" },
  // { label: "按助餐站点统计", name: "third" },
  // { label: "按日期统计", name: "fourth" },
  // { label: "按街道统计", name: "fifth" },
  // { label: "按补贴分类统计（仅供参考）", name: "sixth" },
];
export default {
  name: "StatisticsTabs",
  components: { hltableZ, countyDistrictTabs, orderFormInfo },
  props: {
    noOrderNumber: {
      type: [Number, String],
      default: 0,
    },
  },

  data() {
    return {
      tabsList: [...tabsList],
      activeName: "first",
      table: { ...statisticsTablesData.first },
      tableDataSource: [
        {
          areaName: "1",
          chronicDisease: "1",
        },
      ],
    };
  },

  mounted() { },

  methods: {
    // 选项卡点击
    handleClick() {
      this.table = statisticsTablesData[this.activeName];
    },
    // 查看详情
    search() {
      this.$refs.orderFormInfo.$refs.drawerIshow.drawer = true;
    },
    // 导出
    derive() { },
  },
};
</script>
<style lang="scss" scoped>
.tabs {
  margin-top: 1.25rem;

  .noOrderNumberExport {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .noOrderNumber {
      color: #3d9cf0;
      font-weight: bold;
    }
  }

  ::v-deep .el-tabs.el-tabs--top {
    height: 100%;
  }
}
</style>
