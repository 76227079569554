<template>
  <Drawer
    title="账号管理"
    ref="drawerIshow"
    :buttonIsShow="true"
    @resetForm="resetForm"
    submitFormName="保存"
  >
    <subTitle information-name="重置密码"></subTitle>
    <el-row :gutter="24">
      <el-form class="form" ref="form" :model="dataForm" label-width="140px">
        <el-col :span="13">
          <el-form-item label="账号" prop="account">
            <el-input v-model="dataForm.account">
              <i
                slot="suffix"
                class="el-icon-view"
                @click="handlViewPassword"
              ></i>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="13">
          <el-form-item label="请输入密码" prop="password">
            <el-input v-model="dataForm.password"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="13">
          <el-form-item label="请重新输入新密码" prop="newPassword">
            <el-input v-model="dataForm.newPassword"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="13">
          <el-form-item label="">
            <span class="desc">重置后，用户首次登录需要重新设置密码！</span>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </Drawer>
</template>

<script>
import { Drawer } from "@/components";
import subTitle from "@/views/ZC/components/common/subTitle.vue";
export default {
  name: "AccountInfoResetDrawer",
  components: { Drawer, subTitle },
  data() {
    return {
      dataForm: {
        account: "",
        password: "",
        newPassword: "",
      },
    };
  },

  mounted() {},

  methods: {
    resetForm() {
      this.$refs.form.resetFields();
    },
    // 密码显示弹窗
    handlViewPassword() {
      this.$alert(`密码${11111}`, "信息", {
        confirmButtonText: "确定",
      }).then(() => {
        console.log('1111')
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  .el-icon-view {
    cursor: pointer;
  }
  .desc {
    color: red;
  }
}
</style>
