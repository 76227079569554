<template>
  <div class="box" ref="charts"></div>
</template>

<script>
export default {
  name: "pieChart",
  props: {
    data: {
      type: Array,
    },
    data1: {
      type: Array,
    },
    name: {
      type: String,
    },
  },
  data() {
    return {
      option: {},
      mycharts: null,
    };
  },
  mounted() {
    // console.log(this.$refs.charts);

    // this.$nextTick(() => {
    this.getCharts();
    // });
  },
  created() { },
  methods: {
    getCharts() {
      // console.log(this.data, this.data1);

      this.mycharts = this.$echarts.init(this.$refs.charts);
      this.option = {
        backgroundColor: "#fff",
        tooltip: {
          trigger: "item",
        },
        legend: {
          type: "plain",
          orient: "vertical",
          left: "75%",
          top: "50%",
          align: "left",
          itemGap: 10,
          itemWidth: 20, // 设置宽度
          itemHeight: 20, // 设置高度]
          symbolKeepAspect: false,
          selectedMode: false,
          textStyle: {
            color: "#333333",
            fontSize: this.nowSize(14),
          },
        },
        title: {
          text: "数量",
          subtext: `100.00`,
          top: "44%",
          left: "center",
          textStyle: {
            color: "#666666",
            fontSize: this.nowSize(20),
            fontWeight: this.nowSize(400),
          },
          subtextStyle: {
            color: "#333333",
            fontSize: this.nowSize(16),
            fontWeight: this.nowSize(400),
          },
        },
        series: [
          {
            type: "pie",
            radius: ["70%", "85%"],
            center: ["50%", "50%"],
            hoverAnimation: true,
            hoverOffset: this.nowSize(25),
            labelLine: {
              show: true,
              length2: this.nowSize(100),
              length: this.nowSize(20),
              lineStyle: {
                color: "#DDDDDD",
              },
            },
            label: {
              show: false,
              position: "center",
              color: "rgba(13, 17, 29,0)",
              formatter: (params) => {
                const x = params.dataIndex;
                return (
                  "{dot" +
                  x +
                  "|}" +
                  "{name" +
                  x +
                  "|" +
                  params.data.name +
                  "}" +
                  "\n" +
                  "{zb" +
                  x +
                  "|占比" +
                  params.percent +
                  "%}"
                );
              },
            },
            data: this.data,
          },
          {
            type: "pie",
            radius: ["62%", "70%"],
            center: ["50%", "50%"],
            labelLine: {
              show: false,
            },
            label: {
              show: false,
            },
            silent: true,
            data: this.data1,
          },
        ],
      };

      this.mycharts.setOption(this.option);
    },
    nowSize(val, initWidth = 1920) {
      //当前视口宽度
      let nowClientWidth = document.documentElement.clientWidth;
      return val * (nowClientWidth / initWidth);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
}
</style>