<template>
  <Drawer title="准补贴对象库" ref="drawerIshow">
    <div class="subsidyRecipients">
      <!-- 上方标题 -->
      <div class="topTitle">
        <div>
          <i class="el-icon-film"></i>
          <span class="title">准补贴对象</span>
          <span class="desc">((转为正式补贴对象才能享受补贴)</span>
        </div>
        <!-- 新增 -->
        <div class="toolbar">
          <el-button type="primary" size="mini" plain icon="el-icon-add" @click="handleAdd">新增</el-button>
        </div>
      </div>
      <!-- 搜索条件和表格 -->
      <div class="search">
        <el-form :model="query" ref="query" label-width="80px" inline>
          <el-form-item label="助餐站点" prop="mealLocation">
            <el-select v-model="query.buffetId" placeholder="请选择助餐站点">
              <el-option v-for="item in buffetList" :key="item.id" :label="item.buffetName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名" prop="v">
            <el-input v-model="query.oldName" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="老人状态" prop="subsidyRecipient">
            <el-select v-model="query.status" placeholder="">
              <el-option label="在世" value="在世"></el-option>
              <el-option label="异常" value="异常"></el-option>
            </el-select>
          </el-form-item>
          <!-- <template v-if="searchIsShow">
            <el-form-item label="身份证" prop="ICCard">
              <el-input v-model="query.ICCard" placeholder="请输入身份证"></el-input>
            </el-form-item>
            <el-form-item label="补贴分类" prop="subsidyType">
              <el-input v-model="query.subsidyType" placeholder="请输入补贴分类"></el-input>
            </el-form-item>
            <el-form-item label="本地户籍校验" prop="domicileCheck" label-width="100px">
              <el-select v-model="query.domicileCheck" placeholder="">
                <el-option label="全部" :value="null"></el-option>
                <el-option label="未检测或无需检测" value="1"></el-option>
                <el-option label="本地" value="2"></el-option>
                <el-option label="非本地" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否迁出" prop="domicileCheck">
              <el-select v-model="query.domicileCheck" placeholder="">
                <el-option label="全部" :value="null"></el-option>
                <el-option label="未迁出" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="年龄" prop="age">
              <el-input-number v-model="query.startAge" controls-position="right" :min="0" :max="200"></el-input-number>
              -
              <el-input-number v-model="query.endtAge" controls-position="right" :min="0" :max="200"></el-input-number>
            </el-form-item>
          </template> -->
          <el-form-item>
            <el-button size="mini" type="primary" icon="el-icon-search" @click="dataRefresh">查询</el-button>
            <el-button size="mini" icon="el-icon-refresh-left" @click="reset">重置</el-button>
            <el-button size="small" type="info" :icon="searchIsShow == false
              ? 'el-icon-arrow-down'
              : 'el-icon-arrow-up'
              " @click="more">更多</el-button>
          </el-form-item>
        </el-form>
      </div>
      <hltableZ :columnEdit="table.columnEdit" :tableColumn="table.column" :apiurl="table.apiurl"
        :refreshFlag="table.refreshFlag" :selectedRows="table.selectedRows" :isChecked="table.isChecked"
        :isNumber="table.isNumber" :isClickSelect="table.isClickSelect" :formSearch="query" :widthClick="table.widthClick"
        :handleCellStyle="cellStyle">
        <div slot-scope="selected" slot="EditColumn">
          <!-- <el-button icon="el-icon-view" type="text" size="small" @click="handleDetail">详情</el-button> -->
          <el-button icon="el-icon-view" type="text" size="small" @click="handleEdit(selected.singlerow)">编辑</el-button>
          <el-button icon="el-icon-view" type="text" size="small"
            @click="handleFormal(selected.singlerow)">转正式</el-button>
          <el-button icon="el-icon-view" type="text" size="small"
            @click="handledDelete(selected.singlerow)">删除</el-button>
        </div>
      </hltableZ>
    </div>
    <!-- 新增弹窗组件 -->
    <memberInfo :title="memberInfoTitle" ref="memberInfo" :buttonIsShow="buttonIsShow" :submitFormName="submitFormName"
      @event-name="eventName">
    </memberInfo>
  </Drawer>
</template>

<script>
import { Drawer, hltableZ } from "@/components";
import memberInfo from "./memberInfo.vue";

export default {
  name: "SubsidyRecipients",
  components: { Drawer, hltableZ, memberInfo },
  data() {
    return {
      searchIsShow: false,
      // 县区option
      countyDistrictOptions: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "1",
          label: "辛店街道于家店村长者食堂",
        },
        {
          value: "2",
          label: "齐都镇东古村长者食堂",
        },
      ],
      // 街道option
      streetOptions: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "1",
          label: "辛店街道于家店村长者食堂",
        },
        {
          value: "2",
          label: "齐都镇东古村长者食堂",
        },
      ],
      // 社区option
      communityOptions: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "1",
          label: "辛店街道于家店村长者食堂",
        },
        {
          value: "2",
          label: "齐都镇东古村长者食堂",
        },
      ],
      // 补贴对象
      subsidyRecipientOptions: [
        {
          value: null,
          label: "对象性质",
        },
        {
          value: "1",
          label: "正式补贴对象",
        },
        {
          value: "2",
          label: "准补贴对象",
        },
      ],
      // 助餐站点options
      mealLocationOptions: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "1",
          label: "辛店街道于家店村长者食堂",
        },
        {
          value: "2",
          label: "齐都镇东古村长者食堂",
        },
      ],
      table: {
        columnEdit: true,
        column: [
          {
            label: "姓名",
            prop: "oldName",
            checked: true,
            width: "150",
          },
          {
            label: "身份证",
            prop: "idCard",
            checked: true,
            width: "150",
          },
          {
            label: "年龄",
            prop: "age",
            checked: true,
            width: "150",
          },
          {
            label: "责任助餐点",
            prop: "buffetId",
            checked: true,
            width: "150",
          },
          {
            label: "户口所在地",
            prop: "areaName",
            checked: true,
            width: "150",
          },
          {
            label: "本地户籍",
            prop: "address",
            checked: true,
            width: "150",
          },
          {
            label: "生存状态",
            prop: "status",
            checked: true,
            width: "150",
          },
          {
            label: "创建人",
            prop: "createUser",
            checked: true,
            width: "150",
          },
          {
            label: "创建时间",
            prop: "createTime",
            checked: true,
            width: "150",
            formatter(value) {
              let time = new Date(value.createTime)
              let year = time.getFullYear()
              let month = time.getMonth() + 1
              let date = time.getDate()
              let hours = time.getHours()
              let minute = time.getMinutes()
              let second = time.getSeconds()
              if (month < 10) { month = '0' + month }
              if (date < 10) { date = '0' + date }
              if (hours < 10) { hours = '0' + hours }
              if (minute < 10) { minute = '0' + minute }
              if (second < 10) { second = '0' + second }
              return year + '-' + month + '-' + date + ' ' + hours + ':' + minute + ':' + second
            },
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      // 静态资源
      // 搜索条件
      query: {
        type: '6',
        oldName: "",
        status: ''
      },
      // 弹窗标题
      memberInfoTitle: "",
      // 弹窗按钮是否显示
      buttonIsShow: false,
      // 弹窗按钮确定按钮显示文本
      submitFormName: "确定",
      quality: '2',
      buffetList: [],
    };
  },

  mounted() { this.buffet() },

  methods: {
    buffet() {
      this.$store.dispatch("homePage/buffet", {}).then((res) => {
        console.log(999, res)
        this.buffetList = res.result
        this.$refs.memberInfo.buffetList = res.result
      })
    },
    // 查询
    dataRefresh() { this.table.refreshFlag = new Date().getTime().toString(); },
    // 重置
    reset() { },
    // 更多
    more() {
      this.searchIsShow = !this.searchIsShow;
    },
    restc(e) {
      const th = this
      Object.keys(e).forEach(function (key) {
        if (key == 'quality') {
          th.$refs.memberInfo.dataForm.quality = '2'
        } else {
          e[key] = "";
        }

      });
    },
    // 新增
    handleAdd() {
      this.memberInfoTitle = '新增准补贴对象信息'
      this.buttonIsShow = true
      this.submitFormName = '保存'
      this.$refs.memberInfo.$refs.drawerIshow.drawer = true;
      this.$refs.memberInfo.fileStoreEntity2 = []
      this.$refs.memberInfo.idPhotoFile2 = []
      this.$refs.memberInfo.householdFile2 = []
      this.restc(this.$refs.memberInfo.dataForm)
    },
    // 详情
    handleDetail() {
      this.memberInfoTitle = "准补贴对象信息详情";
      this.buttonIsShow = false;
      this.$refs.memberInfo.$refs.drawerIshow.drawer = true;
    },
    // 编辑
    handleEdit(e) {
      this.memberInfoTitle = "编辑准补贴对象信息";
      this.buttonIsShow = true;
      this.submitFormName = "修改";
      this.$refs.memberInfo.$refs.drawerIshow.drawer = true;
      this.$refs.memberInfo.dataForm.quality = this.quality
      Object.keys(this.$refs.memberInfo.dataForm).forEach(key => {
        if (key == 'household') {
          this.$refs.memberInfo.householdFile2 = []
          e.householdFile.forEach((e) => {
            this.$refs.memberInfo.householdFile2.push(e.filePath)
          })
          let a = []
          e.householdFile.forEach((e) => {
            a.push(e.id)
          })
          this.$refs.memberInfo.dataForm.household = a.join(',')
        } else if (key == 'idPhoto') {
          this.$refs.memberInfo.idPhotoFile2 = []
          e.idPhotoFile.forEach((e) => {
            this.$refs.memberInfo.idPhotoFile2.push(e.filePath)
          })
          let a = []
          e.idPhotoFile.forEach((e) => {
            a.push(e.id)
          })
          this.$refs.memberInfo.dataForm.idPhoto = a.join(',')
        } else {
          console.log(8888888, key)
          this.$refs.memberInfo.dataForm[key] = e[key] || this.$refs.memberInfo.dataForm[key]
        }

      })
    },
    // 转正式
    handleFormal(e) {
      this.handleEdit(e)
      this.quality = '1'
      this.$refs.memberInfo.dataForm.quality = this.quality
    },
    // 表格样式
    cellStyle(row, column) {
      if (
        column.property === "IC" ||
        (column.property === "phone" && this.activeName === "second")
      ) {
        return {
          color: "#409EFF",
          cursor: "pointer",
        };
      }
    },
    eventName() {
      this.dataRefresh()
    },
    // 删除
    handledDelete(e) {
      this.$confirm('确定将选择数据删除?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store
          .dispatch("homePage/deleteData", { ids: [e.id] })
          .then((response) => {
            if (response.success) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.dataRefresh();
            }
          })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
  },
};
</script>

<style lang="scss" scoped>
.subsidyRecipients {
  height: 100%;
  display: flex;
  flex-direction: column;

  .topTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.3125rem;
    border-bottom: 1px solid #eee;
    margin-bottom: 0.9375rem;

    .el-icon-film {
      font-size: 0.875rem;
      margin-right: 0.1875rem;
    }

    .title {
      font-size: 1rem;
    }

    .desc {
      font-size: 0.875rem;
    }
  }
}
</style>
