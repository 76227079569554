<template>
  <div class="hl-full-container flexVertical">
    <!-- 上方标题 -->
    <div class="titleButton">
      <div class="topTitle">
        <i class="el-icon-film"></i>
        <span class="title">家庭床位居家服务人员</span>
        <span class="subTitle">(来自于服务组织配置的员工)</span>
      </div>
      <el-button
        type="primary"
        size="small"
        icon="el-icon-film"
        @click="openAssignOccupation"
        >批量分配职业</el-button
      >
    </div>
    <!-- 搜索条件 -->
    <div class="serach">
      <el-form :model="query" ref="query" label-width="80px" inline>
        <!-- <el-form-item label="服务组织" prop="serviceOrganization">
          <el-select
            v-model="query.serviceOrganization"
            placeholder="请选择服务组织"
          >
            <el-option
              v-for="item in serviceOrganizationOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="员工姓名" prop="name">
          <el-input v-model="query.name" placeholder="请输入"></el-input>
        </el-form-item>
        <!-- <el-form-item label="在职状态" prop="status">
          <el-select v-model="query.status" placeholder="请选择服务组织">
            <el-option label="在职" value="1"></el-option>
            <el-option label="离职" value="0"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
          <!-- <el-button size="small" icon="el-icon-refresh-left" @click="reset"
            >重置</el-button
          > -->
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格 -->
    <hltableZ
      :columnEdit="table.columnEdit"
      :tableColumn="table.column"
      :apiurl="table.apiurl"
      :isPage="false"
      :staticData="table.staticData"
      :refreshFlag="table.refreshFlag"
      :selectedRows="table.selectedRows"
      :isChecked="table.isChecked"
      :isNumber="table.isNumber"
      :isClickSelect="table.isClickSelect"
      :formSearch="query"
      :handleCellStyle="cellStyle"
      @getSelectedRows="getSelectedRows"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-button
          icon="el-icon-edit"
          type="text"
          size="small"
          @click="handleEdit(selected.singlerow)"
          >员工档案</el-button
        >
        <el-button
          icon="el-icon-setting"
          type="text"
          size="small"
          @click="handleCareer(selected.singlerow)"
          >职业授权</el-button
        >
      </div>
    </hltableZ>
    <!-- 批量分配 -->
    <batchAllocation
      ref="batchAllocation"
      @close="clearSelecRows"
    ></batchAllocation>
    <!-- 员工档案 -->
    <employeeFile ref="employeeFile"></employeeFile>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
import employeeFile from "./components/jjfwry/EmployeeFile";
import batchAllocation from "./components/jjfwry/BatchAllocation";
export default {
  name: "Jjfwry",
  components: { hltableZ, employeeFile, batchAllocation },
  data() {
    return {
      // 服务组织
      serviceOrganizationOptions: [
        { label: "全部", value: null },
        { label: "郑大姐服务组织", value: 1 },
        { label: "高新区居家养老服务组织", value: 2 },
      ],
      // 职业
      careerOptions: [
        { label: "家政服务", value: 1 },
        { label: "养老护理员", value: 2 },
        { label: "护士", value: 3 },
      ],
      // 表格属性值
      table: {
        staticData: [],
        columnEdit: false,
        column: [
          {
            label: "姓名",
            prop: "a",
            checked: true,
          },
          {
            label: "性别",
            prop: "b",
            checked: true,
          },
          {
            label: "身份证",
            prop: "c",
            checked: true,
          },
          {
            label: "报送单位",
            prop: "d",
            checked: true,
          },
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: true,
        isNumber: true,
        isClickSelect: false,
      },
      tableDataSource: [
        {
          name: "于献成",
          2: 2,
          3: 15655556666,
          career: "家政服务员,医生",
          5: 5,
          6: 6,
          7: 6,
          8: 6,
        },
      ],
      query: {
        serviceOrganization: "",
        name: "",
        career: "",
        status: "",
      },
    };
  },

  mounted() {
    this.dataRefresh();
  },

  methods: {
    // 批量分配职业
    openAssignOccupation() {
      if (this.table.selectedRows.length === 0)
        return this.$message.warning("请至少选择一个服务人员");
      if (this.table.selectedRows[0].career !== "无") {
        this.$confirm(
          `${this.table.selectedRows[0].a}已分配职业。确定继续批量分配职业吗？`,
          "提示",
          {
            type: "warning",
          }
        ).then(() => {
          this.$refs.batchAllocation.$refs.drawerIshow.drawer = true;
        });
      } else {
        this.$refs.batchAllocation.$refs.drawerIshow.drawer = true;
      }
    },
    // 选择项
    getSelectedRows(selectedRows) {
      this.table.selectedRows = selectedRows;
    },
    // 清空选择项
    clearSelecRows() {},
    // 查询
    dataRefresh() {
      this.table.staticData = [
        {
          a: "张杰",
          b: "女",
          c: "370305197701080722",
          d: "义家人社会工作服务中心",
        },
        {
          a: "刘英英",
          b: "女",
          c: "370305197409034366",
          d: "义家人社会工作服务中心",
        },
        {
          a: "顾晓亮",
          b: "男",
          c: "37030519741010371X",
          d: "义家人社会工作服务中心",
        },
        {
          a: "刘卫彩",
          b: "女",
          c: "371122198011203749",
          d: "义家人社会工作服务中心",
        },
        {
          a: "张丽芳",
          b: "女",
          c: "370305197106142466",
          d: "义家人社会工作服务中心",
        },
        {
          a: "郑龙文",
          b: "男",
          c: "370305197204115058",
          d: "义家人社会工作服务中心",
        },
        {
          a: "刁秀杰",
          b: "女",
          c: "370305197203202440",
          d: "义家人社会工作服务中心",
        },
        {
          a: "赵卫菊",
          b: "女",
          c: "370305197601240768",
          d: "义家人社会工作服务中心",
        },
        {
          a: "常向国",
          b: "男",
          c: "370305197201104011",
          d: "义家人社会工作服务中心",
        },
        {
          a: "刘在芹",
          b: "女",
          c: "37072119701116148X",
          d: "义家人社会工作服务中心",
        },
      ];
      this.table.refreshFlag = new Date().getTime().toString();
    },
    // 重置
    reset() {
      this.$refs.query.resetFields();
    },
    // 单元格样式
    cellStyle(row, column) {
      if (column.property === "career") {
        return {
          color: "#ff6e00",
        };
      }
    },
    // 员工档案
    handleEdit() {
      this.$refs.employeeFile.$refs.drawerIshow.drawer = true;
    },
    // 职业授权
    handleCareer() {},
  },
};
</script>

<style lang="scss" scoped>
.hl-full-container {
  font-size: 1rem;
  color: #606266;
  // 上方标题
  .titleButton {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    margin-bottom: 0.9375rem;
    .topTitle {
      .el-icon-film {
        font-size: 14px;
        margin-right: 3px;
      }

      .title {
        font-size: 16px;
        margin-right: 0.3125rem;
      }

      .subTitle {
        font-size: 12px;
        color: #aaa;
      }
    }
  }
}
</style>
