<template>
  <div class="hl-full-container flexVertical">
    <!-- 上方标题 -->
    <div class="topTitle">
      <i class="el-icon-film"></i>
      <span class="title"> 适老化改造施工</span>
    </div>
    <!-- 搜索条件 -->
    <!-- 表格 -->
    <hltableZ
      :columnEdit="table.columnEdit"
      :tableColumn="table.column"
      :apiurl="table.apiurl"
      :isPage="false"
      :onlyShowTotal="true"
      :staticData="table.staticData"
      :refreshFlag="table.refreshFlag"
      :selectedRows="table.selectedRows"
      :isChecked="table.isChecked"
      :isNumber="table.isNumber"
      :isClickSelect="table.isClickSelect"
      :formSearch="query"
      :handleCellStyle="cellStyle"
      @cellClick="cellClick"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-button
          icon="el-icon-edit"
          type="text"
          size="small"
          @click="handleEdit(selected.singlerow)"
          >编辑</el-button
        >
        <el-button
          icon="el-icon-setting"
          type="text"
          size="small"
          @click="handleModule(selected.singlerow)"
          >模块适配</el-button
        >
      </div>
    </hltableZ>
    <!-- 编辑弹窗 -->
    <fwzcInfo ref="fwzcInfo"></fwzcInfo>
    <!-- 账号弹窗 -->
    <accountInfo ref="accountInfo"></accountInfo>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
import fwzcInfo from "./components/fwzc/FwzcInfo.vue";
import accountInfo from "./components/fwzc/AccountInfo.vue";
export default {
  name: "Fwzc",
  components: { hltableZ, fwzcInfo, accountInfo },
  data() {
    return {
      // 营业状态
      businessStatusOptions: [
        { label: "全部", value: null },
        { label: "正常运营", value: "1" },
        { label: "注销", value: "2" },
        { label: "取缔", value: "3" },
        { label: "停业整顿", value: "4" },
        { label: "事实处于未开展服务状态", value: "5" },
        { label: "关停", value: "6" },
      ],
      // 表格属性值
      table: {
        staticData: [],
        columnEdit: false,
        column:[
          {
            label: "姓名",
            prop: "a",
            checked: true,
            width:"100"
          },
          {
            label: "身份证",
            prop: "b",
            checked: true,
            width:"200"
          },
          {
            label: "家庭住址",
            prop: "c",
            checked: true,
          },
          {
            label: "改造项目",
            prop: "d",
            checked: true,
          },
          {
            label: "核实情况",
            prop: "e",
            checked: true,
          },
          {
            label: "去世时间",
            prop: "f",
            checked: true,
          },
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
        isClickSelect: false,
      },
      tableDataSource: [
        {
          1: 1,
          2: 2,
          3: 15655556666,
          4: 4,
          account: 5,
          6: 6,
        },
      ],
      query: {
        institutionName: "",
        businessStatus: "",
      },
    };
  },

  mounted() {
    this.dataRefresh();
  },

  methods: {
    // 查询
    dataRefresh() {
      this.table.staticData = [
    {
        "a": "郭素梅",
        "b": "370305194905055322",
        "c": "山东省淄博市临淄区凤凰镇南王村108号",
        "d": "安装防撞护角/防撞条、提示标识,紧急求救按钮2,紧急求救按钮1,安装防撞护角/防撞条、提示标识,起夜灯,智能无感看护,安装防撞护角/防撞条、提示标识,安全扶手,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王焕栋",
        "b": "370305195610105331",
        "c": "北曹村3-39",
        "d": "智能无感看护,紧急求救按钮2,智能老人卡,紧急求救按钮1",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王桂英",
        "b": "37030519330610532X",
        "c": "北曹村",
        "d": "安装防撞护角/防撞条、提示标识,智能无感看护,安装防撞护角/防撞条、提示标识,智能老人卡,紧急求救按钮1,紧急求救按钮2,安装防撞护角/防撞条、提示标识,起夜灯",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "齐玉华",
        "b": "370305194708295327",
        "c": "临淄区凤凰镇南王村98号",
        "d": "紧急求救按钮1,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安全扶手,紧急求救按钮2,起夜灯,智能无感看护,安装防撞护角/防撞条、提示标识,智能老人卡,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王国三",
        "b": "370305195708015318",
        "c": "临淄区凤凰镇南王村133号",
        "d": "智能无感看护,紧急求救按钮2,紧急求救按钮1,起夜灯,智能老人卡",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王瑞青",
        "b": "370305195801075331",
        "c": "临淄区凤凰镇南王村124号",
        "d": "安装防撞护角/防撞条、提示标识,智能无感看护,紧急求救按钮1,紧急求救按钮2,起夜灯,安装防撞护角/防撞条、提示标识,智能老人卡,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "杨永清",
        "b": "370305194311035324",
        "c": "临淄区凤凰镇南王村178号",
        "d": "安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮1,智能无感看护,起夜灯,紧急求救按钮2,安装防撞护角/防撞条、提示标识,智能老人卡",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王月花",
        "b": "370305195409275347",
        "c": "临淄区凤凰镇南王村60号",
        "d": "安全扶手,智能无感看护,智能老人卡,起夜灯,紧急求救按钮1,安装防撞护角/防撞条、提示标识,紧急求救按钮2,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王春域",
        "b": "37030519470329531X",
        "c": "临淄区凤凰镇南王村78号",
        "d": "智能无感看护,安装防撞护角/防撞条、提示标识,起夜灯,安装防撞护角/防撞条、提示标识,紧急求救按钮1,紧急求救按钮2,智能老人卡",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "段良坤",
        "b": "37030519410507471X",
        "c": "临淄区凤凰镇侯家屯村1组138号",
        "d": "智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮2,安装防撞护角/防撞条、提示标识,起夜灯,紧急求救按钮1,智能老人卡,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王荣祥",
        "b": "370305194102225316",
        "c": "临淄区凤凰镇柴南村",
        "d": "紧急求救按钮2,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,起夜灯,智能老人卡,智能无感看护,紧急求救按钮1,紧急求救按钮2",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王爱英",
        "b": "37030519551111534X",
        "c": "临淄区凤凰镇柴南村",
        "d": "紧急求救按钮2,安装防撞护角/防撞条、提示标识,智能无感看护,起夜灯,紧急求救按钮1,安装防撞护角/防撞条、提示标识,智能老人卡,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "于立德",
        "b": "370305194706085318",
        "c": "临淄区凤凰镇吴家村3组44号",
        "d": "智能无感看护,起夜灯,安全扶手,紧急求救按钮1,智能老人卡,紧急求救按钮2",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "曹桂英",
        "b": "370305194103275323",
        "c": "凤凰镇南曹村3组17号",
        "d": "智能无感看护,安装防撞护角/防撞条、提示标识,安全扶手,智能老人卡,安装防撞护角/防撞条、提示标识,起夜灯,紧急求救按钮1,紧急求救按钮2,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "段广义",
        "b": "370305193812184710",
        "c": "凤凰镇侯家屯村1组43号",
        "d": "安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮2,智能老人卡,紧急求救按钮1,起夜灯,智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": "2023.1"
    },
    {
        "a": "边秀兰",
        "b": "370305193807175326",
        "c": "山东省淄博市临淄区凤凰镇南王村139号",
        "d": "智能老人卡,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮2,安装防撞护角/防撞条、提示标识,安全扶手,紧急求救按钮1,起夜灯,安装防撞护角/防撞条、提示标识,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "杨凤英",
        "b": "370305193205165323",
        "c": "北曹村",
        "d": "智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,智能老人卡,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮2,起夜灯,安装防撞护角/防撞条、提示标识,紧急求救按钮1",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "路恒义",
        "b": "370305193302285319",
        "c": "北曹村",
        "d": "紧急求救按钮2,智能老人卡,安装防撞护角/防撞条、提示标识,起夜灯,紧急求救按钮1,智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "徐秀英",
        "b": "370305192712045321",
        "c": "北曹村",
        "d": "安全扶手,紧急求救按钮2,智能老人卡,智能老人卡,智能无感看护,紧急求救按钮1,紧急求救按钮1,安装防撞护角/防撞条、提示标识,紧急求救按钮2,起夜灯,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "贾桂香",
        "b": "370305194706085326",
        "c": "北曹村",
        "d": "智能老人卡,安全扶手,智能无感看护,安装防撞护角/防撞条、提示标识,起夜灯,紧急求救按钮1,紧急求救按钮2",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "边光友",
        "b": "370305195405054731",
        "c": "临淄区凤凰镇寇家村159号",
        "d": "安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,起夜灯,紧急求救按钮1,安装防撞护角/防撞条、提示标识,智能老人卡,起夜灯,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮2",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "郭庆范",
        "b": "370305195110134726",
        "c": "临淄区凤凰镇寇家村243号",
        "d": "紧急求救按钮2,安装防撞护角/防撞条、提示标识,智能老人卡,起夜灯,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,智能无感看护,紧急求救按钮1,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "张绍芬",
        "b": "370305194204255321",
        "c": "凤凰镇北曹村3组26号",
        "d": "智能老人卡,紧急求救按钮1,安装防撞护角/防撞条、提示标识,智能无感看护,紧急求救按钮2,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王义英",
        "b": "370305192709095328",
        "c": "北曹村",
        "d": "智能无感看护,紧急求救按钮2,安装防撞护角/防撞条、提示标识,起夜灯,紧急求救按钮1,智能老人卡",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王学汤",
        "b": "370305192802055312",
        "c": "临淄区北曹村",
        "d": "安全扶手,智能无感看护,紧急求救按钮1,起夜灯,紧急求救按钮2,智能老人卡,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王希荣",
        "b": "370305194001205324",
        "c": "北曹村",
        "d": "安装防撞护角/防撞条、提示标识,紧急求救按钮2,紧急求救按钮1,智能老人卡,起夜灯,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "孙孟英",
        "b": "370305194204205324",
        "c": "北曹村",
        "d": "智能老人卡,紧急求救按钮2,紧急求救按钮1,智能老人卡,起夜灯,智能无感看护,紧急求救按钮2,安装防撞护角/防撞条、提示标识,紧急求救按钮1,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王建民",
        "b": "370305194405255336",
        "c": "北曹村",
        "d": "安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,起夜灯",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "李秀美",
        "b": "370305194510105348",
        "c": "北曹村",
        "d": "安装防撞护角/防撞条、提示标识,起夜灯,安装防撞护角/防撞条、提示标识,智能老人卡,紧急求救按钮1,智能无感看护,紧急求救按钮2",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "崔秀英",
        "b": "370305194111245327",
        "c": "西老村1-23",
        "d": "安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,智能无感看护,起夜灯,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮2,智能老人卡,紧急求救按钮1",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "孙宝叶",
        "b": "370305196108174731",
        "c": "西申村551号",
        "d": "智能老人卡,紧急求救按钮1,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮2,安装防撞护角/防撞条、提示标识,安全扶手,安装防撞护角/防撞条、提示标识,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "杨焕章",
        "b": "37030519360525531X",
        "c": "西老村16-6",
        "d": "紧急求救按钮1,紧急求救按钮2,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,智能老人卡,起夜灯,安装防撞护角/防撞条、提示标识,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": "2023.6"
    },
    {
        "a": "王玉荣",
        "b": "370305193905115319",
        "c": "柴北村1组50号",
        "d": "起夜灯,紧急求救按钮2,紧急求救按钮1,智能无感看护,安装防撞护角/防撞条、提示标识,智能老人卡,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "周友云",
        "b": "370305195311224728",
        "c": "寇家村232号",
        "d": "安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安全扶手,紧急求救按钮1,智能老人卡,起夜灯,紧急求救按钮2,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王纯贞",
        "b": "370305194805235326",
        "c": "南王村119号",
        "d": "安装防撞护角/防撞条、提示标识,紧急求救按钮2,起夜灯,安装防撞护角/防撞条、提示标识,紧急求救按钮1,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,智能老人卡,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "郭振云",
        "b": "370305195212124721",
        "c": "梁家村83号",
        "d": "起夜灯,安全扶手,安装防撞护角/防撞条、提示标识,紧急求救按钮2,安装防撞护角/防撞条、提示标识,智能老人卡,紧急求救按钮1,智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "边存荣",
        "b": "370305195611115021",
        "c": "东齐村1组16号",
        "d": "起夜灯,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮2,紧急求救按钮1,安装防撞护角/防撞条、提示标识,安全扶手,智能无感看护,智能老人卡",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "滕文霞",
        "b": "370305194511104718",
        "c": "周家屯村委会",
        "d": "安装防撞护角/防撞条、提示标识,智能老人卡,起夜灯,紧急求救按钮1,安装防撞护角/防撞条、提示标识,紧急求救按钮2,安装防撞护角/防撞条、提示标识,智能无感看护,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "马学平",
        "b": "370305195307244718",
        "c": "梁家村238号",
        "d": "智能无感看护,安装防撞护角/防撞条、提示标识,紧急求救按钮1,安装防撞护角/防撞条、提示标识,紧急求救按钮2,智能老人卡,安装防撞护角/防撞条、提示标识,安全扶手,起夜灯",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王连滨",
        "b": "370305195607295314",
        "c": "柴北村2组71号",
        "d": "起夜灯,紧急求救按钮2,智能老人卡,安装防撞护角/防撞条、提示标识,紧急求救按钮1,安装防撞护角/防撞条、提示标识,智能无感看护,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "李德祥",
        "b": "370305195106135312",
        "c": "土桥村1-1",
        "d": "安装防撞护角/防撞条、提示标识,紧急求救按钮1,安装防撞护角/防撞条、提示标识,紧急求救按钮2,智能无感看护,安装防撞护角/防撞条、提示标识,起夜灯,智能老人卡",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "路美德",
        "b": "370305194508094715",
        "c": "西郝家村",
        "d": "智能无感看护,安装防撞护角/防撞条、提示标识,紧急求救按钮1,安装防撞护角/防撞条、提示标识,起夜灯,紧急求救按钮2,智能老人卡,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王秀春",
        "b": "370305195201174723",
        "c": "梁家村163号",
        "d": "安全扶手,智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,起夜灯,安装防撞护角/防撞条、提示标识,紧急求救按钮1,智能老人卡,紧急求救按钮2,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王焕炬",
        "b": "370305195310135336",
        "c": "北曹村2-49",
        "d": "智能无感看护,紧急求救按钮2,智能老人卡,紧急求救按钮1",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "李家夫",
        "b": "370305195008095337",
        "c": "北安合村",
        "d": "安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮1,紧急求救按钮2,智能老人卡,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王焕德",
        "b": "370305194802295331",
        "c": "北曹村2-78",
        "d": "安装防撞护角/防撞条、提示标识,安全扶手,安装防撞护角/防撞条、提示标识,起夜灯",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王建军",
        "b": "370305194201075317",
        "c": "北曹村3-109",
        "d": "智能无感看护,紧急求救按钮2,紧急求救按钮2,智能老人卡",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王泽谊",
        "b": "370305195311104718",
        "c": "西申村207号",
        "d": "智能老人卡,安装防撞护角/防撞条、提示标识,紧急求救按钮2,起夜灯,安装防撞护角/防撞条、提示标识,智能无感看护,安全扶手,紧急求救按钮1,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "呼金生",
        "b": "370305194909175313",
        "c": "九仙村37号",
        "d": "起夜灯,安装防撞护角/防撞条、提示标识,智能无感看护,安装防撞护角/防撞条、提示标识,紧急求救按钮2,安装防撞护角/防撞条、提示标识,紧急求救按钮1,智能老人卡,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "刘国学",
        "b": "370305195708075310",
        "c": "林家村369号",
        "d": "紧急求救按钮2,智能无感看护,紧急求救按钮1,智能老人卡,起夜灯,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": "2022.8"
    },
    {
        "a": "王爱国",
        "b": "370305196008264713",
        "c": "王桥村",
        "d": "紧急求救按钮2,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,智能无感看护,紧急求救按钮1,安全扶手,起夜灯",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "赵文永",
        "b": "370305194903264710",
        "c": "侯家屯村5组126号",
        "d": "起夜灯,安装防撞护角/防撞条、提示标识,安全扶手,紧急求救按钮1,紧急求救按钮2,智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "路其平",
        "b": "370305195012254716",
        "c": "北田旺村",
        "d": "起夜灯,紧急求救按钮2,智能老人卡,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,智能无感看护,紧急求救按钮1,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安全扶手",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "路常远",
        "b": "370305195407254710",
        "c": "西陈家村",
        "d": "紧急求救按钮2,智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,起夜灯,紧急求救按钮1",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "王庆兰",
        "b": "370305193511264725",
        "c": "东申村4组39号",
        "d": "紧急求救按钮2,智能老人卡,安装防撞护角/防撞条、提示标识,紧急求救按钮1,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,智能无感看护",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "杨方时",
        "b": "370305194607065311",
        "c": "北安合村",
        "d": "紧急求救按钮1,智能无感看护,起夜灯,安全扶手,智能老人卡,紧急求救按钮2,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "齐玉柱",
        "b": "370305194403215015",
        "c": "东齐村2组56号",
        "d": "智能老人卡,紧急求救按钮2,智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,紧急求救按钮1,起夜灯,安装防撞护角/防撞条、提示标识,智能无感看护,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": "2023.1"
    },
    {
        "a": "于连堂",
        "b": "370305195611285338",
        "c": "东梧村",
        "d": "安装防撞护角/防撞条、提示标识,智能无感看护,起夜灯,安装防撞护角/防撞条、提示标识,安全扶手,安装防撞护角/防撞条、提示标识,智能老人卡,紧急求救按钮1,紧急求救按钮2,安装防撞护角/防撞条、提示标识",
        "e": "设施与表格相符，户内卫生一般",
        "f": ""
    },
    {
        "a": "范家祥",
        "b": "370305193908155316",
        "c": "西河村",
        "d": "紧急呼叫设备*2,烟感探测器*1,智能网关*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "郭素芳",
        "b": "370305194009115323",
        "c": "西河村",
        "d": "电源插座及开关改造*1,智能网关*1,视频监控设备*1,烟感探测器*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "韩玉考",
        "b": "370305192912053313",
        "c": "彩家村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "胡世芹",
        "b": "370305194005295320",
        "c": "西河村",
        "d": "电源插座及开关改造*1,紧急呼叫设备*1,智能网关*1,视频监控设备*1,烟感探测器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "李保祥",
        "b": "370305193906265319",
        "c": "南台合村",
        "d": "电源插座及开关改造*1,烟感探测器*1,视频监控设备*1,智能网关*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "李民太",
        "b": "370305193802095319",
        "c": "东河村",
        "d": "电源插座及开关改造*1,智能网关*1,视频监控设备*1,紧急呼叫设备*1,烟感探测器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "李盛太",
        "b": "370305194301225319",
        "c": "东河村",
        "d": "烟感探测器*1,智能网关*1,视频监控设备*1,紧急呼叫设备*2",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "李秀英",
        "b": "370305193709095322",
        "c": "西河村",
        "d": "电源插座及开关改造*1,视频监控设备*1,烟感探测器*1,紧急呼叫设备*1,智能网关*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "刘端成",
        "b": "370305193908095317",
        "c": "西河村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "路学英",
        "b": "370305194404015322",
        "c": "西梧村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "罗福荣",
        "b": "370305194307224712",
        "c": "南罗村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "罗麦田",
        "b": "370305192912074717",
        "c": "南罗村",
        "d": "烟感探测器*1,智能网关*1,视频监控设备*1,紧急呼叫设备*2",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "罗荣华",
        "b": "370305194006114712",
        "c": "南罗村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "罗希元",
        "b": "370305194108274717",
        "c": "南罗村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,视频监控设备*1,烟感探测器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "孙景华",
        "b": "370305194305175312",
        "c": "东河村",
        "d": "烟感探测器*1,智能网关*1,视频监控设备*1,紧急呼叫设备*2",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "孙玉英",
        "b": "370305193608288532",
        "c": "东河村",
        "d": "电源插座及开关改造*1,智能网关*1,视频监控设备*1,紧急呼叫设备*1,烟感探测器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王立英",
        "b": "370305192701085328",
        "c": "西河村",
        "d": "电源插座及开关改造*1,烟感探测器*1,智能网关*1,视频监控设备*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王世荣",
        "b": "370305194011255325",
        "c": "西河村",
        "d": "电源插座及开关改造*1,视频监控设备*1,紧急呼叫设备*1,烟感探测器*1,智能网关*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王新清",
        "b": "37030519400902531X",
        "c": "水牛村",
        "d": "电源插座及开关改造*1,紧急呼叫设备*1,智能网关*1,视频监控设备*1,烟感探测器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王友芬",
        "b": "370305193809205322",
        "c": "西河村",
        "d": "电源插座及开关改造*1,智能网关*1,视频监控设备*1,烟感探测器*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "徐桂英",
        "b": "370305193405115320",
        "c": "凤凰镇",
        "d": "电源插座及开关改造*1,紧急呼叫设备*1,智能网关*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "徐玉珍",
        "b": "370305194610155326",
        "c": "吴家桥",
        "d": "电源插座及开关改造*1,烟感探测器*1,视频监控设备*1,智能网关*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "杨桂香",
        "b": "370305193112244727",
        "c": "南罗村",
        "d": "电源插座及开关改造*1,紧急呼叫设备*1,智能网关*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "史冠中",
        "b": "370305193703135311",
        "c": "刘地村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王洋洲",
        "b": "370305194110085317",
        "c": "柴南",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "李淑云",
        "b": "370305194004035316",
        "c": "东河",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "张美芹",
        "b": "37030519431008532X",
        "c": "东河",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王江洲",
        "b": "370305194005285317",
        "c": "柴南",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "张建功",
        "b": "370305193612265313",
        "c": "东齐",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王彩云",
        "b": "370305194012035324",
        "c": "东齐",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王玉莲",
        "b": "370305193612215324",
        "c": "北田旺",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "冯玉兰",
        "b": "37030519280115532X",
        "c": "西申",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王和春",
        "b": "370305194302164714",
        "c": "王桥村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王英才",
        "b": "370305194001254716",
        "c": "王桥村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "曹清元",
        "b": "370305195309285337",
        "c": "南曹村",
        "d": "安装自动感应灯具*1,防滑处理*4,一字扶手*2,蹲便器改坐便器*1,三脚拐凳*1,配置淋浴椅*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "陈良玉",
        "b": "370305195009104717",
        "c": "大路南村",
        "d": "安装自动感应灯具*1,电源插座及开关改造*2,防滑处理*4,一字扶手*2,助行器*1,配置防压疮坐垫*1,配置淋浴椅*1,蹲便器改坐便器*1,烟感探测器*1,紧急呼叫设备*1,视频监控设备*1,智能网关*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "董庆年",
        "b": "37030519430524471X",
        "c": "小张村",
        "d": "配置护理床*1,蹲便器改坐便器*1,智能网关*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "李会全",
        "b": "370305194211065315",
        "c": "温家村",
        "d": "安装自动感应灯具*2,防滑处理*4,一字扶手*2,助听器*1,三脚拐凳*1,防撞条角*4,配置淋浴椅*1,助行器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "李茂太",
        "b": "370305194010045318",
        "c": "东河村",
        "d": "电源插座及开关改造*2,一字扶手*1,安装自动感应灯具*1,防滑处理*4,配置防压疮床垫*1,配置淋浴椅*1,三脚拐凳*1,视频监控设备*1,烟感探测器*1,紧急呼叫设备*1,智能网关*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "刘成功",
        "b": "370305194701115354",
        "c": "温家村",
        "d": "安装自动感应灯具*2,配置护理床*1,三脚拐凳*1,紧急呼叫设备*1,烟感探测器*1,智能网关*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "刘申贵",
        "b": "370305194201125310",
        "c": "南霸村",
        "d": "安装自动感应灯具*1,防滑处理*4,一字扶手*2,蹲便器改坐便器*1,三脚拐凳*1,配置淋浴椅*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "齐利民",
        "b": "370305195012095313",
        "c": "南齐村",
        "d": "安装自动感应灯具*1,电源插座及开关改造*1,防滑处理*4,一字扶手*2,配置淋浴椅*1,四脚拐杖*1,蹲便器改坐便器*1,紧急呼叫设备*2,烟感探测器*1,防走失装置*1,智能网关*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "史纯章",
        "b": "37030519490119531X",
        "c": "史家村",
        "d": "一字扶手*2,安装自动感应灯具*1,防滑处理*4,三脚拐凳*1,防撞条角*4,蹲便器改坐便器*1,配置淋浴椅*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "史业诚",
        "b": "370305195210115311",
        "c": "史家村",
        "d": "安装自动感应灯具*1,一字扶手*2,防滑处理*4,蹲便器改坐便器*1,防撞条角*4,三脚拐凳*1,配置淋浴椅*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "孙桂英",
        "b": "370305192409244723",
        "c": "大路北村",
        "d": "三脚拐凳*1,配置护理床*1,防撞条角*4,紧急呼叫设备*1,烟感探测器*1,智能网关*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王继文",
        "b": "370305195510125335",
        "c": "土桥村",
        "d": "一字扶手*2,安装自动感应灯具*1,防滑处理*4,蹲便器改坐便器*1,配置防压疮坐垫*1,配置淋浴椅*1,烟感探测器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王敬",
        "b": "370305196101304740",
        "c": "北罗村",
        "d": "安装自动感应灯具*1,防滑处理*2,一字扶手*2,三脚拐凳*1,蹲便器改坐便器*1,配置淋浴椅*1,智能网关*1,防走失装置*1,烟感探测器*1,活动监测设备*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王克茂",
        "b": "370305195303054714",
        "c": "寇家村",
        "d": "安装自动感应灯具*2,一字扶手*2,防滑处理*4,配置淋浴椅*1,配置防压疮坐垫*1,助行器*1,蹲便器改坐便器*1,烟感探测器*1,智能网关*1,视频监控设备*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王淑夫",
        "b": "370305194603034710",
        "c": "西申村",
        "d": "电源插座及开关改造*2,防撞条角*4,轮椅*1,自助进食器具*1,台盆扶手*1,紧急呼叫设备*2,烟感探测器*1,智能网关*1,活动监测设备*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王秀花",
        "b": "370305195510195325",
        "c": "柴南村",
        "d": "安装自动感应灯具*3,一字扶手*2,安装闪光震动门铃*1,防滑处理*4,四脚拐杖*1,蹲便器改坐便器*1,配置防压疮靠垫*1,防撞条角*4,配置淋浴椅*1,紧急呼叫设备*2,防走失装置*1,智能网关*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王旬良",
        "b": "370305194603015317",
        "c": "王青村",
        "d": "一字扶手*3,电源插座及开关改造*2,安装自动感应灯具*1,防滑处理*4,配置淋浴椅*1,三脚拐凳*1,助听器*1,视频监控设备*1,紧急呼叫设备*2,智能网关*1,烟感探测器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王玉增",
        "b": "370305196208155319",
        "c": "西老村",
        "d": "安装自动感应灯具*3,防滑处理*3,一字扶手*2,轮椅*1,配置淋浴椅*1,防撞条角*4,安装床边护栏（抓杆）*1,紧急呼叫设备*1,烟感探测器*1,智能网关*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王元农",
        "b": "370305195102034717",
        "c": "北罗村",
        "d": "安装自动感应灯具*3,防滑处理*4,电源插座及开关改造*1,一字扶手*2,蹲便器改坐便器*1,配置淋浴椅*1,智能网关*1,紧急呼叫设备*2,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "张正德",
        "b": "370305194503244710",
        "c": "小张村",
        "d": "安装自动感应灯具*3,防滑处理*4,一字扶手*1,配置淋浴椅*1,蹲便器改坐便器*1,配置防压疮坐垫*1,三脚拐凳*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "赵立俭",
        "b": "370305195110044712",
        "c": "侯屯村",
        "d": "安装自动感应灯具*3,防滑处理*4,一字扶手*2,四脚拐杖*1,防撞条角*4,配置淋浴椅*1,助听器*1,蹲便器改坐便器*1,安装床边护栏（抓杆）*1,智能网关*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "张树槐",
        "b": "370305194511085350",
        "c": "南太合村",
        "d": "安装自动感应灯具*3,防滑处理*4,电源插座及开关改造*1,一字扶手*2,蹲便器改坐便器*1,配置淋浴椅*1,智能网关*1,紧急呼叫设备*2,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王炳芳",
        "b": "370305194408204710",
        "c": "王桥村",
        "d": "安装自动感应灯具*3,防滑处理*4,电源插座及开关改造*1,一字扶手*2,蹲便器改坐便器*1,配置淋浴椅*1,智能网关*1,紧急呼叫设备*2,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "韩淑兰",
        "b": "370305194709104728",
        "c": "南罗村",
        "d": "安装自动感应灯具*3,防滑处理*4,电源插座及开关改造*1,一字扶手*2,蹲便器改坐便器*1,配置淋浴椅*1,智能网关*1,紧急呼叫设备*2,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "孙绪香",
        "b": "370305194112054725",
        "c": "东申村",
        "d": "电源插座及开关改造*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1,视频监控设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "孙玉忠",
        "b": "370305194002244712",
        "c": "大张村",
        "d": "电源插座及开关改造*1,智能网关*1,视频监控设备*1,紧急呼叫设备*1,烟感探测器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王绪耀",
        "b": "370305194003144713 ",
        "c": "王桥村",
        "d": "安装自动感应灯具*3,防滑处理*3,一字扶手*2,四脚拐杖*1,配置淋浴椅*1,助听器*1,安装床边护栏（抓杆）*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "王玉朴",
        "b": "370305195612085311",
        "c": "柴南村",
        "d": "一字扶手*2,安装自动感应灯具*3,防滑处理*4,三脚拐凳*1,蹲便器改坐便器*1,安装床边护栏（抓杆）*1,防撞条角*4,配置淋浴椅*1,烟感探测器*1,智能网关*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "罗领臣",
        "b": "37030519531018471x",
        "c": "南罗村",
        "d": "一字扶手*2,防滑处理*4,电源插座及开关改造*2,配置防压疮床垫*1,配置淋浴椅*1,助行器*1,视频监控设备*1,烟感探测器*1,智能网关*1,紧急呼叫设备*2",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "罗公平",
        "b": "370305195201264737",
        "c": "南罗村",
        "d": "电源插座及开关改造*1,配置护理床*1,三脚拐凳*1,智能网关*1,紧急呼叫设备*1,烟感探测器*1",
        "e": "设施与表格相符，正常使用",
        "f": ""
    },
    {
        "a": "罗洪友",
        "b": "370305195105034739",
        "c": "南罗村",
        "d": "蹲便器改坐便器*1,配置护理床*1,智能网关*1,紧急呼叫设备*1",
        "e": "设施与表格相符，正常使用",
        "f": "2024.7"
    }
]
      this.table.refreshFlag = new Date().getTime().toString();
    },
    // 重置
    reset() {
      this.$refs.query.resetFields();
    },
    // 单元格点击
    cellClick(row, column, cell, event) {
      if (column.property === "account") {
        this.handleAccount();
      }
    },
    cellStyle(row, column) {
      if (column.property === "account") {
        return {
          color: "#409EFF",
          cursor: "pointer",
        };
      }
    },
    // 编辑
    handleEdit() {
      this.$refs.fwzcInfo.$refs.drawerIshow.drawer = true;
    },
    // 模块适配
    handleModule() {},
    // 打开账号
    handleAccount() {
      this.$refs.accountInfo.$refs.drawerIshow.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.hl-full-container {
  font-size: 1rem;
  color: #606266;
  // 上方标题
  .topTitle {
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    margin-bottom: 0.9375rem;
    .el-icon-film {
      font-size: 14px;
      margin-right: 3px;
    }

    .title {
      font-size: 16px;
      margin-right: 0.3125rem;
    }

    .subTitle {
      font-size: 12px;
      color: #aaa;
    }
  }
}
</style>
