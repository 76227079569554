<template>
    <div style="height:calc(100% - 1.74rem);">
        <div class="chart" ref="chart" :style="{ width: '100%', height: height }"></div>
    </div>
</template>

<script>

export default {
    name: 'pieEcharts',

    data() {
        return {
            chart: null
        };
    },
    props: {
        height: {
            type: String,
            default: '100%'
        },
    },
    mounted() {
        this.echarts()
    },

    methods: {
        nowSize(val, initWidth = 1920) {
            //当前视口宽度
            let nowClientWidth = document.documentElement.clientWidth;
            return val * (nowClientWidth / initWidth);
        },
        echarts() {
            this.chart = this.$echarts.init(this.$refs.chart); // 初始化echarts
            var option = {
                xAxis: {
                    type: 'value',
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        color: '#fff', // 设置 x 轴文字颜色
                        show: false
                    },
                    axisLine: { show: false }, // 隐藏x轴线
                    axisTick: { show: false }, // 隐藏刻度线

                },
                yAxis: {
                    show: false,
                    type: 'category',
                    data: ['辛家庄长者食堂', '金门街道逍遥村长者食堂', '小村庄长者食堂', '张村长者食堂', '麦岛长者食堂'],
                    splitLine: {
                        show: false
                    },
                    position: 'right',
                    axisTick: { show: false }, // 隐藏刻度线
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: '#ffffff', // x轴字体颜色
                            fontSize: this.nowSize(10),
                        },
                    },

                    axisLine: {
                        show: false, // 隐藏x轴的下标线
                    },
                },
                series: [{
                    type: 'bar',
                    layout: 'horizontal',
                    data: [10, 20, 30, 40, 50],
                    barWidth: this.nowSize(10),// 设置条形宽度为20%
                    barGap: '50%',
                    itemStyle: {
                        normal: {
                            borderRadius: [0, 5, 5, 0], // 设置四个角的圆角大小
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "#009fe1" },
                                { offset: 1, color: "#00ffff" },
                            ]),
                        }
                    },
                    label: {
                        show: true,
                        position: 'top',
                        formatter: '{b}   {c}', // 显示数据和标签名称
                        fontSize: this.nowSize(10),
                        color: '#fff',
                        align: 'left',
                    },
                }],
                grid: {
                    left: "10%",
                    right: "10%",
                    bottom: "0%",
                    top: "10%",
                    containLabel: false
                },
            };
            this.chart.setOption(option); // 设置对应配置项
            // 当窗口大小改变 echarts重置大小
            window.addEventListener("resize", () => {
                this.chart.resize();
            });
        }
    },
};
</script>

<style lang="scss" scoped></style>