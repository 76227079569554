<template>
  <div class="task-histServ">
    <hltableZ
      :isNumber="true"
      :isChecked="false"
      :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag"
      :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows"
      :apiurl="TableOptions.apiurl"
      @getSelectedRows="getSelectedRows"
    >
    </hltableZ>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
export default {
  name: "",
  components: { hltableZ },
  props: {
    idCard:{
      type: String, 
      default: ''  
    }
  },
  watch:{
    idCard(newVal,oldVal){
      this.form.idCard = newVal
      this.dataRefresh();
    }
  },
  data() {
    return {
      form: {
        idCard:this.idCard
      },
      TableOptions: {
        selectedRows: [],
        apiurl: "api/getOldDataList",
        refreshFlag: "",
        columnEdit: true,
        column: [
          {
            label: "姓名",
            prop: "name",
            checked: true,
          },
          {
            label: "年龄",
            prop: "age",
            checked: true,
          },
          {
            label: "身份证号",
            prop: "idCard",
            checked: true,
          },
          {
            label: "年龄段",
            prop: "ageBracket",
            checked: true,
          },
          {
            label: "补贴时间",
            prop: "createTime",
            checked: true,
            formatter: this.timeFormatter,
          },
        ],
      },
    };
  },
  mounted() {
    // this.dataRefresh();
    console.log(this.form)
  },
  methods: {
    dataRefresh() {
      this.TableOptions.refreshFlag = new Date().getTime().toString();
    },
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows;
    },
    timeFormatter(row, column, cellValue) {
      return cellValue ? this.$timestampTostr(cellValue) : "";
    },
  },
};
</script>

<style scoped lang="scss">
.task-histServ {
  height: 70vh;
}
</style>