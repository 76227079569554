<template>
  <div class="hl-full-container flexVertical">
    <!-- 上方标题 -->
    <div class="topTitle">
      <i class="el-icon-film"></i>
      <span class="title">养老机构管理</span>
    </div>
    <!-- 表格 -->
    <hltableZ
      :columnEdit="table.columnEdit"
      :tableColumn="table.column"
      :apiurl="table.apiurl"
      :isPage="false"
      :onlyShowTotal="true"
      :staticData="table.staticData"
      :refreshFlag="table.refreshFlag"
      :selectedRows="table.selectedRows"
      :isChecked="table.isChecked"
      :isNumber="table.isNumber"
      :isClickSelect="table.isClickSelect"
      :formSearch="query"
      :handleCellStyle="cellStyle"
      @cellClick="cellClick"
    >
    </hltableZ>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
export default {
  name: "Yljggl",
  components: { hltableZ },
  data() {
    return {
      // 营业状态
      businessStatusOptions: [
        { label: "全部", value: null },
        { label: "正常运营", value: "1" },
        { label: "注销", value: "2" },
        { label: "取缔", value: "3" },
        { label: "停业整顿", value: "4" },
        { label: "事实处于未开展服务状态", value: "5" },
        { label: "关停", value: "6" },
      ],
      // 表格属性值
      table: {
        staticData: [],
        columnEdit: false,
        column: [
          {
            label: "所属区划",
            prop: "a",
            checked: true,
            width:"210"
          },
          {
            label: "机构名称",
            prop: "b",
            checked: true,
            width:"210"
          },
          {
            label: "运营状态",
            prop: "c",
            checked: true,
          },
          {
            label: "机构类别",
            prop: "d",
            checked: true,
            width:"120"
          },
          {
            label: "法人性质",
            prop: "e",
            checked: true,
            width:"120"
          },
          {
            label: "统一社会信用代码",
            prop: "f",
            checked: true,
          },
          {
            label: "法定代表人",
            prop: "g",
            checked: true,
          },
          {
            label: "法定代表人手机号",
            prop: "h",
            checked: true,
            width:"110"
          },
          {
            label: "机构地址",
            prop: "i",
            checked: true,
            width:"220"
          },
          {
            label: "机构建设情况",
            prop: "j",
            checked: true,
            width:"100"
          },
          {
            label: "运营方式",
            prop: "k",
            checked: true,
          },
          {
            label: "兴办主体",
            prop: "l",
            checked: true,
            width:"110"
          },
          {
            label: "医疗服务兴办方式",
            prop: "m",
            checked: true,
            width:"170"
          },
          {
            label: "医疗服务方式",
            prop: "n",
            checked: true,
            width:"150"
          },
          {
            label: "评估星级",
            prop: "o",
            checked: true,
          },
          {
            label: "评估日期",
            prop: "p",
            checked: true,
          },
          {
            label: "成立日期",
            prop: "q",
            checked: true,
          },
          {
            label: "床位总数",
            prop: "r",
            checked: true,
          },
          {
            label: "护理型床位数",
            prop: "s",
            checked: true,
          },
          {
            label: "入住老年人数",
            prop: "t",
            checked: true,
          },
          {
            label: "从业人员数",
            prop: "u",
            checked: true,
          },
          {
            label: "许可备案证号",
            prop: "v",
            checked: true,
          },
          {
            label: "许可备案时间",
            prop: "w",
            checked: true,
          },
          {
            label: "开始运营时间",
            prop: "x",
            checked: true,
          },
          {
            label: "房屋建筑面积（平方米）",
            prop: "y",
            checked: true,
          },
          {
            label: "房屋占地面积（平方米）",
            prop: "z",
            checked: true,
          },
          {
            label: "能力完好最少",
            prop: "qq",
            checked: true,
          },
          {
            label: "能力完好最多",
            prop: "ww",
            checked: true,
          },
          {
            label: "轻度失能最少",
            prop: "ee",
            checked: true,
          },
          {
            label: "轻度失能最多",
            prop: "rr",
            checked: true,
          },
          {
            label: "中度失能最少",
            prop: "tt",
            checked: true,
          },
          {
            label: "中度失能最多",
            prop: "yy",
            checked: true,
          },
          {
            label: "重度失能最少",
            prop: "uu",
            checked: true,
          },
          {
            label: "重度失能最多",
            prop: "ii",
            checked: true,
          },
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
        isClickSelect: false,
      },
      tableDataSource: [
        {
          1: 1,
          2: 2,
          3: 15655556666,
          4: 4,
          account: 5,
          6: 6,
        },
      ],
      query: {
        institutionName: "",
        businessStatus: "",
      },
    };
  },

  mounted() {
    this.dataRefresh();
  },

  methods: {
    // 查询
    dataRefresh() {
      this.table.staticData = [
    {
        "xh": "1",
        "a": "山东省淄博市临淄区辛店街道",
        "b": "淄博市临淄区康平园养护院",
        "c": "正常运营",
        "d": "民办养老机构",
        "e": "民办非企业法人",
        "f": "52370305MJF116894N",
        "g": "王景水",
        "h": "13953301785",
        "i": "淄博市临淄区辛店街道辛三路13号院内",
        "j": "新建",
        "k": "民办民营",
        "l": "民营企业",
        "m": "医疗机构兴办养老机构",
        "n": "",
        "o": "2星",
        "p": "2023-10-09",
        "q": "2021-12-21",
        "r": "83",
        "s": "83",
        "t": "31",
        "u": "9",
        "v": "YLJG3703052022003",
        "w": "2022-03-07",
        "x": "2021-12-21",
        "y": "2502",
        "z": "5321",
        "qq": "2400",
        "ww": "2700",
        "ee": "2700",
        "rr": "3000",
        "tt": "3300",
        "yy": "3600",
        "uu": "3500",
        "ii": "8400"
    },
    {
        "xh": "2",
        "a": "山东省淄博市临淄区辛店街道",
        "b": "淄博市康寿护理养生院",
        "c": "正常运营",
        "d": "民办养老机构",
        "e": "民办非企业法人",
        "f": "52370300MJE1335967",
        "g": "顾国明",
        "h": "18553376111",
        "i": "淄博市临淄区胜炼中路10号",
        "j": "新建",
        "k": "民办民营",
        "l": "民营企业",
        "m": "医疗机构兴办养老机构",
        "n": "内设护理站",
        "o": "4星",
        "p": "2023-10-09",
        "q": "2011-04-29",
        "r": "180",
        "s": "110",
        "t": "133",
        "u": "71",
        "v": "MZ2019003",
        "w": "2017-06-07",
        "x": "2011-04-29",
        "y": "6182.9",
        "z": "2498.82",
        "qq": "2100",
        "ww": "2400",
        "ee": "2400",
        "rr": "3900",
        "tt": "2700",
        "yy": "4200",
        "uu": "2700",
        "ii": "5850"
    },
    {
        "xh": "3",
        "a": "山东省淄博市临淄区辛店街道",
        "b": "淄博市临淄区中医医院",
        "c": "正常运营",
        "d": "民办养老机构",
        "e": "事业单位法人",
        "f": "12370305493197728H",
        "g": "路世勇",
        "h": "15550315985",
        "i": "淄博市临淄区辛南二路74号",
        "j": "改（扩）建",
        "k": "公办公营",
        "l": "公立医院",
        "m": "医疗机构兴办养老机构",
        "n": "内设护理站",
        "o": "3星",
        "p": "2023-10-09",
        "q": "2009-01-15",
        "r": "120",
        "s": "110",
        "t": "74",
        "u": "7",
        "v": "YLJG3703052022012",
        "w": "2017-12-25",
        "x": "2010-12-05",
        "y": "3473.62",
        "z": "1837.5",
        "qq": "1600",
        "ww": "1700",
        "ee": "1800",
        "rr": "2100",
        "tt": "2500",
        "yy": "2600",
        "uu": "3000",
        "ii": "3400"
    },
    {
        "xh": "4",
        "a": "山东省淄博市临淄区齐都镇",
        "b": "淄博市临淄区春雨慈光养老院",
        "c": "正常运营",
        "d": "民办养老机构",
        "e": "民办非企业法人",
        "f": "52370305MJF1166837",
        "g": "许秀萍",
        "h": "15964474465",
        "i": "山东省淄博市临淄区齐都镇南关村村委西邻",
        "j": "改（扩）建",
        "k": "民办民营",
        "l": "民营企业",
        "m": "养老机构内设医疗机构",
        "n": "",
        "o": "2星",
        "p": "2022-08-03",
        "q": "2017-05-17",
        "r": "48",
        "s": "30",
        "t": "27",
        "u": "9",
        "v": "YLGJ3703052019002",
        "w": "2019-09-25",
        "x": "2019-05-08",
        "y": "1441.8",
        "z": "4537",
        "qq": "0",
        "ww": "1000",
        "ee": "0",
        "rr": "1800",
        "tt": "0",
        "yy": "2000",
        "uu": "0",
        "ii": "2800"
    },
    {
        "xh": "5",
        "a": "山东省淄博市临淄区齐都镇",
        "b": "淄博市临淄区夕阳红桓公台养老中心",
        "c": "正常运营",
        "d": "民办养老机构",
        "e": "民办非企业法人",
        "f": "52370305MJF116245J",
        "g": "边国宾",
        "h": "18560288888",
        "i": "淄博市临淄区农圣路7号",
        "j": "改建",
        "k": "民办民营",
        "l": "民营企业",
        "m": "养老机构内设医疗机构",
        "n": "内设医务室、门诊",
        "o": "2星",
        "p": "2022-08-03",
        "q": "2017-09-01",
        "r": "100",
        "s": "70",
        "t": "34",
        "u": "19",
        "v": "14370450005",
        "w": "2017-10-25",
        "x": "2017-09-01",
        "y": "3770.7",
        "z": "6000",
        "qq": "1200",
        "ww": "1800",
        "ee": "1700",
        "rr": "2500",
        "tt": "1700",
        "yy": "2500",
        "uu": "2000",
        "ii": "3600"
    },
    {
        "xh": "6",
        "a": "山东省淄博市临淄区皇城镇",
        "b": "淄博九拾阳光康养养老服务有限公司",
        "c": "正常运营",
        "d": "敬老院",
        "e": "企业法人",
        "f": "91370305MAC2BPCM5K",
        "g": "左文娜",
        "h": "18764396405",
        "i": "山东省淄博市临淄区皇城镇北羊路48号北侧东50米路北",
        "j": "改建",
        "k": "委托运营",
        "l": "民营企业",
        "m": "与外部开展医疗协作",
        "n": "",
        "o": "2星",
        "p": "2022-11-23",
        "q": "2022-11-16",
        "r": "64",
        "s": "64",
        "t": "42",
        "u": "12",
        "v": "YLJG3703052022010",
        "w": "2022-11-16",
        "x": "2022-11-16",
        "y": "1541.64",
        "z": "7003.5",
        "qq": "1000",
        "ww": "1290",
        "ee": "1290",
        "rr": "1880",
        "tt": "1735",
        "yy": "2140",
        "uu": "2140",
        "ii": "3980"
    },
    {
        "xh": "7",
        "a": "山东省淄博市临淄区皇城镇",
        "b": "淄博市临淄温馨之家养老服务有限公司",
        "c": "正常运营",
        "d": "民办养老机构",
        "e": "企业法人",
        "f": "91370305MA3W8QXR7R",
        "g": "陈良英",
        "h": "13864391666",
        "i": "临淄区皇城镇皇城村",
        "j": "新建",
        "k": "民办民营",
        "l": "民营企业",
        "m": "养老机构内设医疗机构",
        "n": "内设医务室、门诊",
        "o": "1星",
        "p": "2023-09-24",
        "q": "2021-02-25",
        "r": "48",
        "s": "48",
        "t": "22",
        "u": "10",
        "v": "YLJG3703052022001",
        "w": "2022-03-03",
        "x": "2021-12-15",
        "y": "860",
        "z": "860",
        "qq": "1200",
        "ww": "1500",
        "ee": "1600",
        "rr": "1800",
        "tt": "2000",
        "yy": "2400",
        "uu": "2600",
        "ii": "3000"
    },
    {
        "xh": "8",
        "a": "山东省淄博市临淄区敬仲镇",
        "b": "淄博健寿苑养老服务有限公司敬仲健馨苑养老分公司",
        "c": "正常运营",
        "d": "敬老院",
        "e": "企业法人",
        "f": "91370305MA954UT49R",
        "g": "高爱兰",
        "h": "13723994486",
        "i": "临淄区敬仲镇敬仲路23号",
        "j": "改建",
        "k": "委托运营",
        "l": "乡镇、街道",
        "m": "医疗机构兴办养老机构",
        "n": "内设医务室、门诊",
        "o": "2星",
        "p": "2022-08-03",
        "q": "2020-12-18",
        "r": "100",
        "s": "65",
        "t": "32",
        "u": "14",
        "v": "YLJG3703052022004",
        "w": "2022-03-26",
        "x": "2020-12-20",
        "y": "2729.08",
        "z": "4800",
        "qq": "1050",
        "ww": "2500",
        "ee": "1050",
        "rr": "2600",
        "tt": "1735",
        "yy": "2800",
        "uu": "2140",
        "ii": "4000"
    },
    {
        "xh": "9",
        "a": "山东省淄博市临淄区朱台镇",
        "b": "淄博市临淄区齐康养老院",
        "c": "正常运营",
        "d": "民办养老机构",
        "e": "民办非企业法人",
        "f": "52370305MJF1166244",
        "g": "钟金堂",
        "h": "13583363617",
        "i": "淄博市临淄区朱台镇罗家村",
        "j": "新建",
        "k": "民办民营",
        "l": "个人",
        "m": "与外部开展医疗协作",
        "n": "",
        "o": "2星",
        "p": "2022-08-03",
        "q": "2019-01-02",
        "r": "31",
        "s": "20",
        "t": "14",
        "u": "6",
        "v": "YLJG3703052019004",
        "w": "2019-02-02",
        "x": "2019-02-02",
        "y": "916",
        "z": "1533",
        "qq": "1380",
        "ww": "1500",
        "ee": "1800",
        "rr": "2000",
        "tt": "1800",
        "yy": "2000",
        "uu": "2200",
        "ii": "2500"
    },
    {
        "xh": "10",
        "a": "山东省淄博市临淄区朱台镇",
        "b": "淄博市临淄区朱台镇上河东村养老院",
        "c": "正常运营",
        "d": "敬老院",
        "e": "民办非企业法人",
        "f": "52370305MJF1911493",
        "g": "孙艳丽",
        "h": "13573301269",
        "i": "上河东",
        "j": "新建",
        "k": "民办民营",
        "l": "乡镇、街道",
        "m": "养老机构内设医疗机构",
        "n": "内设护理站",
        "o": "2星",
        "p": "2021-11-23",
        "q": "2021-01-02",
        "r": "34",
        "s": "34",
        "t": "19",
        "u": "8",
        "v": "YLJG3703052022009",
        "w": "2022-10-10",
        "x": "2022-11-01",
        "y": "681",
        "z": "740",
        "qq": "1500",
        "ww": "2000",
        "ee": "2000",
        "rr": "2500",
        "tt": "2500",
        "yy": "3000",
        "uu": "3500",
        "ii": "4000"
    },
    {
        "xh": "11",
        "a": "山东省淄博市临淄区凤凰镇",
        "b": "淄博健寿苑养老服务有限公司",
        "c": "正常运营",
        "d": "敬老院、民办养老机构",
        "e": "企业法人",
        "f": "91370305MA3RA6FN1A",
        "g": "王光发",
        "h": "13806437668",
        "i": "临淄区温家路18号",
        "j": "改（扩）建",
        "k": "委托运营",
        "l": "乡镇、街道",
        "m": "医疗机构兴办养老机构",
        "n": "内设医务室、门诊",
        "o": "3星",
        "p": "2023.10.9",
        "q": "2020-02-01",
        "r": "100",
        "s": "60",
        "t": "59",
        "u": "16",
        "v": "YLJG3703052020002",
        "w": "2020-07-21",
        "x": "2020-07-21",
        "y": "2411",
        "z": "10405",
        "qq": "1250",
        "ww": "1250",
        "ee": "1250",
        "rr": "1450",
        "tt": "1450",
        "yy": "1750",
        "uu": "2450",
        "ii": "2750"
    },
    {
        "xh": "12",
        "a": "山东省淄博市临淄区金岭回族镇",
        "b": "临淄区金岭清源老年健康服务中心",
        "c": "正常运营",
        "d": "民办养老机构",
        "e": "民办非企业法人",
        "f": "52370305MJE179295M",
        "g": "王景水",
        "h": "13953301785",
        "i": "淄博市临淄区金岭回族镇金岭三村清田苑小区内",
        "j": "新建",
        "k": "民办民营",
        "l": "民营企业",
        "m": "医疗机构兴办养老机构",
        "n": "",
        "o": "2星",
        "p": "2022-08-03",
        "q": "2013-05-01",
        "r": "106",
        "s": "68",
        "t": "91",
        "u": "29",
        "v": "YLJG3703052022006",
        "w": "2014-11-26",
        "x": "2014-12-01",
        "y": "3175",
        "z": "6540",
        "qq": "1350",
        "ww": "1800",
        "ee": "1800",
        "rr": "2100",
        "tt": "2100",
        "yy": "2400",
        "uu": "2400",
        "ii": "2900"
    },
    {
        "xh": "13",
        "a": "山东省淄博市临淄区金岭回族镇",
        "b": "淄博中康爱邻里养老服务有限公司临淄分公司",
        "c": "正常运营",
        "d": "敬老院",
        "e": "企业法人",
        "f": "91370305MA949FQJ87",
        "g": "张彦琼",
        "h": "13573284649",
        "i": "淄博市临淄区金岭回族镇102线西首",
        "j": "改建",
        "k": "委托运营",
        "l": "乡镇、街道",
        "m": "养老机构内设医疗机构",
        "n": "内设医务室、门诊",
        "o": "2星",
        "p": "2022-08-03",
        "q": "1998-10-01",
        "r": "80",
        "s": "48",
        "t": "29",
        "u": "8",
        "v": "YLJG3703052019001",
        "w": "2019-09-05",
        "x": "2017-04-06",
        "y": "2500",
        "z": "5200",
        "qq": "1200",
        "ww": "1600",
        "ee": "1600",
        "rr": "2300",
        "tt": "2300",
        "yy": "2700",
        "uu": "2700",
        "ii": "3600"
    },
    {
        "xh": "14",
        "a": "山东省淄博市临淄区金山镇",
        "b": "山东颐兴养老服务有限公司",
        "c": "正常运营",
        "d": "民办养老机构",
        "e": "企业法人",
        "f": "91370303MA3EMLGD0L",
        "g": "孙业立",
        "h": "13573318424",
        "i": "临淄区大庆一路9号",
        "j": "改建",
        "k": "民办民营",
        "l": "民营企业",
        "m": "与外部开展医疗协作",
        "n": "",
        "o": "未参加评估",
        "p": "",
        "q": "2023-06-22",
        "r": "30",
        "s": "30",
        "t": "9",
        "u": "9",
        "v": "YLJG3703052023002",
        "w": "2023-06-28",
        "x": "2023-06-22",
        "y": "1740",
        "z": "2162.11",
        "qq": "2200",
        "ww": "2600",
        "ee": "2600",
        "rr": "3400",
        "tt": "3400",
        "yy": "4100",
        "uu": "4100",
        "ii": "5000"
    },
    {
        "xh": "15",
        "a": "山东省淄博市临淄区金山镇",
        "b": "淄博市临淄区养老中心第二康复养老院",
        "c": "正常运营",
        "d": "敬老院",
        "e": "民办非企业法人",
        "f": "52370305MJF1162100",
        "g": "王光发",
        "h": "13806437668",
        "i": "山东省淄博市临淄区建设路5号",
        "j": "改（扩）建",
        "k": "委托运营",
        "l": "公立医院",
        "m": "医疗机构兴办养老机构",
        "n": "",
        "o": "4星",
        "p": "2023-10-09",
        "q": "2006-10-01",
        "r": "660",
        "s": "400",
        "t": "284",
        "u": "150",
        "v": "14370450004",
        "w": "2017-06-03",
        "x": "2006-10-01",
        "y": "22500",
        "z": "28000",
        "qq": "1050",
        "ww": "2800",
        "ee": "1100",
        "rr": "3000",
        "tt": "1200",
        "yy": "3500",
        "uu": "1300",
        "ii": "9000"
    },
    {
        "xh": "16",
        "a": "山东省淄博市临淄区金山镇",
        "b": "淄博悦华养老服务有限公司",
        "c": "正常运营",
        "d": "民办养老机构",
        "e": "企业法人",
        "f": "91370305MA7C26WD8R",
        "g": "黄桂华",
        "h": "13701884688",
        "i": "山东省淄博市临淄区建设路12甲",
        "j": "改建",
        "k": "委托运营",
        "l": "乡镇、街道",
        "m": "与外部开展医疗协作",
        "n": "",
        "o": "3星",
        "p": "2023-10-09",
        "q": "2021-11-16",
        "r": "91",
        "s": "91",
        "t": "33",
        "u": "14",
        "v": "YLJG3703052022002",
        "w": "2022-03-02",
        "x": "2022-03-02",
        "y": "2645.56",
        "z": "686.5",
        "qq": "2000",
        "ww": "3000",
        "ee": "2200",
        "rr": "3200",
        "tt": "2600",
        "yy": "3800",
        "uu": "3800",
        "ii": "5600"
    },
    {
        "xh": "17",
        "a": "山东省淄博市临淄区金山镇",
        "b": "淄博健寿苑养老服务有限公司金山健南苑养老分公司",
        "c": "正常运营",
        "d": "敬老院",
        "e": "企业法人",
        "f": "91370305MAC1RHUG52",
        "g": "高明嘉",
        "h": "13573361687",
        "i": "临淄区金山镇东张村548号",
        "j": "改建",
        "k": "民办民营",
        "l": "民营企业",
        "m": "医疗机构兴办养老机构",
        "n": "内设护理站",
        "o": "2星",
        "p": "2022-11-20",
        "q": "2021-10-01",
        "r": "100",
        "s": "60",
        "t": "60",
        "u": "11",
        "v": "YLGJ3703052022011",
        "w": "2022-11-15",
        "x": "2021-10-01",
        "y": "1518.29",
        "z": "2000",
        "qq": "1200",
        "ww": "2800",
        "ee": "1200",
        "rr": "3500",
        "tt": "1700",
        "yy": "4500",
        "uu": "1800",
        "ii": "7600"
    }
];
      this.table.refreshFlag = new Date().getTime().toString();
    },
    // 重置
    reset() {
      this.$refs.query.resetFields();
    },
    // 单元格点击
    cellClick(row, column, cell, event) {
      if (column.property === "account") {
        this.handleAccount();
      }
    },
    cellStyle(row, column) {
      if (column.property === "account") {
        return {
          color: "#409EFF",
          cursor: "pointer",
        };
      }
    },
    // 编辑
    handleEdit() {
      this.$refs.fwzcInfo.$refs.drawerIshow.drawer = true;
    },
    // 模块适配
    handleModule() {},
    // 打开账号
    handleAccount() {
      this.$refs.accountInfo.$refs.drawerIshow.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.hl-full-container {
  font-size: 1rem;
  color: #606266;
  // 上方标题
  .topTitle {
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    margin-bottom: 0.9375rem;
    .el-icon-film {
      font-size: 14px;
      margin-right: 3px;
    }

    .title {
      font-size: 16px;
      margin-right: 0.3125rem;
    }

    .subTitle {
      font-size: 12px;
      color: #aaa;
    }
  }
}
</style>
