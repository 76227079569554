<template>
    <div class="hl-full-container flexVertical">
        <!-- 表单s -->
        <el-drawer :title="dailogTitle" :visible.sync="dialogFormVisible" direction="rtl" size="100%" :modal="false"
            :destroy-on-close="true" class="el-drawer-hl" @close="handleClose">
            <el-form ref="elForm" :model="formData" :disabled="submitType == 'view' ? true : false" :rules="rules"
                size="medium" class="padding15" label-width="100px">
                <el-col :span="12">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="用户名" prop="userName">
                                <el-input v-model="formData.userName" placeholder="请输入用户名" clearable
                                    :style="{ width: '100%' }">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="24">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="姓名" prop="fullName">
                                <el-input v-model="formData.fullName" placeholder="请输入姓名" clearable
                                    :style="{ width: '100%' }">
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="角色" prop="roleId">
                                <el-select v-model="formData.roleId" placeholder="请选择角色" clearable
                                    :style="{ width: '100%' }">
                                    <el-option v-for="(item, index) in roleIdOptions" :key="index" :label="item.roleName"
                                        :value="item.id" :disabled="item.disabled"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="24">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="密码" prop="password">
                                <el-input v-model="formData.password" placeholder="请输入密码" clearable show-password
                                    :style="{ width: '100%' }"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="确认密码" prop="confirmpassword">
                                <el-input v-model="formData.confirmpassword" placeholder="请输入确认密码" clearable show-password
                                    :style="{ width: '100%' }"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="24">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="备注" prop="remark">
                                <el-input v-model="formData.remark" type="textarea" placeholder="请输入备注"
                                    :autosize="{ minRows: 4, maxRows: 4 }" :style="{ width: '100%' }"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="24">
                    <el-row>
                        <el-form-item size="large" style="text-align: center;">
                            <el-button v-show="submitType != 'view' ? true : false" class="qconfirm" type="primary"
                                size="medium" @click="submitForm()">提交</el-button>
                            <el-button v-show="submitType != 'view' ? true : false" class="qcancle" size="medium"
                                @click="resetForm()">
                                重置
                            </el-button>
                        </el-form-item>
                    </el-row>
                </el-col>
            </el-form>
        </el-drawer>
        <!-- 表单e -->
        <div class="search">
            <el-form :model="form" ref="form" label-width="80px" inline>
                <el-form-item label="用户名" prop="userName">
                    <el-input v-model="form.userName" placeholder="请输入用户名" :maxlength="100" clearable
                        :style="{ width: '100%' }"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button size="small" icon="el-icon-search" @click="dataRefresh">查询</el-button>
                    <el-button size="small" icon="el-icon-refresh-left" @click="reset">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="toolbar">
            <div class="crud">
                <el-button @click="Add" size="small">
                    <i class="el-icon-plus"></i> 新增
                </el-button>
                <el-button @click="Del" size="small">
                    <i class="el-icon-delete"></i> 删除
                </el-button>
            </div>
            <el-button-group class="right-btn">
                <el-button icon="el-icon-refresh" @click="dataRefresh"></el-button>
                <el-dropdown trigger="click" :hide-on-click="false">
                    <el-button icon="el-icon-s-grid">
                        <i class="el-icon-caret-bottom"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item, index) in TableOptions.column" :key="index">
                            <el-checkbox :label="item.label" v-model="item.checked">
                            </el-checkbox>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-button-group>
        </div>
        <hltableZ :isNumber="false" :columnEdit="TableOptions.columnEdit" :formSearch="form"
            :refreshFlag="TableOptions.refreshFlag" :tableColumn="TableOptions.column"
            :selectedRows="TableOptions.selectedRows" :apiurl="TableOptions.apiurl" @getSelectedRows="getSelectedRows">
            <div slot-scope='selected' slot="EditColumn">
                <el-button type="primary" icon="el-icon-edit" size="mini" circle
                    @click="Edit(selected.singlerow)"></el-button>
                <el-button type="warning" size="mini" icon="el-icon-search" circle
                    @click="View(selected.singlerow)"></el-button>
            </div>
        </hltableZ>
    </div>
</template>
  
<script>
import cryptoAES from '@/utils/cryptoAES'
import { hltableZ } from '@/components'
export default {
    name: 'userManage',
    components: { hltableZ },
    props: {
        organIds: {
            type: String
        }
    },
    data() {
        var _this = this
        var Pass = (rule, value, callback) => {
            if (!value && _this.submitType == "add") {
                callback(new Error('请输入密码'));
            } else {
                if (this.formData.confirmpassword !== '') {
                    this.$refs.elForm.validateField('confirmpassword');
                }
                callback();
            }
        }
        var PassConfirm = (rule, value, callback) => {
            if (!value && _this.submitType == "add") {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.formData.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        }
        return {
            dailogTitle: "",
            dialogFormVisible: false,
            submitType: '',
            //表单信息-s
            formData: {
                userName: '',
                fullName: '',
                roleId: '',
                password: '',
                confirmpassword: '',
                remark: '',
                organId: this.organIds
            },
            rules: {
                userName: [{
                    required: true,
                    message: '请输入用户名',
                    trigger: 'blur'
                }],
                fullName: [{
                    required: true,
                    message: '请输入姓名',
                    trigger: 'blur'
                }],
                roleId: [{
                    required: true,
                    message: '请选择角色',
                    trigger: 'change'
                }],
                password: [
                    { validator: Pass, trigger: 'blur', required: true, }
                ],
                confirmpassword: [
                    { validator: PassConfirm, trigger: 'blur', required: true, }
                ],
                remark: [],
            },
            roleIdOptions: [],
            //表单信息-e
            form: {
                userName: "",
                organId: this.organIds
            },
            TableOptions: {
                selectedRows: [],
                apiurl: "sysUser/getDataList",
                refreshFlag: '',
                columnEdit: true,
                column: [{
                    label: "用户名",
                    prop: "userName",
                    checked: true,
                }, {
                    label: "用户姓名",
                    prop: "fullName",
                    checked: true,
                }, {
                    label: "用户角色",
                    prop: "roleName",
                    checked: true,
                }, {
                    label: "备注",
                    prop: "remark",
                    checked: true,
                }]
            },
        }
    },
    watch: {
        organIds: {
            handler(news) {
                this.form.organId = news
                this.formData.organId = news
                this.dataRefresh()
            },
            deep: true,
            immediate: true
        }
    },
    created() {
    },
    mounted() {
        this.getRoleId()
        this.dataRefresh()

    },
    destroyed() {
    },
    methods: {
        dynamicKey() {
            this.$store.dispatch('user/dynamicKey')
        },
        getRoleId() {
            this.$store.dispatch("sysRole/getAllList")
                .then((response) => {
                    if (response.success) {
                        this.roleIdOptions = response.result
                    }
                    else {
                        this.$message.error(response.message);
                    }
                }).catch((error) => {
                    console.log(error)
                })
        },
        dataRefresh() {
            this.TableOptions.refreshFlag = (new Date()).getTime().toString()
        },
        handleClose() {
            this.resetForm()
        },
        resetForm() {
            const th = this
            Object.keys(this.formData).forEach(function (key) {
                th.formData[key] = ""
            })
        },
        submitForm() {
            this.$refs['elForm'].validate(valid => {
                if (valid) {
                    this.loading = true
                    const copyForm = Object.assign({}, this.formData);
                    const aesPassword = cryptoAES.encrypt((!copyForm.password ? "" : copyForm.password), this.$store.getters.user.key)
                    copyForm.password = aesPassword
                    if (this.submitType == "add") {
                        copyForm.id = ''
                    }
                    this.$store.dispatch('sysUser/saveData', copyForm)
                        .then((response) => {
                            if (response.success) {
                                this.$message.success("操作成功");
                                this.dataRefresh()
                            }
                            else {
                                this.$message.error(response.message);
                            }
                            this.dialogFormVisible = false
                        })
                        .catch((error) => {
                            this.dialogFormVisible = false
                            console.log(error)
                        })
                }
                else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        loadform(row) {
            const rowinfo = Object.assign({}, row)
            this.formData = rowinfo;
        },
        setformType(type, title) {
            this.dialogFormVisible = true;
            this.dailogTitle = title
            this.submitType = type
        },
        Add() {
            this.dynamicKey()
            this.setformType("add", "新增")
        },
        Edit(row) {
            this.dynamicKey()
            this.setformType("edit", "编辑")
            this.loadform(row)
        },
        Del: function () {
            if (this.TableOptions.selectedRows.length == 0) {
                this.$message({
                    type: 'error',
                    message: '请勾选下方表格中的数据'
                })
                return false;
            }
            var ids = []
            this.TableOptions.selectedRows.forEach(key => {
                ids.push(key.id)
            })
            this.$confirm('确定要进行删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('sysUser/deleteData', { ids: ids })
                    .then((response) => {
                        if (response.success) {
                            this.$message.success("操作成功");
                            this.dataRefresh()
                        }
                        else {
                            this.$message.error(response.message);
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
        },
        View(row) {
            this.setformType("view", "查看")
            this.loadform(row)
        },
        getSelectedRows(rows) {
            this.TableOptions.selectedRows = rows
        },
        reset() {
            // this.$refs['form'].resetFields()
            // 比上方获取demo的清理方式更彻底
            const th = this
            Object.keys(this.form).forEach(function (key) {
                th.form[key] = ""
            })
        },
    }
}
</script>
<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";
</style>
  