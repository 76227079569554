<template>
  <!-- // 政府购买服务专用项目 -->
  <div class="gov">
    <div class="box-header">
      <div class="box-headertotle">
        政府购买服务专用项目为“政府购买服务”专用服务项目，一般由政策文件定义；市场化服务项目为网站发布、展示的面向公众的服务项目。
      </div>
      <el-button type="primary" size="small" icon="el-icon-plus" @click="add()">新增</el-button>
    </div>
    <div class="box-line"></div>
    <div class="jtcw">
      <hltableZ :columnEdit="table1.columnEdit" :tableColumn="table1.column" :apiurl="table1.apiurl"
        :refreshFlag="table1.refreshFlag" :selectedRows="table1.selectedRows" :isChecked="table1.isChecked"
        :isNumber="table1.isNumber" :formSearch="query1">
        <div slot-scope="selected" slot="EditColumn">
          <el-button icon="el-icon-delete" type="text" size="small" @click="editing(selected.singlerow)">编辑</el-button>
          <el-button icon="el-icon-delete" type="text" size="small" @click="editHandle(selected.singlerow)">删除</el-button>
        </div>
      </hltableZ>
    </div>
    <!-- 新增弹窗 -->
    <Drawer :title="title" ref="drawerIshow" :drawerSize="drawerSize" :button-is-show="true" @submitForm="submitForm"
      :submit-form-name="submitFormName">
      <div class="schadd">
        <p class="schaddp">基本信息</p>
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="服务类型名称:">
            <el-input v-model="form.projectName"></el-input>
          </el-form-item>
          <!-- <el-form-item label="末级节点:">
            <el-select v-model="form.region" placeholder="">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item label="市场化类型:">
            <el-select v-model="form.region" placeholder="请选择">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="官方定义类型:">
            <el-select v-model="form.pid" placeholder="请选择" @change="selectc">
              <el-option v-for="(item, index) in serviceOrganizationList" :label="item.projectName"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="服务状态:">
            <el-select v-model="form.status" placeholder="请选择">
              <el-option label="上架" value='1'></el-option>
              <el-option label="下架" value='2'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="价格:">
            <el-input type="textarea" v-model="form.money"></el-input>
          </el-form-item>
          <!-- <el-form-item label="排序码:">
            <el-input type="textarea" v-model="form.sort"></el-input>
          </el-form-item> -->
          <el-form-item label="服务项目介绍:">
            <el-input type="textarea" v-model="form.introduce"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </Drawer>
    <!-- 新增选择 -->
    <!-- <Drawer :title="titlesl" ref="drawerIsl" :drawerSize="drawerSize">
      <el-form ref="formsl" :model="formsl" label-width="80px">
        <el-col :span="8">
          <el-form-item label="活动名称">
            <el-input v-model="formsl.name" placeholder="输入名称自动搜索"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <div class="treesl">
        <el-tree :data="data" show-checkbox node-key="id" :props="defaultProps">
        </el-tree>
      </div>
    </Drawer> -->
  </div>
</template>

<script>
import { hltableZ } from "@/components";
import { Drawer } from "@/components";
export default {
  components: { hltableZ, Drawer },
  data() {

    return {
      submitFormName: '保存',
      drawerSize: "60%",
      title: "政府购买服务项目新增",
      titlesl: "父级项目服务选择",
      table1: {
        columnEdit: true,
        column: [
          {
            label: "服务项目名称",
            prop: "projectName",
            checked: true,
          },
          {
            label: "官方定义统计大类",
            prop: "type",
            checked: true,
          },
          {
            label: "启用状态",
            prop: "status",
            checked: true,
            formatter(value) {
              const status = value.status;
              if (status == '1') {
                return '上架'
              } else if (status == '2') {
                return '下架'
              }
            },
          },
          {
            label: "价格",
            prop: "money",
            checked: true,
          },
          {
            label: "介绍",
            prop: "introduce",
            checked: true,
          },
        ],
        apiurl: "jbsz/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      query1: {
        isEnd: 1
      },
      //新增
      form: {
        projectName: "",
        type: "",
        sort: "",
        pid: '',
        isEnd: 1,
        money: '',
        status: "",
        introduce: '',
        id: ''
      },
      //选择
      formsl: {
        name: "",
      },
      defaultProps: {
        children: "children",
        label: "label",
      },
      serviceOrganizationList: [],
      message: ''
    };
  },
  mounted() {
    this.livingProjectDictgetList()
  },
  methods: {
    submitForm() {
      this.$store.dispatch("jbsz/livingProjectDictsaveData", this.form).then((res) => {
        this.$message({
          message: this.message,
          type: 'success'
        });
        this.dataRefresh()
        this.$refs.drawerIshow.drawer = false
      })
    },
    selectc(e) {
      this.livingProjectDictgetList()
      const result = this.serviceOrganizationList.find(item => item.id === e);
      console.log(result.projectName)
      this.form.type = result.projectName
    },
    livingProjectDictgetList() {
      this.$store.dispatch("jbsz/livingProjectDictgetList", { isEnd: 2, }).then((res) => {
        this.serviceOrganizationList = res.result
      })
    },
    editHandle(singlerow) {
      this.$confirm("确定将选择数据删除?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store
            .dispatch("jbsz/livingProjectDictdeleteData", { ids: [singlerow.id] })
            .then((response) => {
              if (response.success) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
                this.dataRefresh();
              }
            })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    dataRefresh() {
      this.table1.refreshFlag = (new Date()).getTime().toString()
    },
    add() {
      this.message = '新增成功'
      const th = this
      Object.keys(th.form).forEach(function (key) {
        if (key !== 'isEnd') {
          th.form[key] = ''
        }

      })
      this.$refs.drawerIshow.drawer = true;
    },
    editing(e) {
      this.form.id = e.id
      Object.keys(this.form).forEach(key => {
        this.form[key] = e[key] || this.form[key]
      })
      this.message = '修改成功'
      this.$refs.drawerIshow.drawer = true;
    },
    selection() {
      this.$refs.drawerIsl.drawer = true;
    },
    clear() { },
    save() { },
    back() { },
  },
};
</script>
<style lang="scss" scoped>
.gov {
  width: 100%;
  height: 100%;

  .box-header {
    width: 100%;
    height: 47px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 47px;

    .el-button--small {
      height: 30px !important;
    }
  }

  .box-line {
    width: 100%;
    height: 1px;
    background: #ccd2d9;
    margin-bottom: 20px;
  }

  .jtcw {
    width: 100%;
    height: 40rem;
    margin-top: 1rem;
  }
}

.schbut {
  width: 30%;
  display: flex;
  margin-left: 40%;
  justify-content: center;
}

.el-col-3 {
  width: 8.5%;
}

.el-button--primary {
  height: 36px;
}

.content {
  display: flex;
  flex-direction: column;

  .treesl {
    width: 100%;
    height: 80%;
  }
}
</style>
