<template>
  <div>
    <!-- 分配服务商 -->
    <Drawer :title="title" ref="drawer" :button-is-show="true" :submit-form-name="submitFormName"
      @submitForm="submitForm">
      <el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="200px" class="demo-dynamic">
        <div class="title" style="
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            font-weight: 700;
            border-bottom: 1px solid #ddd;
          ">
          政府购买服务订单
        </div>

        <el-form-item prop="orderSelected" label="已选择订单:" v-for='(item, index) in  tableList ' :key="index"
          style="width:31rem">
          <div display="flex">
            <el-button type="text" @click="buttonclick(index)">{{ item.oldName
            }}</el-button><span v-if="indexc == index">*</span>
            <el-input disabled v-model="item.code"></el-input>
          </div>

        </el-form-item>

        <div class="title" style="
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            font-weight: 700;
            border-bottom: 1px solid #ddd;
          ">
          服务管理
        </div>
        <el-form-item label="选择政府服务批次：" prop="name">
          <el-select v-model="dynamicValidateForm.batchId" placeholder="请选择选择政府服务批次" @change="batchIdClick"
            style="width:18rem">
            <el-option v-for="( item, index ) in  livingBatchgetLists " :label="item.describe"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <div class="title" style="
          height: 30px;
          line-height: 30px;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 10px;
          font-weight: 700;
          border-bottom: 1px solid #ddd;
        ">
          服务包设置
        </div>
        <el-select v-model="dynamicValidateForm.packageId" placeholder="请选择服包" @change='elclect'>
          <el-option v-for="( item, index ) in  optionss " ::key="index" :value="item.id"
            :label="item.packageName"></el-option>
        </el-select>
        <div style="height:19rem;margin-bottom: 1rem;">
          <hltableZ :column-edit="table.columnEdit" :table-column="table.column" :apiurl="table.apiurl"
            :refresh-flag="table.refreshFlag" :selected-rows="table.selectedRows" :is-checked="table.isChecked"
            :is-number="table.isNumber" :formSearch="dynamicValidateForm" :isPage="false" :isShow="false">
          </hltableZ>
        </div>

        <div class="title" style="
          height: 30px;
          line-height: 30px;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 10px;
          font-weight: 700;
          border-bottom: 1px solid #ddd;
        ">
          服务商服务人员设置
        </div>
        <el-form-item prop="orderSelected" label="分配服务商:" style="width:31rem">
          <el-select v-model="dynamicValidateForm.organId" placeholder="请选择分配服务商" @change="change1" style="width:20rem">
            <el-option v-for=" item  in  serviceStaffList " :key="item.id" :label="item.organName"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="orderSelected" label="分配服务人员:" style="width:31rem">
          <el-select v-model="dynamicValidateForm.fullId" placeholder="请选择分配服务人员" style="width:20rem">
            <el-option v-for=" item  in  serviceStaffList2 " :key="item.id" :label="item.fullName"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </Drawer>
  </div>
</template>

<script>
import { Drawer, hltableZ } from "@/components";
export default {
  components: { Drawer, hltableZ },
  data() {
    return {
      optionss: [],
      submitFormName: "分配",
      dynamicValidateForm: {
        organId: '',
        fullId: "",
        packageId: "",
        batchId: ''
      },
      serviceStaffList: [

      ],
      serviceStaffList2: [],
      title: "分配服务商",
      serviceID: "",
      serviceList: [
        {
          id: 1,
          name: "临淄区特困老人服务685（30小时）-分散-半自理-03-12 -  - 通用(当前选择)",
        },
        {
          id: 2,
          name: "临淄区特困老人服务685（30小时）-分散-半自理-03-12 -  - 通用(当前选择)",
        },
      ],
      tableList: [],
      serviceID: "",
      serviceList: [
        {
          id: 1,
          name: "临淄区特困老人服务685（30小时）-分散-半自理-03-12 -  - 通用(当前选择)",
        },
        {
          id: 2,
          name: "临淄区特困老人服务685（30小时）-分散-半自理-03-12 -  - 通用(当前选择)",
        },
      ],
      //   表格
      table: {
        columnEdit: false,
        column: [
          {
            label: "项目",
            prop: "projectName",
            checked: true,
          }
        ],
        apiurl: "jbsz/livingProjectDictgetList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
        datahand: {}
      },
      livingBatchgetLists: [],
      indexc: 0
    };
  },
  created() { },
  mounted() {
    this.livingBatchgetList()
  },
  methods: {
    buttonclick(e) {
      this.indexc = e
      this.tablell(e)
    },
    tablell(e) {
      const th = this
      this.dynamicValidateForm.batchId = this.tableList[e].batchId
      this.batchIdClick(this.tableList[e].batchId)
      this.dynamicValidateForm.packageId = this.tableList[e].packageId
      this.elclect(this.tableList[e].packageId)
      this.dynamicValidateForm.organId = this.tableList[e].organId
      this.change1()
    },
    elclect(e) {
      this.dataRefresh()
      this.organizationGetList(e)
    },
    dataRefresh() {
      this.table.refreshFlag = new Date().getTime().toString();
    },
    // 服务包
    livingPackagegetList(e) {
      this.$store.dispatch("jbsz/livingPackagegetList", { batchId: e }).then((res) => {
        console.log(77777, res)
        this.optionss = res.result
      });
    },
    batchIdClick(e) {
      console.log(9999, e)
      this.livingPackagegetList(e)
    },
    livingBatchgetList() {
      this.$store.dispatch("jbsz/livingBatchgetList").then((res) => {
        this.livingBatchgetLists = res.result
      })
    },
    submitForm() {
      this.$emit('submitForm', this.dynamicValidateForm)
    },
    handleSave() { },
    //服务商
    organizationGetList(e) {
      this.$store.dispatch("jbsz/organizationGetList", { batchId: e }).then((res) => {
        this.serviceStaffList = res.result
      })
    },
    sysUserGetListByOrganId() {
      this.$store.dispatch("jbsz/sysUserGetListByOrganId", { organId: this.dynamicValidateForm.organId }).then((res) => {
        this.serviceStaffList2 = res.result
      })
    },
    open() {
      this.$refs.drawer.drawer = true;
    },
    change1(e) {
      this.sysUserGetListByOrganId()
    }
  },
};
</script>

<style lang="scss" scoped></style>
