<template>
  <div class="hl-full-container flexVertical">
    <!-- 表单s -->
    <el-drawer
      title="历史记录"
      :visible.sync="dialogFormVisible"
      direction="rtl"
      size="100%"
      :modal="false"
      :destroy-on-close="true"
      class="el-drawer-hl"
    >
      <rechRecordHistory :childId="childId"/>
    </el-drawer>
    <!-- 表单e -->
    <div class="search">
      <el-form :model="form" ref="form" label-width="80px" inline>
        <el-form-item label="姓名">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item>

        <el-form-item label="助餐站点">
          <el-select filterable v-model="form.siteId" placeholder="请选择助餐站点">
            <el-option v-for="item in mealSiteData" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="时间选择">
          <el-date-picker v-model="valueTime" type="daterange" :default-time="['00:00:00', '23:59:59']"
            range-separator="至" value-format="timestamp" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search" @click="dataRefresh">查询</el-button>
          <el-button size="small" icon="el-icon-refresh-left" @click="reset('Form')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="toolbar">
      <!-- <el-button type="primary" plain size="mini" icon="el-icon-download" @click="derive">导出</el-button>
      <el-button type="danger" plain size="mini" icon="el-icon-delete" @click="Del">删除</el-button> -->
      <div style="margin-left: 2rem">
        充值总计：<span style="color: #409eff; font-weight: bold">{{
          money
        }}</span>
        元
      </div>
    </div>
    <hltableZ :isNumber="true" :columnEdit="TableOptions.columnEdit" :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag" :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows" :apiurl="TableOptions.apiurl" @getSelectedRows="getSelectedRows">
      <div slot-scope="selected" slot="EditColumn">
        <el-button type="warning" size="mini" icon="el-icon-search" circle
          @click="View(selected.singlerow)"></el-button>
      </div>
    </hltableZ>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
import rechRecordHistory from './rechRecordHistory'
export default {
  name: 'rechRecord',
  components: { hltableZ,rechRecordHistory },
  data() {
    return {
      dialogFormVisible:false,
      childId:[],
      mealSiteData: [],
      form: {
        name: "",
        siteId: "",
        createTime: new Date().getTime() + 1000 * 60 * 60 * 24 * -1,
        endTime: new Date().getTime(),
      },
      money: 0,
      valueTime: [],
      TableOptions: {
        selectedRows: [],
        apiurl: "api/getRechargeList",
        refreshFlag: "",
        columnEdit: true,
        column: [
          {
            label: "姓名",
            prop: "name",
            checked: true,
          },
          {
            label: "身份证号",
            prop: "idCard",
            checked: true,
          },
          {
            label: "助餐食堂",
            prop: "siteName",
            checked: true,
          },
          {
            label: "充值金额(元)",
            prop: "money",
            checked: true,
          },
          {
            label: "充值时间",
            prop: "createTime",
            checked: true,
            formatter: this.timeFormatter,
          },
          {
            label: "充值方式",
            prop: "validateType",
            checked: true,
            formatter(value) {
              const validateType = value.validateType;
              return validateType == "1" ? "人脸" : "管理员录入";
            },
          },
        ],
      },
    }
  },
  async mounted() {
    var date = new Date();
    this.valueTime = [
      date.getTime() + 1000 * 60 * 60 * 24 * -1,
      date.getTime(),
    ];
    await this.getMealSite();
    this.dataRefresh();
  },
  methods: {
    timeFormatter(row, column, cellValue) {
      return cellValue ? this.$timestampTostr(cellValue) : "";
    },
    View(row) {
      // console.log(row)
      this.childId = [row.idCard,row.siteId]
      this.dialogFormVisible = true
    },
    async getMealSite() {
      const siteData = await this.$store.dispatch("api/getMealSiteAllList", {
        areaCode: this.$store.getters.user.areaCode,
      });
      if (siteData.success) {
        this.mealSiteData = siteData.result;
        this.form.siteId = siteData.result[0].id;
      } else {
        this.$message.error(siteData.message);
      }
    },
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows;
    },
    Del: function () {
      if (this.TableOptions.selectedRows.length == 0) {
        this.$message({
          type: "error",
          message: "请勾选下方表格中的数据",
        });
        return false;
      }
      var ids = [];
      this.TableOptions.selectedRows.forEach((key) => {
        ids.push(key.id);
      });
      this.$confirm("确定要进行删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store
          .dispatch("api/delMealRecord", { ids: ids })
          .then((response) => {
            if (response.success) {
              this.$message.success("操作成功");
              this.dataRefresh();
            } else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    async derive() {
      const token = await this.$store.dispatch("user/getToken");
      this.$axios({
        method: "post",
        url: "/api/mealRecord/exportData",
        data: this.form,
        headers: {
          token: token,
          platform: "web",
        },
        responseType: "blob",
      })
        .then((response) => {
          if (response.headers["content-disposition"]) {
            const content = response.data;
            const blob = new Blob([content]); //构造一个blob对象来处理数据

            // fileName必用这种方式进行解析，否则乱码
            // Server.UrlEncode(filename);

            let contentDisposition = response.headers["content-disposition"];
            // fileName必用这种方式进行解析，否则乱码
            let fileName = window.decodeURI(
              contentDisposition.substring(contentDisposition.indexOf("=") + 1)
            );
            //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
            if ("download" in document.createElement("a")) {
              const link = document.createElement("a"); //创建a标签
              link.download = fileName; //a标签添加属性
              link.style.display = "none";
              link.href = URL.createObjectURL(blob);
              document.body.appendChild(link);
              link.click(); //执行下载
              URL.revokeObjectURL(link.href); //释放url
              document.body.removeChild(link); //释放标签
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    reset() {
      // this.$refs['form'].resetFields()
      // 比上方获取demo的清理方式更彻底
      const th = this;
      Object.keys(this.form).forEach(function (key) {
        th.form[key] = "";
      });
    },
    async dataRefresh() {
      this.form.createTime =
        this.valueTime && this.valueTime.length > 0 ? this.valueTime[0] : 0;
      this.form.endTime =
        this.valueTime && this.valueTime.length > 0 ? this.valueTime[1] : 0;
      this.TableOptions.refreshFlag = new Date().getTime().toString();

      const moneyData = await this.$store.dispatch("api/getRechMoney", this.form);
      if (moneyData.success) {
        this.money = moneyData.result;
      } else {
        this.$message.error(moneyData.message);
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/tabletoptool.scss";
</style>