<template>
  <div>
    <Drawer :title="title" ref="drawerIshow">
      <div class="operateStatisticsDrawer">
        <div>
          <el-form :model="query" ref="query" label-width="60px" inline>
            <el-form-item label="区划">
              <el-select v-model="query.level" placeholder="请选择" @change="changeArea">
                <el-option key="全部" label="全部" :value="null"></el-option>
                <el-option key="待服务" label="待服务" value="待服务"></el-option>
                <el-option key="服务中" label="服务中" value="服务中"></el-option>
                <el-option key="已服务" label="已服务" value="已服务"></el-option>
              </el-select>
              -
              <el-select v-model="query.level" placeholder="请选择" @change="changeStreet">
                <el-option key="全部" label="全部" :value="null"></el-option>
                <el-option key="待服务" label="待服务" value="待服务"></el-option>
                <el-option key="服务中" label="服务中" value="服务中"></el-option>
                <el-option key="已服务" label="已服务" value="已服务"></el-option>
              </el-select>
              -
              <el-select v-model="query.level" placeholder="请选择" @change="changeCommunity">
                <el-option key="全部" label="全部" :value="null"></el-option>
                <el-option key="待服务" label="待服务" value="待服务"></el-option>
                <el-option key="服务中" label="服务中" value="服务中"></el-option>
                <el-option key="已服务" label="已服务" value="已服务"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="searchIsShow">
              <el-input v-model="query.bh" placeholder="请输入机构名称" style="width: 20rem"></el-input>
            </el-form-item>
            <el-form-item label="月份" v-if="searchIsShow">
              <el-date-picker v-model="value2" type="month" placeholder="选择月" value-format="yyyy-MM">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="dataRefresh">查询</el-button>
              <el-button size="small" icon="el-icon-refresh-left" @click="reset('Form')">重置</el-button>
              <el-button size="small" type="info" :icon="searchIsShow == false
                  ? 'el-icon-arrow-down'
                  : 'el-icon-arrow-up'
                " @click="more">更多</el-button>
            </el-form-item>
          </el-form>
        </div>
        <hltableZ :columnEdit="table.columnEdit" :tableColumn="table.column" :apiurl="table.apiurl"
          :refreshFlag="table.refreshFlag" :selectedRows="table.selectedRows" :isChecked="table.isChecked"
          :isNumber="table.isNumber" :staticData="tableDataSource"></hltableZ>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { Drawer, hltableZ } from "@/components";

export default {
  name: "OperateStatisticsDrawer",
  components: { Drawer, hltableZ },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "经营统计",
    },
  },

  data() {
    return {
      query: {},
      searchIsShow: false,
      table: {
        columnEdit: false,
        column: [
          {
            label: "助餐点名称",
            prop: "areaName",
            checked: true,
            width: 150
          },
          {
            label: "会员数量",
            prop: "chronicDisease",
            checked: true,
            width: 150
          },
          {
            label: "补贴会员数",
            prop: "chronicDisease",
            checked: true,
            width: 150
          },
          {
            label: "总订总金额(元)",
            prop: "chronicDisease",
            checked: true,
            width: 200
          },
          {
            label: "补贴总金额(元)",
            prop: "chronicDisease",
            checked: true,
            width: 200
          },
          {
            label: "自费总额(元)",
            prop: "chronicDisease",
            checked: true,
            width: 200
          },
          {
            label: "会员自费总额(元)",
            prop: "chronicDisease",
            checked: true,
            width: 200
          },
          {
            label: "非会员自费总额(元)",
            prop: "chronicDisease",
            checked: true,
            width: 200
          },
          {
            label: "订单总数(单)",
            prop: "chronicDisease",
            checked: true,
            width: 200
          },
          {
            label: "会员订单总数(单)",
            prop: "chronicDisease",
            checked: true,
            width: 200
          },
          {
            label: "补贴订单数(单)",
            prop: "chronicDisease",
            checked: true,
            width: 200
          },
          {
            label: "非会员订单总数(单)",
            prop: "chronicDisease",
            checked: true,
            width: 200
          },
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      tableDataSource: [{
        areaName: '1',
        chronicDisease: '1'
      }]
    };
  },

  mounted() { },

  methods: {
    // 查询
    dataRefresh() { },
    // 重置
    reset() { },
    more() {
      this.searchIsShow = !this.searchIsShow;
    },
    // 更改县区
    changeArea() { },
    // 更改街道
    changeStreet() { },
    // 更改社区
    changeCommunity() { },
  },
};
</script>

<style lang="scss" scoped>
.operateStatisticsDrawer {
  height: 90%;
}
</style>
