<template>
  <div>
    <Drawer :title="title" ref="drawerIshow" :buttonIsShow="true">
      <el-form class="form" :model="dataForm">
        <!-- 修改人脸照片 -->
        <div v-if="type === 'img'" key="img">
          <el-form-item label="老人姓名">
            <el-input
              v-model="dataForm.name"
              disabled
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证号">
            <el-input
              v-model="dataForm.ICard"
              disabled
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="人脸照片">
            <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :on-success="handlerImgUpload"
            >
              <img
                v-if="dataForm.imgSrc"
                :src="dataForm.imgSrc"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </div>
        <!-- 修改手机号 -->
        <div v-if="type === 'phone'" key="phone">
          <el-form-item label="老人姓名">
            <el-input
              v-model="dataForm.name"
              disabled
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="原手机号">
            <el-input
              v-model="dataForm.oldPhone"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="新手机号">
            <el-input
              v-model="dataForm.newPhone"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </div>
        <!-- 充值 -->
        <div class="recharge" v-if="type === 'recharge'" key="recharge">
          <el-form-item label="老人姓名">
            <el-input
              v-model="dataForm.name"
              disabled
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="">
            <div class="rechargeMoney">
              充值金额:(充值金额为<span>负数</span>时为退款操作)
            </div>
            <el-input v-model="dataForm.money" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="充值方式">
            <el-select
              v-model="dataForm.rechargeType"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="item in rechargeTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="dataForm.remark" placeholder="请输入"></el-input>
          </el-form-item>
        </div>
        <!-- 转移余额 -->
        <div class="transfer" v-if="type === 'transfer'" key="transfer">
          <el-form-item label="补贴会员身份证号">
            <el-input
              v-model="dataForm.transferID"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="转移金额">
            <el-input
              v-model="dataForm.transferMoney"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <span class="illustrate"
            >1.普通会员转移给自己的补贴会员 2.补贴会员转给其他补贴会员</span
          >
        </div>
         <!-- 绑定IC卡号 -->
        <div v-if="type === 'binding'">
          <el-form-item label="老人姓名">
            <el-input v-model="dataForm.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="IC卡号">
            <el-input
              v-model="dataForm.ICNumber"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
    </Drawer>
  </div>
</template>

<script>
import { Drawer } from "@/components";
export default {
  name: "MemberInfoOperate",
  components: { Drawer },
  props: {
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dataForm: {
        name: "",
        ICard: "",
        imgSrc:
          "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
        oldPhone: "",
        newPhone: "",
        money: "",
        rechargeType: "1",
        remark: "",
        transferID: "",
        transferMoney: "",
        ICNumber: "",
      },
      rechargeTypeOptions: [
        {
          value: "1",
          label: "微信",
        },
        {
          value: "2",
          label: "支付宝",
        },
        {
          value: "3",
          label: "现金",
        },
      ],
    };
  },

  mounted() {},

  methods: {
    // 上传
    handlerImgUpload(res) {
      if (res.code === 200) {
        this.dataForm.imgSrc = res.url;
      } else {
        this.$message.warning(res.msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 80%;
  // 上传图片缩略图样式
  .avatar-uploader {
    display: inline-block;
    text-align: center;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    cursor: pointer;
    line-height: 146px;
    vertical-align: top;
    .avatar {
      width: 148px;
      height: 148px;
    }
    i {
      font-size: 28px;
      color: #8c939d;
    }
  }
  // 充值
  .recharge {
    .rechargeMoney {
      color: "#FFF";
      span {
        color: red;
      }
    }
  }
  // 转移金额
  .transfer {
    // 说明
    .illustrate {
      font-size: 14px;
      color: red;
    }
  }
}
</style>
