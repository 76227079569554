<template>
  <!-- 政府购买服务规则设置/ -->
  <div class="HomeGovernment">
    <div class="Governmentgm">
      <p class="fwcl">服务策略</p>
      <span @click="modification()">修改记录</span>
    </div>
    <div class="col-xs-12">
      <div style="padding: 5px; margin-left: 5px">
        策略B3：按实际服务价值进行账户扣款
      </div>
      <div style="padding: 5px; margin-left: 5px">策略D：每月固定时长服务</div>
    </div>
    <div class="form-unit">服务操作</div>
    <div class="form-serve">
      <el-form ref="form" :model="form" label-width="200px">
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="签字确认:">
              <el-select v-model="form.isSign" placeholder="请选择签字确认:">
                <el-option label="开启" value="1"></el-option>
                <el-option label="关闭" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>当服务人员签出时，服务人员和老人的签字均不能为空。</div>
          </el-col>
        </el-row>
        <!-- 福卡授权验证:: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="福卡授权验证::">
              <el-select v-model="region" placeholder="请选择福卡授权验证:" disabled>
                <el-option label="开启" value="1"></el-option>
                <el-option label="不开启" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>需要服务对象福卡号认证身份，方可完成签出等闭环操作。</div>
          </el-col>
        </el-row>
        <!-- 服务人员人脸识别: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="服务人员人脸识别:">
              <el-select v-model="form.isFace" placeholder="请选择服务人员人脸识别:">
                <el-option label="允许" value="1"></el-option>
                <el-option label="不允许" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>需要服务人员人脸识别，方可完成签入。</div>
          </el-col>
        </el-row>
        <!-- 用户现场评价: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="用户现场评价:">
              <el-select v-model="form.isEvaluate" placeholder="请选择用户现场评价:">
                <el-option label="允许" value="1"></el-option>
                <el-option label="不允许" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>当服务人员签出前，需要填写用户对服务的评价。</div>
          </el-col>
        </el-row>
        <!-- 评价录音上传: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="评价录音上传:">
              <el-select v-model="form.isRecord" placeholder="请选择评价录音上传:">
                <el-option label="允许" value="1"></el-option>
                <el-option label="不允许" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div></div>
          </el-col>
        </el-row>
        <!-- 服务录像上传: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="服务录像上传:">
              <el-select v-model="form.isVideo" placeholder="请选择服务录像上传:">
                <el-option label="允许" value="1"></el-option>
                <el-option label="不允许" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div></div>
          </el-col>
        </el-row>
        <div class="form-unit">服务要求</div>
        <!-- 从相册选择服务图片:: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="从相册选择服务图片:">
              <el-select v-model="form.isPhoto" placeholder="请选择服务图片">
                <el-option label="允许" value="1"></el-option>
                <el-option label="不允许" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>若不允许，则只能实时拍照上传</div>
          </el-col>
        </el-row>
        <!-- 照片水印::: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="照片水印:">
              <el-select v-model="form.isWatermark" placeholder="请选择照片水印:">
                <el-option label="允许" value="1"></el-option>
                <el-option label="不允许" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>
              开启对工单内所有照片都生效。水印内容默认为图片上传时间，如需显示其他内容，请联系技术人员配置。
            </div>
          </el-col>
        </el-row>
        <!-- 允许单日重复服务:: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="允许单日重复服务:">
              <el-select v-model="form.isRepet" placeholder="请选择允许单日重复服务:">
                <el-option label="允许" value="1"></el-option>
                <el-option label="不允许" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>某服务对象的同一服务项在一天内是否允许被服务多次。</div>
          </el-col>
        </el-row>
        <!-- 服务人员年龄: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="服务人员年龄:">
              <div class="julimi">
                <el-input-number v-model="form.staffStartAge" controls-position="right" :min="18"
                  :max="60"></el-input-number>
                <span>到</span>
                <el-input-number v-model="form.staffEndAge" controls-position="right" :min="18"
                  :max="60"></el-input-number>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>当服务人员签入时，会对其年龄进行校验。</div>
          </el-col>
        </el-row>
        <!-- 定位距离: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="服务定位距离（米）:">
              <div>
                <el-input-number v-model="form.distance" controls-position="right"></el-input-number>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>1、非强制：签入签出时进行距离检测，若超出设定的值，或手机定位失败，依然允许签入签出，并判定该工单为“反常数据”，需在“服务位置反常数据检测”中进行人工审查和处理。
              2、强制：签入签出时距离校验超出设定值，不允许签入签出。
              3、若有效签到范围设置为0，则不启用距离检测</div>
          </el-col>
        </el-row>
        <!-- 允许签入时间范围: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="允许签入时间范围）:">
              <div>
                <el-input v-model="form.socpe"></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>服务每天允许签入的时间范围，多个范围逗号分隔，如06:30-12:00,13:00-21:00</div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="but">
      <el-button type="success" @click="seve()">保存</el-button>
    </div>

    <Drawer :title="title" ref="drawerIshow" :drawerSize="drawerSize">
      <Modifyingrecords></Modifyingrecords>
    </Drawer>
  </div>
</template>

<script>
import { Drawer } from "@/components";
import Modifyingrecords from "./Modifyingrecords";
export default {
  data() {
    return {
      title: "窗口",
      region: '2',
      form: {

      },
      drawerSize: "60%",
    };
  },
  components: { Drawer, Modifyingrecords },
  mounted() {
  },
  computed: {
    NEWdata() {
      return this.$store.getters.sidebar.form;
    },
  },
  watch: {
    NEWdata: {
      handler: function (v) {
        this.form = this.NEWdata
      },
      immediate: true,
    },
  },
  methods: {
    seve() {
      this.$store
        .dispatch("jbsz/saveData", this.form)
        .then((res) => {
          this.$message({
            message: '保存成功',
            type: 'success'
          });
        })
    },
    modification() {
      this.$refs.drawerIshow.drawer = true;
    },
  },
};
</script>
<style scoped lang="scss">
.HomeGovernment {
  font-size: 16px;

  .Governmentgm {
    position: relative;

    span {
      text-decoration: underline;
      color: #1fcfe2;
      padding-top: 4px;
      position: absolute;
      top: -5px;
      left: 100px;
    }

    .fwcl {
      font-size: 15px;
      color: #6379bb;
      border-bottom: 1px solid #ddd;
      margin: 8px 10px 25px 10px;
      padding-bottom: 5px;
    }
  }

  .form-unit {
    font-size: 15px !important;
    color: #6379bb !important;
    border-bottom: 1px solid #ddd !important;
    margin: 8px 10px 25px 10px !important;
    padding-bottom: 5px !important;
  }

  .el-select {
    width: 350px;
  }

  .julimi {
    display: flex;
    justify-content: flex-end;
    width: 350px;
  }

  .form-serve {
    div {
      color: #aaa;
      font-size: 13px;
      line-height: 30px;
    }
  }

  .but {
    display: flex;
    justify-content: center;
  }
}
</style>
