<template>
    <div style="height:calc(100% - 4rem)">
        <div class="chart" ref="chart" :style="{ width: '100%', height: '100%' }"></div>
        <div class="zs">
            <div style="font-size: 1.5rem;
font-family: Source Han Sans CN, Source Han Sans CN-Bold;
font-weight: 700;
text-align: left;
color: #ffffff">{{ allNum }}</div>
            <div style="font-size: 1rem;
font-family: Source Han Sans CN, Source Han Sans CN-Regular;
font-weight: 400;
text-align: center;
color: #7ee4f5;">总数</div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'pieEcharts',

    data() {
        return {
            chart: null
        };
    },
    props: {
        height: {
            type: String,
            default: '10rem'
        },
        dataList: {
            type: Array
        },
        allNum: {
            type: Number,
            default: 128
        }
    },
    mounted() {
        this.echarts()
    },

    methods: {
        nowSize(val, initWidth = 1920) {
            //当前视口宽度
            let nowClientWidth = document.documentElement.clientWidth;
            return val * (nowClientWidth / initWidth);
        },
        echarts() {
            this.chart = this.$echarts.init(this.$refs.chart); // 初始化echarts
            var option = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {c} ({d}%)'
                },
                label: {
                    show: true,
                    color: '#ffffff',
                    fontSize: this.nowSize(1),
                    formatter: (params) => {
                        console.log(89898989, params.data.value)
                        return params.data.name + ':' + Math.ceil(params.data.value / this.allNum * 100) + '%'
                    },
                },
                legend: {
                    selectedMode: false,
                    icon: "circle",
                    itemHeight: this.nowSize(10),
                    top: "18%",
                    orient: "vertical",
                    itemGap: this.nowSize(3),
                    right: "0%",
                    textStyle: {
                        color: "#fff", // 图例文字颜色
                        textAlign: 'center',
                        fontSize: this.nowSize(13)
                    },
                },
                color: ['#ff5e6b', '#6da9ea', '#fa6941', '#a8a3f3', '#fab567', '#ff9075', '#6deaa2', ' #fe804e', '#39e6ec', '#ccc456'],

                series: [
                    {
                        type: 'pie',
                        radius: ['60%', '80%'],
                        center: ['37.8%', '50%'],
                        labelLine: {
                            show: true,
                            fontSize: this.nowSize(9),
                        },
                        data: this.dataList,
                        label: {
                            normal: {
                                textStyle: {
                                    fontSize: this.nowSize(10)// 调整字体大小
                                }
                            }
                        },
                    }
                ]
            };
            this.chart.setOption(option); // 设置对应配置项
            // 当窗口大小改变 echarts重置大小
            window.addEventListener("resize", () => {
                this.chart.resize();
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.zs {
    position: absolute;
    top: calc(50% - 0.5rem);
    left: 34%
}
</style>