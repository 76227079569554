<template>
  <div>
    <!-- 服务包设置 -->
    <Drawer :title="title" ref="drawer">
      <el-form size="small" :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="服务包名称：">
          <el-input v-model="formInline.packageName" placeholder="请输入服务包名称"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="formInline.status" placeholder="请选择状态">
            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="dataRefresh">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="onCancel">重置</el-button>
        </el-form-item>
        <el-form-item style="float: right">
          <el-button icon="el-icon-plus" size="small" type="primary" @click="handleAdd">新增</el-button></el-form-item>
      </el-form>
      <div class="tabContent">
        <hltableZ :column-edit="table.columnEdit" :table-column="table.column" :apiurl="table.apiurl"
          :refresh-flag="table.refreshFlag" :selected-rows="table.selectedRows" :is-checked="table.isChecked"
          :is-number="table.isNumber" :formSearch="formInline">
          <div slot-scope="selected" slot="EditColumn">
            <el-button icon="el-icon-s-tools" type="text" size="small"
              @click="handleService(selected.singlerow)">服务项配置</el-button>
            <el-button icon="el-icon-edit" type="text" size="small" @click="handleEdit(selected.singlerow)">编辑</el-button>
            <el-button icon="el-icon-delete" type="text" size="small"
              @click="handleCancel(selected.singlerow)">删除</el-button>
          </div>
        </hltableZ>
      </div>
      <servicePack :title="serviceTitle" ref="servicePack" @addsubmitForm="addsubmitForm" :datahand="datahand">
      </servicePack>
    </Drawer>
    <serviceConfiguration ref="serviceConfiguration"></serviceConfiguration>
  </div>
</template>

<script>
import serviceConfiguration from "./serviceConfiguration";
import servicePack from "./servicePack.vue";
import { Drawer, hltableZ } from "@/components";
export default {
  components: { Drawer, hltableZ, servicePack, serviceConfiguration },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      serviceTitle: "",
      //   表格
      table: {
        columnEdit: true,
        column: [
          {
            label: "服务包",
            prop: "packageName",
            checked: true,
            width: 100,
          },
          {
            label: "适用服务组织",
            prop: "organ",
            checked: true,
            width: 170,
          },
          {
            label: "服务项数量",
            prop: "batchName",
            checked: true,
            width: 150,
          },
          {
            label: "状态",
            prop: "status",
            checked: true,
            formatter(value) {
              const status = value.status;
              if (status == '1') {
                return '可用'
              } else if (status == '2') {
                return '不可用'
              }
            },
          },
          {
            label: "服务策略",
            prop: "strategy",
            checked: true,
            width: 120,
          },
          {
            label: "描述",
            prop: "describe",
            checked: true,
            width: 200,
          },
          {
            label: "创建日期",
            prop: "creationDate",
            checked: true,
            width: 120,
          },
        ],
        apiurl: "jbsz/livingPackagegetDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      formInline: {
        packageName: "", //服务包名称
        status: undefined, // 状态
        batchId: ''
      },
      statusOptions: [
        {
          value: 1,
          label: "可用",
        },
        {
          value: 2,
          label: "不可用",
        },
      ],
      datahand: {},
      ids: [],
      idhand: ''
    };
  },
  created() { },
  methods: {
    handleService(e) {
      this.idhand = e.id
      this.$refs.serviceConfiguration.formInline.packageId = e.id
      this.$refs.serviceConfiguration.open();
      this.$refs.serviceConfiguration.livingPackagegetList();
      this.$refs.serviceConfiguration.dataRefresh();
    },
    handleEdit(e) {
      this.datahand = e
      this.serviceTitle = "修改服务包";
      this.$refs.servicePack.open();
    },
    handleCancel(e) {

      this.ids.push(e.id)
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("jbsz/deleteData", { ids: this.ids }).then((res) => {
            this.ids.shift()
            this.dataRefresh()
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleAdd() {
      console.log(88888, this.formInline.batchId)
      this.$refs.servicePack.open(this.formInline.batchId);
      this.$refs.servicePack.ruleForm.batchId = this.formInline.batchId
      console.log(88888, this.$refs.servicePack)
      this.serviceTitle = "新增服务包";


    },
    onCancel() {
      this.formInline = {
        serviceName: "", //服务包名称
        status: undefined, // 状态
      };
    },
    open() {
      // alert(22)
      let name = "（按时长）临淄区特困老人居家服务";
      let time1 = "2022-05-19";
      let time2 = "2025-05-19";
      this.title = `${name}服务包管理`;
      this.$refs.drawer.drawer = true;
    },
    addsubmitForm() {
      this.dataRefresh()
    },
    dataRefresh() {
      this.table.refreshFlag = new Date().getTime().toString();
    },
  },
};
</script>

<style lang="scss" scoped>
.formItem {
  display: flex;
}

.tabContent {
  height: 30rem;
  width: 100%;
  overflow: auto;
}
</style>
