<template>
    <div class="jgyl">
        暂无数据
    </div>
</template>

<script>
export default {
    name: 'YanglaoJgyl',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped></style>