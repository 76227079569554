<template>
  <div>
    <!-- 服务包设置 -->
    <Drawer :title="title" ref="drawer">
      <div class="tabContent">
        <hltableZ
          :column-edit="table.columnEdit"
          :table-column="table.column"
          :apiurl="table.apiurl"
          :refresh-flag="table.refreshFlag"
          :selected-rows="table.selectedRows"
          :is-checked="table.isChecked"
          :is-number="table.isNumber"
        >
          <div slot-scope="selected" slot="EditColumn">
            <el-button
              icon="el-icon-info"
              type="text"
              size="small"
              @click="handleDetail(selected.singlerow)"
              >详情</el-button
            >
          </div>
        </hltableZ>
      </div>
      <detailedInfor ref="detailedInfor"></detailedInfor>
    </Drawer>
  </div>
</template>

<script>
import detailedInfor from '../components/detailedInfor'
import { Drawer, hltableZ } from "@/components";
export default {
  components: { Drawer, hltableZ, detailedInfor },
  data() {
    return {
      title: "规则修改记录",
      //   表格
      table: {
        columnEdit: true,
        column: [
          {
            label: "修改时间",
            prop: "updateTime",
            checked: true,
            width: 100,
          },
          {
            label: "修改人",
            prop: "updatePeople",
            checked: true,
          },
          {
            label: "修改内容",
            prop: "updateContent",
            checked: true,
            width: 150,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
    };
  },
  created() {},
  methods: {
    handleDetail(row) {
      console.log(row);
      this.$refs.detailedInfor.open()
    },
    open() {
      this.$refs.drawer.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.tabContent {
  height: 30rem;
  width: 100%;
  overflow: auto;
}
</style>
