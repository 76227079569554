<template>
  <div class="chart" ref="chart" :style="{ width: eWidth, height: eHeight }"></div>
</template>

<script>
export default {
  name: "barEcharts",
  props: {
    option: {
      type: Object,
      default: () => ({}),
    },
    eWidth: {
      type: String,
      default: "100PX",
    },
    eHeight: {
      type: String,
      default: "100px",
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.chart = this.$echarts.init(this.$refs.chart);
    this.$nextTick(() => {
      this.chart.setOption(this.option);
    });
    window.addEventListener("resize", this.resize);
  },
  watch: {
    option: {
      handler(n) {
        this.$nextTick(() => {
          this.chart.setOption(n, true);
        });
      },
      deep: true,
    },
  },
  methods: {
    resize() {
      this.chart.resize();
    },
    nowSize(val, initWidth = 1920) {
      //当前视口宽度
      let nowClientWidth = document.documentElement.clientWidth;
      return val * (nowClientWidth / initWidth);
    },
  },
};
</script>

<style lang="scss" scoped></style>
