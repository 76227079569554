<template>
  <div class="hl-full-container flexVertical">
    <div class="title">
      <div>服务批次配置<span class="resetting">（政府购买服务）</span></div>
      <el-button icon="el-icon-plus" size="small" type="primary" @click="handleAdd">新增</el-button>
    </div>
    <el-form size="small" :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="所属年度：">
        <el-date-picker v-model="formInline.year" type="year" placeholder="选择所属年度" value-format="yyyy">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="批次名称：">
        <el-input v-model="formInline.batchName"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="dataRefresh">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>

    <hltableZ :column-edit="table.columnEdit" :table-column="table.column" :apiurl="table.apiurl"
      :refresh-flag="table.refreshFlag" :selected-rows="table.selectedRows" :is-checked="table.isChecked"
      :is-number="table.isNumber" :formSearch="formInline">
      <div slot-scope="selected" slot="EditColumn">
        <el-button icon="el-icon-s-tools" type="text" size="small"
          @click="handleService(selected.singlerow)">服务包设置</el-button>
        <el-button icon="el-icon-edit" type="text" size="small" @click="handleEdit(selected.singlerow)">编辑</el-button>
        <!-- <el-dropdown trigger="click">
            <el-button size="small" type="text" :icon="searchIsShow == false
              ? 'el-icon-arrow-down'
              : 'el-icon-arrow-up'
              " @click="more">更多</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item><el-button type="text" plain size="small"
                  @click="handleDerive">服务规则</el-button></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
      </div>
    </hltableZ>
    <!-- 中间 -->
    <!-- <div class="toolbar">
        <el-button
          type="primary"
          plain
          size="small"
          icon="el-icon-download"
          @click="derive"
          >服务规则</el-button
        >
      </div> -->
    <serviceBatch :title="title" ref="serviceBatch" @addsubmitForm="addsubmitForm"></serviceBatch>
    <serviceSettings :title="title" ref="serviceSettings"></serviceSettings>
    <modifyRecord ref="modifyRecord"></modifyRecord>
  </div>
</template>

<script>
import modifyRecord from "./components/modifyRecord.vue";
import serviceBatch from "./components/serviceBatch";
import serviceSettings from "./components/serviceSettings";
import { hltableZ } from "@/components";
export default {
  components: {
    hltableZ,
    serviceBatch,
    serviceSettings,
    modifyRecord,
  },
  data() {
    return {
      title: "",
      searchIsShow: false,
      formInline: {
        year: "",
        batchName: "",
      },
      //   表格
      table: {
        columnEdit: true,
        column: [
          {
            label: "批次名称",
            prop: "batchName",
            checked: true,
          },
          {
            label: "所属年度",
            prop: "year",
            checked: true,
          },
          {
            label: "有效开始时间",
            prop: "startTime",
            checked: true,
            formatter: this.timeFormatter,
          },
          {
            label: "有效结束时间",
            prop: "endTime",
            checked: true,
            formatter: this.timeFormatter,
          },
          {
            label: "适用范围",
            prop: "area",
            checked: true,
          },
          {
            label: "服务对象人数",
            prop: "numberServiceRecipients",
            checked: true,
          },
          {
            label: "服务策略",
            prop: "strategy",
            checked: true,
          },
          {
            label: "创建日期",
            prop: "creationDate",
            checked: true,
          },
          {
            label: "描述",
            prop: "describe",
            checked: true,
          },
        ],
        apiurl: "jbsz/livingBatchgetDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
        datahand: {}
      },
    };
  },
  created() { },
  methods: {
    timeFormatter(row, column, cellValue) {
      return cellValue ? this.$timestampTostr(cellValue) : "";
    },
    handleDerive() {
      this.$refs.modifyRecord.open();
    },
    handleAdd() {
      this.$refs.serviceBatch.message = '新增成功'

      this.$refs.serviceBatch.open();
      this.title = "添加政府购买服务批次";
    },
    dataRefresh() {
      this.table.refreshFlag = new Date().getTime().toString();
    },
    reset() {
      const th = this
      Object.keys(th.formInline).forEach(function (key) {

        th.formInline[key] = ''


      })
      this.dataRefresh();
    },
    handleService(e) {
      console.log(e)
      this.$store.dispatch("app/todan", e.strategy);
      this.$store.dispatch("app/idsClick", e.id);
      this.$refs.serviceSettings.formInline.batchId = e.id
      this.$refs.serviceSettings.open();
      this.$refs.serviceSettings.dataRefresh()
    },
    handleEdit(e) {
      console.log(789, e)
      this.datahand = e
      this.$refs.serviceBatch.message = '修改成功'
      const th = this
      Object.keys(th.$refs.serviceBatch.ruleForm).forEach(key => {
        th.$refs.serviceBatch.ruleForm[key] = th.datahand[key] || th.$refs.serviceBatch.ruleForm[key]
      })
      this.$refs.serviceBatch.open();
      this.title = "修改政府购买服务批次";
    },
    more() {
      this.searchIsShow = !this.searchIsShow;
    },
    addsubmitForm() {
      this.dataRefresh()
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";

.title {
  padding-right: 1.25rem;
  padding-bottom: 5px;
  font-size: 14px;
  // color: #409eff;
  border-bottom: 1px solid #ddd;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .resetting {
    font-size: 12px;
    color: #00c187;
  }
}
</style>
