<template>
  <div>
    <!-- 分配服务商 -->
    <Drawer
      :title="title"
      ref="drawer"
      :button-is-show="true"
      :submit-form-name="submitFormName"
      @submitForm="submitForm"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="100px"
        class="demo-dynamic"
      >
        <div
          class="title"
          style="
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            font-weight: 700;
            border-bottom: 1px solid #ddd;
          "
        >
          政府购买服务订单
        </div>

        <el-form-item
          prop="orderSelected"
          label="已选择订单:"
          :rules="[
            { required: true, message: '请输入订单', trigger: 'blur' },
            {
              type: 'orderSelected',
              message: '请输入订单',
              trigger: ['blur'],
            },
          ]"
        >
          <el-input
            disabled
            style="width: 28.125rem"
            v-model="dynamicValidateForm.orderSelected"
          ></el-input>
        </el-form-item>

        <div
          class="title"
          style="
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            font-weight: 700;
            border-bottom: 1px solid #ddd;
          "
        >
          服务人员
        </div>

        <el-form-item
          prop="orderSelected"
          label="分配服务人员:"
          label-width="150"
          :rules="[
            { required: true, message: '请选择分配服务人员', trigger: 'blur' },
            {
              type: 'orderSelected',
              message: '请选择分配服务人员',
              trigger: ['change'],
            },
          ]"
        >
          <el-select
            v-model="dynamicValidateForm.serviceStaff"
            placeholder="请选择活动区域"
          >
            <el-option
              v-for="item in serviceStaffList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </Drawer>
  </div>
</template>

<script>
import { Drawer } from "@/components";
export default {
  components: { Drawer },
  data() {
    return {
      submitFormName: "分配",
      dynamicValidateForm: {
        orderSelected: "许成安(G37032060022)",
        serviceStaff: "",
      },
      serviceStaffList: [
        {
          id: 1,
          name: "许峰",
        },
        {
          id: 2,
          name: "许峰",
        },
      ],
      title: "分配服务人员",
      serviceID: "",
      serviceList: [
        {
          id: 1,
          name: "临淄区特困老人服务685（30小时）-分散-半自理-03-12 -  - 通用(当前选择)",
        },
        {
          id: 2,
          name: "临淄区特困老人服务685（30小时）-分散-半自理-03-12 -  - 通用(当前选择)",
        },
      ],
    };
  },
  created() {},
  methods: {
    submitForm() {},
    handleSave() {},
    open() {
      this.$refs.drawer.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
