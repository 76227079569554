<template>
  <Drawer title="异常订单" ref="drawerIshow">
    <!-- 异常订单选项卡 -->
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-for="item in tabsList"
        :key="item.label"
        :label="item.label"
        :name="item.name"
      >
        <!-- 异常订单 -->
        <div class="abnormalOrders">
          <!-- 描述 -->
          <div class="desc">
            <span class="desc_title">{{ descTitle }}</span>
            <span class="desc_warning"
              >(查询过程需要消耗一些时间，请耐心等待)</span
            >
          </div>
          <!-- 搜索 -->
          <div class="search">
            <el-form :model="query" ref="form" label-width="80" inline>
              <el-form-item label="" prop="mealLocation">
                <el-select v-model="query.mealLocation" placeholder="请选择">
                  <el-option
                    v-for="item in mealLocationOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="就餐日期" prop="diningDate">
                <el-date-picker
                  v-model="query.diningDate"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-search"
                  @click="dataRefresh"
                  >查询</el-button
                >
                <el-button
                  size="small"
                  icon="el-icon-refresh-left"
                  @click="reset"
                  >重置</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <!-- 表格 -->
          <hltableZ
            v-if="item.name === activeName"
            :columnEdit="table.columnEdit"
            :tableColumn="table.column"
            :apiurl="table.apiurl"
            :refreshFlag="table.refreshFlag"
            :isChecked="table.isChecked"
            :isNumber="table.isNumber"
            :isClickSelect="table.isClickSelect"
            :staticData="tableDataSource"
            :formSearch="query"
          >
            <div slot-scope="selected" slot="EditColumn">
              <el-button
                icon="el-icon-error"
                type="text"
                size="small"
                @click="unqualified(selected.singlerow)"
                >不合格</el-button
              >
            </div>
          </hltableZ>
        </div>
      </el-tab-pane>
    </el-tabs>
  </Drawer>
</template>

<script>
import { Drawer, hltableZ } from "@/components";
import { abnormalOrdersTablesData } from "../../statisticsTablesData";
const abnormalOrdersTabs = [
  { label: "重复订单", name: "first" },
  { label: "机构老人订单", name: "second" },
  { label: "异常老人订单", name: "third" },
];
const descTitleObj = {
  first: "同一天同一餐段内两次以及以上补贴的订单",
  second: "已入住养老机构的老人产生的助餐订单",
  third: "生存状态异常的老人产生的助餐订单将不会计算补贴",
};
export default {
  name: "AbnormalOrders",
  components: { Drawer, hltableZ },
  data() {
    return {
      tabsList: [...abnormalOrdersTabs],
      activeName: "first",
      descTitle: descTitleObj.first,
      // 助餐站点options
      mealLocationOptions: [
        {
          value: "1",
          label: "辛店街道于家店村长者食堂",
        },
        {
          value: "2",
          label: "齐都镇东古村长者食堂",
        },
      ],
      table: { ...abnormalOrdersTablesData.first },
      // 表格静态数据
      tableDataSource: [
        {
          1: 1,
          2: 1,
          3: 1,
          4: 1,
          5: 1,
          6: 1,
          7: 1,
          8: 1,
          9: 1,
          10: 1,
          12: 1,
          13: 1,
          14: 1,
          15: 1,
          16: 1,
          17: 1,
          18: 1,
        },
      ],
      query: {
        mealLocation: "",
        diningDate: "",
      },
    };
  },

  mounted() {},

  methods: {
    // 选项卡点击
    handleClick() {
      this.descTitle = descTitleObj[this.activeName];
      this.table = abnormalOrdersTablesData[this.activeName];
    },
    // 查询
    dataRefresh() {},
    // 重置
    reset() {
      this.$refs.form.forEach((item) => item.resetFields());
    },
    // 不合格
    unqualified() {
      this.$confirm(
        "确定该订单审查不合格吗，不合格的订单将无法申请补贴;如需修改，请到 订单质量审查 界面操作。",
        "信息",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {})
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.el-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  ::v-deep .el-tabs__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tab-pane {
      flex: 1;
      display: flex;
      flex-direction: column;
      .abnormalOrders {
        flex: 1;
        display: flex;
        flex-direction: column;
        .desc {
          border-bottom: 1px solid #eee;
          padding-bottom: 0.3125rem;
          margin-bottom: 0.9375rem;
          .desc_title {
            font-size: 0.875rem;
            color: red;
            margin-right: 0.3125rem;
          }
          .desc_warning {
            font-size: 0.875rem;
            color: #f0c674;
          }
        }
      }
    }
  }
}
</style>
