<template>
  <div>
    <!-- 添加/修改  政府购买服务批次 -->
    <Drawer :title="title" ref="drawer" :button-is-show="buttonIsShow" :submit-form-name="submitFormName"
      @submitForm="submitForm">
      <div style="
          height: 30px;
          line-height: 30px;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 10px;
          font-weight: 700;
        ">
        基本信息
      </div>
      <el-form size="small" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
        :label-position="labelPosition">
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="名称:" prop="packageName">
              <el-input placeholder="请输入批次名称" v-model="ruleForm.packageName">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="适用服务组织:" prop="organ">
          <el-select v-model="ruleForm.organ" placeholder="请选择适用服务组织">
            <el-option v-for="item in serviceOrganizationList" :key="item.id" :label="item.organName"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="状态:" prop="status">
              <el-select placeholder="请选择状态" v-model="ruleForm.status">
                <el-option v-for="item in statusOptions" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="服务包描述:" prop="describe">
              <el-input type="textarea" :rows="2" placeholder="请输入服务包描述" v-model="ruleForm.describe">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="服务策略:" prop="strategy">
          <el-select v-model="ruleForm.strategy" placeholder="请选择服务策略" disabled>
            <!-- <el-option
              label="策略B3：按实际服务价值进行账户扣款"
              value="1"
            ></el-option> -->
            <el-option label="策略D：每月固定时长服务" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="策略要求:" prop="strategicRequirements">
          <div>
            每月享受补贴的时长(小时)：<el-input-number v-model="ruleForm.hour"></el-input-number>
          </div>
          <div>
            每月最高补贴金额(元)：<el-input-number v-model="ruleForm.money"></el-input-number>
          </div>
          <div>
            单次上门服务政府认可的最大时长(分钟)：<el-input-number v-model="ruleForm.approvedDuration"></el-input-number>
          </div>
        </el-form-item>
      </el-form>
    </Drawer>
  </div>
</template>

<script>
import { Drawer } from "@/components";
export default {
  components: { Drawer },
  props: {
    title: {
      type: String,
      default: "",
    },
    datahand: {
      type: Object,
    }
  },
  watch: {
    title: {
      handler(newValue, oldvalue) {
        if (newValue === '新增服务包') {
          const th = this
          Object.keys(th.ruleForm).forEach(function (key) {
            th.ruleForm[key] = ''
          })
        } else {
          const th = this
          Object.keys(th.ruleForm).forEach(key => {
            th.ruleForm[key] = th.datahand[key] || th.ruleForm[key]
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      statusOptions: [
        {
          value: 1,
          label: "可用",
        },
        {
          value: 2,
          label: "不可用",
        },
      ],
      ruleForm: {
        packageName: "",
        organ: "",
        status: "",//状态（1.可用 2.不可用）
        describe: "",
        strategy: '',
        hour: null,//补贴时长
        money: "",//补贴金额
        approvedDuration: 136,//认可最大时长（分钟）
        batchId: ""//服务批次id
      },
      serviceOrganizationList: [


      ],
      subsidyAmountFlag: false,
      labelPosition: "left",
      rules: {},
      submitFormName: "保存",
      buttonIsShow: true,
    };
  },
  computed: {
    datahands() {
      return this.$store.getters.sidebar.datahands;
    },
    ids() {
      return this.$store.getters.sidebar.ids;
    }
  },
  created() {
    this.organization()
  },
  mounted() { this.ruleForm.strategy = this.datahands },
  methods: {
    organization() {
      this.$store.dispatch("jbsz/organization").then((res) => {
        this.serviceOrganizationList = res.result
      })
    },
    handleSelect(val) {
      if (val === "2") {
        this.subsidyAmountFlag = true;
      } else {
        this.subsidyAmountFlag = false;
      }
    },
    open(e) {
      this.$refs.drawer.drawer = true;
    },
    submitForm() {
      console.log(this.ruleForm)
      this.ruleForm.batchId = this.ids
      this.$store.dispatch("jbsz/livingPackagesaveData", this.ruleForm).then((res) => {
        const th = this
        Object.keys(th.ruleForm).forEach(function (key) {
          th.ruleForm[key] = ''
        })
        this.$emit('addsubmitForm')
      })
    }
  },
};
</script>

<style lang="scss" scoped></style>
