<template>
  <!-- 海浪警报 -->
  <div class="hl-full-container flexVertical">
    <div class="search">
      <el-form :model="query" ref="query" label-width="80px" inline>
        <el-form-item label="工单编号">
          <el-input
            v-model="query.name"
            placeholder="请输入工单编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="机构名称">
          <el-input v-model="query.bh" placeholder="请输入机构名称"></el-input>
        </el-form-item>
        <el-form-item label="工单类型">
          <el-input
            v-model="query.gdlx"
            placeholder="请输入工单类型"
          ></el-input>
        </el-form-item>
        <template v-if="searchIsShow">
          <el-form-item label="服务项目">
            <el-input
              v-model="query.fwxm"
              placeholder="请输入服务项目"
            ></el-input>
          </el-form-item>
          <el-form-item label="下单时间" prop="kqzt">
            <el-date-picker
              v-model="query.kazt"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="服务日期" prop="kqzt">
            <el-date-picker
              v-model="query.jclb"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="工单状态">
            <el-select v-model="query.level" placeholder="请选择">
              <el-option key="待服务" label="待服务" value="待服务">
              </el-option>
              <el-option key="服务中" label="服务中" value="服务中"></el-option>
              <el-option key="已服务" label="已服务" value="已服务"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="区域" prop="kqzt">
            <el-cascader v-model="query.qy" :options="options"></el-cascader>
          </el-form-item>
        </template>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
          <!-- <el-button size="small" icon="el-icon-refresh-left" @click="reset('Form')">重置</el-button>
                    <el-button size="small" type="info"
                        :icon='searchIsShow == false ? "el-icon-arrow-down" : "el-icon-arrow-up"'
                        @click="more">更多</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <!-- 中间 -->
    <!-- <div class="toolbar">
      <el-button type="primary" plain size="mini" icon="el-icon-download"
        >导出</el-button
      >
      <el-button
        type="success"
        plain
        size="mini"
        icon="el-icon-edit"
        @click="statistics"
        >统计</el-button
      >
      <el-button
        type="danger"
        plain
        size="mini"
        icon="el-icon-folder-remove"
        @click="Anomalous"
        >反常数据</el-button
      >
    </div> -->
    <hltableZ
      style="margin-top: 10px"
      :columnEdit="table.columnEdit"
      :tableColumn="table.column"
      :apiurl="table.apiurl"
      :isPage="false"
      :staticData="table.staticData"
      :refreshFlag="table.refreshFlag"
      :selectedRows="table.selectedRows"
      :isChecked="table.isChecked"
      :widthClick="widthClick"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-button
          icon="el-icon-view"
          type="text"
          size="small"
          @click="search(selected.singlerow)"
          >查看</el-button
        >
        <!-- <el-dropdown @command="elDropdown($event)">
          <span class="el-dropdown-link">
            工单处理<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a">工单驳回</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
    </hltableZ>

    <!-- 弹窗 -->
    <Drawer :title="title" ref="drawerIshow">
      <!-- 1 -->
      <div class="lrxx">
        <div class="gdTitle">
          <div class="colors"></div>
          <div class="gdWord">老人信息</div>
        </div>
      </div>
      <el-descriptions class="margin-top" :column="3" border>
        <el-descriptions-item v-for="item in descriptionsList" :key="item.name">
          <template slot="label">
            {{ item.label }}
          </template>
          {{ item.name }}
        </el-descriptions-item>
      </el-descriptions>
      <!-- 2 -->
      <div class="lrxx">
        <div class="gdTitle">
          <div class="colors"></div>
          <div class="gdWord">工单信息</div>
        </div>
      </div>
      <el-descriptions class="margin-top" :column="3" border>
        <el-descriptions-item
          v-for="item in descriptionsList2"
          :key="item.name"
        >
          <template slot="label">
            {{ item.label }}
          </template>
          {{ item.name }}
        </el-descriptions-item>
      </el-descriptions>
      <!-- 3 -->
      <div class="lrxx">
        <div class="gdTitle">
          <div class="colors"></div>
          <div class="gdWord">服务信息</div>
        </div>
      </div>
      <el-descriptions class="margin-top" :column="3" border>
        <el-descriptions-item
          v-for="item in descriptionsList3"
          :key="item.name"
        >
          <template slot="label">
            {{ item.label }}
          </template>
          {{ item.name }}
        </el-descriptions-item>
      </el-descriptions>
      <!-- 4 -->
      <!-- <div class="lrxx">
                <div class="gdTitle">
                    <div class="colors">

                    </div>
                    <div class="gdWord">
                        服务详情
                    </div>
                </div>
            </div>
            <el-timeline>
                <el-timeline-item type="primary">
                    <h4>入户签字</h4>
                    <el-card>
                        <p>工单号:G37032050054-188</p>
                        <p>服务人员亓艳红，于2023-04-27 09:51:54入户签入成功</p>
                    </el-card>
                </el-timeline-item>
                <el-timeline-item type="">
                    <h4>服务过程</h4>
                    <el-card>
                        <template v-for="(item, index) in  fwList ">
                            <h3 :key="index">
                                {{ item.title }}
                            </h3>
                            <div class="block" v-for="item2 in  item.list ">
                                <p class="demonstration">{{ item2.time }}</p>
                                <el-image style="width: 200px; height: 200px" :src="item2.srcImage"></el-image>
                            </div>
                        </template>

                    </el-card>
                </el-timeline-item>
                <el-timeline-item type="primary">
                    <h4>服务确认</h4>
                    <el-card style="display: flex;">
                        <div class="block" v-for="item2 in  nameOk ">
                            <p class="demonstration">{{ item2.title }}</p>
                            <el-image style="width: 400px; height: 200px" :src="item2.srcImage"></el-image>
                        </div>
                    </el-card>
                </el-timeline-item>
                <el-timeline-item type="primary">
                    <h4>工单签出</h4>
                    <el-card>
                        <p>亓艳红于2023-06-12 14:19:04发起补录申请，待管理人员审批</p>
                        <p>申请补录工单签出时间：2023-04-27 14:18:00</p>
                        <p>工单未现场签出原因：手机没电</p>
                    </el-card>
                </el-timeline-item>
            </el-timeline> -->
      <!-- 流程 -->
    </Drawer>
    <!-- 统计 -->
    <Drawer :title="title2" ref="drawerIshow2" :drawerSize="drawerSize">
      <el-cascader
        v-model="query.qy"
        :options="options"
        placeholder="请选择区域"
      ></el-cascader>
      <!-- 1 -->
      <h1>TOP10</h1>

      <el-select v-model="query.level" placeholder="请选择内容">
        <el-option key="服务商" label="服务商" value="服务商"> </el-option>
        <el-option key="服务项目" label="服务项目" value="服务项目"></el-option>
      </el-select>
      <div style="height: 15rem">
        <columnar></columnar>
      </div>

      <!-- 2 -->
      <h1>区域居家组织数量统计</h1>
      <div style="height: 15rem">
        <columnar></columnar>
      </div>
      <!-- 3 -->
      <h1>区域工单统计</h1>
      <div style="height: 15rem">
        <columnar></columnar>
      </div>

      <!-- 4 -->
      <div style="width: 100%; height: 15rem; display: flex">
        <div style="width: 50%">
          <h1>服务工单统计</h1>
          <div style="height: 15rem">
            <lines></lines>
          </div>
        </div>
        <div style="width: 50%">
          <h1>老人性别占比</h1>
          <div style="height: 15rem">
            <pie1 :dataList="data"></pie1>
          </div>
        </div>
      </div>
    </Drawer>
    <!-- 驳回 -->
    <Drawer
      :title="title3"
      ref="drawerIshow3"
      :buttonIsShow="true"
      :submitFormName="submitFormName2"
    >
      <el-input type="textarea" :rows="9" placeholder="请输入驳回原因">
      </el-input>
    </Drawer>
    <!-- 反常数据 -->
    <Drawer
      :title="title4"
      ref="drawerIshow4"
      :submitFormName="submitFormName2"
      :drawerSize="drawerSize2"
    >
      <anomalous></anomalous>
    </Drawer>
  </div>
</template>

<script>
import { hltableZ, Drawer } from "@/components";
import columnar from "./components/echarts/columnar.vue";
import pie1 from "./components/echarts/pie1.vue";
import lines from "./components/echarts/line.vue";
import anomalous from "./home/anomalous.vue";
export default {
  name: "jjfwgd",
  components: { hltableZ, Drawer, columnar, pie1, lines, anomalous },
  data() {
    return {
      drawerSize2: "75%",
      videoUrl: "",
      autoplay: true, // 自动播放
      controls: "controls", //操作
      controlslist: "nodownload   noplaybackrate",
      query: {
        name: "",
        name2: "",
        bh: "",
        jclb: "",
        kazt: "",
        qu: "",
        gdlx: "",
        fwxm: "",
      },
      //   表格
      widthClick: "180",
      table: {
        staticData: [],
        columnEdit: false,
        column: [
          {
            label: "工单号",
            prop: "a",
            checked: true,
            width: "150",
          },
          {
            label: "服务对象",
            prop: "b",
            checked: true,
            width: "200",
          },
          // {
          //   label: "户籍[城镇/农村]",
          //   prop: "c",
          //   checked: true,
          //   width: "200",
          // },
          {
            label: "评估等级",
            prop: "d",
            checked: true,
            width: "200",
          },
          {
            label: "享受标准",
            prop: "e",
            checked: true,
            width: "200",
          },
          {
            label: "身份证号",
            prop: "f",
            checked: true,
            width: "200",
          },
          {
            label: "联系电话 ",
            prop: "g",
            checked: true,
            width: "200",
          },
          {
            label: "行政区划",
            prop: "h",
            checked: true,
            width: "200",
          },
          {
            label: "地址",
            prop: "i",
            checked: true,
            width: "200",
          },
          {
            label: "服务组织",
            prop: "j",
            checked: true,
            width: "200",
          },
          {
            label: "服务人员",
            prop: "k",
            checked: true,
            width: "200",
          },
          {
            label: "身份证号",
            prop: "l",
            checked: true,
            width: "200",
          },
          {
            label: "签入距离(米)",
            prop: "m",
            checked: true,
            width: "200",
          },
          {
            label: "签入时间 ",
            prop: "n",
            checked: true,
            width: "200",
          },
          {
            label: "签出时间 ",
            prop: "o",
            checked: true,
            width: "200",
          },
          {
            label: "服务时长",
            prop: "p",
            checked: true,
            width: "200",
          },
          {
            label: "服务包",
            prop: "q",
            checked: true,
            width: "200",
          },
          {
            label: "服务大类",
            prop: "r",
            checked: true,
            width: "200",
          },
          {
            label: "服务内容",
            prop: "s",
            checked: true,
            width: "200",
          },
          {
            label: "服务产值(元)",
            prop: "t",
            checked: true,
            width: "200",
          },
          {
            label: "补贴金额(元)",
            prop: "u",
            checked: true,
            width: "200",
          },
          {
            label: "实际支付(元)",
            prop: "v",
            checked: true,
            width: "200",
          },
          {
            label: "签入坐标校验",
            prop: "w",
            checked: true,
            width: "200",
          },
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
      },
      TableDataSource: [
        {
          1: "ST20221209183542616",
          2: "王德非",
          3: "13699858955",
          4: "110101191903075356",
          5: "安徽,六安,金安,中市",
          6: "舒城县康泰养老服务中心",
          7: "民政工单",
          8: "评价完成",
          9: "助餐服务",
          10: "早餐糕点",
          11: "80",
          12: "25.89",
          13: "余额",
          14: "王护士",
          15: "13222222222",
          16: "2023-06-30 18:37:06",
          17: "2023-06-30 21:09:27",
          18: "2023-06-30 19:59:58",
        },
      ],
      options: [
        {
          value: "zhinan",
          label: "闻韶街道",
          children: [
            {
              value: "yizhi",
              label: "南王镇",
            },
            {
              value: "fankui",
              label: "敬仲镇",
            },
            {
              value: "xiaolv",
              label: "梧台镇",
            },
            {
              value: "kekong",
              label: "朱台镇",
            },
          ],
        },
      ],
      rulesAdd: [],
      descriptionsList: [
        {
          label: "老人姓名",
          name: "王德非",
        },
        {
          label: "老人类别",
          name: "-",
        },
        {
          label: "身份证",
          name: "110101191903075356",
        },
        {
          label: "联系电话",
          name: "13699858955",
        },
        {
          label: "服务地址",
          name: "安徽,六安,金安,中市",
        },
      ],
      descriptionsList2: [
        {
          label: "工单编号",
          name: "ST202212091835426160011",
        },
        {
          label: "工单类型",
          name: "民政工单",
        },
        {
          label: "工单状态",
          name: "评价完成",
        },
        {
          label: "服务大类",
          name: "助餐服务",
        },
        {
          label: "服务项目",
          name: "早餐糕点",
        },
        {
          label: "服务单价(元)",
          name: "3.89",
        },
        {
          label: "服务总价(元)",
          name: "25.89",
        },
        {
          label: "购买数量(个)",
          name: "5",
        },
        {
          label: "实付金额(元)",
          name: "25.89",
        },
        {
          label: "支付方式",
          name: "余额",
        },
        {
          label: "工单来源",
          name: "APP",
        },
      ],
      descriptionsList3: [
        {
          label: "服务机构",
          name: "舒城县康泰养老服务中心",
        },
        {
          label: "服务人员",
          name: "王护士",
        },
        {
          label: "联系电话",
          name: "13222222222",
        },
        {
          label: "下单时间",
          name: "2023-06-30 18:37:06",
        },
        {
          label: "预约时间",
          name: "2023-06-30 18:37:06",
        },
        {
          label: "实付时间",
          name: "2023-06-30 13:24:50",
        },
        {
          label: "派单时间",
          name: "2023-06-30 18:37:06",
        },
        {
          label: "接单时间",
          name: "2023-06-30 18:37:06",
        },
        {
          label: "服务开始时间",
          name: "2023-06-30 18:37:06",
        },
        {
          label: "服务开始地点",
          name: "安徽,六安,金安,中市",
        },
        {
          label: "服务完成时间",
          name: "2023-06-30 21:09:27",
        },
        {
          label: "服务时长(分)",
          name: "80",
        },
        {
          label: "服务完成地点",
          name: "安徽,六安,金安,中市",
        },
        {
          label: "工单备注",
          name: "-",
        },
      ],
      srcImage:
        "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
      title: "查看详情",
      searchIsShow: false,
      // 统计
      title2: "统计",
      drawerSize: "100%",
      data: [
        {
          value: 12,
          name: "男性",
        },
        {
          value: 50,
          name: "女性",
        },
      ],
      title3: "驳回原因",
      submitFormName2: "确定",
      activities: [
        {
          title: "入户签字",
          timestamp: "2018-04-12 20:46",
          type: "primary",
        },
        {
          title: "服务过程",
          timestamp: "2018-04-03 20:46",
        },
        {
          title: "服务确认",
          timestamp: "2018-04-03 20:46",
        },
        {
          title: "工单签出",
          timestamp: "2018-04-03 20:46",
        },
      ],
      title4: "反常数据",
      fwList: [
        {
          title: "助洁",

          list: [
            {
              srcImage:
                "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
              time: "2022-12-12",
            },
          ],
        },
      ],
      nameOk: [
        {
          title: "服务对象/委托人签名",
          srcImage:
            "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
        },
        {
          title: "服务人员签名",
          srcImage:
            "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
        },
      ],
    };
  },

  mounted() {
    this.dataRefresh();
  },

  methods: {
    //   查询
    dataRefresh() {
      this.table.staticData = [
        {
          a: "2311022245970105",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "324.87",
          n: "2023-06-05T11:02:22",
          o: "2023-06-05T15:08:01",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "上门送餐",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2311453932521067",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "329.29",
          n: "2023-06-03T11:45:39",
          o: "2023-06-03T15:31:25",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "上门送餐",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2311274650044623",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "312.11",
          n: "2023-05-20T11:27:47",
          o: "2023-05-20T17:22:23",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "上门送餐",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2311411623972071",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "311.15",
          n: "2023-05-11T11:41:16",
          o: "2023-05-11T15:57:23",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "上门送餐",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2310382336039530",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "304.95",
          n: "2023-05-07T10:38:23",
          o: "2023-05-07T15:30:18",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "人文关怀",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2317201964283110",
          b: "孙孟尧",
          c: "",
          d: "中度失能",
          e: "",
          f: "37030519340918311X",
          g: "",
          h: "临淄区-稷下街道-陈家村村委会",
          i: "陈家村",
          j: "淄博市临淄区蓝丝带社会服务中心",
          k: "亓艳红",
          l: "370304197909050024",
          m: "",
          n: "2023-04-24T08:07:59",
          o: "2023-04-24T12:07:48",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "人文关怀",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2308075871414924",
          b: "孙孟尧",
          c: "",
          d: "中度失能",
          e: "",
          f: "37030519340918311X",
          g: "",
          h: "临淄区-稷下街道-陈家村村委会",
          i: "陈家村",
          j: "淄博市临淄区蓝丝带社会服务中心",
          k: "亓艳红",
          l: "370304197909050024",
          m: "2092.07",
          n: "2023-04-24T08:07:59",
          o: "2023-04-24T12:07:48",
          p: "240.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "人文关怀,协助更衣及指导,餐后清洁,清洁照料",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2310401837857000",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "318.19",
          n: "2023-04-18T10:40:18",
          o: "2023-04-18T22:30:45",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "清洁照料",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2311063842315543",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "318.67",
          n: "2023-04-09T11:06:38",
          o: "2023-04-09T14:18",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "人文关怀",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2315311544427081",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "316.65",
          n: "2023-04-01T15:31:15",
          o: "2023-04-01T17:41:56",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "清洁照料",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },

        {
          a: "2309445798952869",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "65.9",
          n: "2023-03-14T09:44:58",
          o: "2023-03-14T21:29:44",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "情绪疏导",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2312501260910612",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "283.5",
          n: "2023-03-12T12:50:13",
          o: "2023-03-12T22:21:23",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "清洁照料",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2308350319286115",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "118.14",
          n: "2023-03-08T08:35:03",
          o: "2023-03-08T16:15:13",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "情绪疏导",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2310044535071225",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "58.66",
          n: "2023-02-21T10:04:45",
          o: "2023-02-21T16:07:31",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "上门送餐",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2316424427754549",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "61.44",
          n: "2023-02-18T16:42:44",
          o: "2023-02-18T18:35:34",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "清洁照料",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2316533244772033",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "50.62",
          n: "2023-02-13T16:53:32",
          o: "2023-02-13T20:40:38",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "上门送餐",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2309580183864675",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "67.66",
          n: "2023-02-12T09:58:02",
          o: "2023-02-12T19:26:50",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "人文关怀",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2311501930276667",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "336.68",
          n: "2023-01-14T11:50:19",
          o: "2023-01-14T22:26:16",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "上门送餐",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2315191312474949",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "62.66",
          n: "2023-01-11T15:19:13",
          o: "2023-01-11T18:35",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "洗涤服务",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2215011620829493",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "54.35",
          n: "2022-12-27T15:01:16",
          o: "2022-12-27T19:24:21",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "心理支持",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2210542685908386",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "54.38",
          n: "2022-12-26T10:54:27",
          o: "2022-12-26T15:05:23",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "情绪疏导",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2215265091053350",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "61.75",
          n: "2022-12-21T15:26:51",
          o: "2022-12-21T18:03:15",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "洗涤服务",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2211365100792432",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "340.72",
          n: "2022-12-16T11:36:51",
          o: "2022-12-16T16:08:03",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "上门送餐",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2209083063551084",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "57.02",
          n: "2022-12-15T09:08:31",
          o: "2022-12-15T16:39:56",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "心理支持",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2211111758391130",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "335.02",
          n: "2022-12-12T11:11:18",
          o: "2022-12-12T21:25:25",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "清洁照料",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2211313617484762",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "58.65",
          n: "2022-12-11T11:31:36",
          o: "2022-12-11T20:26:07",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "人文关怀",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2217484722729559",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "29.73",
          n: "2022-12-05T17:48:47",
          o: "2022-12-05T21:59:29",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "餐后清洁",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2218405752390858",
          b: "李化义",
          c: "",
          d: "中度失能",
          e: "199.0",
          f: "370305194808291534",
          g: "15169366787",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "李惠芝",
          l: "370305197808161522",
          m: "170.46",
          n: "2022-11-29T18:40:58",
          o: "2022-11-29T20:23:58",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "居家清洁及消毒",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2210161787935414",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "71.64",
          n: "2022-11-29T10:16:18",
          o: "2022-11-29T12:25:04",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "清洁照料",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2208440553392041",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "56.21",
          n: "2022-11-28T08:44:06",
          o: "2022-11-28T13:25:04",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "人文关怀",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2208434640341630",
          b: "李化义",
          c: "",
          d: "中度失能",
          e: "199.0",
          f: "370305194808291534",
          g: "15169366787",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "李惠芝",
          l: "370305197808161522",
          m: "176.7",
          n: "2022-11-28T08:43:46",
          o: "2022-11-28T16:16:47",
          p: "180.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "餐后清洁,居家清洁及消毒",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2210404243275518",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "80.21",
          n: "2022-11-25T10:40:42",
          o: "2022-11-25T12:27:55",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "居家清洁及消毒",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2216513451829811",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "55.46",
          n: "2022-11-23T16:51:35",
          o: "2022-11-23T18:58:59",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "洗涤服务",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2210534770539147",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "321.47",
          n: "2022-11-18T10:53:48",
          o: "2022-11-18T17:41:56",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "上门送餐",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2215445151725824",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "59.37",
          n: "2022-11-17T15:44:52",
          o: "2022-11-17T19:11:10",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "情绪疏导",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2210293543369913",
          b: "李化义",
          c: "",
          d: "中度失能",
          e: "199.0",
          f: "370305194808291534",
          g: "15169366787",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "李惠芝",
          l: "370305197808161522",
          m: "249.82",
          n: "2022-11-17T10:29:35",
          o: "2022-11-17T15:35:53",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "陪同出行",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2210220782527596",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "316.29",
          n: "2022-11-15T10:22:08",
          o: "2022-11-15T15:50:08",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "人文关怀",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2210030728751070",
          b: "李化义",
          c: "",
          d: "中度失能",
          e: "199.0",
          f: "370305194808291534",
          g: "15169366787",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "李惠芝",
          l: "370305197808161522",
          m: "195.45",
          n: "2022-11-11T10:03:07",
          o: "2022-11-11T15:46:13",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "居家清洁及消毒",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2210383209727239",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "33.78",
          n: "2022-11-08T10:38:32",
          o: "2022-11-08T17:12:29",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "清洁照料",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2210585892774810",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "52.24",
          n: "2022-11-07T10:58:59",
          o: "2022-11-07T12:34:22",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "人文关怀",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2214521541886650",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "54.03",
          n: "2022-11-01T14:52:15",
          o: "2022-11-01T18:55:05",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "心理支持",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2211515551167817",
          b: "李化义",
          c: "",
          d: "中度失能",
          e: "199.0",
          f: "370305194808291534",
          g: "15169366787",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "李惠芝",
          l: "370305197808161522",
          m: "195.51",
          n: "2022-10-29T11:51:56",
          o: "2022-10-29T20:08:13",
          p: "180.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "餐后清洁,居家清洁及消毒",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2210100235694098",
          b: "李化义",
          c: "",
          d: "中度失能",
          e: "199.0",
          f: "370305194808291534",
          g: "15169366787",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "李惠芝",
          l: "370305197808161522",
          m: "242.08",
          n: "2022-10-28T10:10:02",
          o: "2022-10-28T13:32:17",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "陪同出行",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2210260924119091",
          b: "李化义",
          c: "",
          d: "中度失能",
          e: "199.0",
          f: "370305194808291534",
          g: "15169366787",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "李惠芝",
          l: "370305197808161522",
          m: "146.38",
          n: "2022-10-26T10:26:09",
          o: "2022-10-26T13:08:15",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "陪同出行",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2214370918230982",
          b: "李化义",
          c: "",
          d: "中度失能",
          e: "199.0",
          f: "370305194808291534",
          g: "15169366787",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "李惠芝",
          l: "370305197808161522",
          m: "176.34",
          n: "2022-10-25T14:37:09",
          o: "2022-10-25T16:54:17",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "居家清洁及消毒",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2209143626970856",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "314.21",
          n: "2022-10-21T09:14:36",
          o: "2022-10-21T20:51:05",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "情绪疏导",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2217591745498314",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "62.2",
          n: "2022-10-19T17:59:17",
          o: "2022-10-19T20:55:07",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "上门送餐",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2207194690902604",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "54.19",
          n: "2022-10-17T07:19:47",
          o: "2022-10-17T09:40:42",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "心理支持",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2210505834895311",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "319.73",
          n: "2022-10-15T10:50:58",
          o: "2022-10-15T20:53:01",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "餐后清洁",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2209413497724275",
          b: "李化义",
          c: "",
          d: "中度失能",
          e: "199.0",
          f: "370305194808291534",
          g: "15169366787",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "李惠芝",
          l: "370305197808161522",
          m: "201.93",
          n: "2022-10-15T09:41:35",
          o: "2022-10-15T12:38:45",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "陪同出行",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2214560796062948",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "73.0",
          n: "2022-10-14T14:56:08",
          o: "2022-10-14T16:37:25",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "清洁照料",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2212205426131501",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "66.37",
          n: "2022-10-13T12:20:54",
          o: "2022-10-13T16:51:26",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "情绪疏导",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2209590164878978",
          b: "李化义",
          c: "",
          d: "中度失能",
          e: "199.0",
          f: "370305194808291534",
          g: "15169366787",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "李惠芝",
          l: "370305197808161522",
          m: "195.98",
          n: "2022-10-13T09:59:02",
          o: "2022-10-13T11:38:02",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "陪同出行",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2213213820061289",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "57.21",
          n: "2022-10-10T13:21:38",
          o: "2022-10-10T15:50:19",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "心理支持",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2209580357372622",
          b: "李化义",
          c: "",
          d: "中度失能",
          e: "199.0",
          f: "370305194808291534",
          g: "15169366787",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "李惠芝",
          l: "370305197808161522",
          m: "139.49",
          n: "2022-10-10T09:58:04",
          o: "2022-10-10T15:00:25",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "居家清洁及消毒",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2209053126855414",
          b: "赵忠美",
          c: "",
          d: "重度失能",
          e: "",
          f: "370305195103212124",
          g: "13287019391",
          h: "临淄区-金岭镇-金南社区居委会",
          i: "金岭回族镇金南社区",
          j: "临淄区义家人社会工作服务中心",
          k: "孙淑芳",
          l: "370305197503140026",
          m: "743.63",
          n: "2022-10-09T09:05:31",
          o: "2022-10-09T13:39:41",
          p: "274.1分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "预防保健,生命体征监测,口服给药,康复护理及指导",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2209030349335100",
          b: "贾玉兰",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305193108272127",
          g: "",
          h: "临淄区-金岭镇-金岭三村委会",
          i: "金岭三村",
          j: "临淄区义家人社会工作服务中心",
          k: "韩英",
          l: "370305197002051826",
          m: "208.9",
          n: "2022-10-09T09:03:03",
          o: "2022-10-09T14:15:21",
          p: "270.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "康复护理及指导,生命体征监测,预防保健",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2209013583311351",
          b: "孙家富",
          c: "",
          d: "重度失能",
          e: "",
          f: "370305194112192116",
          g: "13173269615",
          h: "临淄区-金岭镇-金岭二村委会",
          i: "金岭回族镇金岭二村",
          j: "临淄区义家人社会工作服务中心",
          k: "郑萍",
          l: "370305198204024046",
          m: "407.24",
          n: "2022-10-09T09:01:36",
          o: "2022-10-09T13:50:28",
          p: "266.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "康复护理及指导,生命体征监测,预防保健",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2209292913832982",
          b: "孙家富",
          c: "",
          d: "重度失能",
          e: "",
          f: "370305194112192116",
          g: "13173269615",
          h: "临淄区-金岭镇-金岭二村委会",
          i: "金岭回族镇金岭二村",
          j: "临淄区义家人社会工作服务中心",
          k: "韩英",
          l: "370305197002051826",
          m: "1273.91",
          n: "2022-10-08T09:29:29",
          o: "2022-10-08T21:28:46",
          p: "450.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "心理支持,协助更衣及指导,人文关怀,居家清洁及消毒,危机干预,清洁照料",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2317201956218773",
          b: "孙家富",
          c: "",
          d: "重度失能",
          e: "",
          f: "370305194112192116",
          g: "13173269615",
          h: "临淄区-金岭镇-金岭二村委会",
          i: "金岭回族镇金岭二村",
          j: "临淄区义家人社会工作服务中心",
          k: "韩英",
          l: "370305197002051826",
          m: "",
          n: "2022-10-08T09:29:29",
          o: "2022-10-08T21:28:46",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "危机干预",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201938761228",
          b: "孙家富",
          c: "",
          d: "重度失能",
          e: "",
          f: "370305194112192116",
          g: "13173269615",
          h: "临淄区-金岭镇-金岭二村委会",
          i: "金岭回族镇金岭二村",
          j: "临淄区义家人社会工作服务中心",
          k: "韩英",
          l: "370305197002051826",
          m: "",
          n: "2022-10-08T09:29:29",
          o: "2022-10-08T21:28:46",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "心理支持",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201947299144",
          b: "孙家富",
          c: "",
          d: "重度失能",
          e: "",
          f: "370305194112192116",
          g: "13173269615",
          h: "临淄区-金岭镇-金岭二村委会",
          i: "金岭回族镇金岭二村",
          j: "临淄区义家人社会工作服务中心",
          k: "韩英",
          l: "370305197002051826",
          m: "",
          n: "2022-10-08T09:29:29",
          o: "2022-10-08T21:28:46",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "清洁照料",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201921427278",
          b: "赵家玲",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305195204122128",
          g: "15153317182",
          h: "临淄区-金岭镇-金南社区居委会",
          i: "金南社区居委会",
          j: "临淄区义家人社会工作服务中心",
          k: "郑萍",
          l: "370305198204024046",
          m: "",
          n: "2022-10-08T09:28:06",
          o: "2022-10-08T16:50:40",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "陪同出行",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201904344867",
          b: "赵家玲",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305195204122128",
          g: "15153317182",
          h: "临淄区-金岭镇-金南社区居委会",
          i: "金南社区居委会",
          j: "临淄区义家人社会工作服务中心",
          k: "郑萍",
          l: "370305198204024046",
          m: "",
          n: "2022-10-08T09:28:06",
          o: "2022-10-08T16:50:40",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "清洁照料",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201930084081",
          b: "赵家玲",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305195204122128",
          g: "15153317182",
          h: "临淄区-金岭镇-金南社区居委会",
          i: "金南社区居委会",
          j: "临淄区义家人社会工作服务中心",
          k: "郑萍",
          l: "370305198204024046",
          m: "",
          n: "2022-10-08T09:28:06",
          o: "2022-10-08T16:50:40",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "心理支持",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201912526844",
          b: "赵家玲",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305195204122128",
          g: "15153317182",
          h: "临淄区-金岭镇-金南社区居委会",
          i: "金南社区居委会",
          j: "临淄区义家人社会工作服务中心",
          k: "郑萍",
          l: "370305198204024046",
          m: "",
          n: "2022-10-08T09:28:06",
          o: "2022-10-08T16:50:40",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "洗涤服务",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2209280617122148",
          b: "赵家玲",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305195204122128",
          g: "15153317182",
          h: "临淄区-金岭镇-金南社区居委会",
          i: "金南社区居委会",
          j: "临淄区义家人社会工作服务中心",
          k: "郑萍",
          l: "370305198204024046",
          m: "947.01",
          n: "2022-10-08T09:28:06",
          o: "2022-10-08T16:50:40",
          p: "443.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "清洁照料,陪同出行,居家清洁及消毒,人文关怀,餐后清洁,危机干预,心理支持,洗涤服务",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2317201895937209",
          b: "赵忠美",
          c: "",
          d: "重度失能",
          e: "",
          f: "370305195103212124",
          g: "13287019391",
          h: "临淄区-金岭镇-金南社区居委会",
          i: "金岭回族镇金南社区",
          j: "临淄区义家人社会工作服务中心",
          k: "陈帅",
          l: "370305199012154721",
          m: "",
          n: "2022-10-08T09:26:23",
          o: "2022-10-08T16:49:40",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "居家清洁及消毒",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201887534146",
          b: "赵忠美",
          c: "",
          d: "重度失能",
          e: "",
          f: "370305195103212124",
          g: "13287019391",
          h: "临淄区-金岭镇-金南社区居委会",
          i: "金岭回族镇金南社区",
          j: "临淄区义家人社会工作服务中心",
          k: "陈帅",
          l: "370305199012154721",
          m: "",
          n: "2022-10-08T09:26:23",
          o: "2022-10-08T16:49:40",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "人文关怀",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201879387193",
          b: "赵忠美",
          c: "",
          d: "重度失能",
          e: "",
          f: "370305195103212124",
          g: "13287019391",
          h: "临淄区-金岭镇-金南社区居委会",
          i: "金岭回族镇金南社区",
          j: "临淄区义家人社会工作服务中心",
          k: "陈帅",
          l: "370305199012154721",
          m: "",
          n: "2022-10-08T09:26:23",
          o: "2022-10-08T16:49:40",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "情绪疏导",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2209262326061025",
          b: "赵忠美",
          c: "",
          d: "重度失能",
          e: "",
          f: "370305195103212124",
          g: "13287019391",
          h: "临淄区-金岭镇-金南社区居委会",
          i: "金岭回族镇金南社区",
          j: "临淄区义家人社会工作服务中心",
          k: "陈帅",
          l: "370305199012154721",
          m: "953.46",
          n: "2022-10-08T09:26:23",
          o: "2022-10-08T16:49:40",
          p: "443.3分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "情绪疏导,居家清洁及消毒,餐后清洁,危机干预,清洁照料,洗涤服务,心理支持,人文关怀",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2209254298671444",
          b: "贾玉兰",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305193108272127",
          g: "",
          h: "临淄区-金岭镇-金岭三村委会",
          i: "金岭三村",
          j: "临淄区义家人社会工作服务中心",
          k: "张春梅",
          l: "370305197404023465",
          m: "528.41",
          n: "2022-10-08T09:25:43",
          o: "2022-10-08T16:48:36",
          p: "386.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "心理支持,清洁照料,危机干预,居家清洁及消毒,人文关怀,情绪疏导",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2207485611432070",
          b: "李作远",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "37030519301214151X",
          g: "13973338362",
          h: "临淄区-金山镇-杨上村委会",
          i: "金山镇杨上村",
          j: "淄博市齐佳社会工作服务中心",
          k: "田海燕",
          l: "370305198207271528",
          m: "53.12",
          n: "2022-10-08T07:48:56",
          o: "2022-10-08T16:32:31",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "人文关怀",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2317201861699085",
          b: "马传富",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305193508102119",
          g: "13285330968",
          h: "临淄区-金岭镇-金南社区居委会",
          i: "金岭南路1117号",
          j: "临淄区义家人社会工作服务中心",
          k: "张春梅",
          l: "370305197404023465",
          m: "",
          n: "2022-10-07T15:00:23",
          o: "2022-10-07T20:10:44",
          p: "90.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "预防保健",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201870582270",
          b: "马传富",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305193508102119",
          g: "13285330968",
          h: "临淄区-金岭镇-金南社区居委会",
          i: "金岭南路1117号",
          j: "临淄区义家人社会工作服务中心",
          k: "张春梅",
          l: "370305197404023465",
          m: "",
          n: "2022-10-07T15:00:23",
          o: "2022-10-07T20:10:44",
          p: "90.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "康复护理及指导",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2215002287665679",
          b: "马传富",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305193508102119",
          g: "13285330968",
          h: "临淄区-金岭镇-金南社区居委会",
          i: "金岭南路1117号",
          j: "临淄区义家人社会工作服务中心",
          k: "张春梅",
          l: "370305197404023465",
          m: "872.89",
          n: "2022-10-07T15:00:23",
          o: "2022-10-07T20:10:44",
          p: "270.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "生命体征监测,预防保健,康复护理及指导",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2317201853269866",
          b: "马传富",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305193508102119",
          g: "13285330968",
          h: "临淄区-金岭镇-金南社区居委会",
          i: "金岭南路1117号",
          j: "临淄区义家人社会工作服务中心",
          k: "张春梅",
          l: "370305197404023465",
          m: "",
          n: "2022-10-07T15:00:23",
          o: "2022-10-07T20:10:44",
          p: "90.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "生命体征监测",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201713911667",
          b: "贺艾兰",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305194303232125",
          g: "15169305060",
          h: "临淄区-金岭镇-刘辛村委会",
          i: "刘辛村116号",
          j: "临淄区义家人社会工作服务中心",
          k: "张春梅",
          l: "370305197404023465",
          m: "",
          n: "2022-10-07T08:59:05",
          o: "2022-10-07T14:44:54",
          p: "90.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "生命体征监测",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2208590466659076",
          b: "贺艾兰",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305194303232125",
          g: "15169305060",
          h: "临淄区-金岭镇-刘辛村委会",
          i: "刘辛村116号",
          j: "临淄区义家人社会工作服务中心",
          k: "张春梅",
          l: "370305197404023465",
          m: "2078.1",
          n: "2022-10-07T08:59:05",
          o: "2022-10-07T14:44:54",
          p: "270.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "生命体征监测,预防保健,康复护理及指导",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2317201836084359",
          b: "贺艾兰",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305194303232125",
          g: "15169305060",
          h: "临淄区-金岭镇-刘辛村委会",
          i: "刘辛村116号",
          j: "临淄区义家人社会工作服务中心",
          k: "张春梅",
          l: "370305197404023465",
          m: "",
          n: "2022-10-07T08:59:05",
          o: "2022-10-07T14:44:54",
          p: "90.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "预防保健",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201844695112",
          b: "贺艾兰",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305194303232125",
          g: "15169305060",
          h: "临淄区-金岭镇-刘辛村委会",
          i: "刘辛村116号",
          j: "临淄区义家人社会工作服务中心",
          k: "张春梅",
          l: "370305197404023465",
          m: "",
          n: "2022-10-07T08:59:05",
          o: "2022-10-07T14:44:54",
          p: "90.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "康复护理及指导",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201667179346",
          b: "姚尊孔",
          c: "",
          d: "中度失能",
          e: "199.0",
          f: "370305194605052111",
          g: "13589555135",
          h: "临淄区-金岭镇-艾庄村委会",
          i: "金岭艾庄村299号",
          j: "临淄区义家人社会工作服务中心",
          k: "陈帅",
          l: "370305199012154721",
          m: "",
          n: "2022-10-07T08:58:39",
          o: "2022-10-07T16:27:39",
          p: "90.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "康复护理及指导",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2208583915306485",
          b: "姚尊孔",
          c: "",
          d: "中度失能",
          e: "199.0",
          f: "370305194605052111",
          g: "13589555135",
          h: "临淄区-金岭镇-艾庄村委会",
          i: "金岭艾庄村299号",
          j: "临淄区义家人社会工作服务中心",
          k: "陈帅",
          l: "370305199012154721",
          m: "2355.3",
          n: "2022-10-07T08:58:39",
          o: "2022-10-07T16:27:39",
          p: "270.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "康复护理及指导,预防保健,生命体征监测",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2317201686794748",
          b: "姚尊孔",
          c: "",
          d: "中度失能",
          e: "199.0",
          f: "370305194605052111",
          g: "13589555135",
          h: "临淄区-金岭镇-艾庄村委会",
          i: "金岭艾庄村299号",
          j: "临淄区义家人社会工作服务中心",
          k: "陈帅",
          l: "370305199012154721",
          m: "",
          n: "2022-10-07T08:58:39",
          o: "2022-10-07T16:27:39",
          p: "90.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "生命体征监测",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201675580627",
          b: "姚尊孔",
          c: "",
          d: "中度失能",
          e: "199.0",
          f: "370305194605052111",
          g: "13589555135",
          h: "临淄区-金岭镇-艾庄村委会",
          i: "金岭艾庄村299号",
          j: "临淄区义家人社会工作服务中心",
          k: "陈帅",
          l: "370305199012154721",
          m: "",
          n: "2022-10-07T08:58:39",
          o: "2022-10-07T16:27:39",
          p: "90.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "预防保健",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2208581330270337",
          b: "李素珍",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305192602162121",
          g: "",
          h: "临淄区-金岭镇-披甲村委会",
          i: "披甲村",
          j: "临淄区义家人社会工作服务中心",
          k: "郑萍",
          l: "370305198204024046",
          m: "2230.08",
          n: "2022-10-07T08:58:13",
          o: "2022-10-07T14:59:03",
          p: "341.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "生命体征监测,预防保健,口服给药,康复护理及指导",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2317201648227452",
          b: "李素珍",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305192602162121",
          g: "",
          h: "临淄区-金岭镇-披甲村委会",
          i: "披甲村",
          j: "临淄区义家人社会工作服务中心",
          k: "郑萍",
          l: "370305198204024046",
          m: "",
          n: "2022-10-07T08:58:13",
          o: "2022-10-07T14:59:03",
          p: "90.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "口服给药",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201639411790",
          b: "李素珍",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305192602162121",
          g: "",
          h: "临淄区-金岭镇-披甲村委会",
          i: "披甲村",
          j: "临淄区义家人社会工作服务中心",
          k: "郑萍",
          l: "370305198204024046",
          m: "",
          n: "2022-10-07T08:58:13",
          o: "2022-10-07T14:59:03",
          p: "90.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "预防保健",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201630150847",
          b: "李素珍",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305192602162121",
          g: "",
          h: "临淄区-金岭镇-披甲村委会",
          i: "披甲村",
          j: "临淄区义家人社会工作服务中心",
          k: "郑萍",
          l: "370305198204024046",
          m: "",
          n: "2022-10-07T08:58:13",
          o: "2022-10-07T14:59:03",
          p: "90.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "生命体征监测",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201658182692",
          b: "李素珍",
          c: "",
          d: "重度失能",
          e: "199.0",
          f: "370305192602162121",
          g: "",
          h: "临淄区-金岭镇-披甲村委会",
          i: "披甲村",
          j: "临淄区义家人社会工作服务中心",
          k: "郑萍",
          l: "370305198204024046",
          m: "",
          n: "2022-10-07T08:58:13",
          o: "2022-10-07T14:59:03",
          p: "90.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "康复护理及指导",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2208572980391989",
          b: "宋月英",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "370305194401072129",
          g: "",
          h: "临淄区-金岭镇-披甲村委会",
          i: "披甲村",
          j: "临淄区义家人社会工作服务中心",
          k: "孙淑芳",
          l: "370305197503140026",
          m: "2133.11",
          n: "2022-10-07T08:57:30",
          o: "2022-10-07T16:16:34",
          p: "270.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "生命体征监测,康复护理及指导,预防保健",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2317201622222121",
          b: "宋月英",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "370305194401072129",
          g: "",
          h: "临淄区-金岭镇-披甲村委会",
          i: "披甲村",
          j: "临淄区义家人社会工作服务中心",
          k: "韩英",
          l: "370305197002051826",
          m: "",
          n: "2022-10-07T08:57:03",
          o: "2022-10-07T15:13:37",
          p: "90.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "餐后清洁",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201603363772",
          b: "宋月英",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "370305194401072129",
          g: "",
          h: "临淄区-金岭镇-披甲村委会",
          i: "披甲村",
          j: "临淄区义家人社会工作服务中心",
          k: "韩英",
          l: "370305197002051826",
          m: "",
          n: "2022-10-07T08:57:03",
          o: "2022-10-07T15:13:37",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "心理支持",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201614051246",
          b: "宋月英",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "370305194401072129",
          g: "",
          h: "临淄区-金岭镇-披甲村委会",
          i: "披甲村",
          j: "临淄区义家人社会工作服务中心",
          k: "韩英",
          l: "370305197002051826",
          m: "",
          n: "2022-10-07T08:57:03",
          o: "2022-10-07T15:13:37",
          p: "60.0分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "危机干预",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2208570273924254",
          b: "宋月英",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "370305194401072129",
          g: "",
          h: "临淄区-金岭镇-披甲村委会",
          i: "披甲村",
          j: "临淄区义家人社会工作服务中心",
          k: "韩英",
          l: "370305197002051826",
          m: "2133.11",
          n: "2022-10-07T08:57:03",
          o: "2022-10-07T15:13:37",
          p: "376.5分钟",
          q: "基本照料(需要医疗服务）",
          r: "基本照料",
          s: "心理支持,人文关怀,居家清洁及消毒,情绪疏导,清洁照料,餐后清洁,危机干预",
          t: "0.00",
          u: "0.00",
          v: "0.0",
          w: "在范围内",
        },
        {
          a: "2317201593843222",
          b: "王朋书",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "370305195605012130",
          g: "18653325716",
          h: "临淄区-金岭镇-金岭一村委会",
          i: "金岭一村",
          j: "临淄区义家人社会工作服务中心",
          k: "韩英",
          l: "370305197002051826",
          m: "",
          n: "2022-10-06T15:48:36",
          o: "2022-10-06T19:20:55",
          p: "90.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "生命体征监测",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201573613904",
          b: "王朋书",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "370305195605012130",
          g: "18653325716",
          h: "临淄区-金岭镇-金岭一村委会",
          i: "金岭一村",
          j: "临淄区义家人社会工作服务中心",
          k: "韩英",
          l: "370305197002051826",
          m: "",
          n: "2022-10-06T15:48:36",
          o: "2022-10-06T19:20:55",
          p: "90.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "康复护理及指导",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
        {
          a: "2317201585290892",
          b: "王朋书",
          c: "",
          d: "轻度失能",
          e: "199.0",
          f: "370305195605012130",
          g: "18653325716",
          h: "临淄区-金岭镇-金岭一村委会",
          i: "金岭一村",
          j: "临淄区义家人社会工作服务中心",
          k: "韩英",
          l: "370305197002051826",
          m: "",
          n: "2022-10-06T15:48:36",
          o: "2022-10-06T19:20:55",
          p: "90.0分钟",
          q: "医疗服务",
          r: "医疗服务",
          s: "预防保健",
          t: "0.00",
          u: "",
          v: "",
          w: "",
        },
      ];
      this.table.refreshFlag = new Date().getTime().toString();
    },
    reset() {
      const th = this;
      Object.keys(th.query).forEach(function (key) {
        if (key == "value") {
          th.query.value = "1";
        } else {
          th.query[key] = "";
        }
      });
      this.dataRefresh();
    },
    search() {
      this.$refs.drawerIshow.drawer = true;
    },
    more() {
      this.searchIsShow = !this.searchIsShow;
    },
    // 统计
    statistics() {
      this.$refs.drawerIshow2.drawer = true;
    },
    elDropdown(e) {
      if (e == "a") {
        this.$refs.drawerIshow3.drawer = true;
      }
    },
    // 反常数据
    Anomalous() {
      this.$refs.drawerIshow4.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";

::v-deep .el-table th,
::v-deep .el-table td {
  text-align: center !important;
}

.data {
  display: flex;
}

.lrxx {
  margin: 0.7rem;

  .gdTitle {
    display: flex;
    align-items: center;

    .colors {
      width: 5px;
      height: 14px;
      background-color: #2362fb;
      margin-right: 0.7rem;
    }

    .gdWord {
      font-weight: 900;
    }
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  margin-left: 9px;
  font-size: 12px;
  font-weight: 500;
}

.el-icon-arrow-down {
  font-size: 12px;
  // transform: scale(2);
}
</style>
