<template>
  <!-- 海浪警报 -->
  <div class="hl-full-container flexVertical">
    <div class="search">
      <el-form :model="query" ref="query" label-width="80px" inline>
        <el-form-item label="工单编号">
          <el-input
            v-model="query.code"
            placeholder="请输入工单编号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
          <el-button
            size="small"
            icon="el-icon-refresh-left"
            @click="reset('Form')"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 中间 -->
    <div class="toolbar">
      <el-button
        type="success"
        plain
        size="mini"
        icon="el-icon-edit"
        @click="statistics"
        >统计</el-button
      >
      <el-button
        type="danger"
        plain
        size="mini"
        icon="el-icon-folder-remove"
        @click="Anomalous"
        >反常数据</el-button
      >
    </div>
    <hltableZ
      :columnEdit="table.columnEdit"
      :tableColumn="table.column"
      :apiurl="table.apiurl"
      :isPage="false"
      :onlyShowTotal="true"
      :staticData="table.staticData"
      :refreshFlag="table.refreshFlag"
      :selectedRows="table.selectedRows"
      :isChecked="table.isChecked"
      :widthClick="widthClick"
      :formSearch="query"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-button
          icon="el-icon-view"
          type="text"
          size="small"
          @click="search(selected.singlerow)"
          >查看</el-button
        >
        <el-dropdown @command="elDropdown($event, selected.singlerow)">
          <span class="el-dropdown-link">
            工单处理<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a">工单驳回</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </hltableZ>

    <!-- 弹窗 -->
    <Drawer :title="title" ref="drawerIshow">
      <el-timeline-item type="">
        <h4>服务过程</h4>
        <el-card>
          <template v-for="(item, index) in fwList">
            <h3>
              {{ item.serviceType }}
            </h3>
            <div class="block" v-for="item2 in item.fileStoreEntity">
              <p class="demonstration">
                {{ $timestampTostr(item2.createTime) }}
              </p>
              <el-image
                style="width: 200px; height: 200px"
                :src="item2.filePath"
              ></el-image>
            </div>
          </template>
        </el-card>
      </el-timeline-item>
      <el-timeline-item type="primary">
        <h4>服务确认</h4>
        <el-card style="display: flex">
          <div class="block">
            <p class="demonstration">服务对象/委托人签名</p>
            <el-image
              style="width: 400px; height: 200px"
              v-for="item in consignors"
              :src="item.filePath"
            ></el-image>

            <p class="demonstration">服务人员签名</p>
            <el-image
              style="width: 400px; height: 200px"
              v-for="item in hashslingers"
              :src="item.filePath"
            ></el-image>
          </div>
        </el-card>
      </el-timeline-item>
      <el-timeline-item type="primary">
        <h4>工单签出</h4>
        <el-card v-if="gdqc.backTime">
          <p>
            {{ gdqc.fullName }}于{{
              $timestampTostr(gdqc.backTime)
            }}发起补录申请，待管理人员审批
          </p>
          <p>申请补录工单签出时间：{{ $timestampTostr(gdqc.signOffTime) }}</p>
          <p>工单未现场签出原因：{{ gdqc.cause }}</p>
        </el-card>
        <el-card v-else>
          <p>服务于{{ $timestampTostr(gdqc.signOffTime) }}完成签出</p>
          <p>服务人员移动端现场确认</p>
          <p>总服务时长：{{ gdqc.hour }}</p>
        </el-card>
      </el-timeline-item>
      <!-- 流程 -->
    </Drawer>
    <!-- 统计 -->
    <Drawer :title="title2" ref="drawerIshow2" :drawerSize="drawerSize">
      <el-cascader
        v-model="query.qy"
        :options="options"
        placeholder="请选择区域"
      ></el-cascader>
      <!-- 1 -->
      <h1>TOP10</h1>

      <el-select v-model="query.level" placeholder="请选择内容">
        <el-option key="服务商" label="服务商" value="服务商"> </el-option>
        <el-option key="服务项目" label="服务项目" value="服务项目"></el-option>
      </el-select>
      <div style="height: 15rem">
        <columnar></columnar>
      </div>

      <!-- 2 -->
      <h1>区域居家组织数量统计</h1>
      <div style="height: 15rem">
        <columnar></columnar>
      </div>
      <!-- 3 -->
      <h1>区域工单统计</h1>
      <div style="height: 15rem">
        <columnar></columnar>
      </div>

      <!-- 4 -->
      <div style="width: 100%; height: 15rem; display: flex">
        <div style="width: 50%">
          <h1>服务工单统计</h1>
          <div style="height: 15rem">
            <lines></lines>
          </div>
        </div>
        <div style="width: 50%">
          <h1>老人性别占比</h1>
          <div style="height: 15rem">
            <pie1 :dataList="data"></pie1>
          </div>
        </div>
      </div>
    </Drawer>
    <!-- 驳回 -->
    <Drawer
      :title="title3"
      ref="drawerIshow3"
      :buttonIsShow="true"
      :submitFormName="submitFormName2"
      @submitForm="submitForm"
      @resetForm="resetForm"
    >
      <el-input
        v-model="query.audit"
        type="textarea"
        :rows="9"
        placeholder="请输入驳回原因"
      >
      </el-input>
    </Drawer>
    <!-- 反常数据 -->
    <Drawer
      :title="title4"
      ref="drawerIshow4"
      :submitFormName="submitFormName2"
      :drawerSize="drawerSize2"
    >
      <anomalous @submitFormreach="submitFormreach"> </anomalous>
    </Drawer>
  </div>
</template>

<script>
import { hltableZ, Drawer } from "@/components";
import columnar from "./components/echarts/columnar.vue";
import pie1 from "./components/echarts/pie1.vue";
import lines from "./components/echarts/line.vue";
import anomalous from "./home/anomalous.vue";
import jjfwgdData from './fakeData'
export default {
  name: "jjfwgd",
  components: { hltableZ, Drawer, columnar, pie1, lines, anomalous },
  data() {
    return {
      title: "详情",
      drawerSize2: "75%",
      videoUrl: "",
      autoplay: true, // 自动播放
      controls: "controls", //操作
      query: {
        code: "",
        status: "4",
        organName: "",
        type: "",
        serviceStartTime: "",
        serviceEndTime: "",
        jclb: "",
        areaName: "",
        audit: "",
        id: "",
      },
      //   表格
      widthClick: "180",
      table: {
        staticData: [],
        columnEdit: false,
        layout:"total",
        column: [
          {
            label: "工单编号",
            prop: "a",
            checked: true,
            width: "200",
          },
          {
            label: "工单状态",
            prop: "b",
            checked: true,
            width: "80",
          },
          {
            label: "年份",
            prop: "c",
            checked: true,
            width: "80",
          },
          {
            label: "是否上门服务",
            prop: "d",
            checked: true,
            width: "80",
          },
          {
            label: "工单总金额",
            prop: "e",
            checked: true,
            width: "120",
          },
          {
            label: "民政补助金额",
            prop: "f",
            checked: true,
            width: "120",
          },
          {
            label: "镇街补助金额",
            prop: "g",
            checked: true,
            width: "120",
          },
          {
            label: "自费金额",
            prop: "h",
            checked: true,
            width: "120",
          },
          {
            label: "现金金额",
            prop: "i",
            checked: true,
            width: "120",
          },
          {
            label: "服务时长(h)",
            prop: "j",
            checked: true,
            width: "100",
          },
          {
            label: "要求服务地址",
            prop: "k",
            checked: true,
            width: "200",
          },
          {
            label: "客户名称快照",
            prop: "l",
            checked: true,
            width: "100",
          },
          {
            label: "客户电话快照",
            prop: "m",
            checked: true,
            width: "200",
          },
          {
            label: "服务站名称快照",
            prop: "n",
            checked: true,
            width: "200",
          },
          {
            label: "运营公司名称快照",
            prop: "o",
            checked: true,
            width: "200",
          },
          {
            label: "服务大类名称",
            prop: "p",
            checked: true,
            width: "200",
          },
          {
            label: "服务团队名称",
            prop: "q",
            checked: true,
            width: "200",
          },
          {
            label: "是否市场化工单",
            prop: "r",
            checked: true,
            width: "200",
          },
          {
            label: "是否养老床位工单",
            prop: "s",
            checked: true,
            width: "200",
          },
          {
            label: "创建人",
            prop: "t",
            checked: true,
            width: "200",
          },
          {
            label: "备注信息",
            prop: "u",
            checked: true,
            width: "200",
          },
          
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
      },
      options: [],
      rulesAdd: [],

      searchIsShow: false,
      // 统计
      title2: "统计",
      drawerSize: "100%",
      data: [
        {
          value: 12,
          name: "男性",
        },
        {
          value: 50,
          name: "女性",
        },
      ],
      title3: "驳回原因",
      submitFormName2: "确定",
      title4: "反常数据",
      fwList: [],
      consignors: [],
      hashslingers: [],
      gdqc: {},
      optionProps: {
        value: "pcode",
        label: "name",
        children: "children",
      },
    };
  },

  mounted() {
    this.dataRefresh();
  },

  methods: {
    timeFormatter(row, column, cellValue) {
      return cellValue ? this.$timestampTostr(cellValue) : "";
    },
    getDataTree() {
      this.$store
        .dispatch("area/getDataTree", {
          areaCode: this.$store.getters.user.areaCode,
        })
        .then((res) => {
          this.options = res.result;
        });
    },
    timechange(e) {
      this.serviceEndTime = e[0];
      this.startOrderTime = e[1];
    },
    //   查询
    dataRefresh() {
      this.table.staticData = jjfwgdData
      this.table.refreshFlag = new Date().getTime().toString();
    },
    reset() {
      const th = this;
      Object.keys(th.query).forEach(function (key) {
        if (key == "value") {
          th.query.value = "1";
        } else {
          th.query[key] = "";
        }
      });
      this.dataRefresh();
    },
    async derive() {
      const token = await this.$store.dispatch("user/getToken");
      this.$axios({
        method: "post",
        url: "/api/orderService/doDownLoad",
        data: this.query,
        headers: {
          token: token,
          platform: "web",
        },
        responseType: "blob",
      })
        .then((response) => {
          if (response.headers["content-disposition"]) {
            const content = response.data;
            const blob = new Blob([content]); //构造一个blob对象来处理数据

            // fileName必用这种方式进行解析，否则乱码
            // Server.UrlEncode(filename);

            let contentDisposition = response.headers["content-disposition"];
            // fileName必用这种方式进行解析，否则乱码
            let fileName = window.decodeURI(
              contentDisposition.substring(contentDisposition.indexOf("=") + 1)
            );
            //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
            if ("download" in document.createElement("a")) {
              const link = document.createElement("a"); //创建a标签
              link.download = fileName; //a标签添加属性
              link.style.display = "none";
              link.href = URL.createObjectURL(blob);
              document.body.appendChild(link);
              link.click(); //执行下载
              URL.revokeObjectURL(link.href); //释放url
              document.body.removeChild(link); //释放标签
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    search(e) {
      this.$refs.drawerIshow.drawer = true;
      this.fwList = e.serviceContent;
      this.consignors = e.consignors;
      this.hashslingers = e.hashslingers;
      this.gdqc = e;
      console.log(e);
    },
    more() {
      this.searchIsShow = !this.searchIsShow;
    },
    // 统计
    statistics() {
      this.$refs.drawerIshow2.drawer = true;
    },
    elDropdown(e, a) {
      if (e == "a") {
        this.query.id = a.id;
        this.$refs.drawerIshow3.drawer = true;
      }
    },
    // 反常数据
    Anomalous() {
      this.$refs.drawerIshow4.drawer = true;
    },
    submitForm() {
      this.query.status = "3";
      this.$store
        .dispatch("fwjg/orderServicesaveData", this.query)
        .then((res) => {
          this.$refs.drawerIshow.drawer = false;
          const th = this;
          this.dataRefresh();
          Object.keys(th.query).forEach(function (key) {
            if (key == "status") {
              th.query.status = "4";
            } else {
              th.query[key] = "";
            }
          });
        });
    },
    resetForm() {},
    submitFormreach() {
      this.dataRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";

::v-deep .el-table th,
::v-deep .el-table td {
  text-align: center !important;
}

.data {
  display: flex;
}

.lrxx {
  margin: 0.7rem;

  .gdTitle {
    display: flex;
    align-items: center;

    .colors {
      width: 5px;
      height: 14px;
      background-color: #2362fb;
      margin-right: 0.7rem;
    }

    .gdWord {
      font-weight: 900;
    }
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  margin-left: 9px;
  font-size: 12px;
  font-weight: 500;
}

.el-icon-arrow-down {
  font-size: 12px;
  // transform: scale(2);
}
</style>
