var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Drawer',{ref:"drawer",attrs:{"title":_vm.title,"button-is-show":true,"submit-form-name":_vm.submitFormName},on:{"submitForm":_vm.submitForm}},[_c('el-form',{ref:"dynamicValidateForm",staticClass:"demo-dynamic",attrs:{"model":_vm.dynamicValidateForm,"label-width":"100px"}},[_c('div',{staticClass:"title",staticStyle:{"height":"30px","line-height":"30px","font-size":"16px","font-weight":"700","margin-bottom":"10px","border-bottom":"1px solid #ddd"}},[_vm._v(" 政府购买服务订单 ")]),_c('el-form-item',{attrs:{"prop":"orderSelected","label":"已选择订单:","rules":[
          { required: true, message: '请输入订单', trigger: 'blur' },
          {
            type: 'orderSelected',
            message: '请输入订单',
            trigger: ['blur'],
          },
        ]}},[_c('el-input',{staticStyle:{"width":"28.125rem"},attrs:{"disabled":""},model:{value:(_vm.dynamicValidateForm.orderSelected),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "orderSelected", $$v)},expression:"dynamicValidateForm.orderSelected"}})],1),_c('div',{staticClass:"title",staticStyle:{"height":"30px","line-height":"30px","font-size":"16px","font-weight":"700","margin-bottom":"10px","border-bottom":"1px solid #ddd"}},[_vm._v(" 服务人员 ")]),_c('el-form-item',{attrs:{"prop":"orderSelected","label":"分配服务人员:","label-width":"150","rules":[
          { required: true, message: '请选择分配服务人员', trigger: 'blur' },
          {
            type: 'orderSelected',
            message: '请选择分配服务人员',
            trigger: ['change'],
          },
        ]}},[_c('el-select',{attrs:{"placeholder":"请选择活动区域"},model:{value:(_vm.dynamicValidateForm.serviceStaff),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "serviceStaff", $$v)},expression:"dynamicValidateForm.serviceStaff"}},_vm._l((_vm.serviceStaffList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }