<template>
  <div class="hl-full-container flexVertical">
    <IconsDialog :visible.sync="iconsVisible" @select="setIcon"></IconsDialog>
    <!-- 表单s -->
    <el-drawer :title="dailogTitle" :visible.sync="dialogFormVisible" direction="rtl" size="100%" :modal="false"
      :destroy-on-close="true" class="el-drawer-hl" @close="handleClose">
      <el-form ref="elForm" :model="formData" :rules="rules" size="medium" class="padding15"
        :disabled="submitType == 'view' ? true : false" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="功能名称" prop="moduleName">
              <el-input v-model="formData.moduleName" placeholder="请输入功能名称" clearable :style="{ width: '100%' }">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="功能路径" prop="url">
              <el-input v-model="formData.url" placeholder="请输入功能路径" clearable :style="{ width: '100%' }"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="功能图标" prop="icon">
              <el-input v-model="formData.icon" placeholder="请点击右侧小手选择功能图标" clearable readonly :style="{ width: '100%' }">
                <el-button slot="append" icon="el-icon-thumb" @click="openIconsDialog()">
                  选择
                </el-button>
                <el-button slot="append" icon="el-icon-delete" @click="clearIcons()">
                  清空
                </el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序序号" prop="sortNo">
              <el-input v-model="formData.sortNo" placeholder="请输入排序序号" clearable :style="{ width: '100%' }">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="formData.remark" type="textarea" placeholder="请输入备注" :autosize="{ minRows: 4, maxRows: 4 }"
              :style="{ width: '100%' }"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item size="large" style="text-align: center;">
            <el-button v-show="submitType != 'view' ? true : false" class="qconfirm" type="primary" size="medium"
              @click="submitForm()">提交</el-button>
            <el-button v-show="submitType != 'view' ? true : false" class="qcancle" size="medium" @click="resetForm()">
              重置
            </el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </el-drawer>
    <!-- 表单e -->
    <div class="search" v-if="false">
      <el-form :model="form" ref="form" label-width="60px" inline>
        <el-form-item label="名称" prop="Name">
          <el-input v-model="form.Name" placeholder="请输入名称" :maxlength="100" clearable
            :style="{ width: '100%' }"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" icon="el-icon-search" @click="dataRefresh" class="searchClick">查询</el-button>
          <el-button size="small" icon="el-icon-refresh-left" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="toolbar">
      <div class="crud">
        <el-popover placement="top-start" title="帮助" width="200" trigger="hover" content="点击可创建父级功能节点">
          <el-button slot="reference" size="small" @click="Add()">
            <i class="el-icon-plus"></i> 新增功能节点
          </el-button>
        </el-popover>
      </div>
      <el-button-group class="right-btn">
        <el-button icon="el-icon-refresh" @click="dataRefresh"></el-button>
        <el-dropdown trigger="click" :hide-on-click="false">
          <el-button icon="el-icon-s-grid">
            <i class="el-icon-caret-bottom"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in TableOptions.column" :key="index">
              <el-checkbox :label="item.label" v-model="item.checked">
              </el-checkbox>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-button-group>
    </div>
    <hltableZ rowKey="id" :isPage="false" :isNumber="false" :isChecked="false" :columnEdit="TableOptions.columnEdit"
      :formSearch="form" :tableColumn="TableOptions.column" :selectedRows="TableOptions.selectedRows"
      :apiurl="TableOptions.apiurl" :refreshFlag="TableOptions.refreshFlag" :isClickSelect="false"
      @getSelectedRows="getSelectedRows">
      <div slot-scope='selected' slot="EditColumn">
        <el-button type="success" size="mini" icon="el-icon-plus" circle
          @click="Add(selected.singlerow, true)"></el-button>
        <el-button type="primary" icon="el-icon-edit" size="mini" circle @click="Edit(selected.singlerow)"></el-button>
        <el-button type="danger" size="mini" icon="el-icon-delete" circle @click="Del(selected.singlerow)"></el-button>
        <el-button type="warning" size="mini" icon="el-icon-search" circle @click="View(selected.singlerow)"></el-button>
      </div>
    </hltableZ>
  </div>
</template>

<script>
import { hltableZ, IconsDialog } from '@/components'
export default {
  name: 'funManage',
  components: { hltableZ, IconsDialog },
  data() {
    return {
      iconsVisible: false,
      dailogTitle: "",
      dialogFormVisible: false,
      submitType: '',
      formData: {
        moduleName: '',
        url: '',
        icon: '',
        sortNo: '',
        remark: '',
        pid: ''
      },
      rules: {
        moduleName: [{
          required: true,
          message: '请输入功能名称',
          trigger: 'blur'
        }],
        url: [{
          message: '请输入功能路径',
        }],
        icon: [],
        sortNo: [{
          pattern: /^\d+(\.\d+)?$/,
          message: '请输入数字',
          trigger: 'blur'
        }],
        remark: [],
      },
      form: {
        Name: ""
      },
      TableOptions: {
        selectedRows: [],
        apiurl: "sysModule/moduleTree",
        refreshFlag: '',
        columnEdit: true,
        column: [{
          label: "功能名称",
          prop: "moduleName",
          checked: true,
        }, {
          label: "功能路径",
          prop: "url",
          checked: true,
        }, {
          label: "功能图标",
          prop: "icon",
          checked: true,
        }, {
          label: "排序序号",
          prop: "sortNo",
          checked: true,
        }, {
          label: "备注",
          prop: "remark",
          checked: true,
        }]
      },
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {
    this.dataRefresh()
  },
  destroyed() {
  },
  methods: {
    openIconsDialog() {
      this.iconsVisible = true
    },
    setIcon(icon) {
      this.formData.icon = icon
    },
    clearIcons() {
      this.formData.icon = ''
    },
    dataRefresh() {
      this.TableOptions.refreshFlag = (new Date()).getTime().toString()
    },
    handleClose() {
      this.resetForm()
    },
    resetForm() {
      const th = this
      Object.keys(this.formData).forEach(function (key) {
        th.formData[key] = ""
      })
    },
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (valid) {
          this.loading = true
          const copyForm = Object.assign({}, this.formData);
          if (this.submitType == "add") {
            copyForm.id = ''
          }
          this.$store.dispatch('sysModule/saveData', copyForm)
            .then((response) => {
              if (response.success) {
                this.$message.success("操作成功");
                this.dataRefresh()
              }
              else {
                this.$message.error(response.message);
              }
              this.dialogFormVisible = false
            })
            .catch((error) => {
              this.dialogFormVisible = false
              console.log(error)
            })
        }
        else {
          console.log('error submit!!')
          return false
        }
      })
    },
    loadform(row) {
      const rowinfo = Object.assign({}, row)
      this.formData = rowinfo;
    },
    setformType(type, title) {
      this.dialogFormVisible = true;
      this.dailogTitle = title
      this.submitType = type
    },
    Add(row, rowclick) {
      if (rowclick) {
        this.formData.pid = row.id
      }
      else {
        this.formData.pid = ""
      }
      this.setformType("add", "新增")
    },
    Edit(row) {
      this.setformType("edit", "编辑")
      this.loadform(row)
    },
    Del(row) {
      this.$confirm('确定要进行删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('sysModule/deleteData', { id: row.id })
          .then((response) => {
            if (response.success) {
              this.$message.success("操作成功");
              this.dataRefresh()
            }
            else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            console.log(error)
          })
      })
    },
    View(row) {
      this.setformType("view", "查看")
      this.loadform(row)
    },
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows
    },
    reset() {
      // this.$refs['form'].resetFields()
      // 比上方获取demo的清理方式更彻底
      const th = this
      Object.keys(this.form).forEach(function (key) {
        th.form[key] = ""
      })
    },
  }
}
</script>
<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";
</style>
