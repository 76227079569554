<template>
  <div>
    <!-- 分配服务人员 -->
    <Drawer :title="title" ref="drawer">
      <div class="title" style="
          height: 30px;
          line-height: 30px;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 10px;
          font-weight: 700;
          border-bottom: 1px solid #ddd;
        ">
        服务包设置
      </div>
      <el-row :gutter="20" style="margin-bottom: 20px">
        <el-col :span="16">
          <el-select style="width: 100%" v-model="serviceID" placeholder="请选择服务包" size="small">
            <el-option v-for="item in serviceList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-button size="small" style="margin-left: 20px" type="primary" @click="handleSave">确认保存</el-button>
        </el-col>
      </el-row>
      <hltableZ :table-column="table.column" :apiurl="table.apiurl" :refresh-flag="table.refreshFlag"
        :selected-rows="table.selectedRows" :is-checked="table.isChecked" :is-number="table.isNumber"
        :form-search="serviceID">
      </hltableZ>
    </Drawer>
  </div>
</template>

<script>
import { Drawer, hltableZ } from "@/components";
export default {
  components: { Drawer, hltableZ },
  data() {
    return {
      title: "当前页面数值为运营的服务规则",
      serviceID: "",
      serviceList: [
        {
          id: 1,
          name: "临淄区特困老人服务685（30小时）-分散-半自理-03-12 -  - 通用(当前选择)",
        },
        {
          id: 2,
          name: "临淄区特困老人服务685（30小时）-分散-半自理-03-12 -  - 通用(当前选择)",
        },
      ],
      //   表格
      table: {
        columnEdit: true,
        column: [
          {
            label: "服务项",
            prop: "ServiceItems",
            checked: true,
            width: "300",
          },
          {
            label: "服务要求",
            prop: "serviceRequirements",
            checked: true,
          },
        ],
        apiurl: "jbsz/livingPackagegetDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
    };
  },
  created() { },
  methods: {
    handleSave() { },
    open() {
      this.$refs.drawer.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
