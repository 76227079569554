import { render, staticRenderFns } from "./dishes.vue?vue&type=template&id=8e1ecfde&scoped=true"
import script from "./dishes.vue?vue&type=script&lang=js"
export * from "./dishes.vue?vue&type=script&lang=js"
import style0 from "./dishes.vue?vue&type=style&index=0&id=8e1ecfde&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e1ecfde",
  null
  
)

export default component.exports