import { render, staticRenderFns } from "./FwzcInfo.vue?vue&type=template&id=7ecd8f56&scoped=true"
import script from "./FwzcInfo.vue?vue&type=script&lang=js"
export * from "./FwzcInfo.vue?vue&type=script&lang=js"
import style0 from "./FwzcInfo.vue?vue&type=style&index=0&id=7ecd8f56&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ecd8f56",
  null
  
)

export default component.exports