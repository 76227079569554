<template>
  <div>
    <!-- 服务项配置 -->
    <Drawer :title="title" ref="drawer">
      <div style="
          height: 30px;
          line-height: 30px;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 10px;
          font-weight: 700;
          display: flex;
          justify-content: space-between;
        ">
        <div>
          可选服务项列表
          <span class="serviceSelect">
            <i class="el-icon-info"></i>
            本服务包已选择：6项服务，合计服务时长：6分钟，合计价值:0
          </span>
        </div>
      </div>
      <el-form :model="forms" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="服务项目" prop="projectId">
          <el-select v-model="forms.projectId" placeholder="请选择服务项" clearable>
            <el-option v-for="(item, index) in optionss" ::key="index" :value="item.id"
              :label="item.projectName"></el-option>
          </el-select>
          <el-button type="primary" icon="el-icon-plus" size="mini" style="margin-left: 1rem;"
            @click='elclect'>确定</el-button>
        </el-form-item>

      </el-form>

      <hltableZ :column-edit="table.columnEdit" :table-column="table.column" :apiurl="table.apiurl"
        :refresh-flag="table.refreshFlag" :selected-rows="table.selectedRows" :is-checked="table.isChecked"
        :is-number="table.isNumber" :formSearch="formInline" :isPage="false">
        <div slot-scope="selected" slot="EditColumn">
          <el-button icon="el-icon-delete" type="text" size="small" @click="removedata(selected.singlerow)">删除</el-button>
        </div>
      </hltableZ>
    </Drawer>
  </div>
</template>

<script>
import { Drawer, hltableZ } from "@/components";
export default {
  components: { Drawer, hltableZ },
  data() {
    return {
      titles: '新增',
      submitFormName: "保存",
      buttonIsShow: true,
      tableData: [],
      title: "服务项配置",
      //   表格
      table: {
        columnEdit: true,
        column: [
          {
            label: "项目",
            prop: "projectName",
            checked: true,
          }
        ],
        apiurl: "jbsz/livingProjectDictgetList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
        datahand: {}
      },
      forms: {
        projectId: "",
        packageId: ''
      },
      formInline: {
        packageId: ''
      },
      ruleForm: {},
      rules: {},
      optionss: []
    };
  },
  created() {
    this.tableData.push({
      serviceItemsId: 1,
    });
  },
  mounted() {
  },
  methods: {
    elclect() {
      this.forms.packageId = this.formInline.packageId
      this.$store.dispatch("jbsz/livingProjectsaveData", this.forms).then((res) => {
        this.dataRefresh()
        const th = this
        Object.keys(th.form).forEach(function (key) {
          th.form[key] = ''
        })
      });
    },
    livingPackagegetList() {
      this.$store.dispatch("jbsz/livingPackage55", {}).then((res) => {
        console.log(77777, res)
        this.optionss = res.result
      });
    },
    dataRefresh() {
      this.table.refreshFlag = new Date().getTime().toString();
    },
    open() {

      this.$refs.drawer.drawer = true;
    },
    removedata(e) {
      console.log(e)
      this.$confirm("确定将选择数据删除?", "", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: "warning",
      })
        .then(() => {
          this.$store
            .dispatch("sysModule/livingProjectdeleteData", { ids: [e.projectId] })
            .then((response) => {
              if (response.success) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
                this.dataRefresh();
              }
            })
        })
        .catch(() => {

          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleAdd() {
      this.$refs.draweradd.drawer = true
    }
  },
};
</script>

<style lang="scss" scoped>
.serviceSelect {
  color: #808080;
  font-size: 14px;
  margin-left: 20px;
}
</style>
