<template>
  <div class="jujia">
    <el-tabs v-model="activeName">
      <el-tab-pane label="政府购买服务规则设置" name="first">
        <HomeMarket></HomeMarket>
      </el-tab-pane>
      <!-- <el-tab-pane label="市场化服务规则设置" name="second">

        <HomeGovernment></HomeGovernment>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import HomeGovernment from "./comtow/HomeGovernment.vue";
import HomeMarket from "./comtow/HomeMarket.vue";
export default {
  name: "YanglaoConfiguration2",
  components: {
    HomeGovernment,
    HomeMarket,
  },
  data() {
    return {
      activeName: "first",
    };
  },

  mounted() { },

  methods: {},
};
</script>

<style lang="scss" scoped></style>
