<template>
  <div>
    <Drawer :title="title" ref="drawer" :button-is-show="buttonIsShow" :submit-form-name="submitFormName"
      @submitForm="submitForm">
      <el-form size="mini" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm"
        :label-position="labelPosition">
        <div style="
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            font-weight: 700;
          ">
          {{ communityInfo }}
        </div>
        <el-form-item label="选择街道/居委会:" prop="areaCode" style="width: 49%">
          <el-cascader v-model="ruleForm.areaCode" :options="options" @change="handleBaseValue"
            :props="optionProps"></el-cascader>
        </el-form-item>
        <el-form-item label="选择区县:" prop="cityType" style="width: 49%">
          <el-select v-model="ruleForm.cityType" placeholder="请选择">
            <el-option v-for="item in illustrateList" :key="item.id" :label="item.type" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市补贴金额（元）:" label-width="120" prop="subsidyAmount">
          <el-select disabled size="mini" v-model="ruleForm.subsidyAmount" placeholder="请选择补贴金额" @change="handleSelect">
            <el-option label="全天统一" value="1"></el-option>
            <el-option label="按餐细化" value="2"></el-option>
          </el-select>
          <span>
            默认额度：
            <el-input-number disabled :precision="0" controls-position="right" :min="0" size="mini"
              v-model="ruleForm.defaultLimit"></el-input-number>
          </span>
        </el-form-item>
        <el-form-item label-width="0" v-if="subsidyAmountFlag">
          <span>
            早餐：<el-input-number size="mini" disabled :precision="0" controls-position="right" :min="0"
              v-model="ruleForm.breakfast"></el-input-number>
          </span>
          <span>
            午餐：<el-input-number disabled size="mini" :precision="0" controls-position="right" :min="0"
              v-model="ruleForm.lunch"></el-input-number>
          </span>
          <span>
            晚餐：<el-input-number disabled size="mini" :precision="0" controls-position="right" :min="0"
              v-model="ruleForm.dinner"></el-input-number>
          </span>
        </el-form-item>
        <el-form-item label="区县补贴金额:">
          <el-input-number disabled size="mini" :precision="0" controls-position="right" :min="0"
            v-model="ruleForm.deliverySubsidyAmount"></el-input-number>
        </el-form-item>
        <el-form-item label="街道补贴金额:">
          <el-input-number size="mini" :precision="0" controls-position="right" :min="0"
            v-model="ruleForm.streetAmount"></el-input-number>
        </el-form-item>
        <el-form-item label="社区补贴金额:">
          <el-input-number size="mini" :precision="0" controls-position="right" :min="0"
            v-model="ruleForm.money"></el-input-number>
        </el-form-item>
        <el-form-item label="补贴金额(合计):" label-width="120px">
          <el-input-number size="mini" :precision="0" controls-position="right" :min="0"
            v-model="ruleForm.sum"></el-input-number>
        </el-form-item>
        <!-- <el-form-item label="社区配送补贴(元):" label-width="130px">
          <span>一次性配送：<el-input-number size="mini" :precision="0" controls-position="right" :min="0"
              v-model="ruleForm.mDSubsidy"></el-input-number></span>
        </el-form-item> -->
      </el-form>
    </Drawer>
  </div>
</template>

<script>
import { Drawer } from "@/components";
export default {
  components: { Drawer },
  props: {
    communityInfo: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      subsidyAmountFlag: false,
      labelPosition: "left",
      rules: {
        // subsidyClassification: [
        //   { required: true, message: "请输入补贴分类", trigger: "blur" },
        // ],
        // subsidyAmount: [
        //   { required: true, message: "请选择补贴金额", trigger: "change" },
        // ],
      },
      options: [],
      optionProps: {
        value: 'code',
        label: 'name',
        children: 'children'
      },
      submitFormName: "保存",
      buttonIsShow: true,
      ruleForm: {
        areaCode: '',
        cityType: "",
        deliverySubsidyAmount: 0, // 区县补贴金额
        streetAmount: 0, // 街道补贴金额
        communityAmount: 0, // 社区补贴金额
        sum: 0, // 补贴金额合计
        subsidyClassification: "", // 补贴分类
        subsidyAmount: "1", // 补贴金额
        defaultLimit: 0, // 默认额度
        breakfast: 0, // 早餐
        lunch: 0, // 午餐
        dinner: 0, // 晚餐
        level: 3
      },
      illustrateList: []
    };
  },
  // watch: {
  //   "ruleForm.subsidyAmount": {
  //     handler(newValue, oldvalue) {
  //       if (newValue === '2') {
  //         this.subsidyAmountFlag = true
  //       } else {
  //         this.subsidyAmountFlag = false
  //       }
  //     },
  //     deep: true,
  //     immediate: true
  //   },
  //   "ruleForm.breakfast": {
  //     handler(newValue, oldvalue) {
  //       this.ruleForm.totalSubsidyAmount = newValue + this.ruleForm.lunch + this.ruleForm.dinner +
  //         this.ruleForm.deliverySubsidyAmount +
  //         this.ruleForm.streetAmount +
  //         this.ruleForm.communityAmount;
  //     },
  //     deep: true,
  //     immediate: true
  //   },
  //   "ruleForm.lunch": {
  //     handler(newValue, oldvalue) {
  //       this.ruleForm.totalSubsidyAmount = newValue + this.ruleForm.breakfast + this.ruleForm.dinner + this.ruleForm.defaultLimit + newValue +
  //         this.ruleForm.streetAmount +
  //         this.ruleForm.communityAmount;
  //     },
  //     deep: true,
  //     immediate: true
  //   },
  //   "ruleForm.dinner": {
  //     handler(newValue, oldvalue) {
  //       this.ruleForm.totalSubsidyAmount = newValue + this.ruleForm.breakfast + this.ruleForm.lunch + this.ruleForm.defaultLimit + this.ruleForm.deliverySubsidyAmount +
  //         newValue +
  //         this.ruleForm.communityAmount;
  //     },
  //     deep: true,
  //     immediate: true
  //   },
  //   "ruleForm.defaultLimit": {
  //     handler(newValue, oldvalue) {
  //       this.ruleForm.totalSubsidyAmount = this.ruleForm.dinner + this.ruleForm.breakfast + this.ruleForm.lunch + newValue +
  //         this.ruleForm.deliverySubsidyAmount +
  //         this.ruleForm.streetAmount +
  //         this.ruleForm.communityAmount;
  //     },
  //     deep: true,
  //     immediate: true
  //   },
  //   "ruleForm.deliverySubsidyAmount": {
  //     handler(newValue, oldvalue) {
  //       this.ruleForm.totalSubsidyAmount = this.ruleForm.dinner + this.ruleForm.breakfast + this.ruleForm.lunch + this.ruleForm.defaultLimit + newValue +
  //         this.ruleForm.streetAmount +
  //         this.ruleForm.communityAmount;
  //     },
  //     deep: true,
  //     immediate: true
  //   },
  //   "ruleForm.streetAmount": {
  //     handler(newValue, oldvalue) {
  //       this.ruleForm.totalSubsidyAmount = this.ruleForm.dinner + this.ruleForm.breakfast + this.ruleForm.lunch + this.ruleForm.defaultLimit + this.ruleForm.deliverySubsidyAmount +
  //         newValue +
  //         this.ruleForm.communityAmount;
  //     },
  //     deep: true,
  //     immediate: true
  //   },
  //   "ruleForm.communityAmount": {
  //     handler(newValue, oldvalue) {
  //       this.ruleForm.totalSubsidyAmount = this.ruleForm.dinner + this.ruleForm.breakfast + this.ruleForm.lunch + this.ruleForm.defaultLimit + this.ruleForm.deliverySubsidyAmount +
  //         this.ruleForm.streetAmount +
  //         newValue;
  //     },
  //     deep: true,
  //     immediate: true
  //   }
  // },
  // computed: {
  //   totalSum: {
  //     get() {
  //       console.log('this.ruleForm-----', this.ruleForm)
  //       return this.ruleForm.deliverySubsidyAmount +
  //       this.ruleForm.streetAmount +
  //       this.ruleForm.communityAmount;
  //     },
  //     set(newValue) {
  //       this.getTotalSum(newValue);
  //     }
  //   },
  // },
  created() {
    this.getDataTree()
    this.getListByLevel()
  },
  methods: {
    getDataTree() {
      this.$store.dispatch("sysModule/areagetTree").then((res) => {
        this.options = res.result
      })
    },
    getListByLevel() {
      this.$store
        .dispatch("sysModule/getListByLevel", { level: 2 })
        .then((response) => {
          if (response.success) {
            this.illustrateList = response.result

          }
        })
    },
    handleBaseValue(val) {
      this.ruleForm.areaCode = val[val.length - 1];
    },
    getTotalSum(sum) {
      this.ruleForm.totalSubsidyAmount = sum;
    },
    handleSelect(val) {
      console.log("val------", val);
      if (val === "2") {
        this.subsidyAmountFlag = true;
      } else {
        this.subsidyAmountFlag = false;
      }
    },
    submitForm(formName) {
      console.log("---------", formName);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("sysModule/subsidyStandardsaveData", this.ruleForm)
            .then((response) => {
              if (response.success) {
                this.$refs.drawer.drawer = false;
                this.$emit('subsidyStandardsaveData4');
              }
            })
          this.$refs.drawer.drawer = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    open() {
      this.$refs.drawer.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
