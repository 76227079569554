<template>
  <div>
    <!-- 查询条件 -->
    <Drawer
      :title="title"
      ref="drawer"
      :button-is-show="true"
      :submit-form-name="submitFormName"
      @submitForm="submitForm"
    >
      <div class="search">
        <el-form
          size="small"
          :model="query"
          ref="query"
          label-width="80px"
          inline
        >
          <el-row :gutter="20" class="rowStyle">
            <el-col :span="6">
              <el-select v-model="query.serviceBatch">
                <el-option
                  v-for="item in serviceBatchList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-input
                v-model="query.contractCNo"
                placeholder="请输入合约号"
              ></el-input>
            </el-col>
            <el-col :span="6">
              <el-input
                v-model="query.serviceName"
                placeholder="请输入服务对象姓名"
              ></el-input>
            </el-col>
            <el-col :span="6">
              <el-input
                v-model="query.servicePhone"
                placeholder="请输入服务对象联系方式"
              ></el-input>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="rowStyle">
            <el-col :span="6">
              <el-input
                v-model="query.serviceID"
                placeholder="请输入服务对象身份证"
              ></el-input>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="rowStyle">
            <el-col :span="24">
              区域：<el-select v-model="query.county">
                <el-option
                  v-for="item in countyList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              -
              <el-select v-model="query.serviceBatch">
                <el-option
                  v-for="item in streetList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              -
              <el-select v-model="query.serviceBatch">
                <el-option
                  v-for="item in communityList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="rowStyle">
            <el-col :span="6">
              <el-select
                v-model="query.serviceNode"
                placeholder="请选择服务节点"
              >
                <el-option
                  v-for="item in serviceNodeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-select
                v-model="query.object"
                placeholder="请选择服务对象分类"
              >
                <el-option
                  v-for="item in objectList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-input
                v-model="query.servicePack"
                placeholder="请输入服务包"
              ></el-input>
            </el-col>
            <el-col :span="6">
              <el-input
                v-model="query.serviceName2"
                placeholder="请输入服务商名称"
              ></el-input>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="rowStyle">
            <el-col :span="24">
              <el-date-picker
                style="width: 50%"
                v-model="query.lastServiceTime"
                type="daterange"
                range-separator="至"
                start-placeholder="最后一次服务开始日期"
                end-placeholder="最后一次服务结束日期"
              >
              </el-date-picker>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="rowStyle">
            <el-col :span="24">
              <el-input-number
                v-model="query.totalNumber1"
                :min="0"
                label="总服务次数"
              ></el-input-number>
              <= 总服务次数 <=
              <el-input-number
                v-model="query.totalNumber2"
                :min="0"
                label="总服务次数"
              ></el-input-number>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="rowStyle">
            <el-col :span="24">
              <el-input-number
                v-model="query.totalServiceTtems1"
                :min="0"
                label="总服务项数"
              ></el-input-number>
              <= 总服务项数 <=
              <el-input-number
                v-model="query.totalServiceTtems2"
                :min="0"
                label="总服务项数"
              ></el-input-number>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="rowStyle">
            <el-col :span="24">
              <el-input-number
                v-model="query.totalServiceMinutes1"
                :min="0"
                label="总服务分钟"
              ></el-input-number>
              <= 总服务分钟 <=
              <el-input-number
                v-model="query.totalServiceMinutes2"
                :min="0"
                label="总服务分钟"
              ></el-input-number>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="rowStyle">
            <el-col :span="24">
              <el-input-number
                v-model="query.totalServiceValue1"
                :min="0"
                label="总服务价值"
              ></el-input-number>
              <= 总服务价值 <=
              <el-input-number
                v-model="query.totalServiceValue2"
                :min="0"
                label="总服务价值"
              ></el-input-number>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="rowStyle">
            <el-col :span="6">
              <el-select
                v-model="query.serviceHeadSculpture"
                placeholder="请选择服务对象头像"
              >
                <el-option label="有" value="1"></el-option>
                <el-option label="无" value="2"></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-select
                v-model="query.positioningStatus"
                placeholder="请选择定位状态"
              >
                <el-option label="未定位" value="1"></el-option>
                <el-option label="已定位" value="2"></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-input
                v-model="query.serviceLeader"
                placeholder="请输入服务负责人"
              ></el-input>
            </el-col>
            <el-col :span="6">
              <el-select
                v-model="query.servicePersonnelNum"
                placeholder="绑定服务人员数"
              >
                <el-option label="无" value="1"></el-option>
                <el-option label="1人" value="2"></el-option>
                <el-option label="2人" value="3"></el-option>
                <el-option label="3人" value="4"></el-option>
                <el-option label="大于3人" value="5"></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="rowStyle">
            <el-col :span="6">
              <el-select
                v-model="query.contractConfirmation"
                placeholder="请选择合约完成确认"
              >
                <el-option label="未确认完成" value="1"></el-option>
                <el-option label="已确认完成" value="2"></el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { Drawer } from "@/components";
export default {
  components: { Drawer },
  data() {
    return {
      title: "更多条件",
      serviceNodeList: [
        // 服务节点
        {
          name: "全部服务节点",
          id: 1,
        },
        {
          id: 2,
          name: "合约未开始",
        },
        {
          id: 3,
          name: "待分配服务商",
        },
        {
          id: 4,
          name: "待服务商接单",
        },
        {
          id: 5,
          name: "待设置服务包",
        },
        {
          id: 6,
          name: "待分配服务人员",
        },
        {
          id: 7,
          name: "合约执行中",
        },
        {
          id: 8,
          name: "合约暂停",
        },
        {
          id: 9,
          name: "合约正常完成",
        },
        {
          id: 10,
          name: "合约终止",
        },
      ],
      objectList: [
        // 对象分类
        {
          name: "分散自理",
          id: 1,
        },
        {
          id: 2,
          name: "分散半自理",
        },
        {
          id: 3,
          name: "低保农村300",
        },
        {
          id: 4,
          name: "低保农村450",
        },
        {
          id: 5,
          name: "低保农村600",
        },
        {
          id: 6,
          name: "待分配服务人员",
        },
        {
          id: 7,
          name: "低保城市450",
        },
        {
          id: 8,
          name: "低保城市675",
        },
        {
          id: 9,
          name: "低保城市900",
        },
      ],
      communityList: [], // 全部社区
      streetList: [], // 全部街道
      countyList: [
        // 全部区县
        {
          id: 1,
          name: "市辖区",
        },
        {
          id: 2,
          name: "淄川区",
        },
        {
          id: 3,
          name: "张店区",
        },
        {
          id: 4,
          name: "博山区",
        },
        {
          id: 5,
          name: "临淄区",
        },
        {
          id: 6,
          name: "周村区",
        },
        {
          id: 7,
          name: "桓台区",
        },
        {
          id: 8,
          name: "高青区",
        },
        {
          id: 9,
          name: "沂源县",
        },
      ],
      serviceBatchList: [
        {
          name: "全部服务批次",
          id: 1,
        },
        {
          name: "已结束（按价值）临淄区特困老人居家服务",
          id: 2,
        },
        {
          name: "（按时长）临淄区特困老人居家服务",
          id: 3,
        },
      ],
      submitFormName: "确定",
      query: {
        serviceBatch: 1,
        contractCNo: "", // 合约号
        serviceName: "", // 服务对象姓名
        servicePhone: "", // 服务对象联系方式
        serviceID: "", // 服务对象身份证
        county: "", // 区县
        serviceNode: "", // 服务节点
        object: "", // 服务对象分类
        servicePack: "", // 服务包
        serviceName2: "", // 服务商名称
        lastServiceTime: "", // 最后一次服务时间
        totalNumber1: 0, // 总服务次数
        totalNumber2: 0, // 总服务次数
        totalServiceTtems1: 0, //总服务项数
        totalServiceTtems2: 0, //总服务项数
        totalServiceMinutes1: 0, // 总服务分钟
        totalServiceMinutes2: 0,
        totalServiceValue1: 0, // 总服务价值
        totalServiceValue2: 0,
        serviceHeadSculpture: "", // 服务对象头像
        positioningStatus: "", // 定位状态
        serviceLeader: "", // 服务负责人
        servicePersonnelNum: "", // 绑定服务人员数
        contractConfirmation: "", // 请选择合约完成确认
      },
    };
  },
  created() {},
  methods: {
    submitForm() {
      this.$emit("getQuery", this.query);
    },
    handleSave() {},
    open() {
      this.$refs.drawer.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.rowStyle {
  margin-bottom: 10px;
}
</style>
