<!-- 堂食菜品 -->
<template>
  <div class="hl-full-container flexVertical">
    <!-- 上方标题 -->
    <div class="topTitle">
      <div>
        <i class="el-icon-film"></i>
        <span class="title">堂食菜品</span>
      </div>
      <div>
        <el-button type="info" size="small" plain icon="el-icon-plus" @click="openAddDish">新增菜品</el-button>
        <el-button type="info" size="small" plain icon="el-icon-s-tools"
          @click="openClassificationManage">分类管理</el-button>
      </div>
    </div>
    <!-- 查询 -->
    <div class="search">
      <el-form :model="query" ref="query" label-width="80" inline>
        <el-form-item label="助餐站点" prop="buffetId">
          <el-select v-model="query.buffetId" placeholder="请选择助餐站点">
            <el-option v-for="item in buffetList" :key="item.id" :label="item.buffetName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="dishTypeNanme">
          <el-input v-model="query.dishTypeNanme" placeholder="请输入菜品分类名称"></el-input>
        </el-form-item>
        <el-form-item label="" prop="dishNanme">
          <el-input v-model="query.dishNanme" placeholder="请输入菜品名称"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status" label-width="60">
          <el-select v-model="query.status" placeholder="请选择">
            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" icon="el-icon-search" @click="dataRefresh">查询</el-button>
          <el-button size="mini" icon="el-icon-refresh-left" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格 -->
    <hltableZ :columnEdit="table.columnEdit" :tableColumn="table.column" :apiurl="table.apiurl"
      :refreshFlag="table.refreshFlag" :selectedRows="table.selectedRows" :isChecked="table.isChecked"
      :isNumber="table.isNumber" :isClickSelect="table.isClickSelect" :formSearch="query" :handleCellStyle="cellStyle"
      @cellClick="cellClick">
      <div slot-scope="selected" slot="EditColumn">
        <el-button icon="el-icon-edit" type="text" size="small" @click="handleEdit(selected.singlerow)">编辑</el-button>
        <el-button icon="el-icon-delete" type="text" size="small"
          @click="handledDelete(selected.singlerow)">删除</el-button>
      </div>
    </hltableZ>
    <!-- 新增菜品弹窗 -->
    <dishesInfo ref="dishesInfo" :title="dishesInfoTitle" @event-name="eventName"></dishesInfo>
    <!-- 分类管理弹窗 -->
    <classificationManage ref="classificationManage"></classificationManage>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
import dishesInfo from "./components/dishes/DishesInfo.vue"
import classificationManage from "./components/dishes/ClassificationManage.vue"

export default {
  name: "Dishes",
  components: { hltableZ, classificationManage, dishesInfo },
  data() {
    return {

      // 状态options
      statusOptions: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "1",
          label: "上架",
        },
        {
          value: "2",
          label: "下架",
        },
      ],
      // 表格属性值
      table: {
        columnEdit: true,
        column: [
          {
            label: "助餐站点",
            prop: "buffetName",
            checked: true,
            width: "150",
          },
          {
            label: "菜品分类",
            prop: "typeName",
            checked: true,
            width: "150",
          },
          {
            label: "菜品类型",
            prop: "type",
            checked: true,
            width: "150",
            formatter(value) {
              var a = ''
              if (value.type == '1') {
                a = '单一菜品'
              } else {
                a = '组合菜品'
              }
              return a;
            },
          },
          {
            label: "菜品名称",
            prop: "dishesName",
            checked: true,
          },
          {
            label: "价格(元)",
            prop: "money",
            checked: true,
            width: "150",
          },
          {
            label: "图片",
            prop: "fileStoreEntity",
            checked: true,
            width: "180",
            columnType: "img",
            formatter(value) {
              const val = value.fileStoreEntity[0].filePath;
              return val
            },
          },
          {
            label: "状态",
            prop: "status",
            checked: true,
            width: "120",
            formatter(value) {
              const val = value.status;
              return val ? "上架" : "下架";
            },
          },
          // {
          //   label: "在线自助点餐",
          //   prop: "onLine",
          //   checked: true,
          //   width: "140",
          //   formatter(value) {
          //     const val = value.onLine;
          //     return val ? "支持" : "不支持";
          //   },
          // },
        ],
        apiurl: "homePage/buffetDishesGetDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: true,
        isNumber: false,
        isClickSelect: false,
      },
      // 新增菜品弹窗标题
      dishesInfoTitle: '',
      // 查询条件
      query: {

      },
      buffetList: []
    };
  },

  mounted() {
    this.buffet()
  },

  methods: {
    buffet() {
      this.$store.dispatch("homePage/buffet", {}).then((res) => {
        this.buffetList = res.result
      })
    },
    // 新增菜品弹窗
    openAddDish() {
      const th = this
      this.dishesInfoTitle = '新增菜品管理'
      this.$refs.dishesInfo.$refs.drawerIshow.drawer = true;
      Object.keys(th.$refs.dishesInfo.dataForm).forEach(function (key) {
        if (key == 'picture') {
          this.$refs.dishesInfo.householdFile2 = []
        } else {
          th.$refs.dishesInfo.dataForm[key] = "";
        }
      });
    },
    // 分类管理弹窗
    openClassificationManage() {
      this.$refs.classificationManage.$refs.drawerIshow.drawer = true;
    },
    // 查询
    dataRefresh() {
      this.table.refreshFlag = new Date().getTime().toString();
    },
    // 重置
    reset() {
      this.$refs.query.resetFields();
    },
    // 编辑
    handleEdit(e) {
      this.dishesInfoTitle = '修改菜品管理'
      this.$refs.dishesInfo.$refs.drawerIshow.drawer = true;
      this.$refs.dishesInfo.getInfo(111)
      Object.keys(this.$refs.dishesInfo.dataForm).forEach(key => {
        if (key == 'picture') {
          this.$refs.dishesInfo.householdFile2 = []
          e.householdFile.forEach((e) => {
            this.$refs.dishesInfo.householdFile2.push(e.filePath)
          })
        } else {
          this.$refs.dishesInfo.dataForm[key] = e[key]
          this.$refs.dishesInfo.dataForm.id = e.id
        }
      })
    },
    // 删除
    handledDelete(e) {
      this.$confirm('确定将选择数据删除?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store
          .dispatch("homePage/buffetDishesDeleteData", { ids: [e.id] })
          .then((response) => {
            if (response.success) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.dataRefresh();
            }
          })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    // 单元格点击
    cellClick(row, column) {
      if (column.property === "status") {
        this.$confirm(
          `确认要${row[column.property] ? "下架" : "上架"}吗？`,
          "信息",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            row[column.property] = !row[column.property];
          })
          .catch(() => { });
      } else if (column.property === "onLine") {
        this.$confirm(
          `确认要${row[column.property] ? "不支持" : "支持"}线上点餐吗？`,
          "信息",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            row[column.property] = !row[column.property];
          })
          .catch(() => { });
      }
    },
    // 更改特定表格样式
    cellStyle(row, column) {
      if (column.property === "status" || column.property === "onLine") {
        return {
          color: "#409EFF",
          cursor: "pointer",
        };
      }
    },
    eventName() {
      this.dataRefresh()
    }
  },
};
</script>

<style lang="scss" scoped>
.hl-full-container {
  font-size: 1rem;
  color: #606266;

  // 上方标题
  .topTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding-bottom: 0.3125rem;

    .el-icon-film {
      font-size: 0.875rem;
      margin-right: 0.1875rem;
    }

    .title {
      font-size: 1rem;
    }
  }

  // 查询
  .search {
    margin-top: 0.625rem;
  }
}
</style>
