<template>
  <div>
    <Drawer :title="title" ref="drawer" :button-is-show="buttonIsShow" :submit-form-name="submitFormName"
      @submitForm="submitForm">
      <el-form size="mini" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm"
        :label-position="labelPosition">
        <div style="
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            font-weight: 700;
          ">
          基本信息
        </div>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="补贴分类:" prop="type">
              <el-input size="mini" v-model="ruleForm.type"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-form-item label="选择市:" prop="areaCode" style="width: 49%">
          <el-cascader v-model="ruleForm.areaCode" :options="options" @change="handleBaseValue"
            :props="optionProps"></el-cascader>
        </el-form-item> -->
        <el-form-item label="补贴金额:" prop="isAll">
          <el-select size="mini" v-model="ruleForm.isAll" placeholder="请选择补贴金额" @change="handleSelect">
            <el-option label="全天统一" value="1"></el-option>
            <!-- <el-option label="按餐细化" value="2"></el-option> -->
          </el-select>
          <span>
            默认额度：
            <el-input-number :precision="0" controls-position="right" :min="0" size="mini"
              v-model="ruleForm.money"></el-input-number>
          </span>
        </el-form-item>
        <el-form-item label-width="0" v-if="subsidyAmountFlag">
          <span>
            早餐：<el-input-number size="mini" :precision="0" controls-position="right" :min="0"
              v-model="ruleForm.breakfast"></el-input-number>
          </span>
          <span>
            午餐：<el-input-number size="mini" :precision="0" controls-position="right" :min="0"
              v-model="ruleForm.lunch"></el-input-number>
          </span>
          <span>
            晚餐：<el-input-number size="mini" :precision="0" controls-position="right" :min="0"
              v-model="ruleForm.dinner"></el-input-number>
          </span>
        </el-form-item>
        <!-- <el-form-item label="市配送补贴(元):" label-width="120px">
          <span>一次性配送：<el-input-number size="mini" :precision="0" controls-position="right" :min="0"
              v-model="ruleForm.mDSubsidy"></el-input-number></span>
        </el-form-item> -->
        <el-row :gutter="20">
          <el-col :span="15">
            <el-form-item label="备注:">
              <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入内容"
                v-model="ruleForm.remark">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </Drawer>
  </div>
</template>

<script>
import { Drawer } from "@/components";
export default {
  components: { Drawer },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      options: [],
      optionProps: {
        value: 'code',
        label: 'name',
        children: 'children'
      },
      subsidyAmountFlag: false,
      labelPosition: "left",
      rules: {
        // type: [
        //   { required: true, message: "请输入补贴分类", trigger: "blur" },
        // ],
        // isAll: [
        //   { required: true, message: "请选择补贴金额", trigger: "change" },
        // ],
      },
      submitFormName: "保存",
      buttonIsShow: true,
      ruleForm: {
        type: "", // 补贴分类
        isAll: "1", // 补贴金额
        money: "", // 默认额度
        mDSubsidy: "", // 市配送补贴
        remark: "", // 备注
        breakfast: "", // 早餐
        lunch: "", // 午餐
        dinner: "", // 晚餐
        cityType: "",
        level: null,
        id: '',
        areaCode: '3703',
        sum: ''
      },
    };
  },
  created() {
    this.getDataTree()
  },
  methods: {
    handleBaseValue(val) {
      this.ruleForm.areaCode = val[val.length - 1];
    },
    getDataTree() {
      this.$store.dispatch("area/getDataTree",{
          areaCode: this.$store.getters.user.areaCode,
        }).then((res) => {
        this.options = res.result
      })
    },
    handleSelect(val) {
      if (val === "2") {
        this.subsidyAmountFlag = true;
      } else {
        this.subsidyAmountFlag = false
      }
    },
    close() {
      const th = this
      Object.keys(th.ruleForm).forEach(function (key) {
        if (key !== 'isAll' && key !== 'areaCode') {
          th.ruleForm[key] = ''
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("sysModule/subsidyStandardsaveData", this.ruleForm)
            .then((response) => {
              if (response.success) {
                this.$refs.drawer.drawer = false;
                this.$emit('subsidyStandardsaveData2');
              }
            })

        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    open() {
      this.$refs.drawer.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
