<template>
  <div class="hl-full-container flexVertical">
    <!-- 市补贴 -->
    <div class="municipalSubsidies">
      <div class="title">
        市补贴
        <el-divider class="divider"></el-divider>
      </div>
      <div class="content">
        <div class="button" @click="subsidyAdd">
          <el-button
            size="mini"
            class="content_button"
            type="primary"
            icon="el-icon-plus"
            >市补贴分类新增</el-button
          >
        </div>
        <div class="illustrate">
          <p class="illustrate_title" style="margin-bottom: 0.3125rem">
            市补贴说明：
          </p>
          <div
            class="illustrate_text"
            v-for="(item, index) in illustrateList"
            :key="item.id"
          >
            <div class="text">
              {{ index + 1 }}、{{ item.type }}：{{
                item.money ? item.money : "0"
              }}元/每餐
            </div>
            <el-button
              size="mini"
              class="illustrate_button1"
              type="primary"
              icon="el-icon-edit"
              @click="compile(item)"
            ></el-button>
            <el-button
              size="mini"
              class="illustrate_button1"
              type="primary"
              style="margin-left: 0"
              icon="el-icon-delete"
              @click="deletes(item)"
            ></el-button>
            <br />
          </div>
        </div>
      </div>
    </div>
    <!-- 新增编辑 -->
    <marketAdd
      ref="drawer"
      :title="title"
      @subsidyStandardsaveData2="subsidyStandardsaveData2"
    ></marketAdd>
    <!-- 各县区补贴规则配置 -->
    <br />
    <div class="countySubsidyRules">
      <div class="title">
        各县区补贴规则配置
        <el-divider class="divider"></el-divider>
      </div>
      <div>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane
            v-for="item in cityList"
            :key="item.id"
            :label="item.name"
            :name="item.active"
          >
            <div class="tabTable" v-if="activeName === 'five'">
              <div class="tabContent1">
                <div class="title subsidiesType">
                  区县补贴分类配置<span style="color: red">【重要】</span>
                  <!-- <el-divider class="divider"></el-divider> -->
                </div>
                <div class="content">
                  <!-- <div
                    class="button"
                    style="float: right"
                    @click="countyAddHandle"
                  >
                    <el-button
                      size="mini"
                      class="content_button"
                      type="primary"
                      icon="el-icon-plus"
                      style="margin-bottom: 1.25rem"
                      >区县补贴分类新增</el-button
                    >
                  </div> -->
                  <div class="tableStyle1">
                    <hltableZ
                      :column-edit="table.columnEdit"
                      :table-column="table.column"
                      :staticData="table.staticData"
                      :refresh-flag="table.refreshFlag"
                      :selected-rows="table.selectedRows"
                      :is-checked="table.isChecked"
                      :is-number="table.isNumber"
                      :table-type="subsidy"
                      :formSearch="formInline"
                      :isPage="false"
                      :isShow="false"
                    >
                      <div slot-scope="selected" slot="EditColumn">
                        <el-button
                          icon="el-icon-edit"
                          type="text"
                          size="sizi"
                          @click="editHandle(selected.singlerow)"
                          >编辑</el-button
                        >
                        <el-button
                          icon="el-icon-delete"
                          type="text"
                          size="sizi"
                          @click="cancelHandle(selected.singlerow)"
                          >删除</el-button
                        >
                      </div>
                    </hltableZ>
                  </div>
                </div>
              </div>
              <div class="tabContent2">
                <!-- <div class="title form_unit">街道社区追加补贴金额</div>
                <div class="button" style="float: right" @click="communitySubsidies">
                  <el-button size="mini" class="content_button" type="primary" icon="el-icon-plus"
                    style="margin-bottom: 1.25rem">街道社区补贴分类新增</el-button>
                </div> -->
                <!-- <div class="tableStyle2">
                  <hltableZ :table-column="table2.column" :column-edit="table.columnEdit" :apiurl="table2.apiurl"
                    :refresh-flag="table2.refreshFlag" :selected-rows="table2.selectedRows" :is-checked="table2.isChecked"
                    :is-number="table2.isNumber" :isPage="false" :isShow="false" :formSearch="formInline2">
                    <div slot-scope="selected" slot="EditColumn">
                      <el-button icon="el-icon-edit" type="text" size="sizi"
                        @click="editHandle2(selected.singlerow)">编辑</el-button>
                      <el-button icon="el-icon-delete" type="text" size="sizi"
                        @click="cancelHandle2(selected.singlerow)">删除</el-button>
                    </div>
                  </hltableZ>
                </div> -->
              </div>
            </div>
            <div v-else style="text-align: center; margin-top: 200px">
              暂无数据
            </div>
          </el-tab-pane>
        </el-tabs>
        <!-- <countyAdd
          ref="countyDrawer"
          :title="countyTitle"
          @subsidyStandardsaveData3="subsidyStandardsaveData3"
        >
        </countyAdd>
        <communitySubsidies
          ref="community"
          :title="communityTitle"
          @subsidyStandardsaveData4="subsidyStandardsaveData4"
        >
        </communitySubsidies> -->
      </div>
    </div>
  </div>
</template>

<script>
import communitySubsidies from "./components/communitySubsidies.vue";
import countyAdd from "./components/countyAdd.vue";
import marketAdd from "./components/marketAdd.vue";
import { hltableZ } from "@/components";
export default {
  name: "Lrxx",
  components: { hltableZ, marketAdd, countyAdd, communitySubsidies },
  data() {
    return {
      subsidy: "subsidy",
      communityInfo: "",
      communityTitle: "",
      countyTitle: "",
      activeName: "five",
      cityList: [
        {
          id: 1,
          active: "first",
          name: "博山区",
        },
        {
          id: 3,
          active: "second",
          name: "淄川区",
        },
        {
          id: 2,
          active: "third",
          name: "张店区",
        },
        {
          id: 0,
          active: "five",
          name: "临淄区",
        },
        {
          id: 4,
          active: "six",
          name: "周村区",
        },
        {
          id: 5,
          active: "seven",
          name: "桓台县",
        },
        {
          id: 6,
          active: "eight",
          name: "高青县",
        },
        {
          id: 7,
          active: "nine",
          name: "沂源县",
        },
      ],
      rules: {},
      title: "",
      illustrateList: [],
      //   表格
      table2: {
        columnEdit: true,
        column: [
          {
            label: "区域",
            prop: "areaName",
            checked: true,
          },
          {
            label: "总补贴",
            prop: "sum",
            checked: true,
          },
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      formInline: {
        level: 2,
      },
      formInline2: {
        level: 3,
      },
      //   表格
      table: {
        staticData: [],
        columnEdit: false,
        column: [
          {
            label: "名称",
            prop: "type",
            checked: true,
            width: "150",
          },
          {
            label: "区县补贴金额",
            prop: "money",
            checked: true,
          },
          {
            label: "市级补贴分类",
            prop: "type",
            checked: true,
          },
          {
            label: "市补贴金额",
            prop: "money",
            checked: true,
          },
          {
            label: "额外补贴助餐点运营补贴",
            prop: "additionalSubsidies",
            checked: true,
          },
          {
            label: "年龄规则",
            prop: "ageRule",
            checked: true,
          },
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      rulesAdd: [],
      token: { token: localStorage.getItem("systoken") },
      xlsAreafileList: [],
      xlsAreaAction: "/api/oldUser/importUser.json",
      activeItem: {},
      systemConfig: this.$store.getters.systemSettings.config,
      searchIsShow: false,
    };
  },

  mounted() {
    this.getListByLevel();
    this.dataRefresh();
  },
  created() {},
  methods: {
    dataRefresh() {
      this.table.staticData = [
        {
          type: "60-79岁补贴",
          money: 2,
          ageRule: "60-79岁",
        },
        {
          type: "80岁以上补贴",
          money: 3,
          ageRule: "80岁以上",
        },
      ];
      this.table.refreshFlag = new Date().getTime().toString();
    },
    dataRefresh2() {
      // this.table2.refreshFlag = new Date().getTime().toString();
    },
    getListByLevel() {
      this.$store
        .dispatch("sysModule/getListByLevel", { level: 1 })
        .then((response) => {
          if (response.success) {
            this.illustrateList = response.result;
          }
        });
    },
    subsidyStandardsaveData2() {
      this.getListByLevel();
    },
    communitySubsidies(a, b, c, d) {
      this.communityTitle = "社区补贴设置";
      this.communityInfo = "西高村居委会-60-79岁补贴对象";
      this.$refs.community.open();
    },
    cancelHandle(row) {
      // 删除
      this.$confirm("确定将选择数据删除?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store
            .dispatch("sysModule/subsidyStandarddeleteData", { ids: [row.id] })
            .then((response) => {
              if (response.success) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.dataRefresh();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    editHandle(e) {
      this.countyTitle = "区县补贴分类编辑";
      this.$refs.countyDrawer.open();
      // 编辑
      Object.keys(this.$refs.countyDrawer.ruleForm).forEach((key) => {
        this.$refs.countyDrawer.ruleForm[key] =
          e[key] || this.$refs.countyDrawer.ruleForm[key];
      });
    },
    editHandle2(e) {
      console.log(777, e);
      this.countyTitle = "街道社区补贴分类编辑";
      this.$refs.community.open();
      // 编辑
      Object.keys(this.$refs.community.ruleForm).forEach((key) => {
        if (key == "deliverySubsidyAmount") {
          this.$refs.community.ruleForm["deliverySubsidyAmount"] =
            e.superior[0].money;
        } else if (key == "defaultLimit") {
          this.$refs.community.ruleForm["defaultLimit"] = e.superior[1].money;
        } else {
          this.$refs.community.ruleForm[key] =
            e[key] || this.$refs.community.ruleForm[key];
        }
      });
    },
    cancelHandle2(row) {
      // 删除
      this.$confirm("确定将选择数据删除?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store
            .dispatch("sysModule/subsidyStandarddeleteData", { ids: [row.id] })
            .then((response) => {
              if (response.success) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.dataRefresh2();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleClick() {},
    countyAddHandle() {
      this.countyTitle = "区县补贴分类新增";
      this.$refs.countyDrawer.open();
      this.$refs.countyDrawer.close();
      this.$refs.countyDrawer.getListByLevel();
    },
    subsidyAdd() {
      this.title = "市级补贴分类新增";
      this.$refs.drawer.open();
      this.$refs.drawer.close();
      this.$refs.drawer.ruleForm.level = 1;
    },
    deletes(singlerow) {
      // 删除
      this.$confirm("确定将选择数据删除?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store
            .dispatch("sysModule/subsidyStandarddeleteData", {
              ids: [singlerow.id],
            })
            .then((response) => {
              if (response.success) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getListByLevel();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    compile(singlerow) {
      this.title = "市级补贴分类编辑";
      this.$refs.drawer.open();
      this.$refs.drawer.ruleForm.id = singlerow.id;
      // 编辑
      Object.keys(this.$refs.drawer.ruleForm).forEach((key) => {
        this.$refs.drawer.ruleForm[key] =
          singlerow[key] || this.$refs.drawer.ruleForm[key];
      });
    },
    subsidyStandardsaveData3() {
      this.dataRefresh();
    },
    subsidyStandardsaveData4() {
      this.dataRefresh2();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";

.tabTable {
  height: 37.5rem;
  overflow: auto;
}

.countySubsidyRules {
  margin-top: 2.5rem;
}

.tabContent1 {
}

.tabContent2 {
  margin-top: 70px;
}

.form_unit {
  font-size: 15px;
  // color: #6379bb;
  border-bottom: 1px solid #ddd;
  margin: 8px 10px 25px 10px;
  padding-bottom: 5px;
}

.hl-full-container {
  height: 71rem;
}

.tableStyle1 {
  height: 30rem;
}

.tableStyle2 {
  height: 30rem;
}

.divider {
  margin: 12px 0 24px;
}

.subsidiesType {
  margin-top: 0.9375rem;
  font-size: 15px;
  // color: #6379bb;
  border-bottom: 1px solid #ddd;
  margin: 8px 10px 25px 10px;
  padding-bottom: 5px;
}

.municipalSubsidies {
  .content {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    .button {
      margin-bottom: 10px;

      .content_button {
        float: right;
      }
    }
  }

  .illustrate {
    margin-right: 3.75rem;
    border: 1px solid #ddd;
    padding: 0.625rem;
    background-color: #409eff;
    color: #ffffff;
    min-height: 3.125rem;

    .illustrate_title {
      font-size: 1rem;
      margin: 0;
      font-weight: 700;
    }

    .illustrate_text {
      display: flex;
      align-items: center;

      .text {
        font-size: 0.875rem;
        cursor: pointer;
      }

      .illustrate_button2 {
        margin: 0;
        // padding: 0.75rem 0 0.75rem 0.75rem;
      }
    }
  }
}

.meals {
  display: flex;
  justify-content: center;
  align-content: center;
}
</style>
