<template>
  <Drawer title="菜品分类管理" ref="drawerIshow" :buttonIsShow="false">
    <div>
      <!-- 查询 -->
      <div class="search">
        <el-form :model="query" ref="query" label-width="80" inline>
          <el-form-item label="助餐站点" prop="buffetId">
            <el-select v-model="query.buffetId" placeholder="请选择助餐站点">
              <el-option v-for="item in buffetList" :key="item.id" :label="item.buffetName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="mini" type="primary" icon="el-icon-search" @click="dataRefresh">查询</el-button>
            <el-button size="mini" icon="el-icon-refresh-left" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <el-button class="addButton" type="primary" size="mini" icon="el-icon-plus" @click="handleAdd">新增</el-button>
      </div>
      <!-- 表格 -->
      <hltableZ style="height: 54rem" :columnEdit="table.columnEdit" :tableColumn="table.column" :apiurl="table.apiurl"
        :refreshFlag="table.refreshFlag" :selectedRows="table.selectedRows" :isChecked="table.isChecked"
        :isNumber="table.isNumber" :isClickSelect="table.isClickSelect" :staticData="tableDataSource" :formSearch="query">
        <div slot-scope="selected" slot="EditColumn">
          <el-button icon="el-icon-edit" type="text" size="small" @click="handleEdit(selected.singlerow)">编辑</el-button>
          <el-button icon="el-icon-delete" type="text" size="small"
            @click="handleDelete(selected.singlerow)">删除</el-button>
        </div>
      </hltableZ>
    </div>
    <!-- 新增、修改详情 -->
    <classificationInfo :title="classificationInfoTitle" ref="classificationInfo" @event-name="eventName">
    </classificationInfo>
  </Drawer>
</template>

<script>
import { hltableZ, Drawer } from "@/components";
import classificationInfo from "./ClassificationInfo.vue"

export default {
  name: "ClassificationManage",
  components: { hltableZ, Drawer, classificationInfo },
  data() {
    return {
      table: {
        columnEdit: true,
        apiurl: "homePage/buffetDishesTypeGetDataList",
        column: [
          {
            label: "助餐站点",
            prop: "buffetName",
            checked: true,
            width: "150",
          },
          {
            label: "分类名称",
            prop: "type",
            checked: true,
            width: "150",
          },
          {
            label: "描述",
            prop: "describe",
            checked: true,
            width: "150",
          },
          {
            label: "排序",
            prop: "sort",
            checked: true,
            width: "150",
          },
          {
            label: "创建时间",
            prop: "createTime",
            checked: true,
            width: "150",
            formatter(value) {
              let time = new Date(value.createTime)
              let year = time.getFullYear()
              let month = time.getMonth() + 1
              let date = time.getDate()
              let hours = time.getHours()
              let minute = time.getMinutes()
              let second = time.getSeconds()

              if (month < 10) { month = '0' + month }
              if (date < 10) { date = '0' + date }
              if (hours < 10) { hours = '0' + hours }
              if (minute < 10) { minute = '0' + minute }
              if (second < 10) { second = '0' + second }
              return year + '-' + month + '-' + date + ' ' + hours + ':' + minute + ':' + second
            },
          },
        ],
        refreshFlag: "",
        selectedRows: [],
        isChecked: true,
        isNumber: false,
        isClickSelect: false,
      },
      // 表格静态数据
      tableDataSource: [
        {
          1: 1,
          2: 2,
          3: 3,
          4: 4,
          5: 5,
          imgSrc:
            "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
          status: true,
          onLine: false,
        },
      ],
      // 查询条件
      query: {
        buffetId: '',
      },
      // 详情弹窗标题
      classificationInfoTitle: '',
      buffetList: []
    };
  },

  mounted() {
    this.buffet()
  },

  methods: {
    buffet() {
      this.$store.dispatch("homePage/buffet", {}).then((res) => {
        this.buffetList = res.result
        this.$refs.memberInfo.buffetList = res.result
      })
    },
    // 查询
    dataRefresh() { this.table.refreshFlag = new Date().getTime().toString(); },
    // 重置
    reset() {
      this.$refs.query.resetFields();
    },
    // 新增
    handleAdd() {
      const th = this
      this.classificationInfoTitle = '新增菜品信息'
      this.$refs.classificationInfo.$refs.drawerIshow.drawer = true
      Object.keys(th.$refs.memberInfo.dataForm).forEach(function (key) {
        th.$refs.memberInfo.dataForm[key] = "";

      });
    },
    // 编辑
    handleEdit(e) {
      this.classificationInfoTitle = '修改菜品信息'
      this.$refs.classificationInfo.$refs.drawerIshow.drawer = true
      this.$refs.classificationInfo.getInfo(111)
      Object.keys(this.$refs.classificationInfo.dataForm).forEach(key => {

        this.$refs.classificationInfo.dataForm[key] = e[key] || this.$refs.memberInfo.dataForm[key]
      })
    },
    // 删除
    handleDelete() {
      this.$confirm("确定要删除此条消息？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => { })
        .catch(() => { });
    },
    eventName() {
      this.dataRefresh()
    }
  },
};
</script>

<style lang="scss" scoped>
// 查询条件
.search {
  position: relative;

  // 新增按钮
  .addButton {
    position: absolute;
    right: .625rem;
    top: .625rem;
  }
}
</style>
