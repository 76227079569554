<template>
  <Drawer :title="title" ref="drawerIshow" :buttonIsShow="true" @resetForm="resetForm" submitFormName="提交"
    @submitForm="submitForm">
    <subTitle information-name="基本信息"></subTitle>
    <el-row :gutter="24">
      <el-form class="form" ref="form" :model="dataForm" label-width="140px">
        <el-col :span="12">
          <el-form-item label="助餐站点" prop="buffetId">
            <el-select v-model="dataForm.buffetId" placeholder="请选择助餐站点" style="width: 100%">
              <el-option v-for="item in buffetList" :key="item.id" :label="item.buffetName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="菜品分类" prop="typeId">
            <el-select v-model="dataForm.typeId" placeholder="请选择" style="width: 100%">
              <el-option v-for="item in getLists" :key="item.id" :label="item.type" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="允许在线自助点餐" prop="onLine">
            <el-select v-model="dataForm.onLine" placeholder="请选择" style="width: 100%">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :span="12">
          <el-form-item label="菜品名称" prop="dishesName">
            <el-input v-model="dataForm.dishesName" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="价格" prop="money">
            <el-input-number :controls="false" :min="0" :precision="2" v-model="dataForm.money" placeholder="请输入"
              show-word-limit style="width: 100%" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="菜品类型" prop="type">
            <el-select v-model="dataForm.type" placeholder="请选择" style="width: 100%">
              <el-option label="单一菜品" :value="1"></el-option>
              <el-option label="组合菜品" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="菜品照片">
            <img v-show="fileStoreEntity2.length !== 0" v-for="(item, index) in fileStoreEntity2" :key="index" :src="item"
              class="avatar" style="width:9rem;height:9rem" />
            <el-upload class="avatar-uploader" :headers="token" :action="xlsAreaAction" :show-file-list="false"
              :on-success="handlerImgUpload">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </Drawer>
</template>

<script>
import { Drawer } from "@/components";
import subTitle from "../common/subTitle.vue";

export default {
  name: "DishesInfo",
  components: { Drawer, subTitle },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 助餐站点options
      mealLocationOptions: [

      ],
      // form字段
      dataForm: {
        buffetId: "",
        typeId: "",
        dishesName: '',
        money: "",
        type: "",
        picture: "",
        id: ''
      },
      buffetList: [],
      getLists: [],
      xlsAreaAction: "/api/fileStore/upload.json",
      token: { token: localStorage.getItem("systoken") },
      fileStoreEntity2: [],
    };
  },

  mounted() {
    this.buffet()
    this.getList()
  },

  methods: {
    buffet() {
      this.$store.dispatch("homePage/buffet", {}).then((res) => {
        this.buffetList = res.result
        // this.$refs.memberInfo.buffetList = res.result
      })
    },
    getList() {
      this.$store.dispatch("homePage/buffetDishesTypeGetList", {}).then((res) => {
        this.getLists = res.result
        // this.$refs.memberInfo.buffetList = res.result
      })
    },

    // 根部id获取详情
    getInfo(id) {
      console.log("id", id);
    },
    // 上传图片
    handlerImgUpload(res, file) {
      this.dataForm.picture += res
      this.fileStoreEntity2.push(file.name)
    },
    // 重置form
    resetForm() {
      this.$refs.form.resetFields();
    },
    submitForm() {
      let formDatas = new FormData();
      formDatas.append('buffetId', this.dataForm.buffetId)
      formDatas.append('typeId', this.dataForm.typeId)
      formDatas.append('dishesName', this.dataForm.dishesName)
      formDatas.append('money', this.dataForm.money)
      formDatas.append('type', this.dataForm.type)
      // formDatas.append('createTime', this.dataForm.createTime)
      formDatas.append('picture', this.dataForm.picture)
      formDatas.append('id', this.dataForm.id)
      this.$store.dispatch("homePage/buffetDishesSaveData2", formDatas).then((res) => {
        if (res.success == true) {
          this.$refs.drawerIshow.drawer = false;
          this.$emit('event-name');

        } else {
          this.$message.error(res.message)
        }

      })
    }
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 100%;

  // 图片缩略图样式
  .avatar-uploader {
    display: inline-block;
    text-align: center;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    cursor: pointer;
    line-height: 146px;
    vertical-align: top;

    .avatar {
      width: 148px;
      height: 148px;
    }

    i {
      font-size: 28px;
      color: #8c939d;
    }
  }
}
</style>
